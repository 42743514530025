import axios from 'axios';
import { appConfig } from '@/config/appConfig';


axios.defaults.withCredentials = appConfig('auth.setCookies');

const BASE_API_URL = appConfig('plataformaAliare.apiURL');

export const defaultAliareApi = axios.create({
	baseURL: `${BASE_API_URL}`,
	headers: {
		'Content-Type': 'application/json',
	},
});
