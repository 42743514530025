
import { ATTACHED_WORKFLOW_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import moment from 'moment';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'AttachedWorkflowModal',
	components: {
		Modal,
		ModalButtonCancel,
		ModalButtonSuccess,
		ScrollInfinite,
	},
	data() {
		return {
			modalBus: new Vue(),
			ATTACHED_WORKFLOW_MODAL_KEY,
			isLoadingTable: false,
			isLoadingPage: false,
			infoMessageModal: '<div class="loading"><div>',
			dataAttachedWorkflow: [],
			scrollInfiniteContinue: false,
			page: 1,
		};
	},
	computed: {
		workflowId() {
			return this.$store.getters.workflowDataId;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async mounted () {
		this.getAttachedWorkflow();
	},
	created() {
	},
	methods: {
		redirectWorkflowExisting(workflowId) {
			this.modalBus.$emit('close');
			this.$router.push({
				name: 'NodeViewExisting',
				params: { name: workflowId, mode: 'Visualizar' },
			});
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		closeDialog() {
			this.$store.commit('setWorkflowDataId', null);
		},
		async getAttachedWorkflow() {
			const payload = {
				page: this.page,
			};

			await this.restApi().attachedWorkflows(this.workspace.id, this.workflowId, payload).then((response) => {
				this.dataAttachedWorkflow.push(...response);
				this.page++;

				if (this.dataAttachedWorkflow.lenght == undefined) {
					this.infoMessageModal = 'Não há Fluxos Anexados';
				}

				if (response.length == 10) {
					this.scrollInfiniteContinue = true;
				} else {
					this.scrollInfiniteContinue = false;
				}
			});
		},
	},
});
