<template>
	<div class="filter-table" v-click-outside="clickOutside">
		<span v-if="selectedValue" class="filter-text">
			{{ selectedValue }}
		</span>

		<i class="filter-icon" @click="activeFilter = !activeFilter"></i>
		<div v-show="activeFilter">
			<slot name="filter"></slot>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		selectedValue: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			activeFilter: false,
		};
	},
	methods: {
		clickOutside() {
			this.activeFilter = false;
		},
	},
};
</script>

<style lang="scss" scoped>

.filter-table {
	margin-left: 10px;
	display: flex;
	position: relative;
}
.filter-icon {
	background: url('./../assets/arrowDownIconGray.svg') center no-repeat;
	background-size: 16px;
	width: 28px;
	height: 28px;
	cursor: pointer;
	display: block;
	border-radius: 4px;
	transition: all .5s;
	position: relative;
}

.filter-text {
	color: #fff
}

</style>
