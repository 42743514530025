
import {
	TIME_RANGE,
} from '@/constants';

export default {
	props: {
		selectedTime: {
			type: String,
			default: '00:00',
		},
		disabled: {
			type: Boolean,
		},
	},
  emits: ['update:selectedTime'],
	data() {
		return {
			timeRange: TIME_RANGE,
			showTimePicker: false,
		};
	},
	methods: {
		hideTimePicker() {
			this.showTimePicker = false;
		},
		valueChanged(value) {
			this.$emit('update:selectedTime', value);
			this.showTimePicker = false;
		},
	},
};
