

import { genericHelpers } from '@/components/mixins/genericHelpers';
import { INodeUi } from '@/Interface';

import mixins from 'vue-typed-mixins';

export default mixins(genericHelpers).extend({
	name: 'DisplayWithChange',
	props: {
		keyName: String,
	},
	computed: {
		node (): INodeUi {
			return this.$store.getters.activeNode;
		},
		nodeType(): INodeTypeDescription | null {
			if (this.node) {
				return this.$store.getters.nodeType(this.node.type, this.node.typeVersion);
			}

			return null;
		},
		nodeTypeName(): string {
			if (this.nodeType) {
				const shortNodeType = this.$locale.shortNodeType(this.nodeType.name);
				return this.$locale.headerText({
					key: `headers.${shortNodeType}.displayName`,
					fallback: this.nodeType.name,
				});
			}

			return '';
		},
		currentValue (): string {
			const getDescendantProp = (obj: object, path: string): string => {
				// @ts-ignore
				return path.split('.').reduce((acc, part) => acc && acc[part], obj);
			};

			if (this.keyName === 'name' && this.node.type.startsWith('n8n-nodes-base.')) {
				const shortNodeType = this.$locale.shortNodeType(this.node.type);

				return this.$locale.headerText({
					key: `headers.${shortNodeType}.displayName`,
					fallback: getDescendantProp(this.node, this.keyName),
				});
			}

			return getDescendantProp(this.node, this.keyName);
		},
	},
	watch: {
		currentValue (val) {
			// Deactivate when the data to edit changes
			// (like when a different node gets selected)
			this.editActive = false;
		},
	},
	data: () => {
		return {
			editActive: false,
			newValue: '',
		};
	},
	methods: {
		noOp () {},
		startEdit () {
			if (this.isReadOnly === true) {
				return;
			}
			this.editActive = true;
			this.newValue = this.currentValue;

			setTimeout(() => {
				(this.$refs.inputField as HTMLInputElement).focus();
			});
		},
		cancelEdit () {
			this.editActive = false;
		},
		openConfigNode() {
			this.$emit('openNodeSettings');
		},
		setValue () {
			const sendData = {
				value: this.newValue,
				name: this.keyName,
			};

			this.$emit('valueChanged', sendData);
			this.editActive = false;
		},
	},
});
