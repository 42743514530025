import { searchPermissions, verifyPermissionDescription, hasPermissionByDescription } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const executionQueuePermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			permission: '',
			manageExecution: '',
		};
	},

	async created() {
		await this.validationAccessExecutionQueue();
	},

	computed: {
		stopExecutionPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.stopExecution, this.manageExecution?.childrens);
		},
	},

	methods: {
		async validationAccessExecutionQueue() {
			this.permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.manageExecution = verifyPermissionDescription(this.PERMISSION_IDS?.manageExecutionQueue, this.permission?.access);

			if (!this.manageExecution) {
				this.$router.push({
					name: 'workspace',
				});

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			}
		},

		canStopExecution(execution) {
			if (execution) {
				return this.stopExecutionPermission && execution.status !== 'Parando';
			}
			return this.stopExecutionPermission;
		},
	},
});
