import { searchPermissions, verifyPermissionDescription, hasPermissionByDescription, verifyAdminUser } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const userProfilePermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			teams: '',
			dataUserProfile: '',
			isAdmin: '',
		};
	},

	async created() {
		await this.validationAccessUserProfile();
	},

	computed: {
		manageUserProfilePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageUserProfileId, this.teams?.childrens);
		},

		registerUserProfilePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.registerUserProfileId, this.dataUserProfile?.childrens);
		},
		editUserProfilePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editUserProfileId, this.dataUserProfile?.childrens);
		},
		activeInactiveUserProfilePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.activeInactiveUserProfileId, this.dataUserProfile?.childrens);
		},
	},

	methods: {
		async validationAccessUserProfile() {
			this.isAdmin = await verifyAdminUser();
			const permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.teams = verifyPermissionDescription(this.PERMISSION_IDS?.manageTeamsId, permission?.access);
			this.dataUserProfile = verifyPermissionDescription(this.PERMISSION_IDS?.manageUserProfileId, this.teams?.childrens);

			if (!this.isAdmin || (!this.teams && !this.dataUserProfile)) {
				this.$router.push({
					name: 'workspace',
				});

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			} else if	(!this.dataUserProfile
				&& (this.$route.name == 'createProfile' || this.$route.name == 'profileDetailing' || this.$route.name == 'editProfile'))
			{
				this.$router.push({
					name: 'user-profiles',
				});
			}
		},

		canViewDetailUserProfile() {
			return this.manageUserProfilePermission && this.isAdmin;
		},

		canViewRegisterUserProfile() {
			return this.registerUserProfilePermission && this.isAdmin;
		},
		canViewEditUserProfile() {
			return this.editUserProfilePermission && this.isAdmin;
		},
		canViewActiveInactiveUserProfile() {
			return this.activeInactiveUserProfilePermission && this.isAdmin;
		},
	},
});
