<template>
	<div class="optionFilterItem" @click="selectedOption">
		<div class="inputCheckbox"  :class="{active: activeOption}"></div>
		<p>{{ $props.title }}</p>
	</div>
</template>

<script>
import mixins from 'vue-typed-mixins';

import { restApi } from '@/components/mixins/restApi';

export default mixins(
	restApi,
).extend({
	name: "OptionFilter",
	data() {
		return {
			activeOption: false,
		};
	},
	props: {
		value: {
			type: String,
		},
		title: {
			type: String,
		},
		selected: {
			type: [],
		},
	},
	mounted() {
		this.verifyIsSelected();
	},
	methods:{
		selectedOption() {
			this.activeOption = !this.activeOption;
			this.$emit('click', this.value);
		},
		verifyIsSelected() {
			this.selected.forEach(el => {
				if (el.id === this.value) {
					this.activeOption = true;
				}
			});
		},
	},
});
</script>

<style scoped lang="scss">
.optionFilterItem {
	display: flex;
	align-items: center;
	column-gap: 15px;

	p {
		line-height: 1;
		font-weight: 400;
		color:rgba(255, 255, 255, 0.88);
	}
	.inputCheckbox {
		width: 18px;
		height: 18px;
		border: 2px solid #749BDE;
		flex-shrink: 0;
		&.active {
			border: none;
			background-image: url('../assets/checkIconBlue.svg');
			background-position: center;
			background-size: 18px 18px;
			background-repeat: no-repeat;
		}
	}
}
</style>
