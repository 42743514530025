/* eslint-disable no-unused-vars */
import { appConfig } from "@/config/appConfig";
import { authorizationApi } from "@/api/internalApis";
import { UserManagerSettings } from 'oidc-client-ts';
import { UserManager, WebStorageStateStore, User, Log } from 'oidc-client-ts';
import PKCE from "@/common/Crypto";
import TokenService from "@/services/token.service";
import WebStorage from "@/common/WebStorage";

class OidcService {

	constructor() {
		const pkce = WebStorage.getItem(PKCE.storageKey);

		this.settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
	  authority: appConfig('plataformaAliare.authorityURL'),
			client_id: appConfig('auth.client_id'),  // aliare-integra
			client_secret: appConfig('auth.client_id'),  // aliare-integra
			post_logout_redirect_uri: `${appConfig('host')}` + '/login',
      redirect_uri: `${appConfig('host')}` + '/auth/sso',
      response_type: 'code',
			response_mode: 'query',
			loadUserInfo: true,
			automaticSilentRenew: false,
			includeIdTokenInSilentRenew: true,
			revokeTokensOnSignout: true,
			revokeTokenTypes: ['access_token'],
			scope: 'notificationHub aliare-backoffice aliare-integra openid profile offline_access',
    };


		this.userManager = new UserManager(this.settings);
		this.code_challenge = pkce?.code_challenge;
		this.code_verifier = pkce?.code_verifier;

    Log.setLogger(console);
    Log.setLevel(Log.INFO);


		this.accessTokenUrl = 'connect/token';
		this.revokeAccessUrl = 'connect/revocation';
		this.redirectPathUrl = [appConfig('plataformaAliare.authorityURL'), 'connect', 'authorize'].join('/');
	}

	async getUser() {
    return this.userManager.getUser();
  }

  signinRedirect() {
    return this.userManager.signinRedirect();
  }
	signinRedirectCallback() {
    return this.userManager.signinRedirectCallback();
  }
	async signinSilent() {
    const result = await this.userManager.signinSilent();
		return result;
	}

	async signinSilentCallback() {
    const result = await this.userManager.signinSilentCallback();
	}
  signinCallback = () => {
    return this.userManager.signinCallback();
  }

	async signoutRedirect(args) {
    return await this.userManager.signoutRedirect(args);
  }

	revokeTokens() {
    return this.userManager.revokeTokens();
  }

	clearStaleState() {
    return this.userManager.clearStaleState();
  }

  getAccessToken = () => {
    return this.userManager.getUser()?.then((data) => {
      return data ? data.access_token : null;
    });
  }

	async authenticateOidc() {
		const commonParams = {
			client_id: appConfig('auth.client_id'),
			grant_type: appConfig('auth.grant_type'),
			redirect_uri: appConfig('auth.redirect_uri'),
		};
		const grantTypeParams = appConfig('auth.grant_type') === `authorization_code` ?
			this.authorizationCodeParams() : this.clientCredentialParams();

		const requestParams = new URLSearchParams({ ...commonParams, ...grantTypeParams });
		const response = await authorizationApi.post("/connect/token", requestParams);

		if (response?.data?.accessToken) TokenService.setTokens(response.data);

		return response;
	}

	authorizationCodeParams() {
		return {
			code: this.authorizationCode,
			code_verifier: this.code_verifier,
		};
	}

	clientCredentialParams() {
		return {
			client_secret: appConfig('auth.client_secret'),
			scope: appConfig('auth.scope'),
		};
	}

	dropAuthorizationCode() {
		this.authorizationCode = null;
	}

	getAuthorizationCode() {
		return this.authorizationCode;
	}

	redirectToLoginPage() {
		const params = {
			client_id: appConfig('auth.client_id'),
			redirect_uri: appConfig('auth.redirect_uri'),
			response_type: appConfig('auth.response_type'),
			scope: appConfig('auth.scope'),
			code_challenge: this.code_challenge,
			code_challenge_method: appConfig('auth.code_challenge_method'),
		};

		const searchParams = new URLSearchParams(params);
		const redirectURL = [this.redirectPath, searchParams].join('?');

		window.location.href = redirectURL;
	}

	async revokeAccess(accessToken) {

		const requestParams = new URLSearchParams({
			client_id: appConfig('auth.client_id'),
			client_secret: appConfig('auth.client_secret'),
			token: accessToken,
			token_type_hint: 'access_token',
		});

		this.dropAuthorizationCode();
		TokenService.removeTokens();

		return await authorizationApi.post('connect/revocation', requestParams);
	}

	setAuthorizationCode(code) {
		this.authorizationCode = code;
	}
}

export default new OidcService();
