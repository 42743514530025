import axios from 'axios';
import { appConfig } from '@/config/appConfig';
import WebStorage from '@/common/WebStorage';
import { defaultApi } from '@/api/internalApis';


axios.defaults.withCredentials = appConfig('auth.setCookies');

class WorkspaceService {
    async getWorkspaceById(workspaceId) {
        const { data } = await defaultApi.get(`/Workspaces/${workspaceId}`);
        return data;
    }
}

export default new WorkspaceService();
