
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import WebStorage from '@/common/WebStorage';
import TokenService from '@/services/token.service';
import { restApi } from '@/components/mixins/restApi';
import { initializeFirebaseMessaging, getMessageFromDb } from '@/components/helpers';
import moment from 'moment';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import mixins from 'vue-typed-mixins';


export default mixins(
	restApi,
).extend({
	name: 'NotificationPanel',

	props: {
	},
	components: {
		AlertNotificationModal,
		ModalButtonSuccess,
		ModalButtonCancel,
	},
	computed: {
		alertNotificationTitle() {
			return `Acessar ${this.activeNotification.TipoObjeto}`;
		},
		suffix() {
			if (this.activeNotification.tipoObjeto == 'credencial' || this.activeNotification.tipoObjeto == 'equivalencia') {
				return 'a';
			} else {
				return 'o';
			}
		},
		suffix2(){
			if (this.activeNotification.tipoObjeto == 'credencial' || this.activeNotification.tipoObjeto == 'equivalencia') {
				return 'a';
			} else {
				return 'e';
			}
		},
		alertNotificationDescription() {
			return `Est${this.suffix2}  ${this.activeNotification.TipoObjeto} está armazenado em outro espaço de trabalho. Ao confirmar você será mudado para o espaço de trabalho "${this.activeNotification.workspaceData.nome}" e redirecionado para o detalhamento d${this.suffix} ${this.activeNotification.TipoObjeto}`;
		},
		notificationApproved() {
			return Notification.permission === 'granted';
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	data() {
		return {
			showAlert: false,
			activeNotification: '',
			openNotificationVariable: false,
			dataMessage: {},
			dataMessageOld: {},
			notificationPermissionData: false,
		};
	},
	mounted() {
		window.addEventListener('localstorage-changed-old', (event) => {
			this.dataMessageOld = JSON.parse(event.detail.storage);
		});
		window.addEventListener('localstorage-changed-new', (event) => {
			this.dataMessage = JSON.parse(event.detail.storage);
		});
		window.addEventListener('notificationPermissionChanged', (event) => {
			this.notificationPermissionData = true;
		});

		this.$root.$on('toggleNotification',async () => {
			this.openNotificationVariable = !this.openNotificationVariable;
			this.$emit('notificationOpen', this.openNotificationVariable);

			if (!this.openNotificationVariable && this.dataMessage.length > 0) {
				for await (const message of this.dataMessage) {
					const vm = this;

					const result = await fetch(`https://notification-hub.uat.conexa.com.br/api/Push/Lida/${message?.id || message?.messageId}`, {
						method: 'POST',
						headers: {
							'Content-Type': "application/json",
							Authorization: `Bearer ${TokenService.getTokens().access_token}`,
							'X-Tenant': WebStorage.getItem("tenantId"),
						},
					});
				}

				this.dataMessage = {};
				getMessageFromDb();
			}
		});
	},
	methods: {
		messageContentWithUrl(message) {
			let data = message?.content.match(/<div>([^<]*)<\/div>/);

			if (!data || !data[1]) return message?.content;


			let string = '{' + data[1]  +'}';
			message.data = JSON.parse(string);
			return message.content;
		},

		closeNotification() {
			this.$root.$emit("toggleNotification");
		},
		formatDate(date) {
			let now = moment(new Date()); //todays date
			let end = moment(date); // another date
			let duration = moment.duration(now.diff(end));

			let months = parseInt(duration.asMonths());
			let weeks = parseInt(duration.asWeeks());
			let days = parseInt(duration.asDays());
			let hours = parseInt(duration.asHours());
			let minute = parseInt(duration.asMinutes());

			if (months <= 0) {
				if (weeks <= 0) {
					if (days <= 0) {
						if (hours <= 0) {
							if (minute <= 0) {
								return 'Agora';
							}
							return minute + ' min';
						}
						return hours + ' h';
					}
					return days + ' d';
				};
				return weeks + ' sem';
			}
			return months + ' m';

		},
		openPermission() {
			initializeFirebaseMessaging();
			//openPermissionNotification();
		},

		async openAlert(message) {
			const workspaceOfObject = await this.restApi().getWorkspaceById(message.workspaceId);

			this.activeNotification = message;
			this.activeNotification.workspaceData = workspaceOfObject;

			if (message.workspaceId == this.workspace.id) {
				this.goToNotification();
			} else {
				this.showAlert = true;
			}
		},
		goToNotification() {
			WebStorage.setItem('workspace', JSON.stringify(this.activeNotification.workspaceData));
			this.$store.commit('setWorkspace', this.activeNotification.workspaceData);

			let urlToGo = '';
			if (this.activeNotification.TipoObjeto == 'Credencial') {
				urlToGo = 'credentialDetailing';
			} else if (this.activeNotification.TipoObjeto == 'Projeto') {
				urlToGo = 'projectDetailing';
			} else if (this.activeNotification.TipoObjeto == 'Equivalencia') {
				urlToGo = 'equivalencesDetailing';
			} else if (this.activeNotification.TipoObjeto == 'Fluxo') {
				urlToGo = 'equivalencesDetailing';
			}

			this.showAlert = false;

			this.$router.push({
				name: urlToGo,
				params: { id: this.activeNotification.objetoId },
			}).catch(()=>{});
		},
	},
});
