<template>
	<Transition name="modal">
		<div v-if="show" class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container" :style="modal">
					<div class="modal-header" :style="header" >
						<slot name="header">default header</slot>
					</div>

					<div class="modal-body" :style="body">
						<slot name="body">default body</slot>
					</div>

					<div class="modal-footer" :style="footer">
						<slot name="footer">
							default footer
						</slot>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
export default {
	props: {
		show: Boolean,
		width_container: String,
		height_container: String,
		background_header: String,
		borderBottom_header: String,
		background_body: String,
		border_container: String,
		shadow_container: String,
		background_footer: String,
		display_footer: String,
	},
	data() {
		return {
			modal: `width:${this.width_container}; height:${this.height_container}; box-shadow:${this.shadow_container} !important; border: ${this.border_container} !important;`,
			header: `background-color:${this.background_header}; border-bottom:${this.borderBottom_header} !important`,
			body: `background-color:${this.background_body} !important;`,
			footer: `background-color:${this.background_footer} !important; display:${this.display_footer} !important`,
		};
	},
};
</script>

<style lang="scss">
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	margin-top: 8px;
	vertical-align: middle;
}

.modal-container {
	width: 520px ;
	height: 288px;
	margin: 0px auto;
	background-color: #1c2638;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;

	.modal-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 16px;
		background-color: #233149;
		color: rgba(255, 255, 255, 0.88);
		border-bottom: 1px solid #34476a;
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
	}

}

.modal-header h3 {
	margin-bottom: 0px;
}

.modal-body {
	margin: 0px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.modal-default-save {
	width: 97px;
	height: 40px;
	border-color: transparent;
	background-color: rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	color: rgba(255, 255, 255, 0.4);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 16px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: capitalize;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-container {
	.modal-footer {
		padding: 17px;
		column-gap: 16px;
	}
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
