<template>
	<div
		v-show="showAlert"
		class="alertDanger"
		v-bind:class="{ alertDangerSuccess: alertSuccess, alertDangerAlert: alertAlert, alertDangerError: alertError }"
	>
		<div class="alertDangerPosition">
			<div :class="{ alertSuccessIcon: alertSuccess, alertDangerIcon: alertAlert, alertDangerError: alertError }"></div>
			<div class="text">
				<slot></slot>
			</div>
			<div class="alertLine"></div>
		</div>
		<div>
			<div class="alertDangerCloseIcon" @click="showModalAlert"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Alert',
	props: {
		show: String,
		success: Boolean,
		alert: Boolean,
		error: Boolean,
	},
	data() {
		return {
			showAlert: true,
			alertSuccess: this.success,
			alertAlert: this.alert,
			alertError: this.error,
		};
	},
	methods:{
		showModalAlert(){
			this.showAlert = false;
			setTimeout(() => {
				this.showAlert = true;
			}, 5000);
		},
	},
};
</script>

<style scoped lang="scss">
.alertDangerSuccess {
	background-color: #0e6a1d !important;
	border-color: #0e6a1d !important;
}
.alertDangerAlert {
	background-color: #A94E0D !important;
	border-color: #A94E0D !important;
}
.alertDangerError {
	background-color: #9d2020 !important;
	border-color: #9d2020 !important;
}
.alertDangerPosition{
	display: flex;
	align-items: center;
	flex: auto;
}
.alertDanger {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	top: 20px;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	color: #ffffff;
	border-radius: 8px;
	padding: 16px;
	z-index: 100000000;;

	.text {
		padding-right: 10px;
		width: 100%;
		border-right: 1px solid rgba(255, 255, 255, 0.32);
	}

	.alertDangerIcon {
		min-width: 20px;
		height: 20px;
		background-image: url('../assets/roundWhiteExclamation.svg');
		background-repeat: no-repeat;
		margin-right: 16px;
	}
	.alertSuccessIcon {
		min-width: 20px;
		height: 20px;
		background-image: url('../assets/checkIconAlert.svg');
		background-repeat: no-repeat;
		margin-right: 16px;
	}

	.alertDangerCloseIcon {
		background-image: url('../assets/closeIcon.svg');
		background-repeat: no-repeat;
		height: 20px;
		min-width: 20px;
		cursor: pointer;
	}
}
.alertLine {
	width: 1px;
	height: 40px;
	margin-right: 18px;
	background-color: rgba(255, 255, 255, 0.32);
}
</style>
