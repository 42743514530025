import VariableService from '../services/variable.service';

export default {
	namespaced: true,

	state: {},

	getters: {},

	actions: {
		async getVariableId({ commit }, id) {
			try {
				const { data } = await VariableService.getVariableId(id);
				commit('setVariableId', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
		async patchVariableId({ commit }, result) {
			try {
				const { data } = await VariableService.patchVariavleId(result.workspaceId, result.id, result.workflowId, result.name, result.valor);
				commit('setPatchVariableId', { data });
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return error;
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
		async deleteVariableId({ commit }, payload) {
			try {
				const { data } = await VariableService.deleteVariableId(payload);
				commit('setDeleteVariableId', { data });
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return error;
			}
		},
		async postVariable({ commit }, post) {
			try {
				const { data } = await VariableService.postVariable(post);
				commit('setPatchVariableId', { data });
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				throw error;
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
	},

	mutations: {
		setVariableId(state, { variableId }) {
			state.variableId = variableId;
		},
		setPatchVariableId(state, { patchVariableId }) {
			state.patchVariableId = patchVariableId;
		},
		setDeleteVariableId(state, { deleteVariableId }) {
			state.deleteVariableId = deleteVariableId;
		},
	},
}
