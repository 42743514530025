
import Vue from 'vue';
import Modals from '@/components/Modals.vue';
import SelectItemsComponent from '@/components/SelectItemsComponent.vue';
import Telemetry from './components/Telemetry.vue';
import AlertAliare from '@/components/AlertAliare.vue';
import LoadingCredentials from '@/components/LoadingCredentials.vue';
import Page403 from '@/components/403.vue';
import SearchBar from '@/components/SearchBar/SearchBarFloat.vue';
import { mapGetters, mapState } from 'vuex';


export default Vue.extend({
	name: 'App',
	components: {
		SelectItemsComponent,
		Telemetry,
		Modals,
		AlertAliare,
		LoadingCredentials,
		Page403,
		SearchBar,
	},
	data() {
		return {
			renderComponent: true,
		}
	},
	methods: {
		async forceRerender() {
			this.renderComponent = false;

			await Vue.nextTick();

			this.renderComponent = true;
    },
	},
	async mounted() {
		this.$root.$on('reloadNodeView', () => {
			this.forceRerender();
		});
	},
	watch: {
		'$route'(route) {
			this.$telemetry.page('Editor', route.name);
		},
	},
	computed:{
		...mapState(['data403']),
		...mapGetters('ui', ['sidebarMenuCollapsed']),
	},
});
