import { encodeToBase64, decodeFromBase64 } from '@/common/Crypto';

class WebStorage {
	constructor() {
		this.localStorage = window.localStorage;
	}

	getItem(key) {
		const encodedKey = encodeToBase64(key);
		const encodedContent = localStorage.getItem(encodedKey);

		return encodedContent ? JSON.parse(decodeFromBase64(encodedContent)) : null;
	}

	setItem(key, content) {
		const encodedKey = encodeToBase64(key);
		const encodedContent = encodeToBase64(JSON.stringify(content));

		localStorage.setItem(encodedKey, encodedContent);
	}

	removeItem(key) {
		this.localStorage.removeItem(encodeToBase64(key));
	}
	saveNewNotificationToLocalStorage(data) {
		if (!data) return;

		localStorage.setItem('newNotificationData', JSON.stringify(data));
		window.dispatchEvent(new CustomEvent('localstorage-changed-new', {
			detail: {
				storage: localStorage.getItem('newNotificationData'),
				count: data.length,
			},
		}));
	}

	changeAccountName(name) {
		if (!name) return;

		localStorage.setItem('accountName', JSON.stringify(name));
		window.dispatchEvent(new CustomEvent('account-name-change', {
			detail: {
				storage: localStorage.getItem('accountName'),
			},
		}));

	}
	saveOldNotificationToLocalStorage(data) {
		if (!data) return;

		localStorage.setItem('oldNotification', JSON.stringify(data));
		window.dispatchEvent(new CustomEvent('localstorage-changed-old', {
			detail: {
				storage: localStorage.getItem('oldNotification'),
			},
		}));
	}
}

export default new WebStorage();
