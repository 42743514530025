import ProjectService from '../services/project.service';

export default {
	namespaced: true,

	state: {},

	getters: {},

	actions: {
		async patch({ commit }, result) {
			try {
				const { data } = await ProjectService.patch(result.workspaceId, result.id, result.name, result.active);
				console.log(data);
				commit('setPatch', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao alterar o projeto.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
		async patchActive({ commit }, payload) {
			try {
				const { data } = await ProjectService.patchActive(payload);
				console.log(data);
				commit('setPatch', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao atualizar atividade.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
		async delete({ commit }, payload) {
			try {
				const { data } = await ProjectService.delete(payload);
				console.log(data);
				commit('setDelete', { data });
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
		async getProjectDetailsId({ commit }, payload) {
			try {
				const { data } = await ProjectService.getProjectDetailsId(payload);
				commit('setProjectDetailsId', { data });
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},
		async getProjectDetails({ commit }, payload) {
			try {
				const { data } = await ProjectService.getProjectDetails(payload);
				commit('setProjectDetails', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},

		async getProjectTotalizers({ commit }, payload) {
			try {
				const { data } = await ProjectService.getProjectTotalizers(payload);
				commit('setProjectTotalizers', { data });
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},
		async getProjectFlow({ commit }, project) {
			try {
				const { data } = await ProjectService.getProjectFlow(project);
				console.log(data);
				commit('setProjectFlow', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},
		async postDuplicateProjectId({ commit }, id) {
			try {
				const { data } = await ProjectService.postDuplicateProjectId(id);
				console.log(data);
				commit('setPost', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao duplicar projeto.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},
		async postProject({ commit }, post) {
			try {
				const { data } = await ProjectService.postProject(post);
				commit('setPost', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
				return Promise.reject(error);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},
	},

	mutations: {
		setGet(state, { id }) {
			state.get = id;
		},
		setProjectDetails(state, { projectDetails }) {
			state.projectDetails = projectDetails;
		},
		setProjectTotalizers(state, { projectTotalizers }) {
			state.projectTotalizers = projectTotalizers;
		},
		setPatch(state, { id }) {
			state.patch = id;
		},
		setDelete(state, { id }) {
			state.delete = id;
		},
		setProjectDetailsId(state, { projectDetailsId }) {
			state.projectDetailsId = projectDetailsId;
		},
		setProjectFlow(state, { projecFlow }) {
			state.projecFlow = projecFlow;
		},
		setPost(state, { post }) {
			state.post = post;
		},
	},
};
