
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { removeItemFromArrayByValue } from '@/components/helpers';
import { equivalencePermission } from '@/components/mixins/permissions/equivalences';

import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import Table from '@/components/Table.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import FilterTable from '@/components/FilterTable.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { previousRoute } from '../router/index.ts';

export default mixins(
	nodeHelpers,
	showMessage,
	equivalencePermission,
).extend({
	components: {
		Table,
		FilterBar,
		FilterIcon,
		FilterTable,
		SelectFilter,
		BackgroundView,
		InteractionMenu,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	name: 'DataSource',
	data() {
		return {
			activeDataCenter: '',
			showAlert: false,
			interactionMenu__link: 'createDataCenter',
			maxheight: '60vh',
			interactionMenu__headerPlaceHolderSearch:
				'credentials.interactionMenu__headerPlaceHolderSearch',
			interactionMenu__buttonToCleanFilter: 'credentials.interactionMenu__buttonToCleanFilter',

			page: 1,
			pageSize: 10,
			filtering: false,
			scrollInfinite: true,
			isLoadingTable: true,
			dataSourceList: [],
			dataSourceListTemp: [],

			dataTableFilteredByName: [],
			dataTableFilteredByStatus: [],
			applyedFilters: [],
			dataFilter:[],
			lastFilterResult: '',

			filtersActive: {},
			clearfiltering: false,
			isLoadingAction: false,
			previousRoute: previousRoute,
			queryStatus: this.$route.query.ativo || [],
			statusOptions: [
				{ label: 'Ativo', value: true },
        { label: 'Inativo', value: false }
      ],
		};
	},
	computed: {
		alertNotificationTitle() {
			return 'Exclusão da Fonte de Dados';
		},
		alertNotificationDescription() {
			return `Excluir a fonte <b>${this.activeDataCenter.titulo}</b> apagará todas as parametrizações feitas e você não poderá recuperá-las. O que deseja fazer?`;
		},
		resultTextStatus() {
			const statusMap = {
				true: 'Ativo',
				false: 'Inativo'
			};
			return this.queryStatus.length ? (statusMap[this.queryStatus] || this.queryStatus) : 'Status';
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async created() {
		await this.initPageDataSource();
	},
	methods: {
		async initPageDataSource() {
			this.isLoadingTable = true;
			const applyPage = WebStorage.getItem('applyPage');

			if (this.previousRoute.name == 'dataCenterDetailing' && applyPage == 'dataSource') {
				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});

				this.queryStatus =  this.$route.query?.ativo;
			};

			if(Object.keys(this.$route.query).length) {
				this.filterTable();

			} else {
				await this.loadTableDataSource();
			}
			this.isLoadingTable = false;
		},

		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async filterByStatus(query) {
			this.queryStatus = !query ? 'Inativo' : 'Ativo';
			this.addOrRemoveFilter(this.filtersActive, 'filterStatus', query);
			this.filtering = !this.queryStatus.length;
		},

		async filterTable(filters) {
			try {
				this.isLoadingTable = true;
				const routeQuery = this.$route.query;
				let isFilterStatusActive;

				if (filters && filters.filterStatus !== undefined) {
					isFilterStatusActive = filters.filterStatus;
				} else if (routeQuery?.ativo) {
					isFilterStatusActive = routeQuery?.ativo == "true";
				}

				const payload = {
					size: 10,
					searchTitulo: filters?.filterSearch || routeQuery?.searchTitulo,
					ativo: isFilterStatusActive,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getDataCenter(this.workspace.id, payload);
				await this.applyFilter(dataFilter, payload);

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});

				this.clearFilter();
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, filters) {
			this.filtering = true;
			this.clearfiltering = true;
			this.dataSourceList = dataFiltered;
			this.$store.commit('setFilters', filters);
			WebStorage.setItem('applyPage', 'dataSource');
		},

		async clearFilter() {
			this.filtering = false;
			this.queryStatus = [];
			this.filtersActive = {};
			this.scrollInfinite = true;
			this.clearfiltering = false;

			await this.$router.replace({'query': null}).catch(() => {});

			this.$store.commit('setFilters', this.filtersActive);
			WebStorage.removeItem('applyPage');
			this.loadTableDataSource();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		openModalDelete(dataCenter) {
			this.activeDataCenter = dataCenter;
			this.showAlert = true;
		},
		goToDataCenterDetailing(id) {
			this.$router.push({
				name: 'dataCenterDetailing',
				params: { id },
			});
		},
		async deleteDataCenter() {
			this.isLoadingAction = true;

			try {
				await this.restApi().deleteDataCenter(this.workspace.id, this.activeDataCenter.id);

				this.showAlert = false;

				this.loadTableDataSource();
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('dataCenter.successDelete', { interpolate: { dataCenterDeleted: this.activeDataCenter.titulo }}),
				});
			} catch (error) {
				console.error(error);

				return;
			}
			this.isLoadingAction = false;
		},
		goToCreateDataCenter() {
			this.$router.push({
				name: 'createDataCenter',
			});
		},
		editDataCenter(id) {
			this.$router.push({
				name: 'editDataCenter',
				params: { id },
			});
		},

		async loadTableDataSource() {
			this.isLoadingTable = true;

			try {
				this.dataSourceList = await this.restApi().getDataCenter(this.workspace.id);
				this.dataSourceListTemp = this.dataSourceList;
			} catch (e) {
				console.log(e);
			}

			this.isLoadingTable = false;

		},
	},
});
