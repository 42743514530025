export default {
  namespaced: true,

  state: {
    pkce: null,
  },

  getters: {
    codeVerifier: state => state.pkce?.code_verifier,
    codeChallenge: state => state.pkce?.code_verifier,
  },

  actions: {
    updatePkce({ commit }, pkce) {
      commit('setPkce', { pkce });
    },
  },

  mutations: {
    setPkce(state, { pkce }) {
      state.pkce = pkce;
    },
  },
};
