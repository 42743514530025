
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';

import BackgroundView from '@/components/BackgroundView.vue';
import AliareInput from '@/components/AliareInput.vue';
import InfoBox from '@/components/InfoBox.vue';
import AliareSelect from '@/components/AliareSelect.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import Modal from '@/components/ModalAliare.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import SearchDataCenterInput from '@/components/SearchDataCenterInput.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import { previousRoute } from '../router/index.ts';

import * as XLSX from 'xlsx';

import WebStorage from '@/common/WebStorage';
import { searchPermissions, removeItemFromArrayByValue } from '@/components/helpers';
import { equivalencePermission } from '@/components/mixins/permissions/equivalences';

import { ref } from 'vue';
import axios from 'axios';
import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import TokenService from '@/services/token.service';

export default mixins(
	restApi,
	nodeHelpers,
	showMessage,
	equivalencePermission,
).extend({
	components: {
		BackgroundView,
		AliareInput,
		AliareSelect,
		ModalButtonSuccess,
		ModalButtonCancel,
		SearchDataCenterInput,
		Modal,
		InfoBox,
		AlertNotificationModal,
		NotificationPage,
	},
	name: 'CreateEquivalence',
	data() {
		return {
			listOption: {
				itemNameKey: "descricao",
				itemValueKey: "codigo",
				itemPageSize: 10,
				itemGap: 0,
			},
			reloadComponent: true,
			isLoadingTable: true,
			isLoadingImport: false,
			equivalenceName: '',
			equivalenceData: '',
			selectedResourceOriginal: {},
			selectedResourceEquivalence: {},
			objectRegister: [],
			forms: ref([
				{ valorOrigem: '', valorEquivalente: '', descricao: '', isValid: null, showOrigem: false, showEquivalente: false},
			]),
			canRegisterEquivalence: false,
			messageNameError: '',
			messageNameSuccess: '',
			optionsOriginalValue: [],
			extraLineAdded: 0,
			optionsEquivalenceValue: [],
			dataCenterOriginal: {},
			dataCenterEquivalence: {},
			validateInputs: false,
			showAlert: false,
			importedFile: false,
			showNotification: false,
			equivalenceActiveData: {},
			oldName: '',
			intervalStart: 0,
			intervalEnd: 50,
		};
	},
	computed: {
		buttonLoadResourceText() {
			return this.isEdit ? 'Carregar dados para  editar ou adicionar novos registros' : 'Carregar Registros';
		},
		textEquivalence() {
			return this.isEdit ? 'Valores Equivalentes definidos' : 'Escolha os Valores Equivalentes';
		},
		textOrigin() {
			return this.isEdit ? 'Valores Origens Definidos' : 'Escolha os Valores Origens';
		},
		nameEquivalenceText() {
			return this.isEdit ? this.$locale.baseText('equivalences.editEquivalence.nameEquivalence') : this.$locale.baseText('equivalences.createEquivalence.giveName');
		},
		discardModalText() {
			return this.isEdit ? this.$locale.baseText('Cancelar Edição') : this.$locale.baseText('Cancelar Cadastro');
		},
		keepModalText() {
			return this.isEdit ? this.$locale.baseText('Manter Edição') : this.$locale.baseText('Manter Cadastro');
		},
		textStep() {
			return this.isEdit ? this.$locale.baseText('equivalences.editEquivalence.step1') : this.$locale.baseText('equivalences.createEquivalence.step1');
		},
		equivalenceEditId() {
			if (this.isEdit) {
				return this.$route.params.id;
			} else {
				return '';
			}
		},
		pageTitle() {
			return this.isEdit ? this.$locale.baseText('equivalences.editEquivalence.title') : this.$locale.baseText('equivalences.createEquivalence.title');
		},
		addRegisterTitle() {
			return this.$locale.baseText('equivalences.createEquivalence.addRegister');
		},
		alertNotificationTitle() {
			return this.isEdit ? 'Cancelamento de Edição' : 'Cancelamento do Cadastro';
		},
		alertNotificationDescription() {
			return 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
		},
		canCreateEquivalence() {
			return this.validateInputs && this.canRegisterEquivalence;
		},
		isEdit() {
			return this.$route.name === 'editEquivalence';
		},
		mode() {
			return this.isEdit ? this.equivalenceData.tipoCadastro == 'Fonte de Dados' ? 'Dados' : 'Manual' : this.$route.params.mode;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser']),
		removeOption(index) {
			this.extraLineAdded -= 1;
			const item = {codigo: this.forms[index].valorOrigem.codigo, descricao: this.forms[index].valorOrigem.descricao};
			this.optionsOriginalValue.splice(this.optionsOriginalValue.findIndex(items => items.codigo === item.codigo), 1);
		},
		hiddenAllInputs() {
			this.forms.forEach(item => {
				if (item.showEquivalente || item.showOrigem) {
					item.showEquivalente = false;
					item.showOrigem = false;
				}
			});
		},
		openSelectEquivalence(index, id) {

			if (!this.importedFile) return;

			this.forms[index].showEquivalente = true;

			if (id) {
				this.reloadComponent = false;
				this.reloadComponent = true;
			}

			this.$nextTick(() => {
				this.$refs.selectEquivalence[0].$el.click();
			});
		},
		openSelectOrigem(index) {
			this.forms[index].showOrigem = true;
			this.$nextTick(() => {
				this.$refs.selectOrigem[0].$el.click();
			});
		},
		handleSelect({ id, select }) {
			this.selected = select;
		},
		goToCreateNewEquivalence() {
			window.location.reload();
		},
		openModalAlert() {
			this.showAlert = true;
		},
		goToEquivalence(id) {
			const equivalenceId = this.$route.name == 'createEquivalence' ? id : id.id;
			this.$router.push({
				name: 'equivalencesDetailing',
				params: { id: equivalenceId },
			});
		},
		goToEquivalenceList() {
			this.$router.push({
				name: 'equivalences',
			});
		},

		async onFileChange(event) {
			this.isLoadingImport = true;
			try {
				var file = event.target.files[0];

				if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") throw 'Arquivo Inválido. Escolha um arquivo .XSLX!';

				var vm = this;
				var reader = new FileReader();
				reader.onload = (event) => {
					// @ts-ignore
					var data = new Uint8Array(event.target.result);
					var workbook = XLSX.read(data, { type: "array" });
					var firstSheet = workbook.SheetNames[0];
					var worksheet = workbook.Sheets[firstSheet];
					var json = XLSX.utils.sheet_to_json(worksheet);

					vm.forms = [];

					json.forEach(el => {
						// @ts-ignore
						vm.forms.push({valorOrigem: (el?.ValoresOriginais ?? '').toString(), valorEquivalente: (el?.ValoresEquivalentes ?? '').toString(), descricao: (el?.Descricoes ?? '').toString()});
					})

					vm.valid = new Array(vm.forms.length).fill(false);
					// Validar inputs
					vm.forms.forEach((_, index) => {
						vm.valid[index] = vm.isValid(index);
					});
				};
				reader.readAsArrayBuffer(file);

				// Important to clear value, if the same file is upload active @change event
				event.target.value = '';
				this.importedFile = true;

				setTimeout(() => {
					this.isLoadingImport = false;
				}, 2000);

			} catch (e) {
				console.error(e);
				this.isLoadingImport = false;

				this.$store.commit('activeAlert', {
					message: e,
					status: 'error',
				});
			}


    },

		async loadResource() {
			this.isLoadingImport = true;

			this.importedFile = true;
			const removeRepeatItem = true;

			if (!this.isEdit) {
				this.forms = [];
			}
			let workflow1;
			let workflow2;
			let resourceToAdd = [];
			this.optionsEquivalenceValue = []; 

			try {
				workflow1 = await this.restApi().getResourcesDataCenter(this.workspace.id, this.dataCenterOriginal.id, this.selectedResourceOriginal.id);
				workflow2 = await this.restApi().getResourcesDataCenter(this.workspace.id, this.dataCenterEquivalence.id, this.selectedResourceEquivalence.id);
			} catch(e) {

				return;
			}

			workflow1.forEach(el => {
				if (this.isEdit) {
					let valueExist = this.forms.find(register => register.valorOrigem == el.codigo);
					if(valueExist) {
						valueExist.valorOrigem = { codigo: el.codigo.toString(), descricao: el.descricao};
					} else {
						resourceToAdd.push({valorOrigem:{ codigo: el.codigo.toString(), descricao: el.descricao}, valorEquivalente:{ codigo: '', descricao: ''} ,descricao: el.descricao, showOrigem: false, showEquivalente:false, newResource: true})
					}
				} else {
					this.forms.push({valorOrigem:{ codigo: el.codigo.toString(), descricao: el.descricao}, valorEquivalente:{ codigo: '', descricao: ''} ,descricao: el.descricao, showOrigem: false, showEquivalente:false});
				}
			});
			workflow2.forEach(el => {
				if (this.isEdit) {
					this.forms.find(resourceData => {
						if(resourceData.valorEquivalente == el.codigo) {
							resourceData.valorEquivalente = { codigo: el.codigo.toString(), descricao: el.descricao};
							resourceData.showEquivalente = false;
						}
					});
				}

				this.optionsEquivalenceValue.push({codigo: el.codigo.toString(), descricao: el.descricao});
			});

			this.valid = new Array(this.forms.length).fill(false);

			if (resourceToAdd?.length) {
				this.$store.commit('activeAlert', {
					message: `Foi encontrado "${resourceToAdd.length}" novo(s) Registro, você poderá exclui-lo ou adiciona-lo à Equivalência.`,
				});
			} else {
				this.$store.commit('activeAlert', {
					message: `Não existem novos registros a serem inseridos.`,
				});
			}
			this.forms.unshift(...resourceToAdd);

			this.isLoadingImport = false;

			this.validInputs(removeRepeatItem); 

		},
		validInputs(removeRepeatItem) {
			if (this.mode == 'Dados') {
				this.forms.slice(this.intervalStart, this.intervalEnd).forEach((_, index) => {
					this.valid[index] = this.isValid(index, removeRepeatItem);
				});
				if (this.intervalEnd < this.forms.length) {
					this.intervalStart = this.intervalStart + 50;
					this.intervalEnd = this.intervalEnd + 50;
					this.validInputs();
				}
			} else {
				this.forms.forEach((_, index) => {
					this.isValid(index);
				});
			}
		},
		selectedDataCenterOriginal(dataCenter) {
			this.dataCenterOriginal = dataCenter.data;
			this.selectedResourceOriginal = '';
		},
		selectedDataCenterEquivalence(dataCenter) {
			this.dataCenterEquivalence = dataCenter.data;
			this.selectedResourceEquivalence = '';
		},
		onSelectDataOriginalResource(type: string) {
			this.selectedResourceOriginal = type;
		},
		onSelectDataEquivalenceResource(type: string) {
			this.selectedResourceEquivalence = type;
		},


		isValid(index, removeRepeatItem) {
			if (index < 0 || index >= this.forms.length) {
				if (this.forms.length == 0) {
					this.validateInputs = false;
				}
				return false;
			}; // retorna false se o índice estiver fora dos limites

			let valuesEmpty;
			let values;
			let thisInputIsValid;

			if (this.mode == 'Dados') {
				valuesEmpty = this.forms
				.filter(form => form.valorOrigem == '' || form.descricao == '' || form.valorEquivalente.codigo == '').map(form => form.valorOrigem);

				values = this.forms
				.filter(form => form.valorOrigem.codigo !== '').map(form => form.valorOrigem.codigo); // diferente de vazio para não considerar inputs vazios duplicatas

			} else {
				valuesEmpty = this.forms
				.filter(form => form.valorOrigem == '' || form.descricao == '' || form.valorEquivalente == '').map(form => form.valorOrigem);

				values = this.forms
				.filter(form => form.valorOrigem !== '').map(form => form.valorOrigem); // diferente de vazio para não considerar inputs vazios duplicatas

			}

			let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);


			let valuesDuplicates = findDuplicates(values);
			let hasDuplicates = valuesDuplicates.length > 0 ? true : false;
			let hasEmptyValue = valuesEmpty.length > 0 ? true : false;
			thisInputIsValid = !(valuesDuplicates.includes(this.forms[index].valorOrigem?.codigo || this.forms[index].valorOrigem));

			if (!hasDuplicates && !hasEmptyValue) {
				
				this.forms.forEach(form => {
					form.isValid = true;
				});
			} else {
				this.forms[index].isValid = thisInputIsValid;

				if (!thisInputIsValid && removeRepeatItem) {
					this.optionsOriginalValue.push({codigo: this.forms[index].valorOrigem.codigo.toString(), descricao: this.forms[index].valorOrigem.descricao});
					removeItemFromArrayByValue(this.forms, this.forms[index]);
				}
			}

			this.validateInputs = !hasDuplicates && !hasEmptyValue;
			return !hasDuplicates;
		},

		verifyNameEquivalence:
		_.debounce(async function (e)  {
			this.messageNameSuccess = '';
			this.messageNameError = '';
			this.canRegisterEquivalence = false;

			if(this.equivalenceName != '' && this.equivalenceName !== this.oldName) {

				const savedEquivalence = await this.restApi().checkAvailableNameEquivalences(this.workspace.id, {
					nome: this.equivalenceName
				});


				if (savedEquivalence) {
					this.messageNameError = this.$locale.baseText('equivalences.validationTitleInvalid');
					this.messageNameSuccess = '';
					this.canRegisterEquivalence = false;

				} else if (!savedEquivalence) {
					this.messageNameSuccess = this.$locale.baseText('equivalences.validationTitleValid');
					this.messageNameError = '';
					this.canRegisterEquivalence = true;
				}
			} else if (this.isEdit && this.equivalenceName === this.oldName) {
				this.canRegisterEquivalence = true;

			} else {
				this.messageNameSuccess = '';
				this.messageNameError = '';
				this.canRegisterEquivalence = false;
			}
		},500),
		async saveEquivalence() {
			this.isLoadingTable = true;

			try {
				let savedEquivalence = {};
				let nameEquivalence = JSON.parse(JSON.stringify(this.equivalenceName));
				let valuesToPost = JSON.parse(JSON.stringify(this.forms));

				if (this.mode != 'Dados') {
					if (this.isEdit) {
						let valuesToPut = valuesToPost.filter((val) => val.equivalenciaId);
						let newValues = valuesToPost.filter((val) => !val.equivalenciaId);
						let payload = {};


						savedEquivalence = await this.restApi().putEquivalence(this.workspace.id, this.equivalenceEditId, {
							nome: this.equivalenceName,
							valores: valuesToPut,
						});

						if (newValues.length) {
							for (const form of newValues) {
								payload = {
									valorOrigem: form.valorOrigem,
									valorEquivalente: form.valorEquivalente,
									descricao: form.descricao,
								};

								await this.restApi().createNewRegister(this.workspace.id, this.equivalenceEditId, payload);

							}

						}
					} else {
						savedEquivalence = await this.restApi().createNewEquivalence(this.workspace.id, {
							nome: this.equivalenceName,
							valores: valuesToPost,
							tipoCadastro: this.mode == 'Manual' ? 'Manual' : this.mode == 'Importar' ? 'Importado' : '',
						});
					}

					this.equivalenceActiveData = {nome: nameEquivalence, id: savedEquivalence};
				} else {
					if (this.isEdit) {
						let valuesToPutDataCenter = valuesToPost.filter((val) => val.equivalenciaId);
						let newValuesDataCenter = valuesToPost.filter((val) => !val?.equivalenciaId);

						let payload = {};

						let valuesResourcePut = valuesToPutDataCenter.map((form) => {
							return { valorOrigem: form.valorOrigem?.codigo || form.valorOrigem, valorEquivalente: form.valorEquivalente?.codigo || form.valorEquivalente,  descricao: form.descricao, equivalenciaId: form.equivalenciaId};
						});

						savedEquivalence = await this.restApi().putEquivalence(this.workspace.id, this.equivalenceEditId, {
							nome: this.equivalenceName,
							valores: valuesResourcePut,
						});


						if (newValuesDataCenter.length) {
							for (const form of newValuesDataCenter) {
								payload = {
									valorOrigem: form.valorOrigem.codigo == '' ? '' : form.valorOrigem.codigo || form.valorOrigem,
									valorEquivalente: form.valorEquivalente.codigo == '' ? '' : form.valorEquivalente.codigo || form.valorEquivalente,
									descricao: form.descricao,
								};

								await this.restApi().createNewRegister(this.workspace.id, this.equivalenceEditId, payload);

							}

						}
					} else {
						let valuesResource = this.forms.map((form) => {
							return { valorOrigem: form.valorOrigem.codigo, valorEquivalente: form.valorEquivalente.codigo,  descricao: form.descricao};
						});
						savedEquivalence = await this.restApi().createNewEquivalence(this.workspace.id, {
							recursoIdOrigem: this.selectedResourceOriginal.id,
							recursoIdEquivalente: this.selectedResourceEquivalence.id,
							nome: this.equivalenceName,
							valores: valuesResource,
							tipoCadastro: 'Fonte_De_Dados',
						});
					}

					this.equivalenceActiveData = {nome: nameEquivalence, id: savedEquivalence};
				}

				this.showNotification = true;
			} catch (error) {
				console.error(error);

				this.$store.commit('activeAlert', {
					message: error?.notifications[0],
					status: 'error',
				});

				if (this.isEdit) {
					this.loadCurrentEquivalence();
				}

			}  finally {
				this.isLoadingTable = false;
			}
		},
		addFormRegister() {
			this.extraLineAdded += 1;
			if (this.mode != 'Dados') {
				this.forms.push({valorOrigem: '', valorEquivalente: '', descricao: '', isValid: null});
			} else {
				this.forms.push({valorOrigem: {codigo: '', descricao: ''}, valorEquivalente: {codigo: '', descricao: ''}, descricao: '', showOrigem: false, showEquivalente:false, isValid: null});
			}
			this.validInputs();
		},
		removeRow(index) {
			let splicedArray = JSON.parse(JSON.stringify(this.forms));
			if (splicedArray[index].valorOrigem?.codigo) {
				this.optionsOriginalValue.push({codigo: splicedArray[index].valorOrigem.codigo, descricao: splicedArray[index].valorOrigem.descricao});
			} else {
				this.extraLineAdded -= 1;
			}
			removeItemFromArrayByValue(splicedArray, splicedArray[index]);
			this.forms = splicedArray;
			this.validInputs();
		},

		redirectEquivalences() {
			this.$router.push({	name: 'equivalences' });
		},
		async loadCurrentEquivalence() {
			this.isLoadingTable = true;
			const vm = this;

			try {
				const currentEquivalence = await this.restApi().getEquivalenceById(this.workspace.id, this.equivalenceEditId);
				this.equivalenceData = currentEquivalence;
				this.equivalenceName = currentEquivalence.nome;
				this.oldName = currentEquivalence.nome;
				this.forms = currentEquivalence.valores;

				vm.valid = new Array(vm.forms.length).fill(false);

				// Validar inputs
				this.forms.forEach((_, index) => {
					this.isValid(index);
				});
				
				
				if (this.isEdit && this.mode == 'Dados') {
					this.dataCenterOriginal.id = this.equivalenceData.recursoOrigem.fonteDeDados.id;
					this.selectedResourceOriginal.id = this.equivalenceData.recursoOrigem.id;

					this.dataCenterEquivalence.id = this.equivalenceData.recursoEquivalente.fonteDeDados.id;
					this.selectedResourceEquivalence.id = this.equivalenceData.recursoEquivalente.id;
				}
				this.isLoadingTable = false;
				return currentEquivalence;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: 'Ocorreu um problema ao carregar a equivalência, tente novamente.',
					status: 'error',
				});

				console.error(error);

				this.$router.push({
					name: 'equivalences',
				});
				return;
			}
		},
		async verifyMode() {
			this.isLoadingTable = true;
			if (this.isEdit) {
				this.validateInputs = true;
				this.canRegisterEquivalence = true;
			}
			this.isLoadingTable = false;
		},
		async verifyPermissionUserAndInitiate() {
			await this.validationAccessEquivalences().then(async () => {
				let currentEquivalence;

				if (this.isEdit) {
					currentEquivalence = await this.loadCurrentEquivalence();
				}

				if (this.isEdit ? this.canEditEquivalence(currentEquivalence) : this.registerEquivalencePermission) {
					this.verifyMode();
				} else {
					this.$store.commit('setData403', {show: true, prevPage: previousRoute});
				}
			});
		},
	},
	created() {
		this.verifyPermissionUserAndInitiate();
	},
	mounted() {

	},
});
