
import Vue from 'vue';
import WebStorage from '@/common/WebStorage';
import { mapState } from 'vuex';

export default {
	name: 'Page403',

	props: {
	},

	data() {
		return {
			modalBus: new Vue(),
		};
	},
	created() {
	},
	methods: {
		goToPage() {
			if (this.hasHistoryBackPage) {
				this.$router.push({
					name: this.data403.prevPage.name,
				});
			} else {
				this.$router.push({
					name: 'project',
				});
			}
			this.resetData403();
		},

		resetData403() {
			this.$store.commit('setData403', {show: false, prevPage: null});
		},
	},
	computed: {
		...mapState(['data403']),
		backPageText() {
			return this.hasHistoryBackPage ? 'Voltar para Tela Anterior' : 'Voltar para Tela Inicial';
		},

		hasHistoryBackPage() {
			return this.data403.prevPage.fullPath !== '/' && this.data403.prevPage.name !== 'project';
		},

	},


	beforeCreate() {
			document.body.className = 'hideScroll';
	},
	beforeDestroy() {
		this.resetData403();
		document.body.className = '';
	},
};
