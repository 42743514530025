<template>
	<div>
		<div v-if="$props.alertIcon">
			<svg width="138px" height="130px" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" style="" class="">
				<g id="Styleguide" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
					<g class="logo" id="logo" stroke-width="3">
						<g id="Group-2" transform="translate(12.000000, 19.000000)">
							<path class="triangle" id="Path-2" stroke="#FFA53B" d="M40,0L80,70L0,70Z"></path>
							<g id="Exclamation-Mark" stroke="#FFA53B" fill="none" stroke-width="3">
								<path d="M40, 60 l0,-02" style="stroke-linecap:round;stroke-width:3;transform-origin: center" class="exclamation_1">
									<animate attributeName="stroke-dashoffset" from="28" to="0" dur="0.5s" begin="Path-2.end"></animate>
								</path>
								<path d="M40, 51 l0,-28" style="stroke-linecap:round;stroke-width:3;transform-origin: center" class="exclamation_2">
									<animate attributeName="stroke-dashoffset" from="72" to="0" dur="0.5s" begin="Path-2.end"></animate>
								</path>
							</g>
						</g>
					</g>
				</g>
			</svg>
		</div>
		<div v-else>
			<svg version="1.1" width="138px" height="130px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style="">
				<path class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" d="M2.999999999999993,65.1A62.1,62.1 0,1,1 127.19999999999999,65.1A62.1,62.1 0,1,1 2.999999999999993,65.1"></path>
				<path class="path check_1" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" d="M100.2,40.2L51.5,88.8L29.8,67.5"></path>
				<path class="path check_2" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" d="M100.2,40.2L51.5,88.8L29.8,67.5"></path>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		alertIcon: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.triangle {
	stroke-dasharray: 242 244;
	stroke-dashoffset: 243;
	animation: animation_draw 2650ms ease-in 0ms forwards;
}
.exclamation_1 {
	stroke-dasharray: 2 4;
	stroke-dashoffset: 3;
	animation: animation_draw 32ms ease-in 2650ms forwards;
}
.exclamation_2 {
	stroke-dasharray: 28 30;
	stroke-dashoffset: 29;
	animation: animation_draw 316ms ease-in 2683ms forwards;
}
@keyframes animation_draw {
	100% {
		stroke-dashoffset: 0;
	}
}

.circle {
	stroke-dasharray: 391 393;
	stroke-dashoffset: 392;
	animation: roundCheck_draw 1666ms linear 0ms forwards;
}

.check_1 {
	stroke-dasharray: 100 102;
	stroke-dashoffset: 101;
	animation: roundCheck_draw 1666ms linear 416ms forwards, roundCheck_fade 1000ms ease-out 2000ms forwards;
	animation-fill-mode: forwards;
}

.check_2 {
	stroke-dasharray: 100 102;
	stroke-dashoffset: 101;
	animation: roundCheck_draw 1666ms linear 833ms forwards, roundCheck_fade 1000ms ease-out 2000ms forwards;
	animation-fill-mode: forwards;
}

@keyframes roundCheck_draw {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes roundCheck_fade {
	0% {
		stroke-opacity: 1;
	}

	89.47368421052632% {
		stroke-opacity: 1;
	}

	100% {
		stroke-opacity: 1;
	}
}
</style>
