<template>
	<div class="quantityFilter">
		<AliareInput
			labelText="Qnt. Mínima"
			v-model="qtdMin"
			type="number"
			@input="applyFilter"
		/>

		<AliareInput
			labelText="Qnt. Máxima"
			v-model="qtdMax"
			type="number"
			@input="applyFilter"
		/>
	</div>
</template>

<script>
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';

import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AliareInput from '@/components/AliareInput.vue';

export default mixins(
	restApi,
).extend({
	name: "QuantityFilter",
	components: {
		ModalButtonSuccess,
		AliareInput,
	},
	props: {
		clearInput: Boolean,
		defaultQntMin: {
			type: String,
			default: '',
		},
		defaultQntMax: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			qtdMin: '',
			qtdMax: '',
		};
	},
	async created() {
		await this.loadCurrentValues();
	},
	methods:{
		applyFilter() {
			this.$emit('filterQtd', this.qtdMin, this.qtdMax);
		},
		clearFilter() {
			this.qtdMin = '';
			this.qtdMax = '';
			this.$emit('clearFilter');
		},
		async loadCurrentValues() {
			this.qtdMin = this.defaultQntMin;
			this.qtdMax = this.defaultQntMax;
		},
	},
	watch: {
    clearInput(newValue) {
			if (newValue !== true){
				 this.clearFilter();
			}
    },
  },
});
</script>

<style scoped lang="scss">
.quantityFilter {
	position: absolute;
	top: 54px;
	right: 10px;
	display: flex;
	width: 184px;
	flex-direction: column;
	row-gap: 20px;
	background: #1C2638;
	border: 1px solid #4A638F;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.32);
	border-radius: 4px;
	padding: 17px;
}

.clearButton {
	background: #FF495F;
	color: white;
}
</style>
