
import Vue from 'vue';
import { mapGetters } from "vuex";

import Modal from './Modal.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { CREDENTIAL_SELECT_MODAL_KEY } from '../constants';

export default Vue.extend({
	name: 'CredentialsSelectModal',
	components: {
		Modal,
		ModalButtonCancel,
	},
	data() {
		return {
			modalBus: new Vue(),
			selected: '',
			isFocused: false,
			CREDENTIAL_SELECT_MODAL_KEY,
		};
	},
	computed: {
		...mapGetters('credentials', ['allCredentialTypes']),
	},
	methods: {
		onFocus() {
			this.isFocused = !this.isFocused;
		},
		onSelect(type: string) {
			this.selected = type;
		},
		openCredentialType () {
			this.modalBus.$emit('close');
			this.$store.dispatch('ui/openNewCredential', { type: this.selected });
			this.$telemetry.track('User opened Credential modal', { credential_type: this.selected, source: 'primary_menu', new_credential: true, workflow_id: this.$store.getters.workflowId });
		},
	},
});
