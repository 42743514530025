<template>
	<div>
		<div
			class="accessOptions__bodyCell"
			v-for="cell in names"
			:key="cell.length"
			v-on:click="$emit('cellSelect', accessOptions)"
		>
			{{ $locale.baseText(cell.cell__1) }}
			<active-deactivate-button
				:cells="cell.cell__1"
				:deactiveButtons="cell.deactiveButton__1"
				:firstBtnText="active"
				:secondBtnText="inactive"
				@advanceButton="advance__Button"
			>
			</active-deactivate-button>
		</div>
	</div>
</template>

<script>
import { result } from 'lodash';
import ActiveDeactivateButton from './ActiveDeactivateButton.vue';
export default {
	name: 'Cell',
	components: { ActiveDeactivateButton },
	emits: ['cellSelect'],
	props: {
		final: String,
		init: String,
	},
	created() {
		this.dataArray();
	},
	methods: {
		dataArray() {
			const dados = [];
			for (let i = this.init; i < this.final; i++) {
				dados.push({
					cell__1: `accessOptions.accessOptions__bodyCell__${i}`,
					deactiveButton__1: `${i}`,
					advanceButton__1: `advanceButton${i}`,
				});
			}
			this.names = dados;
		},
		advance__Button(res) {
			this.accessOptions = res[0];
		},
	},
	data() {
		return {
			names: [],
			accessOptions: [],
			inactive: 'activeDeactivateButton.inactive',
			active: 'activeDeactivateButton.active',
		};
	},
};
</script>

<style>
</style>
