import { render, staticRenderFns } from "./ModalCreateEditProject.vue?vue&type=template&id=037fd1af&scoped=true&"
import script from "./ModalCreateEditProject.vue?vue&type=script&lang=js&"
export * from "./ModalCreateEditProject.vue?vue&type=script&lang=js&"
import style0 from "./ModalCreateEditProject.vue?vue&type=style&index=0&id=037fd1af&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "037fd1af",
  null
  
)

export default component.exports