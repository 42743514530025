
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { mapActions, mapGetters } from 'vuex';

import { MAX_WORKFLOW_NAME_LENGTH, PLACEHOLDER_EMPTY_WORKFLOW_ID } from '@/constants';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { titleChange } from '@/components/mixins/titleChange';
import { showMessage } from '@/components/mixins/showMessage';
import WebStorage from '@/common/WebStorage';
import AliareInput from '@/components/AliareInput.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import InfoBox from '@/components/InfoBox.vue';
import TagsDropdown from '@/components/TagsDropdown.vue';
import Modal from './Modal.vue';

export default mixins(showMessage, workflowHelpers, titleChange).extend({
	components: {
		TagsDropdown,
		Modal,
		AliareInput,
		ModalButtonSuccess,
		InfoBox,
	},
	name: 'DuplicateWorkflow',
	props: ['modalName', 'isActive'],
	data() {
		return {
			selectedProject: '',
			dataProject: '',
			name: '',
			isSaving: false,
			modalBus: new Vue(),
			dropdownBus: new Vue(),
			isFocused: false,
			nameWorkflow: '',
			MAX_WORKFLOW_NAME_LENGTH,
		};
	},
	async mounted() {
		this.getProjects();
	},
	computed: {
		isVersion() {
			return this.$route.name === 'VersionById';
		},
		workflowVersionData() {
			return this.$store.getters.getWorkflowVersionData;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	watch: {
	},
	methods: {
		onSelect(type: string) {
			this.selectedProject =  type;
		},
		async getProjects() {
			const payload = {
				size: 9999,
			};
			await this.restApi().getProjects(this.workspace.id, payload).then((response) => {
				this.dataProject = response;
			});
		},
		onFocus() {
			this.isFocused = !this.isFocused;
		},

		duplicateWorkflow() {
			try {
				let	workflowDuplicated = this.restApi().duplicateWorkflow(this.workspace.id, this.$store.getters.workflowId, { projetoId: this.selectedProject.id }).then((cloneWorkflow) => {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('duplicateWorkflowDialog.successDuplicate', { interpolate: {savedWorkflowName: cloneWorkflow.nome, savedAccountName: WebStorage.getItem("accountName")} }),
						link: {
							message: `Acessar Fluxo`,
							src: `/workflow/${cloneWorkflow.id}`,
						},
					});
				});

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('duplicateWorkflowDialog.initDuplicate'),
				});

				this.modalBus.$emit('close');

			} catch(e) {
				this.$store.commit('activeAlert', {
					message: e?.notifications[0],
					status: 'error'
				});
			}
		},

		async saveCopy() {
			try {
				let	data = await this.restApi().getWorkflow(this.workspace.id, this.workflowVersionData.workflowId);

				data = this.cleanWorkflowObject(data);

				const workflowData = await this.restApi().createNewWorkflow(this.workspace.id, data);

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('VersionHistoryDetails.saveCopySuccess', { interpolate: {savedWorkflowName: workflowData.nome, savedProjectName: this.selectedProject.nome, savedAccountName: WebStorage.getItem("accountName")} }),
				});

				this.modalBus.$emit('close');

				this.openWorkflow(workflowData.id);
			} catch(e) {
				this.$store.commit('activeAlert', {
					message: e?.notifications[0],
					status: 'error'
				});
			}
		},

		cleanWorkflowObject(data) {
			delete data.id;
			delete data.modificadoEmDataHora;
			delete data.modificadoPorUsuario;
			delete data.workflowData.id;
			delete data.triggerError;
			data.projetoId = this.selectedProject.id;
			delete data.projeto;
			delete data.tenant;
			data.nome = this.nameWorkflow;
			data.workflowData.name = data.nome;
			data.workflowData.nodes = this.workflowVersionData.workflowData.nodes;
			data.workflowData.connections = this.workflowVersionData.workflowData.connections;

			return data;
		},

		async openWorkflow(workflowId: string) {
			this.$titleSet(this.workflowName, "IDLE");
			// Change to other workflow
			this.$router.push({
				name: "NodeViewExisting",
				params: { name: workflowId },
			});
		},


		closeDialog(): void {
			this.modalBus.$emit('close');
		},
	},
	beforeDestroy() {
		this.$store.commit('setWorkflowId', PLACEHOLDER_EMPTY_WORKFLOW_ID);
	},
});
