import CoreService from "@/services/core.service";

export default {
  namespaced: true,

  state: {
    tenant: null,
    userId: null,
    actionStatus: {
      loadingSubscriptions: false,
      loadingTenants: false,
    },
  },

  getters: {
    tenant: state => state.tenant,
    loadingSubscriptionsStatus: state => state.actionStatus.loadingSubscriptions,
    loadingTenantsStatus: state => state.actionStatus.loadingTenants,
  },

  actions: {
    async getTenants({ commit }) {
      commit('turnOnLoadingTenantStatus');

      try {
        const result = await CoreService.getTenant();
        commit('setTenant', { tenant: result });

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        commit('turnOffLoadingTenantStatus');
      }
    },
  },

  mutations: {
    setTenant(state, { tenant }) {
      state.tenant = tenant;
    },
    turnOnLoadingTenantStatus(state) {
      state.actionStatus.tenant = true;
    },
    turnOffLoadingTenantStatus(state) {
      state.actionStatus.tenant = false;
    },
  },
};
