

import {
	INodeTypeDescription,
} from 'n8n-workflow';
import {
	INodeUi,
	IUpdateInformation,
} from '../Interface';

import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';

import VariableSelector from '@/components/VariableSelector.vue';
import NodeSettings from '@/components/NodeSettings.vue';
import RunData from '@/components/RunData.vue';

import mixins from 'vue-typed-mixins';

export default mixins(externalHooks, nodeHelpers, workflowHelpers).extend({
	name: 'DataDisplay',
	components: {
		NodeSettings,
		VariableSelector,
		RunData,
		ModalButtonSuccess,
	},
	data () {
		return {
			basePath: this.$store.getters.getBaseUrl,
			showDocumentHelp: false,
			item: {
				key: 'ovo',
				value: 'gema',
			},
		};
	},
	computed: {
		documentationUrl (): string {
			if (!this.nodeType) {
				return '';
			}

			if (this.nodeType.documentationUrl && this.nodeType.documentationUrl.startsWith('http')) {
				return this.nodeType.documentationUrl;
			}

			return 'https://docs.n8n.io/nodes/' + (this.nodeType.documentationUrl || this.nodeType.name) + '?utm_source=n8n_app&utm_medium=node_settings_modal-credential_link&utm_campaign=' + this.nodeType.name;
		},
		node (): INodeUi {
			return this.$store.getters.activeNode;
		},
		nodeType (): INodeTypeDescription | null {
			if (this.node) {
				return this.$store.getters.nodeType(this.node.type);
			}
			return null;
		},
	},
	watch: {
		node (node, oldNode) {
			if(node && !oldNode) {
				this.$externalHooks().run('dataDisplay.nodeTypeChanged', { nodeSubtitle: this.getNodeSubtitle(node, this.nodeType, this.getWorkflow()) });
				this.$telemetry.track('User opened node modal', { node_type: this.nodeType ? this.nodeType.name : '', workflow_id: this.$store.getters.workflowId });
			}
		},
	},
	methods: {
		valueChanged (parameterData: IUpdateInformation) {
			this.$emit('valueChanged', parameterData);
		},
		nodeTypeSelected (nodeTypeName: string) {
			this.$emit('nodeTypeSelected', nodeTypeName);
		},
		close () {
			this.$externalHooks().run('dataDisplay.nodeEditingFinished');
			this.showDocumentHelp = false;
			this.$store.commit('setActiveNode', null);
		},
		onDocumentationUrlClick () {
			this.$externalHooks().run('dataDisplay.onDocumentationUrlClick', { nodeType: this.nodeType, documentationUrl: this.documentationUrl });
		},
	},
});

