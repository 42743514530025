<template>
	<div class="search-user">
		<AliareInput
			labelText="Pesquisar um Usuário"
			v-model="userInput"
		/>
		<div>

		</div>
	</div>
</template>

<script>
import mixins from 'vue-typed-mixins';
import AliareInput from '@/components/AliareInput.vue';

import { restApi } from '@/components/mixins/restApi';

export default mixins(
	restApi,
).extend({
	components: {
		AliareInput,
	},
	name: "SearchUser",
	props: {

	},
	data() {
		return {
			userInput: '',
		};
	},

	methods:{
	},
});
</script>

<style scoped lang="scss">

</style>
