<template>
	<div>
		<Modal
			:name="DUPLICATE_PROJECT_MODAL_KEY"
			:eventBus="modalBus"
			:title="'Duplicar Projeto'"
			minWidth="868px"
			minHeight="501px"
			maxWidth="1302px"
			width="min-content"
			footerOut
		>
			<template v-slot:content>
				<InfoBox>
					<template #content>
						<div>
							<p>
								A <b>duplicação</b>  deste Projeto e de seus Fluxos <b>não reaproveitará</b>
								as configurações dos seguintes itens: 1. <b>Conector: Equivalência</b>; 2. <b>Conector: Executar Fluxo</b>;
								3. <b>Credências dos Conectores</b> e 4. <b>Fluxos de Falha</b>. Além disso os <b>valores</b>  das Variáveis também <b>não serão reaproveitados</b>.
							</p>
						</div>
					</template>
				</InfoBox>

				<div v-if="!isLoading">
					<div class="container-cards">

						<div class="step-wrapper" v-if="mode === 'multiAccount'">
							<div class="step-container">
								<div class="step-header step-header--one">
									<div class="title-container">
										<div class="title-number">
											1
										</div>
										<div class="title-text">
											Escolha a Organização de Destino *
										</div>
									</div>

									<div class="searchBar">
										<input
											type="text"
											v-model="searchInput"
											class="searchBar--input"
											@input="filteredOrganization()"
											:placeholder="'Pesquisar uma Organização'"
										>
										<i class="searchBar--input__icon"></i>
									</div>
								</div>

								<div class="step-body">
									<ul class="step-list">
										<li v-for="(item, index) in organizations" :key="index" :class="{ selectedItem: item === selected }">
											<input type="radio" :id="'item-' + index" :name="'item'" :value="item" v-model="selected">
											<div class="container-labels">
												<label :for="'item-' + index">{{ item.description }}</label>

												<label :for="'item-' + index"	v-if="item?.owner?.identificationDocument?.lenght > 11" class="step-text">
													{{ maskCnpj(item?.owner?.identificationDocument) }}
												</label>

												<label :for="'item-' + index" v-else class="step-text">
													{{ maskCpf(item?.owner?.identificationDocument) }}
												</label>
											</div>
										</li>
									<ScrollInfinite v-if="scrollInfinite" @endOfScroll="pagination()" />
									</ul>
								</div>

							</div>
						</div>

						<div class="step-wrapper">
							<div class="step-container">
								<div class="step-header step-header--one">
									<div class="title-container">
										<div class="title-number">
											{{ mode === 'multiAccount' ? 2 : 1 }}
										</div>
										<div class="title-text">
											Escolha o Espaço de Trabalho de Destino *
										</div>
									</div>


									<div v-if="workspaces.length > 0" class="searchBar">
										<input
											class="searchBar--input"
											type="text"
											:placeholder="'Pesquisar um Espaço de Trabalho'"
											v-model="searchWorkspace"
										>
										<i class="searchBar--input__icon"></i>
									</div>
								</div>

								<div  v-if="!selected && mode === 'multiAccount'" class="container-infoBox">
									<i class="icon-infoBox"></i>
									<div class="infoBox-message">
										<p>
											Selecione a <b>Organização de Destino</b> do Projeto.
										</p>
									</div>
								</div>

								<div v-else class="step-body">
									<ul class="step-list" v-if="workspaces.length > 0 && !isLoadingWorkspaces">
										<li v-for="(item, index) in workspaceFilter" :key="index" :class="{ selectedItem: item === workspaceSelected }">
											<input type="radio" :id="'item-' + index + item.nome" :name="'workspace'" :value="item" v-model="workspaceSelected">
											<div class="container-labels">
												<label :for="'item-' + index + item.nome">{{ item.nome }}</label>
											</div>
										</li>
									</ul>
									<div v-else-if="isLoadingWorkspaces" class="container-loading">
										<div class="loading-spin"></div>
									</div>
									<div v-else class="container-infoBox">
										<i class="icon-infoBox"></i>
										<div class="infoBox-message">
											<p>
												Não existe <b>Espaço de Trabalho</b> criado na Organização selecionada.
											</p>
										</div>
									</div>

								</div>

							</div>
						</div>

						<div class="step-wrapper">
							<div class="step-container">
								<div class="step-header step-header--two">
									<div class="title-container">
										<div class="title-number">
											{{ mode === 'multiAccount' ? 3 : 2 }}
										</div>
										<div class="title-text">
											Defina o Título do Projeto
										</div>
									</div>
								</div>

								<div class="step-body">
									<div class="input-body">

										<div v-if="mode === 'multiAccount' ? !selected || !workspaceSelected : !workspaceSelected" class="container-infoBox">
											<i class="icon-infoBox"></i>
											<div class="infoBox-message">
												<p v-html="defineTitleText"></p>
											</div>
										</div>

										<AliareInput
											v-else
											required
											:labelText="titleInput"
											v-model="inputModalName"
											@input="verifyNameProject"
										/>

										<div v-if="messageNameError || messageNameSuccess">
											<p class="messageProject" :class="{ error: messageNameError, success: messageNameSuccess }">
												{{ messageNameError || messageNameSuccess }}
											</p>
											<p class="iconProject"	:class="{ errorIcon: messageNameError, successIcon: messageNameSuccess }"	/>
										</div>

										<div v-if="!duplicateOneProject" class="projects-preview">
											<span v-for="project in dataModal.selectedProjects" :key="project.id">{{ inputModalName + (inputModalName ? ' - ' : '') +  project.nome}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				<div class="container-loading" v-else>
					<div class="loading-spin"></div>
				</div>
			</template>
			<template v-slot:footer>
				<div class="footer-buttons">
					<ModalButtonSuccess
						@click="mode === 'multiAccount' ? duplicateMultiAccount() : duplicateOneAccount()"
						:active="activeBtn"
						:titleButton="'Concluir'"
						width="111"
					/>
					<ModalButtonCancel
						@click="closeModal"
						width="111"
					/>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import SearchBar from '@/components/SearchBar/SearchBar.vue';
import AliareInput from '@/components/AliareInput.vue';
import InfoBox from '@/components/InfoBox.vue';
import Modal from './Modal.vue';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import { PLACEHOLDER_EMPTY_WORKFLOW_ID, DUPLICATE_PROJECT_MODAL_KEY } from '@/constants';
import { restApi } from '@/components/mixins/restApi';
import TokenService from '@/services/token.service';
import WebStorage from '@/common/WebStorage';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';
import Vue from 'vue';

export default mixins(
	restApi,
)
.extend({
	name: 'DuplicateProject',
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},
	components: {
		Modal,
		InfoBox,
		SearchBar,
		AliareInput,
		ScrollInfinite,
		ModalButtonCancel,
		ModalButtonSuccess,
	},
	data() {
		return {
			DUPLICATE_PROJECT_MODAL_KEY,
			modalBus: new Vue(),
			isLoading: false,
			isLoadingWorkspaces: false,
			scrollInfinite: false,

			page: 1,
			userId: '',
			tenantId: WebStorage.getItem("tenantId"),
			tokenData: WebStorage.getItem('tokenData'),
			selected: null,
			searchWorkspace: '',
			workspaceSelected: null,

			searchInput: "",

			workspaces: '',
			accountData: [],
			organizations: [],
			organizationsTemp: [],

			inputModalName: '',
			messageNameSuccess: '',
			messageNameError: '',
			activeBtn: false,
		};
	},
	async created() {
		this.isLoading = true;
		if (this.mode === 'multiAccount') {
			await this.getOrganizations();
		} else {
			await this.getWorkspacesAvailables();
		}
		this.isLoading = false;
	},
	mounted() {
	},
	computed: {
		titleInput() {
			if (this.duplicateOneProject) {
				return this.$locale.baseText('project.edit.titleInputModal');
			} else {
				return 'Prefixo do Projeto';
			}
		},
		duplicateOneProject() {
			if (this.dataModal?.selectedProjects && this.dataModal?.selectedProjects.length == 1 || this.dataModal?.projectId) {
				return true;
			} else {
				return;
			}
		},
		defineTitleText() {
			if(this.mode === 'multiAccount') {
				return`Selecione a <b>Organização de Destino e o Espaço de Trabalho</b> do Projeto.`;
			}
			return `Selecione o <b>Espaço de Trabalho de Destino</b> do Projeto.`;
		},
		workspaceFilter() {
      return this.workspaces.filter(workspaces => {
        return workspaces.nome?.toLowerCase().includes(this.searchWorkspace.toLowerCase());
      });
    },
		messageSucessName() {
			if(this.mode === 'multiAccount') {
				return this.$locale.baseText('Este título está disponível para esta organização.');
			}
			return this.$locale.baseText('project.edit.inlineProjectNameMessageAvailable', { interpolate: { savedProjectName: this.inputModalName } });
		},
		messageErrorName() {
			if(this.mode === 'multiAccount') {
				return this.$locale.baseText('Este título já está em uso para esta organização.');
			}
			return this.$locale.baseText('project.edit.inlineProjectNameMessageUnavailable', { interpolate: { savedProjectName: this.inputModalName } });
		},
		workspace() {
			return this.$store.getters.workspace;
		},
  },
	watch: {
		selected(newValue, oldValue) {
			if(newValue !== oldValue) {
				this.activeBtn = false;
				this.inputModalName = '';
				this.messageNameSuccess = '';
				this.workspaceSelected = null;
				this.messageNameSuccess = '';
				this.getWorkspacesAvailables();
			}
		},
		workspaceSelected(newValue, oldValue) {
			if(newValue !== oldValue) {
				this.activeBtn = false;
				this.inputModalName = '';
				this.messageNameSuccess = '';
				this.messageNameSuccess = '';
			}
		}
	},
	methods: {
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser', 'getAccountData', 'getAccountTenantV2', 'getUserPermissionById']),

		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},
		maskCpf(cpf) {
      return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    maskCnpj(cnpj) {
      return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },

		async getOrganizations() {
			this.organizations = JSON.parse(WebStorage.getItem('cardOrganization'));
			this.organizations.sort((x, y) => x?.name?.toUpperCase().localeCompare(y?.name?.toUpperCase()));
			this.organizationsTemp = this.organizations;

			if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0' ) {
				this.scrollInfinite = true;
			}
		},
		async getWorkspacesAvailables() {
			this.isLoadingWorkspaces = true;
			if (this.selected) {
				WebStorage.setItem('tenantId', this.selected.id);
			}

			const response = await this.restApi().getWorkspaces({ size: 300, ativo: true });
			this.workspaces = response;

			WebStorage.setItem('tenantId', this.tenantId);
			this.isLoadingWorkspaces = false;
		},
		verifyNameProject:
		_.debounce(async function (e) {
			if (!this.duplicateOneProject) {
				this.activeBtn = true;
				return;
			}
			if (this.selected) {
				WebStorage.setItem('tenantId', this.selected.id);
			}

			if (this.inputModalName && this.inputModalName.length > 3) {

				const workspaceId = this.workspaceSelected.id;
				const search = await this.restApi().checkAvailableNameProject( workspaceId, {
					nome: this.inputModalName,
				});

				if (search) {
					this.messageNameError = this.messageErrorName;
					this.messageNameSuccess = '';
					this.activeBtn = false;

				} else {
					this.messageNameSuccess = this.messageSucessName;
					this.messageNameError = '';
					this.activeBtn = true;
				}
			} else {
				this.messageNameSuccess = '';
				this.messageNameError = '';
				this.activeBtn = false;
			}

			WebStorage.setItem('tenantId', this.tenantId);
		},500),

		duplicateMultiAccount() {
			let accountData = this.selected;
			let savedProjectName = this.inputModalName;
			let accountName = accountData.description;
			const projectId = this.dataModal.projectId || this.dataModal.selectedProjects[0].id;

			try {
				if (this.duplicateOneProject) {
					this.restApi().duplicateProject(this.workspace.id, projectId, {tenantId: accountData.id, workspaceId: this.workspaceSelected.id, nome: this.inputModalName})
					.then((cloneProject) => {
						this.$root.$emit("reloadProject");
						cloneProject.multi = true;
						cloneProject.route = 'duplicateProject';
						cloneProject.workspace = this.workspaceSelected;
						cloneProject.account = accountData;

						this.$store.commit('setLoadingData', cloneProject);
						this.$root.$emit('closeSelectItemsComponent');
						this.$store.commit('activeAlert', {
							message: this.$locale.baseText('project.duplicate.duplicateSuccessMultiOrg', { interpolate: {savedProjectName, savedAccountName: accountName} }),
							link: {
								message: `Acessar Projeto`,
								src: `/organizacao/${cloneProject.account.id}/espacos-de-trabalho/${cloneProject.workspace.id}/projetos/detalhamento-de-projeto/${cloneProject.id}`,
							},
						});
					})
					.catch((error) => {
						return this.$store.commit('activeAlert', {
							message: `Erro na Duplicação de Projeto: ${savedProjectName} da conta ${accountName}. Tente Novamente.`,
							status: 'error',
						});
					});
				} else {
					this.duplicateMultipleProject();
				}

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('project.duplicate.initDuplicate'),
				});

				this.modalBus.$emit('close');

			} catch(e) {
				this.$store.commit('activeAlert', {
					message: e?.notifications[0],
					status: 'error'
				});
			}
		},
		duplicateOneAccount() {
			const savedProjectName = this.inputModalName;
			let accountData = JSON.parse(WebStorage.getItem('accountInfo'));
			let tenantId = accountData.tenantId;
			const projectId = this.dataModal.projectId || this.dataModal.selectedProjects[0].id;
			try {
				if (this.duplicateOneProject) {
					this.restApi().duplicateProject(this.workspace.id, projectId, {tenantId: tenantId, workspaceId: this.workspaceSelected.id, nome: this.inputModalName})
					.then((cloneProject) => {
						this.$root.$emit("reloadProject");
						cloneProject.multi = false;
						cloneProject.route = 'duplicateProject';
						cloneProject.workspace = this.workspaceSelected;
						this.$store.commit('setLoadingData', cloneProject);
						this.$store.commit('activeAlert', {
							message: this.$locale.baseText('project.duplicate.duplicateSuccess', { interpolate: {savedProjectName, savedAccountName: accountData.name} }),
							link: {
								message: `Acessar Projeto`,
								src: `/organizacao/${tenantId}/espacos-de-trabalho/${cloneProject.workspace.id}/projetos/detalhamento-de-projeto/${cloneProject.id}`,
							},
						});

						this.$root.$emit('closeSelectItemsComponent');
					}).catch((error) => {
						this.$store.commit('activeAlert', {
							message: `Erro na Duplicação de Projeto: ${savedProjectName} da conta ${accountData.name}. Tente Novamente.`,
							status: 'error',
						});
					});

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('project.duplicate.initDuplicate'),
					});
				} else {
					this.duplicateMultipleProject();
				}



				this.modalBus.$emit('close');

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e?.notifications,
					status: 'error'
				});
			}
		},
		closeModal() {
			this.modalBus.$emit('close');
		},
		async duplicateMultipleProject() {
			let accountData =  this.selected || JSON.parse(WebStorage.getItem('accountInfo'));
			let tenantId = accountData.tenantId || accountData.id;
			const selectedProjectsId = this.dataModal.selectedProjects.map(item => item.id);
			console.log(accountData)

			this.restApi().duplicateMultipleProject(this.workspace.id, {tenantId: tenantId, workspaceId: this.workspaceSelected.id, prefixoProjeto: this.inputModalName, projetoIds: selectedProjectsId})
			.then((response) => {
				this.$root.$emit("reloadProject");

				this.$store.commit('activeAlert', {
					message: `Múltiplos projetos duplicados com sucesso na conta ${accountData?.description || accountData?.name}`,

				});

				this.$root.$emit('closeSelectItemsComponent');

			}).catch((error) => {
				this.$store.commit('activeAlert', {
					message: `Erro na duplicação de múltipla projetos da conta ${accountData?.description || accountData?.name}. Tente Novamente.`,
					status: 'error',
				});
			});

			this.$store.commit('activeAlert', {
				message: this.$locale.baseText('project.duplicate.initDuplicate'),
			});
		},

		async pagination() {
			if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0') {
				this.page++;
				this.scrollInfinite = true;

				try {
					const response = await this.getAccountTenantV2({ page: this.page, pageSize: 15 });
					const filteredData = response.data.filter(account => account.active && !account.blocked);

					this.organizations.push(...filteredData);
					if (response.data.length < 15) {
						this.scrollInfinite = false;
					}
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			}
		},

		filteredOrganization:
		_.debounce(async function (e) {
			this.scrollInfinite = false;
			const canFilterByPermission = this.tokenData.Level === 'N2' || (this.tokenData.Level !== 'N2' && !this.tokenData.hasPermission);

			if (!this.searchInput.length) {
				this.organizations = this.organizationsTemp;
				this.scrollInfinite = canFilterByPermission ? false : true;

			} else {
				if (canFilterByPermission) {
					this.organizations = this.organizationsTemp?.filter(item => item?.description?.toLowerCase().includes(this.searchInput.toLowerCase()));

				} else {
					this.organizations = [];
					const response = await this.getAccountTenantV2({ search: this.searchInput });
					this.organizations = response.data.filter(item => item.active && !item.blocked);
				}
			}
		}, 500),
	},
});
</script>

<style lang="scss" scoped>
.projects-preview {
	display: flex;
	flex-direction: column;
	height: 238px;
	overflow-y: auto;
	font-size: 13px;
	margin-top: 33px;
	color: rgba(255, 255, 255, 0.88);
}
.title-container {
  display: flex;
  align-items: center;
	margin: 0 0 10px 0px;
}

.title-number {
  border-radius: 50%;
  background-color: rgba(0, 195, 221, 0.16);;
  color: #FFFFFFE0;
  font-size: 16px;
  font-weight: bold;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.title-text {
  font-size: 16px;
  font-weight: bold;
}

.duplicateModal {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	.duplicateModalIcon {
		background-image: url('../assets/closeIcon.svg');
		background-position: center;
		background-repeat: no-repeat;
		height: 32px;
		width: 32px;
		cursor: pointer;
		border-radius: 3px;
	}
	.duplicateModalIcon:hover {
		border: 1px solid #4a638f;
	}
}

.container-cards {
	display: flex;
  margin: 3px 0px;
	justify-content: space-around;
}

.container-input {
  margin: 20px 0 0 0;
}

.step-wrapper {
	margin: 8px 10px 8px 10px;
	vertical-align: middle;
	align-self: start;
}

.step-container {
	min-width: 368px;
	max-width: 401px;
	height: 401px;
	margin: 0px auto;
	background-color: #1c2638;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	border: 1px solid #34476A;
	border-radius: 4px;

	.step-header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 15px 16px;
		color: rgba(255, 255, 255, 0.88);
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;

		&--one {
			background-color: #233149;
			border-bottom: 1px solid #34476a;
		}

		&--two {
			background-color: #1c2638;
		}
	}
}

.step-body {
	margin: 0px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.input-body {
	width: 90%;
}

.step-list {
	color: #FFFFFFE0;
  width: 100%;
  height: 277px;
  padding: 0px 10px 0px 10px;
  overflow: auto;
	font-size: 14px;
}

.container-labels {
	display: flex;
  flex-direction: column;
}

.step-text {
	font-size: 12px;
	color:#FFFFFFA3;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
  padding: 10px;
	text-overflow: ellipsis;
  background: #1C2638;
	border: 1px solid #4A638F;
	border-radius: 4px;
	display: flex;
  align-items: center;

	&:hover {
		background: linear-gradient(0deg, rgba(0, 195, 221, 0.08), rgba(0, 195, 221, 0.08)), #1C2638;
		border: 1px solid #00C3DD;
		color: #00C3DD;

		.step-text {
			color: #00C3DD;
		}

		input[type="radio"] {
			appearance: none;
			margin-right: 10px;

			border: 3px solid #00C3DD;
		}

		input[type="radio"]:checked {
			background-image: radial-gradient(#00C3DD 35%, black 40%);
		}
	}
}

li.selectedItem {
  background: linear-gradient(0deg, rgba(0, 195, 221, 0.08), rgba(0, 195, 221, 0.08)), #1C2638;
	border: 1px solid #00C3DD;
	color: #00C3DD;

	.step-text {
		color: #00C3DD;
	}

	input[type="radio"] {
		appearance: none;
		margin-right: 10px;
		border: 3px solid #00C3DD;
	}

	input[type="radio"]:checked {
		background-image: radial-gradient(#00C3DD 35%, black 40%);
	}
}

input[type="radio"] {
  appearance: none;
	margin-right: 10px;

  border: 3px solid #749BDE;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  background-color: #1C2638;
}

input[type="radio"]:checked {
  background-image: radial-gradient(#749BDE 35%, black 40%);
}

.searchBar {
	position: relative;
  width: 1012px;
  height: 40px;
	display: flex;
  margin: auto;

	.searchBar--input:focus ~ .searchBar--input__icon {
		background-image: url('../assets/icon/lupaBlue.svg');

		&:before {
			width: 2px;
			background: #00C3DD;
		}
	}
	&--input {
		width: 100%;
		padding: 9px 40px 9px 9px;
		background: #223049;
		border: 1px solid #4A638F;
		border-radius: 4px;
		outline: none;
		color: #ffffff;

		&:focus {
			border: 2px solid #00C3DD;
		}

		&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.56);
			opacity: 1; /* Firefox */
		}

		&__icon {
			background-image: url('../assets/icon/lupa.svg');
			background-size: 100%;
			position: absolute;
			top: 50%;
			right: 9px;
			transform: translateY(-50%);
			width: 18px;
			height: 18px;

			&:before {
				content: '';
				width: 1px;
				height: 16px;
				display: block;
				background: #4A638F;
				position: absolute;
				left: -9px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

.container-infoBox {
	padding: 14px 8px;
	background: #1C2D69;
	border-radius: 8px;
  min-width: 329px;
	max-width: 367px;
	height: 307px;
	display: flex;
	column-gap: 8px;
	align-items: center;

	* {
		line-height: 19px;
		color: rgba(255, 255, 255, 0.88);
		font-size: 14px;
	}

	p { margin: 0; }

	b {
		font-weight: 700;
	}
	.infoBox-message {
		flex: 1;
	}

	.icon-infoBox {
		background-image: url('../assets/roundWhiteExclamation.svg');
		width: 20px;
		height: 20px;
		margin: 20px;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.messageProject {
	position: absolute;
  font-size: 12px;
  overflow-wrap: anywhere;
	&.success {
		color: #59D64C;
	}
	&.error {
		color: #FF495F;
	}
}
.iconProject {
	position: absolute;
  width: 23px;
  height: 20px;
  right: 54px;
  bottom: 310px;

	&.successIcon {
		background: url('../assets/icon/greenCheckIcon.svg') no-repeat;
	}
	&.errorIcon {
		background: url('../assets/icon/alert.svg') no-repeat;
	}

	&.successIconOne {
		bottom: 96px;
    right: 25px;
		background: url('../assets/icon/greenCheckIcon.svg') no-repeat;
	}
	&.errorIconOne {
		bottom: 102px;
    right: 25px;
		background: url('../assets/icon/alert.svg') no-repeat;
	}
}

.footer-buttons {
	display: flex;
	margin: 15px;
  float: right;
}

.container-loading {
	display: flex;
  align-items: center;
  align-self: center;
	padding: 22px 0px 18px 0px;
  justify-content: center;
	width: 100%;
	height: 277px;
}
.loading-spin {
  width: 120px;
  height: 120px;
  background-image: url('../assets/loading-modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

:deep {
	.dialog-wrapper .el-dialog {
		width: min-content !important;
	}

	.container-info {
		background: #5C321B;
		padding: 14px 12px;
		* {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
		}
		p {
			margin: 0px 0 0 7px;
		}
		.icon-info {
			background-image: url('../assets/warningIcon.svg');
		}
		.list-duplicate {
			margin: 15px 0 15px 15px;
		}
	}

	.modal-default-success {
		margin: -1px 20px 0px 0px;
	}
}
</style>
