import { getNewWorkflow, getWorkflowTemplate, getNameWorkflow } from '@/api/workflows';
import { DUPLICATE_POSTFFIX, MAX_WORKFLOW_NAME_LENGTH, DEFAULT_NEW_WORKFLOW_NAME } from '@/constants';
import { ActionContext, Module } from 'vuex';
import WorkflowsService from '../services/workflows.service';
import {
	IRootState,
	IWorkflowsState,
	IWorkflowTemplate,
} from '../Interface';

const module: Module<IWorkflowsState, IRootState> = {
	namespaced: true,
	state: {},
	actions: {
		setNewWorkflowName: async (context: ActionContext<IWorkflowsState, IRootState>, options: {projetoId?: string, attempt?: number}): Promise<void> => {
			let newName = '';
			if (!options.attempt) {
				options.attempt = 1;
			} else {
				options.attempt ++;
			}


			try {
				newName = 'Novo Fluxo' + (options.attempt > 1 ? ' ' + options.attempt.toString() : '');
				const nameUnavailable = await getNameWorkflow(context.rootGetters.getRestApiContext, options.projetoId, newName);

				if (nameUnavailable) {
					await context.dispatch('setNewWorkflowName', {projetoId: options.projetoId, attempt: options.attempt});
					return;
				}
			}
			catch (e) {
				// in case of error, default to original name
				newName = DEFAULT_NEW_WORKFLOW_NAME;
			}

			context.commit('setWorkflowName', { newName }, { root: true });
		},
		verifyNameAvailable: async (context: ActionContext<IWorkflowsState, IRootState>, options: {projetoId: string, name: string}): Promise<Boolean> => {
			const nameUnavailable = await getNameWorkflow(context.rootGetters.getRestApiContext, options.projetoId, options.name);
			return !nameUnavailable;
		},


		async patchWorkflowsWorkflowActive({commit}, status) {
			try {
				const { data } = await WorkflowsService.patchWorkflowsWorkflowActive(status);
				return data;
			} catch (error) {
				console.error(`Erro ao alterar status: \n${error}`);
				return Promise.reject(error);
			}
		},

		async deleteWorkflowsId({commit}, id) {
			try {
				const { data } = await WorkflowsService.deleteWorkflowsId(id);
				return data;
			} catch (error) {
				console.error(`Erro ao alterar status: \n${error}`);
				return error;
			}
		},

		async patchWorkflowsWorkflowAutomatic({commit}, status){
			try{
				const {data} = await WorkflowsService.patchWorkflowsWorkflowAutomatic(status);
				return data;
			}catch (error) {
				return Promise.reject(error);
			}
		},

		async patchWorkflowFail({ commit }, workflow) {
			try {
				const { data } = await WorkflowsService.patchWorkflowFail(workflow);
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},

		async getWorkflows({ commit }, payload) {
			try {
				const { data } = await WorkflowsService.getWorkflows(payload);
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},

		async getWorkflowsDetails({ commit }, payload) {
			try {
				const { data } = await WorkflowsService.getWorkflowsDetails(payload);
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},

		async getWorkflowsFails({ commit }, payload) {
			try {
				const { data } = await WorkflowsService.getWorkflowsFails(payload);
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},

		async getWorkflowsId({ commit }, payload) {
			try {
				const { data } = await WorkflowsService.getWorkflowsId(payload);
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},

		async getWorkflowsIdVariable({ commit }, payload) {
			try {
				const { data } = await WorkflowsService.getWorkflowsIdVariable(payload);
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},

		async getWorkflowsIdVersionHistory({ commit }, payload: {id: String, size: Number, page: Number}) {
			try {
				const { data } = await WorkflowsService.getWorkflowsIdVersionHistory(payload.id, payload.size, payload.page);
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}
		},

		async getWorkflowsWorkflowsExecutions({ commit }, workflow) {
			try {
				const { data } = await WorkflowsService.getWorkflowsWorkflowsExecutions(workflow);
				commit('setVariableId', { data });
				return data;
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {

			}
		},

		getDuplicateCurrentWorkflowName: async (context: ActionContext<IWorkflowsState, IRootState>): Promise<string> => {
			const currentWorkflowName = context.rootGetters.workflowName;

			if (currentWorkflowName && (currentWorkflowName.length + DUPLICATE_POSTFFIX.length) >= MAX_WORKFLOW_NAME_LENGTH) {
				return currentWorkflowName;
			}

			let newName = `${currentWorkflowName}${DUPLICATE_POSTFFIX}`;

			try {
				const newWorkflow = await getNewWorkflow(context.rootGetters.getRestApiContext, newName);
				newName = newWorkflow.name;
			}
			catch (e) {
				console.log(e);
			}

			return newName;
		},
		getWorkflowTemplate: async (context: ActionContext<IWorkflowsState, IRootState>, templateId: string): Promise<IWorkflowTemplate> => {
			return await getWorkflowTemplate(templateId);
		},
	},
};

export default module;
