
import { TIMEOUT_EXECUTION_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import AliareInput from '@/components/AliareInput.vue';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'ExecutionListModal',
	components: {
		Modal,
		ModalButtonCancel,
		ModalButtonSuccess,
		AliareInput,
	},
	data() {
		return {
			modalBus: new Vue(),
			TIMEOUT_EXECUTION_MODAL_KEY,
			isLoadingTable: false,
			isLoadingPage: false,
			timeoutInput: this.$store.getters.executionTimeout / 60,
			showMessageError: false,
			messageError: 'O tempo deve estar entre 1 e 60 minutos.',
		};
	},
	computed: {
		isNewWorkflow(): Boolean {
			return this.workflowId === '__EMPTY__' ? true : false;
		},
		workflowId(): number {
			return this.$store.getters.workflowId;
		},
	},
	async mounted () {

	},
	created() {
	},
	methods: {
		async saveSettingTimeout() {
			this.$store.commit('setExecutionTimeout', (parseFloat(this.timeoutInput) * 60) || {});

			this.$store.commit('setWorkflowSettings', {
				timezone: this.$store.getters.timezone,
				executionTimeout: (parseFloat(this.timeoutInput) * 60),
				errorWorkflow: this.$store.getters.errorWorkflow,
			} || {});


			if(!this.isNewWorkflow) {
				try {
					await this.saveCurrentWorkflow();
				} catch (error) {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('timeoutExecution.failSave'),
						status: 'error',
					})
					return;
				}
			}

			this.modalBus.$emit('close');
			this.$store.commit('activeAlert', {
				message: this.$locale.baseText('timeoutExecution.successSave'),
			});
		},
		verifyValue() {
			if (this.timeoutInput <= 60 && this.timeoutInput >= 1) {
				this.showMessageError = false;
				return;
			};

			this.showMessageError = true;
		},
	},
});
