<template>
  <div class="searchBar">
    <div v-if="typeFilter == 'search'">
      <input
        class="searchBar--input"
        type="text"
        :placeholder="$props.placeholder"
        v-model="queryInput"
        @input="searchInfoByName()"
        @focus="inputHasFocus = true"
        ref="searchInput"
      >

      <div class="searchBar--container" :class="{'visible': inputHasFocus || queryInput.length <= 2 }">
        <div
          v-show="userListSearch.length || inputHasFocus"
          class="searchBar--container__itemList"
        >
          <div
            v-for="item in userListSearch"
            :key="item.id?.toString()"
            class="searchBar--container__item--link"
          >
            <div class="optionFilterItem" @click="toggleSelectedItem(item)">
              <div class="inputCheckbox" :class="{ active: isSelected(item) }"></div>
              <p>{{ item.person }}</p>
            </div>
          </div>
        </div>
        <div v-if="!isSearching && !userListSearch.length" class="searchBar--container__item--notFound">
          {{ $locale.baseText('searchBar.users.notFound') }}
        </div>
      </div>
      <div class="searchBar--container" v-if="isSearching && !userListSearch.length" :class="{'visible': queryInput.length > 2 && inputHasFocus }">
        <div
          class="searchBar--container__itemList"
        >
          Carregando...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from 'vue-typed-mixins';
import { ref } from 'vue';
import { mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import { restApi } from '@/components/mixins/restApi';
import OptionFilter from '@/components/OptionFilter';
import { addPictureFromUsers, removeDuplicateUsers } from '@/components/helpers';

export default mixins(restApi).extend({
  name: "ShareUserFilter",
  props: {
    placeholder: String,
    clearInput: Boolean,
    typeFilter: {
      type: String,
      default: 'search',
    },
		initialUsers: [],
    filterOptions: [],
  },
  data() {
    return {
      queryInput: '',
      isSearching: true,
      inputHasFocus: false,
      selectedItems: [],
      userListSearch: [],
      userListSearchTemp: [],
    };
  },
	created() {
		this.getUserList();

	},
  mounted() {
		this.loadCurrentValues();
    this.$refs.searchInput.focus();
  },
  methods: {
    ...mapActions('aliareAccount', ['getUsersByTenantId', 'getUserId']),

    isSelected(item) {
      return this.selectedItems.some(selectedItem => selectedItem.id === item.id);
    },
    toggleSelectedItem(item) {
      if (this.isSelected(item)) {
        // Se o item já estiver na lista de itens selecionados, remova-o.
        const index = this.selectedItems.findIndex(selectedItem => selectedItem.id === item.id);
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        }
      } else {
        // Se o item não estiver na lista de itens selecionados, adicione-o.
        this.selectedItems.push(item);
      }
      this.$emit('selectFilter', this.selectedItems);
    },
    searchInfoByName: debounce(async function (e) {
      try {
        if (this.queryInput.length > 2) {
          this.userListSearch = [];
          this.isSearching = true;
          const payloadUser = {
            search: this.queryInput,
            pageSize: 5,
          };

          try {
            await Promise.all([
              this.getUsersByTenantId(payloadUser),
            ]).then(async (userList) => {
              this.userListSearch = userList[0].data;
            }).catch((err) => {
              console.log(err);
            });
          } catch (e) {
            console.log(e);
          }

        } else {
          this.userListSearch = this.userListSearchTemp;
        }
      } finally {
        this.isSearching = false;
      }
    }, 500),

    clearFilter() {
      this.selectedItems = [];
      this.queryInput = '';
      this.userListSearch = [];
      this.getUserList();
    },
    getUserList() {
      this.getUsersByTenantId({ pageSize: 5 })
      .then(async (userList) => {
          this.userListSearch = userList.data;
          this.userListSearchTemp = userList.data;
      }).catch((err) => {
          console.log(err);
      });
    },
    loadCurrentValues() {
			const usersIds = this.initialUsers;

			if (usersIds && usersIds.length > 0) {
				const allUsersPromises = usersIds.map(userId => this.getUserId(userId));

				Promise.all(allUsersPromises).then(users => {
					this.userListSearch = users;
					this.selectedItems = users;

					this.userListSearch.forEach(user => {
						this.isSelected(user.id.toString());
					});

					this.$emit('selectFilter', this.selectedItems);
				})
				.catch(error => {
					console.error('Erro ao buscar usuários:', error);
				});
			}
		},
  },
  watch: {
    clearInput(newValue) {
      if (newValue !== true) this.clearFilter();
    },
  },
  beforeDestroy() {
    this.$root.$off('loadShare', this.loadSelects);
  },
});
</script>


<style scoped lang="scss">
.searchBar {

	* {
		color: rgba(255, 255, 255, 0.88);
		text-decoration: none;
		line-height: 1;
	}

	&--container {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		top: 39px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		background: #223049;
		border: 1px solid #4A638F;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.32);
		border-radius: 4px;
		padding: 16px;
		width: 100%;
		max-height: 369px;
		overflow-y: scroll;


		&.visible {
			visibility: visible;
			opacity: 1;
		}

		&__item {
			cursor: default;

			&--link {
				font-size: 14px;
				text-transform: capitalize;
				min-height: 40px;
				display: flex;
				align-items: center;
				word-break: break-all;
				cursor: pointer;
			}
			&List {
				display: flex;
				flex-direction: column;
			}
			&--notFound {
				min-height: 40px;
				display: flex;
				align-items: center;
				padding-left: 15px;
				padding-top: 16px;
				padding-bottom: 8px;
				font-size: 14px;
				font-style: italic;
				color: rgba(255, 255, 255, 0.56);
			}
			&--title {
				margin: 0;
				font-weight: 400;
				font-size: 16px;
			}
		}
	}
	&--input {
		width: 100%;
		padding: 9px;
		background: #223049;
		border: 1px solid #4A638F;
		border-radius: 4px;
		outline: none;

		&:focus {
			border: 1px solid #00C3DD;
		}

		&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.56);
			opacity: 1; /* Firefox */
		}
	}
}

.optionFilterItem {
  display: flex;
  align-items: center;
  column-gap: 15px;

  p {
    line-height: 1;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.88);
  }
  .inputCheckbox {
    width: 18px;
    height: 18px;
    border: 2px solid #749BDE;
    flex-shrink: 0;
    &.active {
      border: none;
      background-image: url('../assets/checkIconBlue.svg');
      background-position: center;
      background-size: 18px 18px;
      background-repeat: no-repeat;
    }
  }
}


</style>
