
import { SELECT_NODE_MODAL_KEY } from '../constants';
import Vue from 'vue';
import debounce from 'lodash.debounce';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { removeItemFromArrayByValue } from '@/components/helpers';
import {
	IUserShare,
} from '../Interface';

import { INodeTypeDescription } from 'n8n-workflow';
import { INodeCreateElement } from '@/Interface';
import { HIDDEN_NODES  } from '@/constants';

import { matchesNodeType, matchesSelectType } from '@/components/NodeCreator/helpers';


import NodeIcon from './NodeIcon.vue';
import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import InfoAliare from '@/components/InfoAliare.vue';
import AliareInput from '@/components/AliareInput.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'SelectNodeModal',
	components: {
		Modal,
		AliareInput,
		ModalButtonCancel,
		ModalButtonSuccess,
		InfoAliare,
		NodeIcon,
	},
	props: {
	},
	data() {
		return {
			modalBus: new Vue(),
			SELECT_NODE_MODAL_KEY,
			isLoadingTable: false,
			nodeSelected: false,
			isLoadingPage: false,
			searchInput: '',
		};
	},
	computed: {
		nodeTypes(): INodeTypeDescription[] {
			return this.$store.getters.allNodeTypes;
		},

		searchFilter(): string {
			return this.searchInput.toLowerCase().trim();
		},
		filteredNodeTypes(): INodeCreateElement[] {
			const nodeTypes: INodeCreateElement[] = this.searchItems;
			const filter = this.searchFilter;
			const returnData = nodeTypes.filter((el: INodeCreateElement) => {
				return filter && matchesNodeType(el, filter);
			});

			return returnData;
		},
		searchItems(): INodeCreateElement[] {
			const sorted = [...this.visibleNodeTypes];
			sorted.sort((a, b) => {
				const textA = a.displayName.toLowerCase();
				const textB = b.displayName.toLowerCase();
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});

			return sorted.map((nodeType) => ({
				type: 'node',
				category: '',
				key: `${nodeType.name}`,
				properties: {
					nodeType,
					subcategory: '',
				},
				includedByTrigger: nodeType.group.includes('trigger'),
				includedByRegular: !nodeType.group.includes('trigger'),
			}));
		},
		visibleNodeTypes(): INodeTypeDescription[] {
			return this.nodeTypes
				.filter((nodeType: INodeTypeDescription) => {
					return !HIDDEN_NODES.includes(nodeType.name);
				}).reduce((accumulator: INodeTypeDescription[], currentValue: INodeTypeDescription) => {
					// keep only latest version of the nodes
					// accumulator starts as an empty array.
					const exists = accumulator.findIndex(nodes => nodes.name === currentValue.name);
					if (exists >= 0 && accumulator[exists].version < currentValue.version) {
						// This must be a versioned node and we've found a newer version.
						// Replace the previous one with this one.
						accumulator[exists] = currentValue;
					} else {
						accumulator.push(currentValue);
					}
					return accumulator;
				}, []);
		},

		workspace() {
			return this.$store.getters.workspace;
		},
	},
	mounted () {

	},
	async created() {

	},
	methods: {
		selectNode(node) {
			this.nodeSelected = node;
		},

		emitSelectedNodeIntegration() {
			this.$store.commit('addNodeIntegration', this.nodeSelected);
			this.modalBus.$emit('close');
		},
	},
});
