
export default {
	props: {

	},
	methods: {
	},
	computed: {

	},
};
