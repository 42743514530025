import AlertService from '../services/alert.service';

export default {
	namespaced: true,

	state: {},

	getters: {},

	actions: {
		async postAlert(post) {
			try {
				const { data } = await AlertService.postAlert(post);
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
	},

	mutations: {

	},
}
