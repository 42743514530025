
import Vue from 'vue';

import {
	IUpdateInformation,
} from '@/Interface';

import ParameterInput from '@/components/ParameterInput.vue';
import InputHint from './ParameterInputHint.vue';

export default Vue
	.extend({
		name: 'ParameterInputFull',
		components: {
			ParameterInput,
			InputHint,
		},
		data() {
			return {
				focused: false,
				inputHasValue: false,
			};
		},
		props: [
			'displayOptions',
			'isReadOnly',
			'parameter',
			'path',
			'value',
		],
		mounted() {
			this.verifyValues();
		},
		methods: {
			onFocus() {
				this.focused = true;
			},
			onBlur() {
				this.blurredEver = true;
				this.focused = false;
			},

			getArgument (argumentName: string): string | number | boolean | undefined {
				if (this.parameter.typeOptions === undefined) {
					return undefined;
				}

				if (this.parameter.typeOptions[argumentName] === undefined) {
					return undefined;
				}

				return this.parameter.typeOptions[argumentName];
			},

			verifyValues(data) {
				if(!!this.value || typeof data !== 'undefined') {
					this.inputHasValue = true;
				} else {
					this.inputHasValue = false;
				}
			},
			valueChanged (parameterData: IUpdateInformation) {
				this.verifyValues(parameterData);
				this.$emit('valueChanged', parameterData);
			},
		},
		watch: {
			value (newValue) {
				if (newValue !== '') {
					this.inputHasValue = true;
				}
			},
		},

	});
