<template>
	<div class="aliareInput">
		<label slot="prefix" :class="{ focused: isFocused, focusedSelected: selected || value}" class="labelInput">
			{{ $props.label }}
		</label>

		<treeselect
			:beforeClearAll="beforeClearAll"
			:clearValueText="clearValueText"
			:closeOnSelect="closeOnSelect"
			:noResultsText="noResultsText"
			:placeholder="placeholder"
			:alwaysOpen="alwaysOpen"
			:searchable="searchable"
			:clearable="clearable"
			:disabled="disabled"
			:multiple="multiple"
			:maxHeigh="maxHeigh"
			:options="options"
			:limit="limit"
			:label="label"
			:value="value"
			@select="onSelect"
			@deselect="onDeselect"
			@open="onOpen()"
			@close="onClose()"
		>
		</treeselect>
	</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
	components: {
    Treeselect,
  },
  props: {
    name:{
			type: String,
			default: '',
		},

    value: {
      type: null,
    },

		limit: {
			type: Number,
			default: 5,
		},

		beforeClearAll: {
			type: Function,
			default: () => true,
		},

		alwaysOpen: {
			type: Boolean,
			default: false,
		},

		closeOnSelect: {
			type: Boolean,
			default: false,
		},

    label: String,

    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: [],
    },

		clearValueText: {
			type: String,
			default: 'Limpar',
		},

    multiple: {
      type: Boolean,
      default: false
    },

		clearable: {
			type: Boolean,
      default: false
		},

    placeholder: {
      type: String,
      default: "",
    },

		searchable: {
			type: Boolean,
      default: false,
		},

		maxHeigh: {
			Type: Number,
			default: 300,
		},

		noResultsText: {
			type: String,
			default: "Nenhum resultado encontrado...",
		},

  },
	data() {
		return {
			selected: this.value,
			isFocused: false,
		}
	},
	watch: {
    value(newValue) {
      this.internalValue = newValue;
    },
  },
	methods: {
		onSelect(type) {
			this.isFocused = true;
			this.selected = type;
			this.$emit('select', type);
		},
		onDeselect(type) {
			this.isFocused = false;
			this.selected = type;
			this.$emit('deselect', type);
		},
		onOpen() {
			this.$emit('open');
			this.isFocused = true;
		},
		onClose() {
			if(this.selected !== null || this.value !== null) this.isFocused = true;
			this.isFocused = false;

			this.$emit('close');
		},

	},
}
</script>

<style lang="scss" scoped>
:deep  {
	.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) .vue-treeselect__control:hover {
    border-color: #00c3dd;
	}
	.vue-treeselect--single .vue-treeselect__input {
    background: #223049;
		color: #f2f2f2;
	}
	.vue-treeselect__single-value {
    color: #00C3DD;
}
	.vue-treeselect--open .vue-treeselect__control {
    border: 2px solid #00c3dd;
		background: #223049;
	}

	.vue-treeselect--multi .vue-treeselect__input {
		color: #f2f2f2;
	}

	.vue-treeselect--open-below .vue-treeselect__menu, .vue-treeselect--open-above .vue-treeselect__menu{
    background-color: #223049;
		color: #f2f2f2;
		border-color: #4A638F;
		max-height: 150px !important;
	}

	.vue-treeselect__option--highlight {
    background: #00C3DD14;
	}

	.vue-treeselect__checkbox--unchecked {
    border-color: #00C3DD;
    background: #00C3DD14;
	}

	.vue-treeselect__control {
    padding-left: 5px;
    padding-right: 5px;
    display: table;
    table-layout: fixed;
    width: 254px;
    height: 40px;
    border: 1px solid #4A638F;
    border-radius: 5px;
    background: #223049;
	}

	.vue-treeselect__checkbox {
    display: block;
    margin: auto;
    width: 18px;
    height: 18px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
    position: relative;
    transition: 200ms all cubic-bezier(0.075, 0.82, 0.165, 1);
		background-color: #00C3DD14;
	}

	.vue-treeselect__checkbox--checked {
    border-color: #00C3DD;
	}

	.vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
    border-color: 2px solid #749bde;
    background: #749bde;
	}

	.vue-treeselect__check-mark {
    width: 15px;
    height: 15px;
    background-image: url('../assets/blueCheckboxCheck.svg');
    background-size: 17px 17px;
		background-position: center;
		left:0px;
		top:0px;
	}

	.vue-treeselect:not(.vue-treeselect--disabled) .vue-treeselect__control-arrow-container:hover .vue-treeselect__control-arrow{
		color: #f2f4f400;
	}

	.vue-treeselect__control-arrow--rotated {
		color: #00C3DD;
	}

	svg.vue-treeselect__control-arrow--rotated {
		background-image: url('../assets/arrowIconBlue.svg');
		background-repeat: no-repeat;
		background-position: center;
	}

	.vue-treeselect__control-arrow {
    width: 9px;
    height: 9px;
    background-repeat: no-repeat;
		background-image: url('../assets/arrowIconGray.svg');
    color: #f2f4f400;
		background-position: center;
	}

	.vue-treeselect--single .vue-treeselect__option--selected {
    background: #00C3DD14;
		color:#00C3DD;
		background-image: url('../assets/icon/blueCheckIcon.svg');
		background-repeat: no-repeat;
    background-position-x: 220px;
    background-position-y: center;
	}

	.vue-treeselect__multi-value-item {
    background: #1c2638;
    color: #00c3dd;
	}

	.vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove {
    color: #00c3dd;
	}

	.vue-treeselect__value-remove {
    color: #749bde;
		border-left: none;
	}

	.vue-treeselect__x-container:hover {
    color: #00c3dd;
	}

	.vue-treeselect__placeholder, .vue-treeselect__single-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
		line-height: 38px;
		color: #fbf9f9;
	}

	.vue-treeselect__label {
		font-size: 14px;
    font-weight: 400;
	}

	.vue-treeselect__value-container {
    white-space: nowrap;
	}

	.vue-treeselect__tip-text {
    color: white;
	}

	.vue-treeselect--disabled .vue-treeselect__control {
    background: #2E3748;
		border: 1px solid #2E3748;
	}

	.vue-treeselect--disabled .vue-treeselect__control-arrow-container {
    cursor: default;
		display: none;
	}
}

.select-all{
	display: block;
  margin: 7px;
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
  position: relative;
  transition: 200ms all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.aliareInput {
	width: 100%;
	position: relative;

	&--input {
		width: 100%;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #4A638F;
		background: #223049;
		padding: 0 7px;
		color: rgba(255, 255, 255, 0.88);

		&:focus {
			border-width: 2px;
			border-color: #00c3dd;
			outline-width: 0;
		}
	}
}

.labelInput {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all .4s;
		width: max-content;
		line-height: 1;
		font-size: 14px;
		padding: 0 5px;
		left: 7px;
		color: rgba(255, 255, 255, 0.56);

		&.focused {
			background: #1C2638;
			color: #00C3DD;
			top: -1px;
			font-size: 13px;
			z-index: 999;
		}
		&.focusedSelected {
			background: #1C2638;
			top: -1px;
			font-size: 13px;
			color: rgba(255, 255, 255, 0.64);
			z-index: 9;
		}
	}

</style>
