
import { RETRY_EXECUTION_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import {
	IWorkflowDataUpdate,
	IWorkflowSettings,
} from '../Interface';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import moment from 'moment';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'RetryExecutionModal',
	components: {
		Modal,
		ModalButtonSuccess,
		ModalButtonCancel,
	},
	data() {
		return {
			modalBus: new Vue(),
			RETRY_EXECUTION_MODAL_KEY,
			activeRetryExecutionOriginal: false,
			activeRetryExecutionRecent: false,
		};
	},
	computed: {
		executionId() {
			return this.$store.getters.activeExecutionId;
		},

	},
	mounted () {

	},
	methods: {
		async retryExecution () {
			const loadWorkflow = this.activeRetryExecutionRecent ? true : false;
			try {
				const retrySuccessful = this.restApi().retryExecution(this.executionId, loadWorkflow);
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('executionsList.showError.retryExecution.title'),
				);
			}
			this.$store.commit('setActiveExecutionId', null);
			this.modalBus.$emit('close');
			this.$store.commit('activeAlert', {
				message: loadWorkflow ? this.$locale.baseText('retryExecutionModal.initRetryRecent') : this.$locale.baseText('retryExecutionModal.initRetryOriginal'),
			});
		},
		activeCard(second) {
			if (second) {
				this.activeRetryExecutionOriginal = true;
				this.activeRetryExecutionRecent = false;
			} else {
				this.activeRetryExecutionOriginal = false;
				this.activeRetryExecutionRecent = true;
			}
		},
	},
});
