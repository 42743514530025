
import mixins from 'vue-typed-mixins';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from './mixins/showMessage';

export default mixins(
	showMessage,
	nodeHelpers,
).extend({
	name: 'InlineNameEdit',
	props: {
		name: {
			type: String,
		},
		subtitle: {
			type: String,
		},
		type: {
			type: String,
		},
		verifyCredential: {
			type: Boolean,
		},
		suggestName: {
			type: Boolean,
		},
	},
	data() {
		return {
			isNameEdit: false,
			nameOriginal: this.name.toString(),
			messageNameError: '',
			messageNameSuccess: '',
			isLoadingName: true,
		};
	},
	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	mounted() {
		if (this.suggestName) {
			this.isLoadingName = true;
			this.suggestionName();
		} else {
			this.isLoadingName = false;
		}
	},
	methods: {
		onNameEdit(value: string) {
			this.$emit('input', value, !!this.messageNameError);
		},
		enableNameEdit() {
			if (this.isLoadingName) return;
			this.isNameEdit = true;

			setTimeout(() => {
				const input = this.$refs.nameInput as HTMLInputElement;
				if (input) {
					input.focus();
				}
			}, 0);
		},
		async verifyCredentialNameAvailable() {
			this.messageNameError = '';
			this.messageNameSuccess = '';

			if(this.nameOriginal === this.name) {
				this.$emit('nameAvailable', true);
				return;
			}
			const search = await this.restApi().checkAvailableNameCredential(this.workspace.id, {
				nome: this.name,
			});

			if (search) {
				this.messageNameError = this.$locale.baseText('credentialEdit.credentialEdit.inlineNameMessageUnavailable', { interpolate: { savedCredentialName: this.name } });
			} else {
				this.messageNameSuccess = this.$locale.baseText('credentialEdit.credentialEdit.inlineNameMessageAvailable', { interpolate: { savedCredentialName: this.name } });
			}

			this.$emit('nameAvailable', !this.messageNameError);
		},

		async suggestionName(options: {attempt?: number}) {
			let newName;
			let nameUnavailable;
			if (!options?.attempt) {
				options = {attempt:1};
			} else {
				options.attempt ++;
			}

			try {
				newName = this.name + (options.attempt > 1 ? ' ' + options.attempt.toString() : '');

				nameUnavailable = await this.restApi().checkAvailableNameCredential(this.workspace.id, {
					nome: newName,
				});
			}
			catch (e) {
				console.error(e);
			}

			if (nameUnavailable) {
				await this.suggestionName({attempt: options.attempt});
				return;
			}

			this.messageNameError = '';
			this.messageNameSuccess = '';

			if (nameUnavailable) {
				this.messageNameError = this.$locale.baseText('credentialEdit.credentialEdit.inlineNameMessageUnavailable', { interpolate: { savedCredentialName: newName } });
			} else {
				this.messageNameSuccess = this.$locale.baseText('credentialEdit.credentialEdit.inlineNameMessageAvailable', { interpolate: { savedCredentialName: newName } });
			}

			this.nameOriginal = newName;
			this.name = newName;
			this.isLoadingName = false;
			this.$emit('input', newName);
			this.$emit('nameAvailable', !this.messageNameError);
		},
		disableNameEdit() {
			if (!this.name) {
				this.$refs.inlineName.click();

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('credentialEdit.credentialEdit.inlineNameEmpty'),
					status: 'alert',
				});
			} else if (this.verifyCredential && this.isNameEdit) {
					this.verifyCredentialNameAvailable();
			}

			this.isNameEdit = false;
		},
	},
});
