<template>
	<button class="modal-default-cancel" :style="btnStyles" :class="{'active-btn': active && !alwaysActive, 'disable-btn': (!active && !alwaysActive) || loadingAction, 'alwaysActive': alwaysActive && !loadingAction, 'loadingAction': loadingAction}" @click="$emit('click')">
		<div v-if="loadingAction" class="modal-default-cancel--loading-gif"></div>
		<div v-else-if="$props.trashIcon" class="modal-default-cancel--trash-icon"></div>
		<div v-else-if="$props.clearIcon" class="modal-default-cancel--clear-icon"></div>
		<div v-else-if="$props.userInactveIcon" class="modal-default-cancel--userInactve-icon"></div>
		<div v-else-if="$props.forbiddenIcon" class="modal-default-cancel--forbidden-icon"></div>
		<div v-else-if="$props.shovelIcon" class="modal-default-cancel--shovel-icon"></div>
		<div v-else-if="$props.workspaceInactiveIcon" class="modal-default-cancel--inactiveWorkspace-icon"></div>
		<div v-else-if="$props.stopIcon" class="modal-default-cancel--stop-icon"></div>
		<div v-else-if="$props.suspendIcon" class="modal-default-cancel--suspend-icon"></div>

		<div v-else class="modal-default-cancel--icon"></div>

		<span v-if="!$props.titleButton">{{ $locale.baseText('modalButtonDefaultCancel.titleButton') }}</span>
		<span v-else>{{ $props.titleButton }}</span>

	</button>
</template>

<script>
export default {
	props: {
		loadingAction: {
			type: Boolean,
			default: false,
		},
		titleButton: {
			type: String,
			default: '',
		},
		trashIcon: {
			type: Boolean,
			default: false,
		},
		clearIcon: {
			type: Boolean,
			default: false,
		},
		userInactveIcon: {
			type: Boolean,
			default: false,
		},
		forbiddenIcon: {
			type: Boolean,
			default: false,
		},
		shovelIcon: {
			type: Boolean,
			default: false,
		},
		workspaceInactiveIcon: {
			type: Boolean,
			default: false,
		},
		stopIcon: {
			type: Boolean,
			default: false,
		},
		suspendIcon: {
			type: Boolean,
			default: false,
		},
		active: {
			type: Boolean,
			default: false,
		},
		alwaysActive: {
			type: Boolean,
			default: true,
		},
		width: {
			type: String,
			default: 'initial',
		},
	},
	computed: {
		btnStyles() {
		return {
        "width": `${this.width}px`,
      };
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-default-cancel {
	width: initial;
	padding: 0 16px;
	height: 40px;
	color: #ff495f;
	background: #1c2638;
	border: 1px solid #ff495f;
	box-sizing: border-box;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
	column-gap: 8px;
	line-height: 1;

	&.alwaysActive {
		background: #24324C;
		border: 1px solid #ff495f;
		color: #ff495f;

		&:hover {
			background-color: rgba(255, 73, 95, 0.08);
		}
	}

	&.disable-btn {
		background: rgba(255, 255, 255, 0.08);
		color: rgba(255, 255, 255, 0.4);
		pointer-events: none;
		box-shadow: none;
		border: none;

		.modal-default-cancel--icon {
			background-image: url('../assets/closeIcon.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.modal-default-cancel--trash-icon {
			background-image: url('../assets/deleteGrey.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.modal-default-cancel--userInactve-icon {
			background-image: url('../assets/userInactiveGray.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.modal-default-cancel--forbidden-icon {
			background-image: url('../assets/notStoredIcon.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.modal-default-cancel--shovel-icon {
			background-image: url('../assets/shovelIconHover.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.modal-default-cancel--inactiveWorkspace-icon {
			background-image: url('../assets/workspaceInactiveGray.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}
		.modal-default-cancel--stop-icon {
			background-image: url('../assets/stopExecutionIconGray.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: initial;
		}
		.modal-default-cancel--suspend-icon {
			background-image: url('../assets/suspendIconGray.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: initial;
		}
	}
	&--loading-gif {
		background-image: url('../assets/mini-loading-gif.gif');
		width: 15px;
		height: 15px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&--icon {
		background-image: url('../assets/closeIconRed.svg');
		height: 14px;
		width: 14px;
	}

	&--trash-icon {
		background-image: url('../assets/delete.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 18px;
		width: 14px;
	}
	&--clear-icon {
		background-image: url('../assets/clearRed.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 18px;
		width: 14px;
	}
	&--userInactve-icon {
		background-image: url('../assets/userInactive.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 18px;
		width: 18px;
	}
	&--forbidden-icon {
		background-image: url('../assets/notStoredIconRed.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 18px;
		width: 18px;
	}
	&--shovel-icon {
		background-image: url('../assets/shovelIconRed.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 15px;
		width: 16px;
	}
	&--inactiveWorkspace-icon {
		background-image: url('../assets/workspaceInactive.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 23px;
		width: 23px;
	}
	&--stop-icon {
		background-image: url('../assets/stopExecutionIconRed.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: initial;
		height: 26px;
		width: 26px;
	}
	&--suspend-icon {
		background-image: url('../assets/suspendIconRed.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: initial;
		height: 26px;
		width: 26px;
	}
}

</style>
