
export default {
	data() {
		return {
			selected: this.selectedValue,
			placeHolder: '',
			isFocused: false,
			oneOption: false,
		};
	},
	props: {
		arrayList: {
			type: [],
		},
		placeText: {
			type: String,
			default: 'Pesquisar',
		},
		labelText: {
			type: String,
		},
		selectedValue: {
			type: Object,
			default: () => ({}),
		},
		blockSelect: {
			type: Boolean,
			default: false,
		},

	},
	methods: {
		onSelect(data) {
			this.selected = data.id;
			this.$emit('change', data.id, data);
		},
		onFocus() {
			this.$emit('visible-change');
			this.isFocused = !this.isFocused;
		},
	},
	beforeDestroy() {
		this.selected = '';
		this.$emit('change', this.selected);
	},
	watch: {
		arrayList:  {
			handler(newQuestion) {
				this.$emit('oneOption', this.$props.arrayList[0].tenantId, this.$props.arrayList[0]);
				this.oneOption = true;
			},
      immediate: true,
		},
	},
};
