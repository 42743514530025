
import mixins from "vue-typed-mixins";

import { IExecutionResponse } from "../../../Interface";

import { titleChange } from "@/components/mixins/titleChange";
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import moment from 'moment';
import InfoBox from '@/components/InfoBox.vue';
import { workflowPermission } from '@/components/mixins/permissions/workflow';


import WorkflowNameShort from "@/components/WorkflowNameShort.vue";
import ReadOnly from "@/components/MainHeader/ExecutionDetails/ReadOnly.vue";

export default mixins(
	titleChange,
	nodeHelpers,
	workflowPermission,
).extend({
	name: "ExecutionDetails",
	components: {
		WorkflowNameShort,
		ReadOnly,
		InfoBox,
	},
	data() {
		return {
			executionActive: '',
		};
	},
	computed: {
		executionId(): string | undefined {
			return this.$route.params.id;
		},
		executionFinished(): boolean {
			const fullExecution = this.$store.getters.getWorkflowExecution;

			return !!fullExecution && fullExecution.finished;
		},
		executionWaiting(): boolean {
			const fullExecution = this.$store.getters.getWorkflowExecution;

			return !!fullExecution && !!fullExecution.waitTill;
		},
		workflowExecution(): IExecutionResponse | null {
			return this.$store.getters.getWorkflowExecution;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		redirectWorkflowExisting(workflowId, viewMode) {
			this.$router.push({
				name: 'NodeViewExisting',
				params: { name: workflowId, mode: 'Visualizar'},
			});
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		formatDuration(timestamp) {
			if (timestamp < '00:00:01') {
				return '00h:00min:01s';
			} else {
				let hours = timestamp.substring(0, 2) + 'h:';
				let minutes = timestamp.substring(3, 5) + 'min:';
				let seconds = timestamp.substring(6, 8) + 's';

				return hours + minutes + seconds;
			}
		},
		async getActiveExecution() {
			this.executionActive = await this.restApi().getExecutionById(this.workspace.id, this.executionId);
		},
		async openWorkflow(workflowId: string) {
			this.$titleSet(this.workflowName, "IDLE");
			// Change to other workflow
			this.$router.push({
				name: "NodeViewExisting",
				params: { name: workflowId },
			});
		},
	},
	mounted() {
		this.getActiveExecution();
	},
});
