
import mixins from "vue-typed-mixins";

import WebStorage from '@/common/WebStorage';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { titleChange } from '@/components/mixins/titleChange';

import moment from 'moment';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import WorkflowNameShort from "@/components/WorkflowNameShort.vue";
import ReadOnly from "@/components/MainHeader/ExecutionDetails/ReadOnly.vue";
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import { DUPLICATE_MODAL_KEY } from '@/constants';

import ScrollInfinite from '@/components/ScrollInfinite.vue';


export default mixins(
	nodeHelpers,
	workflowHelpers,
	titleChange,
).extend({
	name: "VersionHistoryDetails",
	components: {
		WorkflowNameShort,
		ReadOnly,
		ModalButtonSuccess,
		ModalButtonCancel,
		ScrollInfinite,
		AlertNotificationModal,
	},
	data() {
		return {
			currentVersion: this.$store.getters.getWorkflowVersionData,
			showSelectVersion: false,
			historyVersionData: [],
			page: 1,
			scrollInfiniteContinue: true,
			showAlert: false,
		};
	},
	computed: {
		alertNotificationDescription() {
			return this.$locale.baseText('VersionHistoryDetails.confirmMessage.workflowRestore.message', {
				interpolate: { workflowName: this.workflowName },
			});
		},
		workflowVersionData() {
			return this.$store.getters.getWorkflowVersionData;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		redirectWorkflowVersion(versionId, workflowId) {
			try {
				console.log(workflowId);
				this.$router.push({
					name: 'VersionById',
					params: { versionid: versionId, workflowid: workflowId },
				});

				this.hideSelectVersion();
			} catch (e) {
				console.error(e);
			};
		},

		openModalCopy() {
			this.$store.dispatch('ui/openModal', DUPLICATE_MODAL_KEY);
		},

		hideSelectVersion() {
			this.showSelectVersion = false;
		},
		onFocus() {
			this.isFocused = !this.isFocused;
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		async restoreVersion() {
			try {
				let	data = await this.restApi().getWorkflow(this.workspace.id, this.workflowVersionData.workflowId);

				delete data.modificadoEmDataHora;
				delete data.modificadoPorUsuario;
				delete data.triggerError;
				delete data.tenant;

				data.workflowData.nodes = this.workflowVersionData.workflowData.nodes;
				data.projetoId = data.projeto.id;
				delete data.projeto;
				data.workflowData.connections = this.workflowVersionData.workflowData.connections;

				const workflowData = await this.restApi().updateWorkflow(this.workspace.id, data.id ,data);

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('VersionHistoryDetails.saveCopySuccess', { interpolate: {savedWorkflowName: workflowData.nome, savedProjectName: '', savedAccountName: WebStorage.getItem("accountName")} }),
				});

				this.showAlert = false;
				this.openWorkflow(workflowData.id);
			} catch(e) {
				console.error(e);
				this.$store.commit('activeAlert', {
					message: e?.notifications[0] || e,
					status: 'error'
				});
			}
		},

		async openWorkflow(workflowId: string) {
			this.$titleSet(this.workflowName, "IDLE");
			// Change to other workflow
			this.$router.push({
				name: "NodeViewExisting",
				params: { name: workflowId },
			});
		},
		async getHistoryVersion(resetPage) {
			if (resetPage) {
				this.page = 1;
			}

			try {
				await this.restApi().getWorkflowHistoryVersion(this.workspace.id, this.workflowVersionData.workflowId, { page: this.page })
					.then((response) => {
						if (this.page > 1) {
							this.historyVersionData.push(...response);
						} else {
							this.historyVersionData = response;
						}

						this.page ++;

						response.length == 10 ? this.scrollInfiniteContinue = true : this.scrollInfiniteContinue = false;
					});
			} catch (error) {
				console.error(error);
				return;
			}

		},
	},
	watch: {
		workflowVersionData(newValue) {
			if (newValue != null) {
				this.getHistoryVersion(true);
			}
		}
	},
	mounted() {
	},
});
