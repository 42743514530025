
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { FILTER_PANEL_MODAL_KEY } from '../constants';
import { showMessage } from '@/components/mixins/showMessage';
import { searchPermissions, removeItemFromArrayByValue } from '@/components/helpers';

import TimePickerAliare from '@/components/TimePickerAliare.vue';
import Modal from './Modal.vue';
import InfoBox from '@/components/InfoBox.vue';
import SelectTree from '@/components/SelectTree.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AliareInput from '@/components/AliareInput.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import SwitchAliare from '@/components/SwitchAliare.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import { workflowPermission } from '@/components/mixins/permissions/workflow';
import WebStorage from '@/common/WebStorage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { mapActions } from 'vuex';


import moment from 'moment';



export default mixins(
	restApi,
	showMessage,
	workflowHelpers,
	workflowPermission,
).extend({
	name: 'FilterPanelModal',
	components: {
		Modal,
        TimePickerAliare,
		InfoBox,
		SelectTree,
		SwitchAliare,
		ModalButtonCancel,
		AliareInput,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
		},
	},
	data() {
		return {
			isLoading: false,
			showAlert: {
				mode: '',
				active: false,
			},
            form: {
                date: {
                    dateString: this.$store.getters.dateFilterPanel.dateString,
                    dateStartUtc: moment().local().utc(),
                    dateEndUtc: '',
                    intervalStart: '',
                    intervalStartTime: '00:00',
                    intervalEnd: '',
                    intervalEndTime: '00:00',
                },
                organizationsSelected: [],
                organizationsSelectedIds: [],
                resultWorkflowFilter: [
                    'Sucesso',
                    'Falha',
                    'Alerta',
                    'Nunca executado',
                    'Executando',
                    'Não Armazenado'
                ],
                resultExecutionFilter: [
                    'Sucesso',
                    'Falha',
                    'Alerta',
                    'Nunca executado',
                    'Executando',
                    'Não Armazenado'
                ],
            },
            searchOrganization: '',
            searchResultWorkflow: '',
            searchResultExecution: '',
            showOrganizationOptions: false,
            showResultWorkflowOptions: false,
            showResultExecutionOptions: false,
			tokenData: WebStorage.getItem('tokenData'),
            organizations: '',
            modeDate: '',
			modalBus: new Vue(),
			FILTER_PANEL_MODAL_KEY,
            filterResultOptions: [
                'Sucesso',
                'Falha',
                'Alerta',
                'Nunca executado',
                'Executando',
                'Não Armazenado'
            ],
		};
	},
	async created() {
		this.isLoading = true;
		
        this.getOrganizations();

		this.isLoading = false;
	},
	computed: {
        organizationsFiltered() {
            if (!this.searchOrganization) return this.organizations;

            return this.organizations.filter(organization => organization?.description?.toLowerCase().includes(this.searchOrganization.toLowerCase()))
        },
        resultWorkflowFiltered() {
            if (!this.searchResultWorkflow) return this.filterResultOptions;

            return this.filterResultOptions.filter(filter => filter?.toLowerCase().includes(this.searchResultWorkflow.toLowerCase()))
        },
        resultExecutionFiltered() {
            if (!this.searchResultExecution) return this.filterResultOptions;

            return this.filterResultOptions.filter(filter => filter?.toLowerCase().includes(this.searchResultExecution.toLowerCase()))
        },
        
        todayText() {
            const localDate = moment().local().format('DD [de] MMMM [de] YYYY');
            return `Hoje, ${localDate}`;
        },
        lastSevenText() {
            const localDate = moment().local().format('DD/MM/YYYY');
            const substractDate = moment().local().subtract(7, 'days').format('DD/MM/YYYY');
            return `Últimos 7 dias, de ${substractDate} até ${localDate}`;
        },
        lastThirtyDaysText() {
            const localDate = moment().local().format('DD/MM/YYYY');
            const substractDate = moment().local().subtract(30, 'days').format('DD/MM/YYYY');
            return `Últimos 30 dias, de ${substractDate} até ${localDate}`;
        },
        lastSixtyDaysText() {
            const localDate = moment().local().format('DD/MM/YYYY');
            const substractDate = moment().local().subtract(60, 'days').format('DD/MM/YYYY');
            return `Últimos 60 dias, de ${substractDate} até ${localDate}`;
        },
		keepText() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return 'Manter Fluxo de Falha';
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Manter parametrização'
			}
		},

		cancelText() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return this.$locale.baseText('workflowDetails.patchFlowFail.removeFailureFlow');
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Cancelar parametrização'
			}
		},

        allResultWorkflowSelected() {
            if (this.filterResultOptions?.length == this.form.resultWorkflowFilter?.length) return true;
            return false;
        },

        partialResultWorkflowSelected() {
			return this.filterResultOptions?.length != this.form.resultWorkflowFilter?.length && this.form.resultWorkflowFilter?.length > 0;
		},

        allResultExecutionSelected() {
            if (this.filterResultOptions?.length == this.form.resultExecutionFilter?.length) return true;
            return false;
        },

        partialResultExecutionSelected() {
			return this.filterResultOptions?.length != this.form.resultExecutionFilter?.length && this.form.resultExecutionFilter?.length > 0;
		},

		alertNotificationDescription() {
			if (this.showAlert.mode == 'resetFailWorkflow') {
				return this.$locale.baseText(`Ao parar de usar o <b>Fluxo de Falha</b>, caso a execução do fluxo que você está criando resulte em <b>Falha</b>, não haverá o acionamento de um <b>Fluxo de Falha</b>. O que deseja fazer?`);
			} else if (this.showAlert.mode == 'cancelConfigSettings') {
				return 'Todas as parametrizações que estão sendo feitas serão excluídas e você não poderá recuperá-las. O que você deseja fazer?'
			}
		},
		pageTitleMode() {
			return 'Opções de Filtros';
		},
		workflowId(): number {
			return this.$store.getters.workflowId;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		...mapActions('aliareAccount', ['getAccountTenantV2']),

        addOrRemoveOrganization(organization) {
			if (this.verifyOrganizationSelected(organization.id)) {
				removeItemFromArrayByValue(this.form.organizationsSelectedIds, organization.id);
                const removedArray = this.form.organizationsSelected.filter(organizationSelected => organizationSelected.id != organization.id);
                this.form.organizationsSelected = removedArray;
			} else {
				this.form.organizationsSelected.push({id: organization.id, description: organization.description});
                this.form.organizationsSelectedIds.push(organization.id);
			}
		},

        addOrRemoveResultWorkflow(status) {
			if (this.verifyResultWorkflowSelected(status)) {
				removeItemFromArrayByValue(this.form.resultWorkflowFilter, status);
			} else {
				this.form.resultWorkflowFilter.push(status);
			}
		},

        selectAllResultWorkflow() {
            this.filterResultOptions.forEach(filterResult => {

                if (this.verifyResultWorkflowSelected(filterResult)) return;

                this.form.resultWorkflowFilter.push(filterResult);
                
            });
        },

        deselectAllResultWorkflow() {
            this.filterResultOptions.forEach(filterResult => {
                removeItemFromArrayByValue(this.form.resultWorkflowFilter, filterResult);
            });
        },

        addOrRemoveResultExecution(status) {
			if (this.verifyResultExecutionSelected(status)) {
				removeItemFromArrayByValue(this.form.resultExecutionFilter, status);
			} else {
				this.form.resultExecutionFilter.push(status);
			}
		},

        selectAllResultExecution() {
            this.filterResultOptions.forEach(filterResult => {

                if (this.verifyResultExecutionSelected(filterResult)) return;

                this.form.resultExecutionFilter.push(filterResult);
                
            });
        },

        deselectAllResultExecution() {
            this.filterResultOptions.forEach(filterResult => {
                removeItemFromArrayByValue(this.form.resultExecutionFilter, filterResult);
            });
        },
        
        verifyOrganizationSelected(organizationId) {
			return this.form.organizationsSelectedIds.find(organizationIdArray => organizationIdArray === organizationId);
		},
        verifyResultWorkflowSelected(status) {
			return this.form.resultWorkflowFilter.find(filterSelected => filterSelected === status);
		},

        verifyResultExecutionSelected(status) {
			return this.form.resultExecutionFilter.find(filterSelected => filterSelected === status);
		},
        resetInputOrganization() {
           this.showOrganizationOptions = false;
           this.searchOrganization = '';
        },
        resetInputResultWorkflow() {
           this.showResultWorkflowOptions = false;
        },
        resetInputResultExecution() {
           this.showResultExecutionOptions = false;
        },
        async getOrganizations() {
			const response = await searchPermissions(this.tokenData.sub, this.tokenData.Tenant);

			if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0' && response) {
				const payload = { pageSize: 9999 };

				try {
					const response = await this.getAccountTenantV2(payload);

					const filteredData = response.data.filter(account => account.active && !account.blocked);
					this.organizations = filteredData;
				} catch (e) {
                    console.error(e);
                }
			}
		},
        verifyHasUnsavedChanges() {

        },
        updateTimeStart(newValue) {
			this.form.date.intervalStartTime = newValue;

            this.changeValueStart();

		},
        updateTimeEnd(newValue) {

			this.form.date.intervalEndTime = newValue;

            this.changeValueEnd();
		},
        changeValueEnd() {
            const momentStart = moment(this.form.date.intervalStart);
            const momentEnd = moment(this.form.date.intervalEnd);
            const momentNow = moment().local().utc();

            momentStart.add(this.form.date.intervalStartTime.slice(0, -3), 'hours');
            momentStart.add(this.form.date.intervalStartTime.slice(3), 'minutes');

            momentEnd.add(this.form.date.intervalEndTime.slice(0, -3), 'hours');
            momentEnd.add(this.form.date.intervalEndTime.slice(3), 'minutes');

            console.log(momentEnd.format())


            if (momentEnd.isAfter(momentNow)) {
                this.form.date.intervalEnd = '';
                this.form.date.intervalEndTime = '00:00';
				this.$store.commit('activeAlert', {
					message: 'A data final não pode ser maior que o dia atual!',
					status: 'error',
				});
				return;
            }

            if (this.form.date.intervalStart == '') return;

			if (momentStart.isAfter(momentEnd)) {
				this.form.date.intervalEnd = '';
                this.form.date.intervalEndTime = '00:00';

				this.$store.commit('activeAlert', {
					message: 'A data final não pode ser menor que a data inicial!',
					status: 'error',
				});
				return;
			} else if (momentEnd.diff(momentStart, 'days') > 60) {
                this.form.date.intervalEnd = '';
                this.form.date.intervalEndTime = '00:00';

				this.$store.commit('activeAlert', {
					message: 'O intervalo entre as datas não pode ultrapassar os 60 dias',
					status: 'error',
				});
				return;
            }
		},
        changeValueStart() {
            const momentStart = moment(this.form.date.intervalStart);
            const momentEnd = moment(this.form.date.intervalEnd);
            const momentNow = moment().local().utc();

            momentStart.add(this.form.date.intervalStartTime.slice(0, -3), 'hours');
            momentStart.add(this.form.date.intervalStartTime.slice(3), 'minutes');

            momentEnd.add(this.form.date.intervalEndTime.slice(0, -3), 'hours');
            momentEnd.add(this.form.date.intervalEndTime.slice(3), 'minutes');


            if (momentStart.isAfter(momentNow)) {
                this.form.date.intervalStartTime = '00:00';
                this.form.date.intervalStart = '';
				this.$store.commit('activeAlert', {
					message: 'A data inicial não pode ser maior que o dia atual!',
					status: 'error',
				});
				return;
            }

            if (this.form.date.intervalEnd == '') return;
            
			if (momentStart.isAfter(momentEnd)) {
                this.form.date.intervalStartTime = '00:00';
				this.form.date.intervalStart = '';
				this.$store.commit('activeAlert', {
					message: 'A data inicial não pode ser maior que a data final!',
					status: 'error',
				});
				return;
			}
            else if (momentEnd.diff(momentStart, 'days') > 60) {
                this.form.date.intervalStart = '';
                this.form.date.intervalStartTime = '00:00';

				this.$store.commit('activeAlert', {
					message: 'O intervalo entre as datas não pode ultrapassar os 60 dias',
					status: 'error',
				});
				return;
            }

		},
        verifyIsSelectedDate(dateString) {
            if (this.modeDate == 'interval') return;
            if (dateString == this.form.date.dateString) {
                return true;
            }
        },
        verifyModeDate(mode) {
            if (this.modeDate == mode) {
                return true
            }
        },
		closeDialog() {
			this.keepEditing = false;
			this.modalBus.$emit('close');
		},
        changeFilterDate(dateText) {
            this.modeDate = 'dateOption'
            this.form.date.dateString = dateText;
			this.$store.commit('setDateFilterPanel', {
                dateString: dateText,
            });

            if (dateText == 'today') {
                this.form.date.dateStartUtc = moment().local().utc();
            } else if (dateText == 'lastSeven') {
                this.form.date.dateStartUtc = moment().local().subtract(7, 'days').utc();
            } else if (dateText == 'lastThirty') {
                this.form.date.dateStartUtc = moment().local().subtract(30, 'days').utc();
            } else if (dateText == 'lastSixty') {
                this.form.date.dateStartUtc = moment().local().subtract(60, 'days').utc();
            }

            if (dateText != 'today') {
                this.form.date.dateEndUtc = moment().local().utc();
            }            
        },
		
	},
});
