<template>
	<background-view :isLoading="isLoadingPage">
		<interaction-menu
			:InteractionMenuTitle="interactionMenu__titleHeader"
			:interactionMenuPlaceHolder="interactionMenu__headerPlaceHolderSearch"
			:interactionMenuPlaceHolderSearch="true"
			:interactionMenuButtonToCleanFilter="interactionMenu__buttonToCleanFilter"
		></interaction-menu>
		<Table
			:maxheight="maxheight"
			:tableOverflow="true"
			class="auditLog__table"
		>
			<template v-slot:headerColumns>
				<tr class="table__tableTheadTr">
					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('auditLog.tableTitle__dateAndTime') }}
							</div>
							<FilterTable>
							</FilterTable>
						</div>
					</th>
					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('auditLog.tableTitle__functionalities') }}
							</div>
							<FilterTable>
							</FilterTable>
						</div>
					</th>

					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('auditLog.tableTitle__typesOfOperations') }}
							</div>
							<FilterTable>
							</FilterTable>
						</div>
					</th>
					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('auditLog.tableTitle__executors') }}
							</div>
							<FilterTable>
							</FilterTable>
						</div>
					</th>
					<th class="table__tableTheadTrTh auditLog__tableHeaderAux">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('auditLog.tableTitle__actions') }}
							</div>
						</div>
					</th>
				</tr>
			</template>
			<template v-slot:columns>
				<tr v-for="row in dataTable" :key="row.id">
					<td>{{ row.dateAndTime }}</td>
					<td class="executionLog__tableFunctionalities">
						<div
							@click="acessDetailsAuditLogs(id = row.id)"
							class="auditLog__tableapplicationsOrServices"
						>
							{{ row.functionalities }}
						</div>
					</td>
					<td>
						<div
							class="executionLog__tableTypesOfOperationsFrame"
							v-bind:class="{
								executionLog__tableTypesOfOperationsFrameEdition:
									row.typesOfOperations == 'Edition' || row.typesOfOperations == 'Edição',
								executionLog__tableTypesOfOperationsFrameInclusion:
									row.typesOfOperations == 'Inclusion' || row.typesOfOperations == 'Inclusão',
								executionLog__tableTypesOfOperationsFrameAlert:
									row.typesOfOperations == 'Exclusion' || row.typesOfOperations == 'Exclusão',
							}"
						>
							{{ row.typesOfOperations }}
						</div>
					</td>
					<td>
						<div class="auditLog__bodyTableExecutors">
							<RoundPhotoFrame :image="row.img"></RoundPhotoFrame>
							<div>{{ row.executors }}</div>
						</div>
					</td>
					<td class="auditLog__tableActionsCenter">
						<div class="auditLog__tableActionsFrame">
							<div v-if="acessDetails === true" class="auditLog__tableActionsButtonLeft" @click="acessDetailsAuditLogs(id = row.id)">
								<span class="auditLog__tableActionsButtonLeftDropdown">{{
									$locale.baseText('auditLog.auditLog__tableActionsButtonLeftDropdown')
								}}</span>
							</div>
							<div v-else class="auditLog__tableActionsButtonLeftDisable">
								<span class="auditLog__tableActionsButtonLeftDropdown">{{
									$locale.baseText('permission.noPermissionFuncionality')
								}}</span>
							</div>
						</div>
					</td>
				</tr>
			</template>
		</Table>
	</background-view>
</template>

<script>
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import Table from '@/components/Table.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import FilterTable from '@/components/FilterTable.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import WebStorage from '@/common/WebStorage';
import { removeItemFromArrayByValue, searchPermissions } from '@/components/helpers';
export default {
	components: {
		BackgroundView,
		InteractionMenu,
		Table,
		RoundPhotoFrame,
		FilterTable
	},
	name: 'AuditLogs',
	data() {
		return {
			isLoadingPage: false,
			maxheight: '60vh',
			interactionMenu__titleHeader: 'auditLog.interactionMenu__titleHeader',
			interactionMenu__headerDropdownPlaceHolder: 'auditLog.interactionMenu__headerDropdownPlaceHolder',
			interactionMenu__headerPlaceHolderSearch: 'auditLog.interactionMenu__headerPlaceHolderSearch',
			interactionMenu__buttonToCleanFilter: 'auditLog.interactionMenu__buttonToCleanFilter',

			dataTable: [
				{
					id: 1,
					dateAndTime: '17/12/2021 às 17h:59min',
					functionalities: 'Edição de Fluxo',
					typesOfOperations: 'Edição',
					img: 'https://mario.wiki.gallery/images/8/84/MPS_Donkey_Kong_Artwork.png',
					executors: 'Donkey Kong',
				},
				{
					id: 2,
					dateAndTime: '17/12/2021 às 16h:18min',
					functionalities: 'Edição de Usuário',
					typesOfOperations: 'Exclusão',
					img: 'https://repository-images.githubusercontent.com/6720403/7b831900-68c4-11eb-9c0b-746413f85cc6',
					executors: 'Nelson',
				},
				{
					id: 3,
					dateAndTime: '17/12/2021 às 14h:33min',
					functionalities: 'Edição de Usuário',
					typesOfOperations: 'Inclusão',
					img: 'https://sm.ign.com/ign_br/news/h/halo-4-com/halo-4-coming-to-master-chief-collection-on-pc-with-remaster_3xtt.jpg',
					executors: 'Master Chief',
				},
				{
					id: 4,
					dateAndTime: '17/12/2021 às 17h:59min',
					functionalities: 'Edição de Usuário',
					typesOfOperations: 'Exclusão',
					img: 'https://assets.nintendo.com/image/upload/f_auto/q_auto/dpr_2.625/c_scale,w_400/ncom/pt_BR/games/switch/t/the-legend-of-zelda-breath-of-the-wild-switch/description-image',

					executors: 'Link',
				},
				{
					id: 5,
					dateAndTime: '17/12/2021 às 16h:18min',
					functionalities: 'Edição de Fluxo',
					typesOfOperations: 'Edição',
					img: 'https://s2.glbimg.com/y3hgdFols2kExAmHb7DXg1R78d8=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_08fbf48bc0524877943fe86e43087e7a/internal_photos/bs/2019/3/j/hUGy7XTgaQOaBpBrfeKQ/captura-de-tela-2019-03-07-as-23.11.05.png',

					executors: 'Mario',
				},
				{
					id: 6,
					dateAndTime: '17/12/2021 às 14h:33min',
					functionalities: 'Edição de Fluxo',
					typesOfOperations: 'Edição',
					img: 'https://play-lh.googleusercontent.com/zS5PLmUQ-52nkQZdrDDmue4eMrso8x9GiV3kCTbyx5Ol4olXASU7PoWBAuNGBSeQNxY',

					executors: 'Sonic',
				},
			],
			dataLogsExecution: [],
			acessDetails: true,
		};
	},
	created() {

	},
	methods: {
		acessDetailsAuditLogs (id) {
			this.$router.push(`/auditlog/auditlogdetail/${id}`);
		},
		Format(timestamp, lang) {
			let dateObj = new Date(timestamp)

			let formatado = dateObj.toLocaleString(lang, {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit'
			}).replace(/\//g, '/');

			let date = formatado.substring(0, 10);
			let time =  formatado.substring(11, 13) + 'h:' + formatado.substring(14, 16) + 'min';

			let stringDate = date + ' às ' + time;

			return stringDate;
		},
		formatDuration(timestamp) {
			if (timestamp < '00:00:01') {
				return '00h:00min:01s';
			} else {
				let hours = timestamp.substring(0, 2) + 'h:';
				let minutes = timestamp.substring(3, 5) + 'min:';
				let seconds = timestamp.substring(6, 8) + 's';

				return hours + minutes + seconds;
			}
		},
	}
};
</script>

<style lang="scss">
.auditLog__tableHeaderAux {
	z-index: 3;
}
.auditLog__table {
	padding: 0 1.5%;
	margin-bottom: 1.5%;
	position: relative;
}
.executionLog__tableFunctionalities {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #59d64c;
}
.auditLog__bodyTableExecutors {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: rgba(255, 255, 255, 0.88);
}
.executionLog__tableTypesOfOperationsFrame {
	border: 1px solid #34476a;
	border-radius: 100px;
	padding: 4px 0px;
	display: flex;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
}
.executionLog__tableTypesOfOperationsFrameEdition {
	color: #00c0ff;
}
.executionLog__tableTypesOfOperationsFrameInclusion {
	color: #acd7ff;
}
.executionLog__tableTypesOfOperationsFrameAlert {
	color: #ffa53b;
}
.auditLog__tableActionsCenter {
	.auditLog__tableActionsFrame {
		display: flex;
		position: relative;
		justify-content: center;
		.auditLog__tableActionsButtonLeft {
			width: 32px;
			height: 32px;
			border: 1px solid #4a638f;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24);
			border-radius: 4px;
			background-image: url('../assets/blueEyeIcon.svg');
			background-position: center;
			background-repeat: no-repeat;
			position: relative;
		}
		.auditLog__tableActionsButtonLeftDisable {
			width: 32px;
			height: 32px;
			border: 1px solid #4a638f;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24);
			border-radius: 4px;
			background-image: url('../assets/blueEyeIconGrey.svg');
			background-position: center;
			background-repeat: no-repeat;
			position: relative;
		}
		.auditLog__tableActionsButtonLeft:hover, .auditLog__tableActionsButtonLeftDisable:hover {
			background-color: #29364f;
			cursor: pointer;
			.auditLog__tableActionsButtonLeftDropdown {
				display: flex;
				width: 350%;
				margin: 0 auto;
				background: #070707;
				font-size: 0.85em;
				line-height: 1.6em;
				border-radius: 5px;
				height: auto;
				color: #fff;
				padding: 5px 8px;
				position: absolute;
				margin-top: 38px;
				left: -75px;
				z-index: 300;
				justify-content: center;
			}
		}
	}
}
.auditLog__tableActionsButtonLeftDropdown {
	display: none;
}
.auditLog__tableActionsButtonLeftDropdown:after {
	content: '';
	display: inline;
	width: 0;
	height: 0;
	position: absolute;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #070707;
	top: -5px;
	left: 85px;
	z-index: 300;
}
.auditLog__tableapplicationsOrServices {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #59d64c;
	cursor: pointer;

	&:hover {
		color: #59d64c;
	}
}
</style>
