<template>
  <div
    v-if="showLoading"
  >
    <div class="wrapper-loading">
		<div v-if="!organizationName" class="loading"/>
		<div v-else class="loading-text">
			{{ organizationName }}
			<h2>{{ subTitle }}</h2>
		</div>
	</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoadingCredentials',
  data() {
    return {
        organizationName: '',
    };
  },
  computed: {
    showLoading() {
        return this.$store.getters.showLoadingCredentials;
    },
    
    subTitle() {
        return this.$locale.baseText('loading.organization');
    },
  },
  watch: {

  },
  created() {
    window.addEventListener('account-name-change', (event) => {
        this.organizationName = JSON.parse(event.detail.storage);
    });
  },
  methods: {
    

   
  },
};
</script>



<style scoped>
h2 {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	color: #FFFFFFE0;
	margin-top: 20px;
}

.wrapper-loading {
	display: flex;
	background-image: url('../assets/backgroud.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
    position: absolute;
    z-index: 99;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.loading {
	background-image: url('../assets/loading.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 20%;
    position: absolute;

	-webkit-animation: fadein 7s ease-in infinite;
	-moz-animation: fadein 7s ease-in infinite;
	animation: fadein 7s ease-in infinite;
}

.loading-text {
    position: absolute;
    width: 100%;
    height: 30%;
	color: #FFFFFF;
    font-size: 56px;
	font-weight: 300;
	line-height: 66px;
	text-align: center;
}
</style>

