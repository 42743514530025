/* eslint-disable no-unused-vars */
import VueRouter from 'vue-router';

import PKCE from '../common/Crypto';
import WebStorage from '../common/WebStorage';
import OidcService from '../services/oidc.service';
import TokenService from '../services/token.service';
import { initializeFirebaseMessaging, getPastMessage, validateCredentials } from '@/components/helpers';

let previousRoute = null;

const routes = [
	...require('@/router/routes/auth.routes').default,
	...require('@/router/routes/public.routes').default,
	...require('@/router/routes/user.routes').default,
];


const router = new VueRouter({
	mode: 'history',
	routes,
});
router.beforeEach(async (to, from, next) => {
	if (Notification.permission === 'granted') {
		initializeFirebaseMessaging();
	}
	const currentPkce = WebStorage.getItem(PKCE.storageKey);

	if (!currentPkce?.code_verifier) WebStorage.setItem(PKCE.storageKey, PKCE.generateAuthorizationKeys());

	const publicPages = ['/login', '/recoverpassword'];
	const authRequired = !publicPages.includes(to.path);
	const code = to.query?.code;

	if (code) OidcService.setAuthorizationCode(code);

	const hasToken = TokenService.getStoredAccessToken();
	const loggedIn = Boolean(hasToken);
	//const hasAuthorizationCode = OidcService.getAuthorizationCode();
	//const loggedIn = Boolean(hasToken || hasAuthorizationCode);
	// trying to access a restricted page + not logged in
	// redirect to login page
	
	await validateCredentials(router.app, to.params?.organizationId, to.params?.workspaceId, to).then((validate) => {
		if (validate) {
			let storedOrganizationId = WebStorage.getItem('tenantId');
			let storedWorkspaceId = JSON.parse(WebStorage.getItem('workspace'))?.id;	
			previousRoute = from;
			
			if (authRequired && !loggedIn && !code) {
				next('/login');
			} else {
				to.params.organizationId = storedOrganizationId || '';
				to.params.workspaceId = storedWorkspaceId || '';

				next();
			}
			// if (publicPages.includes(to.path) && loggedIn) {
			// 	next('/project');
			// }
		} else {
			next({path: from.fullPath})
		}	
	})

	
	
});

export default router;
export { previousRoute }; // Exporte a variável global
