
import { TIMEZONE_SELECT_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import {
	IWorkflowDataUpdate,
	IWorkflowSettings,
} from '../Interface';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import moment from 'moment';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'TimezoneSelectModal',
	components: {
		Modal,
		ModalButtonCancel,
		ModalButtonSuccess,
	},
	data() {
		return {
			modalBus: new Vue(),
			isFocused: false,
			timezonesList: [],
			TIMEZONE_SELECT_MODAL_KEY,
			showSelect: false,
			selectedTimezone: '',
			timezoneObject: '',
			defaultValues: {
				timezone: 'America/Fortaleza',
			},
			workflowSettings: {} as IWorkflowSettings,
		};
	},
	computed: {
		isNewWorkflow(): Boolean {
			return this.workflowId === '__EMPTY__' ? true : false;
		},
		workflowId(): number {
			return this.$store.getters.workflowId;
		},
	},
	async mounted () {
		this.defaultValues.timezone = this.$store.getters.timezone;
		await this.loadTimezones();

		const workflowSettings = JSON.parse(JSON.stringify(this.$store.getters.workflowSettings));

		if (workflowSettings.timezone === undefined || workflowSettings.timezone === 'padrao') {
			workflowSettings.timezone = {
				key: 'padrao',
				value: 'Padrão - (GMT-03:00) Fortaleza',
			};
		} else {
			workflowSettings.timezone = this.timezoneObject[workflowSettings.timezone];
		}

		Vue.set(this, 'workflowSettings', workflowSettings);
	},
	methods: {
		hideSelect() {
			this.showSelect = false;
		},
		openSelect() {
			this.showSelect = true;
			this.$refs.selectN8n.$el.click()
		},
		formatMomentToString(timezone) {
			if (typeof timezone === 'object') {
				timezone = timezone.value;
			}
			const GMT = timezone.slice(4, 10)
			moment.locale('pt');
			return moment().utcOffset(GMT).format('LLLL');
		},
		async loadTimezones () {
			if (this.timezonesList.length !== 0) {
				// Data got already loaded
				return;
			}

			const timezones = await this.restApi().getTimezones();
			this.timezoneObject = timezones;
			let defaultTimezoneValue = timezones[this.defaultValues.timezone] as string | undefined;
			if (defaultTimezoneValue === undefined) {
				defaultTimezoneValue = this.$locale.baseText('workflowSettings.defaultTimezoneNotValid');
			}

			this.timezonesList.push({
				key: 'padrao',
				value: this.$locale.baseText(
					'workflowSettings.defaultTimezone',
					{ interpolate: { defaultTimezoneValue } },
				),
			});
			for (const timezone of Object.keys(timezones)) {
				this.timezonesList.push({
					key: timezone,
					value: timezones[timezone] as string,
				});
			}
		},
		async saveWorkflowSettings() {
			this.$store.commit('setTimezone', this.workflowSettings.timezone.key || {});

			this.$store.commit('setWorkflowSettings', {
				timezone: this.workflowSettings.timezone.key,
				executionTimeout: this.$store.getters.executionTimeout,
				errorWorkflow: this.$store.getters.errorWorkflow,
			} || {});


			const data: IWorkflowDataUpdate = {
				settings: {
					timezone: this.workflowSettings.timezone.key,
				},
			};

			if(!this.isNewWorkflow) {
				try {
					await this.saveCurrentWorkflow();
				} catch (error) {
					this.$store.commit('activeAlert', {
						message: 'Ocorreu um erro ao salvar sua alteração de fuso horário, tente novamente.',
						status: 'error',
					})
					return;
				}
			}

			this.$root.$emit('reload');
			this.modalBus.$emit('close');
			this.$store.commit('activeAlert', {
				message: this.$locale.baseText('timezoneModal.successSave', {
					interpolate: { timezoneSelected: this.workflowSettings.timezone.value },
				}),
			});

		},
		onFocus() {
			this.isFocused = !this.isFocused;
		},
	},
});
