<template>
  <div
    v-if="message"
    class="alertDanger"
    v-bind:class="{ alertDangerSuccess: alertSuccess, alertDangerAlert: alertAlert, alertDangerError: alertError, alertDangerInfo: alertInfo }"
    @mouseenter="pauseTimer"
    @mouseleave="resumeTimer"
  >
    <div class="alertDangerPosition">
      <div :class="{ alertSuccessIcon: alertSuccess, alertDangerAlertIcon: alertAlert, alertDangerErrorIcon: alertError, alertDangerInfoIcon: alertInfo }"></div>
      <div class="text">
        <div v-html="message"></div>
        <router-link :to="linkSrc" v-if="linkMessage">{{ linkMessage }}</router-link>
      </div>
      <div class="alertLine"></div>
      <div class="alertDangerCloseIcon" @click="closeAlert"></div>
    </div>

    <div class="alertDangerContent">
      <div class="countdown-bar" :style="{ width: countdownWidth }"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AlertAliare',
  data() {
    return {
      message: '',
      alertSuccess: false,
      alertAlert: false,
      alertError: false,
			alertInfo: false,
      timeOut: null,
      linkMessage: '',
      countdownWidth: '100%',
      remainingTime: 5000,
      isPaused: false,
      timeoutId: null,
      startTime: 0,
      pauseStartTime: 0,
    };
  },
  computed: {
    ...mapState(['alertData']),
  },
  watch: {
    alertData: {
      handler(newValue) {
        if (!!newValue.message && !!newValue.status) {
          this.resetTimer();

          const status = newValue.status;
          this.linkMessage = newValue?.link?.message;
          this.linkSrc = newValue?.link?.src;

          switch (status) {
            case 'success':
              this.message = newValue.message;
              this.alertSuccess = true;
              break;

						case 'info':
              this.message = newValue.message;
              this.alertInfo = true;
              break;

            case 'alert':
              this.message = newValue.message;
              this.alertAlert = true;
              break;

            case 'error':
              this.message = newValue.message;
              this.alertError = true;
              break;
          }

          this.startCountdown();
        }
      },
      deep: true,
    },
  },
  created() {
    this.resetTimer();
  },
  methods: {
    startCountdown() {
      const interval = 100;
      this.startTime = Date.now() - (5000 - this.remainingTime);

      const updateCountdown = () => {
        if (!this.isPaused) {
          const elapsedTime = Date.now() - this.startTime;
          this.remainingTime = Math.max(0, 5000 - elapsedTime);

          const progress = this.remainingTime / 5000;
          this.countdownWidth = `${progress * 100}%`;

          if (progress > 0) {
            this.timeoutId = setTimeout(updateCountdown, interval);
          } else {
            this.closeAlert();
          }
        } else {
          this.timeoutId = setTimeout(updateCountdown, interval);
        }
      };

      updateCountdown();
    },

    pauseTimer() {
      if (!this.isPaused) {
        this.isPaused = true;
        this.pauseStartTime = Date.now();
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeOut);
      }
    },

    resumeTimer() {
      if (this.message && this.alertData.message && this.isPaused) {
        this.isPaused = false;
        const elapsedPausedTime = Date.now() - this.pauseStartTime;
        this.startTime += elapsedPausedTime;
        this.startCountdown();
      }
    },

    resetTimer() {
      clearTimeout(this.timeoutId);
      clearTimeout(this.timeOut);

      this.timeOut = setTimeout(() => {
        this.closeAlert();
        this.$store.commit('resetAlertData');
      }, 5000);

      this.alertSuccess = false;
      this.alertAlert = false;
      this.alertError = false;
			this.alertInfo = false;
      this.message = '';
      this.countdownWidth = '100%';
      this.remainingTime = 5000;
      this.isPaused = false;
      this.linkMessage = '';
      this.linkSrc = '';
    },

    closeAlert() {
      clearTimeout(this.timeoutId);
      clearTimeout(this.timeOut);
      this.alertSuccess = false;
      this.alertAlert = false;
      this.alertError = false;
			this.alertInfo = false;
      this.message = '';
      this.countdownWidth = '100%';
      this.remainingTime = 5000;
      this.isPaused = false;
      this.$store.commit('resetAlertData');
    },
  },
};
</script>



<style scoped lang="scss">
.countdown-bar {
  height: 4px;
  background-color: #ffffff;
  transition: width 0.1s linear;
}

.alertDangerSuccess {
	background-color: #0e6a1d !important;
	border-color: #0e6a1d !important;
}
.alertDangerAlert {
	background-color: #A94E0D !important;
	border-color: #A94E0D !important;
}
.alertDangerError {
	background-color: #9d2020 !important;
	border-color: #9d2020 !important;
}
.alertDangerInfo {
	background-color: #0C32BB !important;
	border-color: #0C32BB !important;
}
.alertDangerPosition{
	display: flex;
	align-items: center;
	flex: auto;
	padding: 16px 16px 3px 16px;
}
.alertDanger {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	top: 20px;
	position: fixed;
	left: 50%;
	transform: translateX(-50%);
	color: #ffffff;
	border-radius: 8px;
	z-index: 100000000;
	max-width: 420px;

	a {
		color: #ffffff;
	}

	.text {
    word-break: break-word;
		padding-right: 10px;
		width: 100%;
		border-right: 1px solid rgba(255, 255, 255, 0.32);
	}

	.alertDangerAlertIcon {
		min-width: 22px;
		height: 19px;
		background-image: url('../assets/warningIconFullWhite.svg');
		background-repeat: no-repeat;
		margin-right: 16px;
	}
	.alertDangerErrorIcon {
		min-width: 20px;
		height: 20px;
		background-image: url('../assets/roundWhiteExclamation.svg');
		background-repeat: no-repeat;
		margin-right: 16px;
	}
	.alertDangerInfoIcon {
		min-width: 20px;
		height: 20px;
		background-image: url('../assets/roundedExclamationWhite.svg');
		background-repeat: no-repeat;
		margin-right: 16px;
	}
	.alertSuccessIcon {
		min-width: 20px;
		height: 20px;
		background-image: url('../assets/checkIconAlert.svg');
		background-repeat: no-repeat;
		margin-right: 16px;
	}

	.alertDangerCloseIcon {
		background-image: url('../assets/closeIcon.svg');
		background-repeat: no-repeat;
		height: 20px;
		min-width: 20px;
		cursor: pointer;
	}

	.alertDangerContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 3px 0;
  }
}
.alertLine {
	width: 1px;
	height: 40px;
	margin-right: 18px;
}
</style>
