
export default {
	name: 'ModalNotificationUser',

	props: {
		mode: {
			type: String,
		},
	},

	data() {
		return {
			visible: true,
		};
	},
	computed: {
	},
	methods: {
  },
};
