<template>
	<div class="aux">
		<div :class="{ backgroundView: true, expanded: !sidebarMenuCollapsed, loadingScreen: $props.isLoading}">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'BackgroundView',
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
	},
	data() {
		return {
			filter: '',
		};
	},
	methods: {
		clearFilter() {
			this.filter = '';
			this.$event.target.value = '';
		},
	},
};
</script>

<style lang="scss">
.aux{
	padding: 0px 26px 26px 26px;
}
.backgroundView {
	width: 100%;
	max-height: 100%;
	background: #1c2638;
	border: 1px solid #34476a;
	border-radius: 4px;
	padding: 17px;
	display: flex;
	flex-direction: column;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Open Sans', 'Helvetica Neue', sans-serif;
	color: rgba(255, 255, 255, 0.88);

	&.loadingScreen {
		position: relative;
		border: none;

		&:after {
			content: '';
			background-image: url('./../assets/gif-loading.gif');
			background-position: center;
			background-repeat: no-repeat;
			background-size: 50px;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #161E2C;
			position: absolute;
			z-index: 99;
		}
	}

	&.expanded {
		#content {
			left: $--sidebar-expanded-width + 89px;
		}
	}
}

// #content {
// 	top: 98px;
// 	left: 89px;
// }
</style>
