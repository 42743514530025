import axios from 'axios';
import { appConfig } from '@/config/appConfig';
import { defaultApi, n8nApi } from '@/api/internalApis';

axios.defaults.withCredentials = appConfig('auth.setCookies');

class WorkflowsService {

	async getWorkflowFromUrl(url) {
    const res = await n8nApi.post('workflows/from-url', url);
    return res;
	}

	async getWorkflows(query) {
    const res = await defaultApi.get(`Workspaces/${query.workspaceId}/Workflows`, {
      params:{
				id: query?.id,
				codigo: query?.codigo,
				page: query?.page,
				size: query?.size,
        ativo: query?.ativo,
				nome: query?.nome,
      },
    });

    return res;
	}

	async getWorkflowsDetails(query) {
    const res = await defaultApi.get(`Workflows/Detalhes`, {
      params:{
				projetoId: query?.projetoId,
				resultadoExecucao: query?.resultadoExecucao,
				page: query?.page,
				size: query?.size,
        ativo: query?.ativo,
				nome: query?.nome,
				searchNome: query?.searchNome,
      },
    });

    return res;
  }

	async getWorkflowsFails(query) {
    const res = await defaultApi.get(`Workspaces/${query.workspaceId}/Workflows/fluxos-de-falha`, {
      params:{
				projetoId: query?.projetoId,
      },
    });

    return res;
  }

	async getWorkflowsId(query) {
		const res = await defaultApi.get(`Workspaces/${query.workspaceId}/Workflows/${query.id}`);
		return res;
	}

	async deleteWorkflowsId(id) {
		const res = await defaultApi.delete(`Workflows/${id}`);
		return res;
	}
	async getWorkflowsWorkflowsExecutions(workflow) {
		const res = await defaultApi.get(`Workflows/${workflow}/Execucoes`);
		return res;
	}

	async getWorkflowsIdVariable(query) {
		const res = await defaultApi.get(`Workspaces/${query.workspaceId}/Workflows/${query.id}/Variaveis`, {
      params:{
				page: query?.page,
				size: query?.size,
				nome: query?.nome,
				valores: query?.valores,
      },
    });

		return res;
	}

	async getWorkflowsIdVersionHistory(id, size, page) {
		const res = await defaultApi.get(`Workflows/${id}/HistoricoVersoes?size=${size}&page=${page}`);
		return res;
	}

	async patchWorkflowsWorkflowActive(status) {
		const payload = { 'ativo': status.ativo };
		const res = await defaultApi.patch(`Workspaces/${status.workspaceId}/Workflows/${status.id}/Ativo`, payload);
		return res;
	}

	async patchWorkflowsWorkflowAutomatic(status) {
		const payload = { 'automatico': status.automatico};
		const res = await defaultApi.patch(`Workspaces/${status.workspaceId}/Workflows/${status.id}/Automatico`, payload);
		return res;
	}

	async patchWorkflowFail(query) {
		const payload = {'workflowFalha': query.workflowFalha};
    const res = await defaultApi.patch(`Workspaces/${query.workspaceId}/Workflows/${query.id}/fluxos-de-falha`, payload);
    return res;
  }

	async patchWorkflow(id, data) {
		const res = await defaultApi.patch(`Workflows/${id}`, data);
		return res;
	}
}

export default new WorkflowsService();
