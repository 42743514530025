
import Vue from 'vue';
import debounce from 'lodash.debounce';
import mixins from 'vue-typed-mixins';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from './mixins/showMessage';

export default mixins(
	showMessage,
	nodeHelpers,
).extend({
	props: {
		required: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type:String,
		},
		className:{
			type:String,
			default:'',
		},
		maxValue:{
			type:String,
			default:'',
		},
		minValue:{
			type:String,
			default:'',
		},
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: String,
		},
		labelText: {
			type: String,
		},
		blockInput: {
			type: Boolean,
			default: false,
		},
		searchIcon: {
			type: Boolean,
			default: false,
		},
		maxlength: {
			type: Number,
			default: 100,
		},
		stayFocused: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			valueInput: this.value.toString(),
			isFocused: false,
			disableButton: false,

		};
	},
	computed: {
		label() {
			return `${this.labelText + (this.required ? ' *' : '')}`
		},
	},
	methods: {
		onFocus() {
			this.$emit('focus');
			this.isFocused = true;
		},
		onClick() {
			this.$emit('click');
		},
		onBlur() {
			this.$emit('blur');
			this.isFocused = false;
		},
		onEnter() {
			this.$emit('enter');
		},
		textModified:
			_.debounce(async function (value) {
				this.$emit('input', value);
			},
		100),
	},
	beforeDestroy() {
		this.valueInput = '';
		this.$emit('input', this.valueInput);
	},
	watch: {
		value(newValue) {
			this.valueInput = newValue.toString();
    },
	},
});
