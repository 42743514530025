
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { workspacePermission } from '@/components/mixins/permissions/workspace';
import { mainSiderBarPermission } from '@/components/mixins/permissions/sidebar';
import { accessIntegrationPanelPermission } from '@/components/mixins/permissions/panelInfo';
import { previousRoute } from '../router/index.ts';

import MenuOptions from '@/components/MenuOptions.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import HeaderFilterIntegration from '@/components/HeaderFilterIntegration.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

export default mixins(
	restApi,
	workspacePermission,
    mainSiderBarPermission,
	accessIntegrationPanelPermission,
).extend({
	components: {
		MenuOptions,
		ScrollInfinite,
		ModalButtonCancel,
		ModalButtonSuccess,
		HeaderFilterIntegration,
		AlertNotificationModal,
	},
	data() {
		return {
			page: 1,
			size: 10,
			isLoading: false,
			dataWorkspace: [],
			scrollInfinite: false,
            isLoadingPage: false,
			panel1Url: '',
			panel2Url: '',
			activeTab: 'resultIntegrations'
		};
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),
		hasIntegration() {
			if (this.panel1Url || this.panel2Url) {
				return true;
			} else {
				return false;
			}
		},

		isManage() {
			if (this.$route.name === 'adminIntegrationPanel') return true;

			return false;

		},
		
	},
	async created() {
        this.isLoadingPage = true;
        await this.verifyPermissionUserAndInitiate();
		this.isLoadingPage = false;
	},
	methods: {
		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
		pagination() {

        },
		async loadPanel() {
			const idPanel = "73287cca-c0df-4b07-89da-1fa6338cb4d9";
			const parameters = [
				{
					"id": "a65b3e92-063c-4e00-8622-cbcd4bb5b478",
					"descricao": "Organização",
					"valor": [null],
				},
				{
					"id": "b9a67559-4a8f-44b1-b919-380447b9619e",
					"descricao": "Espaço de Trabalho",
					"valor": [null],
				},
				{
					"id": "b8000b33-fe99-44f9-b90d-f84e9ba4dc16",
					"descricao": "projeto",
					"valor": [null],
				},
				{
					"id": "377476cf-3a5f-4a9a-84cb-2553360dd0e6",
					"descricao": "fluxo",
					"valor": [null],
				},
				{
					"id": "8e813f28-6ffd-446d-a50a-29e7eae5dc24",
					"descricao": "Data Hora Inicio",
					"valor": [null]
				},
				{
					"id": "b46db80f-abf2-442e-808a-bd52a3aa8cfc",
					"descricao": "Data Hora Fim",
					"valor": [null]
				},
				{
					"id": "9e0a045a-336a-4ab6-9c26-a3a18983c20c",
					"descricao": "Status",
					"valor": [null],
				}
			];

			const idPanel2 = '8e610c2e-5f59-4123-88f0-12bf098848d2';
			const parameters2 = [
				{
				"id":"a65b3e92-063c-4e00-8622-cbcd4bb5b478",
				"descricao":"Organização",
				"Valor":[
					"380bfe9b-ccf6-4450-9a20-f453ed591245"
					]
				},
				{
				"id":"b9a67559-4a8f-44b1-b919-380447b9619e",
				"descricao":"Espaço de Trabalho",
				"Valor":[
					"995e9477-830e-451e-8b0f-1735086f22ab"
				]
				}
			]



			

			try {
				let panelResults = await this.restApi().sendIdPanelIntegration(idPanel, parameters);
				this.panel1Url = panelResults;
			} catch {
				this.$store.commit('activeAlert', {
					message: 'Erro ao carregar o painel de resultados',
					status: 'error'
				});
			}
			
			try {
				let panelInfoFail = await this.restApi().sendIdPanelIntegration(idPanel2, parameters2);
				this.panel2Url = panelInfoFail;
			} catch {
				this.$store.commit('activeAlert', {
					message: 'Falha ao carregar painel de falhas identificadas, tente novamente.',
					status: 'error'
				});

				this.isLoading = false;

			}
		},
        goToWorkspaceList() {
			this.$router.push({
				name: 'workspace',
			});
		},
        goToStoreList() {
			this.$router.push({
				name: 'integrationStore',
			});
		},

        async initPageEquivalence() {
			this.isLoading = true;
			
			await this.loadPanel();
	
			this.isLoading = false;
		},
        async verifyPermissionUserAndInitiate() {
			await this.validationAccessIntegrationPanel().then(async () => {
				if (this.isManage ? this.canViewAdminPanelIntegration() : this.canViewIntegrationPanel()) {
					await this.initPageEquivalence();
				} else {
					this.$store.commit('setData403', {show: true, prevPage: previousRoute});
				}
			});
		},


	},
});
