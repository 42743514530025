
import Vue from 'vue';

export default Vue.extend({
	name: 'SaveButton',
	props: {
		saved: {
			type: Boolean,
		},
		isSaving: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
		},
		saveLabel: {
			type: String,
		},
		savingLabel: {
			type: String,
		},
		savedLabel: {
			type: String,
		},
	},
	computed: {
		saveButtonLabel() {
			return this.isSaving
				? this.$locale.baseText('saveButton.saving')
				: this.$locale.baseText('saveButton.save');
		},
	},
});
