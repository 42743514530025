<template>
  <div class="activeDeactiveButton__select">
    <div class="activeDeactiveButton__selectAux">
      <label
        :for="cell"
        class="activeDeactiveButton__labelActive"
        v-bind:class="{ activeDeactiveButton__active: activity }"
        @click="activity = true; emitEvents()"
      >{{ $locale.baseText(text_1) }}</label>
      <input
        style="background: #1f2b40"
        :id="cell"
        :name="cell"
        type="radio"
        v-show="false"
        :checked="activity"
      />
      <label
        :for="deactiveButton"
        class="activeDeactiveButton__labelInactive"
        v-bind:class="{ activeDeactiveButton__inactive: !activity }"
        @click="activity = false; emitEvents()"
      >{{ $locale.baseText(text_2) }}</label>
      <input
        style="background: #1f2b40"
        :id="deactiveButton"
        :name="cell"
        type="radio"
        v-show="false"
        :checked="!activity"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActiveDeactiveButton',
  emits: ['advanceButton', 'changeStyle', 'changeExecution'],
  props: {
    cells: String,
    deactiveButtons: String,
    activeOrInactive: Boolean,
    firstBtnText: String,
    secondBtnText: String,
  },
  data() {
    return {
      cell: this.cells,
      deactiveButton: `deactiveButton${this.deactiveButtons}`,
      text_1: this.firstBtnText,
      text_2: this.secondBtnText,
      activity: this.activeOrInactive, // Inicialize activity com o valor de activeOrInactive
    };
  },
  watch: {
    activeOrInactive(newVal) {
      // Atualize a propriedade activity quando activeOrInactive mudar
      this.activity = newVal;
    },
  },
  methods: {
    emitEvents() {
      this.$emit('advanceButton', [{ active: this.activity, name: this.cell }]);
      this.$emit('changeStyle', this.activity);
      this.$emit('changeExecution', this.activity);
    },
  },
};
</script>

<style lang="scss">
.activeDeactiveButton__select {
  height: 28px;
  background: #141722;
  border-radius: 4px;
  padding: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.48);
  .activeDeactiveButton__selectAux {
    display: flex;
    align-items: center;
    justify-content: center;
    .activeDeactiveButton__labelActive {
      padding: 0px 7px;
      min-width: 57px;
      width: 50%;
      height: 22px;
      border-radius: 2px 0px 0px 2px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
    }
    .activeDeactiveButton__labelActive .active {
      background: #00c3dd;
    }

    .activeDeactiveButton__labelInactive {
      padding: 0px 7px;
      min-width: 57px;
      width: 50%;
      height: 22px;
      border-radius: 0px 2px 2px 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
    }
  }
}
.activeDeactiveButton__active {
  background: #00c3dd;
  color: #000000;
}
.activeDeactiveButton__inactive {
  background: #ff495f;
  color: #000000;
}
</style>
