<template>
	<loading v-show="isLoading" />
</template>

<script>
import WebStorage from '@/common/WebStorage';
import Loading from '@/components/Loading.vue';
import { searchPermissions } from '@/components/helpers';

import { mapActions, mapGetters } from 'vuex';

import OidcService from '@/services/oidc.service';
import Alert from '@/components/Alert.vue';
import TokenService from '@/services/token.service';

export default {
	components: {
		Alert,
		Loading,
	},
	async created() {
		this.clearAllSessionData();
	},

	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
	},

	mounted() {
	},

	methods: {
		async clearAllSessionData() {

		},
	},
};
</script>

