
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { INACTIVE_USER_MODAL_KEY } from '../constants';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import SvgAnimation from '@/components/SvgAnimation.vue';
import WebStorage from '@/common/WebStorage';

export default {
	name: 'ModalInactiveUser',
	components: {
		Modal,
		SvgAnimation,
		ModalButtonCancel,
		ModalButtonSuccess,
	},
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			modalBus: new Vue(),
			INACTIVE_USER_MODAL_KEY,
			organizationName: WebStorage.getItem('accountName'),
		};
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		descriptionModal() {
			if (this.mode == 'inactiveUser') {
				return `Inativar o usuário fará com a pessoa perca seu acesso e suas permissões à organização <b>"${this.organizationName}"</b>. O que deseja fazer?`;
			} else {
				return `Inativar o perfil todos os usuários vinculados a ele perderão suas permissões à organização <b>"${this.organizationName}"</b>. O que deseja fazer?`;
			}
		},
		titleModal() {
			if (this.mode == 'inactiveUser') {
				return `Inativação do Usuário`;
			} else {
				return `Inativação do Perfil`;
			}
		},

		disableTextModal() {
			if (this.mode == 'inactiveUser') {
				return `Inativar Usuário`;
			} else {
				return `Inativar Perfil de Usuário`;
			}
		},

		keepTextModal() {
			if (this.mode == 'inactiveUser') {
				return `Manter Usuário Ativo`;
			} else {
				return `Manter Perfil de Usuário Ativo`;
			}
		},
	},
	async created() {
	},
	methods: {
		...mapActions('aliareAccount', ['disableUser', 'disableProfile']),

		async disableProfileUser() {
			await this.disableProfile({ permissionId: this.dataModal.id }).then((response) => {
				this.modalBus.$emit('close');

				this.$store.dispatch('ui/openNotificationModal', { data: {nome: this.dataModal.nome, permissionId: this.dataModal.id}, mode: 'inactiveProfile'});
			}).catch((error) => {
				console.error(error);
					//this.$store.commit('activeAlert', {
				// 		message: error.response?.data?.notifications[0],
				// 		status: 'error',
				// 	})
			});

		},
		async modalDisableUser() {
				await this.disableUser(this.dataModal.permission.userProfile.id).then((response) => {
					if(!this.sidebarMenuCollapsed === true) {
						this.$store.commit('ui/toggleSidebarMenuCollapse');
					}

					this.modalBus.$emit('close');
					this.$store.dispatch('ui/openNotificationModal', {data: this.dataModal, mode: 'inactiveUser'});
				}).catch((error) => {
					console.error(error);
				});
		},
  },
};
