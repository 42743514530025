import axios from 'axios';
import { appConfig } from '@/config/appConfig';

axios.defaults.withCredentials = appConfig('auth.setCookies');

const PLATAFORMA_ALIARE_BASE_URL = appConfig('plataformaAliare.baseURL');
const PLATAFORMA_ALIARE_API_BASE_URL = appConfig('plataformaAliare.apiURL');
const PLATAFORMA_ALIARE_AUTH_URL = appConfig('plataformaAliare.authorityURL');

const BACKEND_URL = appConfig('baseURL');
const N8N_URL = appConfig('n8n.baseURL');

export const defaultApi = axios.create({
	baseURL: `${BACKEND_URL}/api/v1/`,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const n8nApi = axios.create({
	baseURL: `${N8N_URL}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const authorizationApi = axios.create({
	baseURL: `${PLATAFORMA_ALIARE_AUTH_URL}`,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
});

export const coreApi = axios.create({
	baseURL: `${PLATAFORMA_ALIARE_BASE_URL}/core`,
	headers: {
		'Content-Type': 'application/json',
	},
});

export default defaultApi;
