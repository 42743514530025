import { hasPermissionByDescription, verifyPermissionDescription, searchPermissions } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const variablePermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			dataVariable: '',
			dataIntegration: '',
		};
	},

	async created() {
		await this.validationAccessVariables();
	},

	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
		manageVariablePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageVariableId, this.dataIntegration?.childrens);
		},
		createVariablePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.createVariableId, this.dataVariable?.childrens);
		},
		editVariablePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editVariableId, this.dataVariable?.childrens);
		},
		deleteVariablePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteVariableId, this.dataVariable?.childrens);
		},
	},
	methods: {
		async validationAccessVariables() {
			const permission = JSON.parse(WebStorage.getItem('userPermission'));
			this.dataIntegration = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationId, permission?.access);
			this.dataVariable = verifyPermissionDescription(this.PERMISSION_IDS?.manageVariableId, this.dataIntegration?.childrens);

			if (!this.dataIntegration && !this.dataVariable) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('permission.noPermissionFuncionality'),
					status: 'error',
				});
			} else if (!this.dataVariable) {
				this.$router.push({ name: 'project' });
			}
		},

		canAcessVariable() {
			return this.manageVariablePermission && this.workspace.ativo;
		},

		canCreateVariable() {
			return this.createVariablePermission && this.workspace.ativo;
		},
		canAddVariable() {
			return this.createVariablePermission && this.workspace.ativo;
		},
		canEditVariable() {
			return this.editVariablePermission && this.workspace.ativo;
		},
		canDeleteVariable() {
			return this.deleteVariablePermission && this.workspace.ativo;
		},
	},
});
