

import { MessageBoxInputData } from 'element-ui/types/message-box';

import {
	IExecutionResponse,
	IWorkflowDataUpdate,
	IMenuItem,
} from '../Interface';

import About from '@/components/About.vue';
import ExecutionsList from '@/components/ExecutionsList.vue';
import GiftNotificationIcon from './GiftNotificationIcon.vue';
import WorkflowSettings from '@/components/WorkflowSettings.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';

import { genericHelpers } from '@/components/mixins/genericHelpers';
import { restApi } from '@/components/mixins/restApi';
import { showMessage } from '@/components/mixins/showMessage';
import { titleChange } from '@/components/mixins/titleChange';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { workflowRun } from '@/components/mixins/workflowRun';

import { saveAs } from 'file-saver';

import mixins from 'vue-typed-mixins';
import { mapGetters , mapActions} from 'vuex';
import MenuItemsIterator from './MainSidebarMenuItemsIterator.vue';
import { CREDENTIAL_LIST_MODAL_KEY, CREDENTIAL_SELECT_MODAL_KEY, DUPLICATE_MODAL_KEY, DUPLICATE_WORKFLOW_MODAL_KEY, TAGS_MANAGER_MODAL_KEY, WORKFLOW_SETTINGS_MODAL_KEY, WORKFLOW_OPEN_MODAL_KEY, EXECUTIONS_MODAL_KEY } from '@/constants';

import WebStorage from '@/common/WebStorage';
import { searchPermissions } from '@/components/helpers';
import { mainSiderBarPermission } from '@/components/mixins/permissions/sidebar';

export default mixins(
	genericHelpers,
	restApi,
	showMessage,
	titleChange,
	workflowHelpers,
	workflowRun,
	mainSiderBarPermission,
)
	.extend({
		name: 'MainHeader',
		components: {
			About,
			ExecutionsList,
			GiftNotificationIcon,
			WorkflowSettings,
			MenuItemsIterator,
			ModalButtonCancel,
		},
		created() {
		},
		data() {
			return {
				tokenData: WebStorage.getItem('tokenData'),
				height: '100px',
				showStore: WebStorage.getItem('showStore') || false,
				aboutDialogVisible: false,
				// @ts-ignore
				basePath: this.$store.getters.getBaseUrl,
				stopExecutionInProgress: false,
			};
		},
		computed: {
			...mapGetters('ui', {
				isCollapsed: 'sidebarMenuCollapsed',
			}),
			canViewAdmin() {
				return this.userN0 && (this.canViewExecutionQueue() || this.canViewAdminPanelIntegration() || this.canManageIntegrations());
			},
			userN0() {
				if (this.tokenData.Level == 'N0') {
					return true;
				}
				return;
			},

			workspace() {
				return this.$store.getters.workspace || JSON.parse(WebStorage.getItem('workspace'));
			},
			exeuctionId(): string | undefined {
				return this.$route.params.id;
			},
			executionFinished(): boolean {
				if (!this.isExecutionPage) {
					// We are not on an exeuction page so return false
					return false;
				}

				const fullExecution = this.$store.getters.getWorkflowExecution;

				if (fullExecution === null) {
					// No exeuction loaded so return also false
					return false;
				}

				if (fullExecution.finished === true) {
					return true;
				}

				return false;
			},
			executionWaitingForWebhook(): boolean {
				return this.$store.getters.executionWaitingForWebhook;
			},
			isExecutionPage(): boolean {
				if (['ExecutionById'].includes(this.$route.name as string)) {
					return true;
				}
				return false;
			},
			isWorkflowActive(): boolean {
				return this.$store.getters.isActive;
			},
			currentWorkflow(): string {
				return this.$route.params.name;
			},
			workflowExecution(): IExecutionResponse | null {
				return this.$store.getters.getWorkflowExecution;
			},
			workflowName(): string {
				return this.$store.getters.workflowName;
			},
			workflowRunning(): boolean {
				return this.$store.getters.isActionActive('workflowRunning');
			},
			sidebarMenuTopItems(): IMenuItem[] {
				return this.$store.getters.sidebarMenuItems.filter((item: IMenuItem) => item.position === 'top');
			},
			sidebarMenuBottomItems(): IMenuItem[] {
				return this.$store.getters.sidebarMenuItems.filter((item: IMenuItem) => item.position === 'bottom');
			},
		},
		methods: {
			...mapActions('auth', ['revokeTokens']),

			toggleMenu() {
				this.showStore = !this.showStore;
				WebStorage.setItem('showStore', this.showStore);
			},
			trackHelpItemClick(itemType: string) {
				this.$telemetry.track('User clicked help resource', { type: itemType, workflow_id: this.$store.getters.workflowId });
			},
			toggleCollapse() {
				this.$store.commit('ui/toggleSidebarMenuCollapse');
			},
			clearExecutionData() {
				this.$store.commit('setWorkflowExecutionData', null);
				this.updateNodesExecutionIssues();
			},
			closeAboutDialog() {
				this.aboutDialogVisible = false;
			},
			openTagManager() {
				this.$store.dispatch('ui/openModal', TAGS_MANAGER_MODAL_KEY);
			},
			async stopExecution() {
				const executionId = this.$store.getters.activeExecutionId;
				if (executionId === null) {
					return;
				}

				try {
					this.stopExecutionInProgress = true;
					await this.restApi().stopCurrentExecution(executionId);

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText(
							'mainSidebar.showMessage.stopExecution.message',
							{ interpolate: { executionId } },
						),
					});
				} catch (error) {
					console.error(error);
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('mainSidebar.showError.stopExecution.message'),
						status: 'error',
					});
				}
				this.stopExecutionInProgress = false;
			},
			async openWorkflow(workflowId: string) {
				// Change to other workflow
				this.$router.push({
					name: 'NodeViewExisting',
					params: { name: workflowId, mode: 'Visualizar' },
				});

				this.$store.commit('ui/closeAllModals');
			},
			async handleFileImport() {
				const reader = new FileReader();

				reader.onload = (event: ProgressEvent) => {
					const data = (event.target as FileReader).result;

					let worflowData: IWorkflowDataUpdate;
					try {
						worflowData = JSON.parse(data as string);
					} catch (error) {

						this.$store.commit('activeAlert', {
							message: this.$locale.baseText('mainSidebar.showMessage.handleFileImport.message'),
							status: 'error',
						});

						return;
					}

					this.$telemetry.track('User imported workflow', { source: 'file', workflow_id: this.$store.getters.workflowId });
					this.$root.$emit('importWorkflowData', { data: worflowData });
				};

				const input = this.$refs.importFile as HTMLInputElement;
				if (input !== null && input.files !== null && input.files.length !== 0) {
					reader.readAsText(input!.files[0]!);
				}
			},
			async handleSelect(key: string, keyPath: string) {
				if (key === 'workflow-open') {
					this.$store.dispatch('ui/openModal', WORKFLOW_OPEN_MODAL_KEY);
				} else if (key === 'workflow-import-file') {
					(this.$refs.importFile as HTMLInputElement).click();
				} else if (key === 'workflow-import-url') {
					try {
						const promptResponse = await this.$prompt(
							this.$locale.baseText('mainSidebar.prompt.workflowUrl') + ':',
							this.$locale.baseText('mainSidebar.prompt.importWorkflowFromUrl') + ':',
							{
								confirmButtonText: this.$locale.baseText('mainSidebar.prompt.import'),
								cancelButtonText: this.$locale.baseText('mainSidebar.prompt.cancel'),
								inputErrorMessage: this.$locale.baseText('mainSidebar.prompt.invalidUrl'),
								inputPattern: /^http[s]?:\/\/.*\.json$/i,
							},
						) as MessageBoxInputData;

						this.$root.$emit('importWorkflowUrl', { url: promptResponse.value });
					} catch (e) {
						console.log(e);// eslint-disable-line no-console
					}
				} else if (key === 'workflow-delete') {
					const deleteConfirmed = await this.confirmMessage(
						this.$locale.baseText(
							'mainSidebar.confirmMessage.workflowDelete.message',
							{ interpolate: { workflowName: this.workflowName } },
						),
						this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.headline'),
						'warning',
						this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.confirmButtonText'),
						this.$locale.baseText('mainSidebar.confirmMessage.workflowDelete.cancelButtonText'),
					);

					if (deleteConfirmed === false) {
						return;
					}

					try {
						await this.restApi().deleteWorkflow(this.workspace.id, this.currentWorkflow);
					} catch (error) {

						this.$store.commit('activeAlert', {
							message: this.$locale.baseText('mainSidebar.showError.workflowDeleteMessage'),
							status: 'error',
						});

						return;
					}
					this.$store.commit('setStateDirty', false);
					// Reset tab title since workflow is deleted.
					this.$titleReset();

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText(
							'mainSidebar.showMessage.handleSelect1.message',
							{ interpolate: { workflowName: this.workflowName } },
						),
					});

					this.$router.push({ name: 'NodeViewNew' });
				} else if (key === 'workflow-download') {
					const workflowData = await this.getWorkflowDataToSave();

					const { tags, ...data } = workflowData;
					if (data.id && typeof data.id === 'string') {
						data.id = parseInt(data.id, 10);
					}
					const blob = new Blob([JSON.stringify(data, null, 2)], {
						type: 'application/json;charset=utf-8',
					});

					let workflowName = this.$store.getters.workflowName || 'unsaved_workflow';

					workflowName = workflowName.replace(/[^a-z0-9]/gi, '_');

					this.$telemetry.track('User exported workflow', { workflow_id: workflowData.id });

					saveAs(blob, workflowName + '.json');
				} else if (key === 'workflow-save') {
					const saved = await this.saveCurrentWorkflow({redirect: true});
					if (saved) this.$store.dispatch('settings/fetchPromptsData');
				} else if (key === 'workflow-duplicate') {
					this.$store.dispatch('ui/openModal', DUPLICATE_WORKFLOW_MODAL_KEY);
				} else if (key === 'help-about') {
					this.aboutDialogVisible = true;
					this.trackHelpItemClick('about');
				} else if (key === 'workflow-settings') {
					this.$store.dispatch('ui/openModal', WORKFLOW_SETTINGS_MODAL_KEY);
				} else if (key === 'workflow-new') {
					const result = this.$store.getters.getStateIsDirty;
					if (result) {
						const importConfirm = await this.confirmMessage(
							this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.message'),
							this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.headline'),
							'warning',
							this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.confirmButtonText'),
							this.$locale.baseText('mainSidebar.confirmMessage.workflowNew.cancelButtonText'),
						);
						if (importConfirm === true) {
							this.$store.commit('setStateDirty', false);
							if (this.$router.currentRoute.name === 'NodeViewNew') {
								this.$root.$emit('newWorkflow');
							} else {
								this.$router.push({ name: 'NodeViewNew' });
							}

							this.$store.commit('activeAlert', {
								message: this.$locale.baseText('mainSidebar.showMessage.handleSelect2.message'),
							});
						}
					} else {
						if (this.$router.currentRoute.name !== 'NodeViewNew') {
							this.$router.push({ name: 'NodeViewNew' });
						}

						this.$store.commit('activeAlert', {
							message: this.$locale.baseText('mainSidebar.showMessage.handleSelect3.message'),
						});
					}
					this.$titleReset();
				} else if (key === 'credentials-open') {
					this.$store.dispatch('ui/openModal', CREDENTIAL_LIST_MODAL_KEY);
				} else if (key === 'credentials-new') {
					this.$store.dispatch('ui/openModal', CREDENTIAL_SELECT_MODAL_KEY);
				} else if (key === 'execution-open-workflow') {
					if (this.workflowExecution !== null) {
						this.openWorkflow(this.workflowExecution.workflowId as string);
					}
				} else if (key === 'executions') {
					this.$store.dispatch('ui/openModal', EXECUTIONS_MODAL_KEY);
				}
			},
			closeWorkspace() {
				this.$router.push({
					name: 'workspace',
				})
			},
			getInitials(text) {
				if (!text) return;

				const nome = text?.split(' ');
				return (nome[0]?.charAt(0) + (nome[1] ? nome[1]?.charAt(0) : ''))?.toUpperCase();
			},
			accessProject() {
				if(Object.keys(this.workspace).length && this.workspace !== null) {
					this.$router.push({
						name: 'project',
					});
				}
			},
		},
	});
