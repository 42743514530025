<template>
	<div>
	<background-view :isLoading="isLoadingPage">
		<interaction-menu
			:InteractionMenuTitle="'Usuários Cadastrados'"
			:interactionMenuButtonToCleanFilter="'credentials.interactionMenu__buttonToCleanFilter'"
			:interactionMenuButtonToRegisterVariableBoolean="true"
			:interactionMenuButtonToRegisterVariable="'Cadastrar Usuário'"
			:interactionMenuLink="interactionMenu__link"
			:interactionMenuFilter="filtering"
			:alwaysActive="canViewRegisterUser()"
			:viewClearFilter="false"
		></interaction-menu>


		<FilterBar
			@search-applied="filterBySearch"
			@filters-applied="filterTable"
			@clearFilter="clearFilter"
			:filtering="filtering"
			:clearfiltering="clearfiltering"
			:activeFilters="filtersActive"
			:defaultInitialSearch="$route.query?.search"
			:placeholder="'Filtre por usuário'"
		>
		</FilterBar>


		<Table
			:maxheight="maxheight"
			:tableOverflow="true"
			class="users_table"
			:key="componentKey"
			:scrollInfinite="scrollInfinite && !filtering"
			:filtering="filtering"
			@endScroll="pagination()"
			id="tabela"
			:textIfEmpty="$locale.baseText('user.emptyList')"
			:isLoading="isLoadingTable"
		>
			<template v-slot:headerColumns>
				<tr class="table__tableTheadTr">
					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('user.userColumn') }}
							</div>
						</div>
					</th>
					<th class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('user.userColumnEmail') }}
							</div>
						</div>
					</th>

					<th width="160px" class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('user.userColumnStatus') }}
							</div>
						</div>
					</th>

					<th  class="table__tableTheadTrTh">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('user.userColumnProfile') }}
							</div>
						</div>
					</th>

					<th width="150px" class="table__tableTheadTrTh users_tableHeaderAux">
						<div class="table__tableTheadTrThTitle">
							<div class="table__tableTheadTrThTitleText">
								{{ $locale.baseText('credentials.tableTitle__actions') }}
							</div>
						</div>
					</th>
				</tr>
			</template>
			<template v-slot:columns>
				<tr v-for="row in dataTable" :key="row.id">
					<td>
						<div
							class="users_tableProfileName"
							@click.stop="canViewDetailUser() && goToUser(row.id)"
						>
							<RoundPhotoFrame
								class="avatar-space"
								size="32"
								:image="row.viewLinkPicture"
								:text="row.person"
								alt="foto-avatar"
							/>
							{{ row.person }}
						</div>
					</td>

					<td>
						<div class="users_tableEmail">
							{{ row.email }}
							<div
								:class="{
									users_Confirmed: row.emailConfirmed == true,
									users_NotConfirmed: row.emailConfirmed == false,
								}"
							>
								<div v-if="row.emailConfirmed == true">{{ $locale.baseText('Confirmado') }}</div>
								<div v-if="row.emailConfirmed == false">{{ $locale.baseText('Aguardando Confirmação') }}</div>
							</div>
						</div>
					</td>

					<td width="160px">
						<div
							class="users__Column_Frame"
							v-bind:class="{
								users_Active: row.activePermission == true,
								users_Inactive: row.activePermission == false,
							}"
						>
							<div v-if="row.activePermission == true">{{ $locale.baseText('Ativo(a)') }}</div>
							<div v-else-if="row.activePermission == false">{{ $locale.baseText('Inativo(a)') }}</div>
							<div v-else-if="row.activePermission == undefined">{{ $locale.baseText('Carregando...') }}</div>
						</div>
					</td>

					<td>
						<div
							class="users__Column_Frame"
						>
							<div v-if="row.profiles">{{ row.profiles }}.</div>
							<div v-else-if="row.profiles == false">{{ $locale.baseText('Não Vinculado') }}</div>
							<div v-else-if="row.profiles == undefined">{{ $locale.baseText('Carregando...') }}</div>
						</div>
					</td>

					<td width="150px" class="users_tableActionsCenter">
						<div class="users_tableActionsFrame">
							<el-tooltip	:content="canViewDetailUser() ? 'Ver Detalhes' : $locale.baseText('permission.noPermissionFuncionality')"
								placement="bottom"
							>
								<div
									class="users_tableActionsButtonLeft eyeButton"
									@click.stop="canViewDetailUser() && goToUser(row.id)"
									:class="{ 'eyeButton--disableEye': !canViewDetailUser() }"
								/>
							</el-tooltip>

							<el-tooltip	:content="canViewEditUser() ? 'Editar Usuário' : $locale.baseText('permission.noPermissionFuncionality')"
								placement="bottom"
							>
								<div
									class="users_tableActionsButtonLeft editButton"
									@click.stop="canViewEditUser() && goToEditUser(row.id, row.profiles)"
									:class="{ 'editButton--disableEdit': !canViewEditUser(row.activePermission)}"
								/>
							</el-tooltip>

							<el-tooltip
								:content="!canViewSendEmail() ? $locale.baseText('permission.noPermissionFuncionality') : row.emailConfirmed ? 'E-mail Confirmado' : 'Reenviar E-mail'"
								placement="bottom"
							>
								<div
									class="users_tableActionsButtonLeft sendButton"
									@click.stop="canViewSendEmail(row.emailConfirmed) && sendEmail(row.email)"
									:class="{ 'sendButton--disableSend': row.emailConfirmed || !canViewSendEmail() }"
								/>
							</el-tooltip>
						</div>
					</td>
				</tr>
			</template>
			<template v-slot:footerColumns>
				<div></div>
			</template>
		</Table>
	</background-view>
	</div>
</template>

<script>
import Table from '@/components/Table.vue';
import FilterTable from '@/components/FilterTable.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';

import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';
import { previousRoute } from '../router/index.ts';

import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import {
	searchPermissions,
	removeItemFromArrayByValue,
} from '@/components/helpers';
import { userPermission } from '@/components/mixins/permissions/user';


export default mixins(
	userPermission
).extend({
	components: {
		Table,
		FilterBar,
		FilterIcon,
		FilterTable,
		SelectFilter,
		BackgroundView,
		RoundPhotoFrame,
		InteractionMenu,
	},
	name: 'Users',
	data() {
		return {
			value: '',
			interactionMenu__link: 'registerUser',
			maxheight: '60vh',
			page: 1,
			pageSize: 10,
			isLoadingTable: false,
			isLoadingPage: false,
			scrollInfinite: true,
			filtering: false,
			dataTable: [],
			dataTableTemp: [],

			componentKey: 0,
			dataFilter:[],

			filtersActive: {},
			clearfiltering: false,
			previousRoute: previousRoute,
		};
	},
	async created() {
		this.isLoadingPage = true;
		await this.initUsers();
		this.isLoadingPage = false;
	},
	async mounted() {
	},
	methods: {
		...mapActions('aliareAccount', ['getUsersByTenantId', 'verifyUserActive', 'getUserPermissionById']),
		...mapActions('auth', ['revokeTokens', 'sendConfirmationEmail']),

		async verifyUsersActive(users, pushValue) {
			if (!users.length) return;
			let returnProductProfile = true;
			let usersVerified = [];
			for await (const user of users) {
				await this.getUserPermissionById({userId: user.id}).then((response) => {
					let indexUser = users.findIndex(userTable => userTable.id === user.id);
					usersVerified.push(users[indexUser]);
					console.log(response);

					if (response.length) {
							response.forEach((permission) => {
									if (!permission.userProfile.blocked && permission.userProfile.active) {
											users[indexUser].activePermission = true;
											users[indexUser].profiles = permission.productProfile.description;
									} else if (!users[indexUser].activePermission) {
											users[indexUser].profiles = permission.productProfile.description;
											users[indexUser].activePermission = false;
									}
							});
					} else {
							// Caso o array response esteja vazio
							users[indexUser].profiles = false;
							users[indexUser].activePermission = false;
					}



				});
			}
			if (pushValue) {
				this.dataTable.push(...usersVerified);
			} else {
				this.dataTable = usersVerified;
				this.dataTableTemp = this.dataTable;
			}
		},

		async initUsers() {
			const applyPage = WebStorage.getItem('applyPage');
			if (this.previousRoute.name == 'userdetailing' && applyPage == 'users' ) {
				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});
			};

			if(Object.keys(this.$route.query).length) {
				await this.filterTable();
			} else {
				await this.getAccount();
			}
		},

		async filterBySearch(search) {
			this.filtering = search.length < 1 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length < 1 ? '' : search);
		},
		async filterTable(filters) {
			try {
				this.isLoading = true;
				const routeQuery = this.$route.query;
				const payload = {
					size: 10,
					search: filters?.filterSearch || routeQuery?.search,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.getUsersByTenantId(payload);

				await this.applyFilter(dataFilter.data, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoading = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.clearfiltering = true;
			this.dataTable = dataFiltered;
			this.$store.commit('setFilters', query);
			WebStorage.setItem('applyPage', 'users');

			this.verifyUsersActive(this.dataTable);
		},

		async clearFilter() {
			this.filtering = false;
			this.filtersActive = {};
			this.clearfiltering = false;

			await this.$router.replace({'query': null}).catch(() => {});

			WebStorage.removeItem('applyPage');
			this.$store.commit('setFilters', this.filtersActive);
			this.getAccount();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		async pagination() {
			this.page++;
			const payload = { ...this.$route.query, page: this.page };

			this.getUsersByTenantId(payload).then((response) => {
				this.verifyUsersActive(response, true);

				for (const index in response?.data) {
					this.dataTable.push(response?.data[index]);
				}

				if (response?.data.length < 25) {
					this.scrollInfinite = false;
				}
			});
		},
		async getAccount() {
			const payload = { ...this.$route.query, page: 1 };
			await this.getUsersByTenantId(payload).then((response) => {
				this.dataTable = response.data;

				if (response.length < 25) {
					this.scrollInfinite = false;
				}

				this.verifyUsersActive(this.dataTable);
			});
		},
		sendEmail(email) {
			this.sendConfirmationEmail(email)
			.then(() => {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('sendEmail.emailSucces'),
				});
			})
			.catch((error) => {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('sendEmail.emailError'),
					status: 'error',
				});
			});
		},
		goToUser(id) {
			this.$router.push({
				name: 'userdetailing',
				params: { id },
			}).catch(()=>{});
		},
		goToEditUser(id, profile) {
			WebStorage.setItem('profileName', profile);

			this.$router.push({
				name: 'editUser',
				params: { id },
			}).catch(()=>{});
		},
	},
});
</script>

<style lang="scss" scoped>

.avatar-space {
	margin: 0 4px;
}

.users_tableHeaderAux {
	z-index: 3;
}

.users_table {
	padding: 0 1.5%;
	margin-bottom: 1.5%;
	position: relative;
}

.users_tableProfileName {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #59d64c;
	text-decoration: none;
	display: flex;
	align-items: center;
  justify-content: flex-start;

	&:hover {
		color: #59d64c;
		cursor: pointer;
	}
}

.users_tableEmail {
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: rgba(255, 255, 255, 0.88);
}

.users_tableActionsCenter {
	.users_tableActionsFrame {
		display: flex;
		justify-content: center;

		.users_tableActionsButtonLeft {
			width: 32px;
			height: 32px;
			border: 1px solid #4a638f;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24);
			cursor: pointer;

			&:hover {
				position: relative;
				background-color: #29364f;

				.tooltipAliare {
					display: flex;
				}

				.tooltipAliareDisable {
					z-index: 9999;
					width: fit-content;
				}
			}
			&.eyeButton {
				border-radius: 4px 0 0 4px;
				background-position: center;
				background-size: 18px 12.27px;
				background-repeat: no-repeat;
				background-image: url('../assets/blueEyeIcon.svg');

				&--disableEye {
					cursor: not-allowed;
					background-color: #29364f;
					background-image: url('../assets/blueEyeIconGrey.svg');
				}
			}
			&.editButton {
				border-radius: 4px 0 0 4px;
				background-image: url('../assets/pen-blue.svg');
				background-position: center;
				background-size: 18px 12.27px;
				background-repeat: no-repeat;
				border-radius: 0;

				&--disableEdit {
					cursor: not-allowed;
					background-color: #29364f;
					background-image: url('../assets/pen.svg');
				}
			}
			&.sendButton {
				border-radius: 0 4px 4px 0;
				background-position: center;
				background-size: 12.44px 16px;
				background-repeat: no-repeat;
				background-image: url('../assets/sendIcon.svg');

				&--disableSend {
					cursor: not-allowed;
					background-color: #29364f;
					background-image: url('../assets/sendIconGray.svg');
				}
			}
		}
	}
}

.users__Column_Frame {
	text-align: center;
	border: 1px solid #34476a;
	border-radius: 100px;
	padding: 4px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.users_Active {
	color: #00C0FF;
}

.users_Confirmed {
	color: #00C0FF;
	font-size: 12px;
}

.users_Inactive {
	color: #ACD7FF;
}

.users_NotConfirmed {
	color: #FFA53B;
	font-size: 12px;
}
</style>
