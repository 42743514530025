
import CardIntegration from '@/components/CardIntegration.vue';

import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { previousRoute } from '../router/index.ts';


import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import BackgroundView from '@/components/BackgroundView.vue';
import AliareInput from '@/components/AliareInput.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import NodeIcon from '@/components/NodeIcon.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import WebStorage from '@/common/WebStorage';
import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import SelectFilter from '@/components/SelectFilter.vue';



export default mixins(
	nodeHelpers,
	showMessage,
	genericHelpers,
).extend({
	components: {
		BackgroundView,
		CardIntegration,
		SelectFilter,
		NodeIcon,
		NotificationPage,
		FilterIcon,
		ScrollInfinite,
		AliareInput,
		AlertNotificationModal,
		FilterBar,
	},
	name: 'integrationStore',
	data() {
		return {
			isLoadingInfo: true,
			isLoadingPage: false,
			integrationCardList: [],
			clearfiltering: false,
			filtersActive: {},
			filtering: false,
			pageSize: 10,
			scrollInfinite: true,
			previousRoute: previousRoute,
			page: 1,
			queryResults: this.$route.query.statusUltimaSolicitacao || [],
		};
	},
	computed: {
		resultQuery() {
			if (typeof this.$route.query?.statusUltimaSolicitacao === 'string') {
				return [this.$route.query?.statusUltimaSolicitacao];
			}
			return this.$route.query?.statusUltimaSolicitacao;
		},
		resultText() {
			if (typeof this.queryResults === 'string') {
				return this.queryResults;
			}
			const length = this.queryResults.length;
			return length === 1 ? this.queryResults[0] : length === 0 ? 'Status' : `${this.queryResults[0]} +${length - 1}`;
		},
	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadCardsIntegration();
		});
	},
	async created() {
		this.initPageIntegrationStore();
	},
	watch: {

	},
	methods: {

		async filterByResult(query) {
			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterStatus', query);

			this.filtering = !this.queryResults.length;
		},
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},
		async loadNodeTypes(): Promise<void> {
			const nodeTypes = await this.restApi().getNodeTypes();
			this.$store.commit('setNodeTypes', nodeTypes);
		},
		async initPageIntegrationStore() {
			this.isLoadingInfo = true;
			const applyPage = WebStorage.getItem('applyPage');
			await this.loadNodeTypes();

			if ((this.previousRoute.name == 'knowMoreIntegration')
				&& applyPage == 'integrationStore' ) {

				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});
			};

			if(Object.keys(this.$route.query).length) {
				this.filterTable();
			} else {
				await this.loadCardsIntegration();
			}
			this.isLoadingInfo = false;
		},
		async clearFilter() {
			this.page = 1;
			this.filtering = false;
			this.clearfiltering = false;
			this.filtersActive = {};
			this.queryResults = [];
			this.scrollInfinite = true;

			await this.$router.replace({'query': null}).catch(() => {});

			this.$store.commit('setFilters', this.filtersActive);
			WebStorage.removeItem('applyPage');
			this.initPageIntegrationStore();
    },
		async applyFilter(dataFiltered, query) {
			this.page = 1;
			this.filtering = true;
			this.clearfiltering = true;
			this.integrationCardList = dataFiltered;

			if(this.integrationCardList.length >= this.pageSize) {
				this.scrollInfinite = true;
			}
			WebStorage.setItem('applyPage', 'integrationStore');
			this.$store.commit('setFilters', query);
		},
		async filterTable(filters) {
			try {
				this.isLoadingInfo = true;
				this.scrollInfinite = false;

				const routeQuery = this.$route.query;

				const payload = {
					size: this.pageSize,
					pesquisa: filters?.filterSearch || routeQuery?.titulo,
					statusUltimaSolicitacao: filters?.filterStatus || routeQuery?.status,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getPublishedIntegrations(payload);
				await this.applyFilter(dataFilter, payload);

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingInfo = false;
			}
		},
		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async loadIntegrationCardScroll() {
			if (this.integrationCardList?.length % this.pageSize === 0) {
				this.page++;
				const payload = { ...this.$route.query, page: this.page };
				this.restApi().getPublishedIntegrations(payload).then((response) => {
					if(!response.length){
						this.scrollInfinite = false;
					}

					for (const index in response) {
						this.integrationCardList.push(response[index]);
					}

					if (response.length < this.pageSize) {
						this.scrollInfinite = false;
					}
				});
			} else {
				this.scrollInfinite = false;
			}
		},
		async loadCardsIntegration() {
			this.isLoadingInfo = true;
			const payload = { ...this.$route.query, page: 1, size: this.pageSize };

			try {
				const response = await this.restApi().getPublishedIntegrations(payload);
				this.integrationCardList = response;

				if (response.length < this.pageSize) {
					this.scrollInfinite = false;
				} else {
					this.scrollInfinite = true;
				}
			} catch (e) {
				console.error(e);
			}

			this.isLoadingInfo = false;
		},

	},
	beforeDestroy() {
		this.$root.$off("reloadList");
	},
});
