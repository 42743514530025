
import mixins from 'vue-typed-mixins';
import { mapGetters } from 'vuex';

import { pushConnection } from '@/components/mixins/pushConnection';


import WorkflowDetails from '@/components/MainHeader/WorkflowDetails.vue';
import VersionHistoryDetails from '@/components/MainHeader/VersionHistoryDetails.vue';
import ExecutionDetails from '@/components/MainHeader/ExecutionDetails/ExecutionDetails.vue';
import TheTopNav from '@/components/TheTopNav/TheTopNav.vue';


export default mixins(pushConnection).extend({
	name: 'MainHeader',
	components: {
		WorkflowDetails,
		ExecutionDetails,
		TheTopNav,
		VersionHistoryDetails,
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		hasPrevPage() {
			return window.history.length > 1;
		},
		isExecutionPage(): boolean {
			return ['ExecutionById'].includes(this.$route.name as string);
		},
		isHistoryVersionPage(): boolean {
			return ['VersionById'].includes(this.$route.name as string);
		},
	},
	async mounted() {
		// Initialize the push connection
		this.pushConnect();
	},
	beforeDestroy() {
		this.pushDisconnect();
	},
	methods: {
		backPage() {
			if (['NodeViewExisting'].includes(this.$route.name as string)) {
				console.log('entrou no if aqui');
				this.$router.push({
					name: "projectDetailing",
					params: { id: this.$store.getters.getWorkflowProjectId },
				});
			} else {
				console.log('entrou no else aqui');
				this.$router.go(-1);
			}
		},
	},
});
