
import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import AliareInput from '@/components/AliareInput.vue';
import mixins from 'vue-typed-mixins';

export default mixins(
	nodeHelpers,
).extend({
	components: {
		AliareInput,
	},
	props: {
		dataInput: {
			type: Object,
		},
	},
	data() {
		return {
			isLoadingDataCenter: false,
			dataCenterSelected: '',
			dataCenterList: '',
			oldSearch: '',
			projectSelected: '',
			searched: false,
			showSelect: false,
			searchDataCenter: '',
		};
	},
	computed: {
		isEdit() {
			if (this.dataInput.item?.workflow?.id) {
				return true;
			} else {
				return;
			}
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		disableInput() {
			this.showSelect = false;
		},

		searchInfoByName:
		_.debounce(async function (e)  {
			let firstFocus = false;
			this.isLoadingDataCenter = true;

			if (!this.dataCenterList.length) {
				firstFocus = true;
			}

			if (firstFocus || this.searchDataCenter.length > 2 && this.oldSearch !== this.searchDataCenter || !firstFocus && this.searchDataCenter.length < 2 && this.oldSearch !== this.searchDataCenter) {
				try {
					this.dataCenterList = await this.restApi().getDataCenter(this.workspace.id, {
						searchTitulo: this.searchDataCenter,
						ativo: true,
					});

					this.oldSearch = JSON.parse(JSON.stringify(this.searchDataCenter));

					this.searched = true;
				} catch (e) {
					console.log(e);
				}
			}

			this.isLoadingDataCenter = false;
		}, 500),
		activeSearch() {
			this.showSelect = !this.showSelect;
			this.$nextTick(() => {
				this.$refs.aliareInput.$refs.input.focus();
			});
		},
		selectedDataCenter(info) {
			this.showSelect = !this.showSelect;
			this.dataCenterSelected = info;
			let dataItem;
			if (this.dataInput) {
				dataItem = JSON.parse(JSON.stringify(this.dataInput));
			}

			this.$emit('selected', {data: info, item: dataItem});
		},

	},

});
