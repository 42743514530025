<template>
	<BackgroundView :isLoading="isLoadingDetail">
		<div class="container-header">
			<div class="header-detailing">
				<div class="pageTitle">
					{{ form.nome }}
				</div>
				<div class="pageSubtitle">{{ accountInfo?.name }}</div>
			</div>

			<div class="container-options">
				<div class="divider"/>
				<el-tooltip :disabled="editProjectPermission" :content="$locale.baseText('permission.noPermissionFuncionality')"
					placement="top"
				>
					<div class="container-buttons button-edit">
						<ModalButtonSuccess
							:editIcon="true"
							:width="'133'"
							:alwaysActive="canRenameProject(form, projectActive)"
							:titleButton="$locale.baseText('project.edit.title')"
							@click="showModalsProject(form,'createEditProject')"
						/>
					</div>
				</el-tooltip>

				<ModalButtonSuccess
					@click="openShareModal({...form, nomeProjeto: form.nome}, 'Projeto', !canShareProject(form, projectActive))"
					:titleButton="$locale.baseText('credentialDetailing.actions.share')"
					:alwaysActive="canViewShareProject(form, projectActive)"
					:shareIcon="true"
					:width="'141'"
				/>

				<div class="header-options"	@click="show = !show"/>
				<MenuOptions :Right="'5px'"	:Top="'45px'"	v-show="show">
					<template v-slot:middle>
						<el-dropdown-item :disabled="!canToggleActiveProject(form)">
							<el-tooltip :disabled="editProjectPermission" :content="$locale.baseText('permission.noPermissionFuncionality')" placement="top">
								<div data-testid="toggle-status-detail-project" class="header-options-body">
									<div
										class="switch_box box_4"
										@click="canToggleActiveProject(form) && changeActiveProject(!projectActive, projectId)"
										:class="{'switch_box--disableButton': false}"
									>
										<div class="input_wrapper">
											<input type="checkbox" v-model="projectActive" class="switch_4" />
											<div class="content-switch is_checked">Ativo</div>
											<div class="content-switch is_unchecked">Inativo</div>
										</div>
									</div>
								</div>
							</el-tooltip>
						</el-dropdown-item>
					</template>

					<template v-slot:end>
						<el-dropdown-item :disabled="!canDeleteProject(form, projectActive)">
							<el-tooltip :disabled="deleteProjectPermission" :content="$locale.baseText('permission.noPermissionFuncionality')" placement="top">
								<div
									data-testid="button-delete-project"
									class="header-options-footer"
									@click="canDeleteProject(form, projectActive) && openModalAlert()"
								>
									<div class="delete-option"></div>
									<div>{{ $locale.baseText('project.textDeleteProject') }}</div>
								</div>
							</el-tooltip>
						</el-dropdown-item>
					</template>
				</MenuOptions>
			</div>
		</div>

		<div class="projectDetailing__body" v-if="!isLoadingDetail">
			<div>
				<div class="projectDetailing__bodyTitle">{{ $locale.baseText('projectDetailing.projectDetailing__bodyTitle') }}</div>
				<div class="projectDetailing__bodyLine"/>

				<div class="projectDetailing__bodyHeader">
					<div class="projectDetailing__bodyHeaderLeft">
						<FilterBar
							@search-applied="filterBySearch"
							@filters-applied="filterTable"
							@clearFilter="clearFilter"
							:filtering="filtering"
							:clearfiltering="clearfiltering"
							:activeFilters="filtersActive"
							:defaultInitialSearch="$route.query?.searchNome"
							:placeholder="'Filtre por fluxo'"
						>
							<template #filters>
								<div class="filter-space">
									<FilterIcon
										:selectedValue="resultTextStatus"
										data-testid="filter-status-detail"
									>
										<template v-slot:filter>
											<SelectFilter
												typeFilter="status"
												:clearInput="clearfiltering"
												@selectFilter="filterByStatus"
												:defaultStatus="$route.query?.ativo"
												:filterOptions="statusOptions"
												class="container__filter"
											/>
										</template>
									</FilterIcon>
								</div>

								<div class="filter-space">
									<FilterIcon
										:selectedValue="resultText"
										data-testid="filter-results-detail"
									>
										<template v-slot:filter>
											<SelectFilter
												typeFilter="multi-fixed"
												:clearInput="clearfiltering"
												@selectFilter="filterByResult"
												:defaultSelectedMultiFixed="queryResults"
												class="container__filter filterResult"
												:filterOptions="['Sucesso', 'Falha', 'Alerta', 'Não Armazenado', 'Interrompido']"
											/>
										</template>
									</FilterIcon>
								</div>
							</template>
						</FilterBar>
					</div>


					<div class="projectDetailing__bodyHeaderButtons">
						<div class="divider"></div>

						<div class="projectDetailing__bodyHeaderRight">
							<el-tooltip :disabled="createFlowsPermission" :content="$locale.baseText('permission.noPermissionFuncionality')"
								placement="top"
							>
								<div class="container-buttons button-edit">
									<ModalButtonSuccess
										v-if="true"
										@click="redirectWorkflowNew(projectId)"
										:titleButton="$locale.baseText('projectDetailing.btn_1')"
										:active="canCreateFlows(form, projectActive)"
										:plusIcon="true"
										:width="'122'"
									/>
								</div>
							</el-tooltip>
						</div>


						<div class="switch_box box_4">
							<div class="input_wrapper">
								<input type="checkbox" v-model="viewDataCard" class="switch_4">
								<div class="content-switch is_checked">
									<i class="card-icon"></i>
									Card
								</div>
								<div class="content-switch is_unchecked">
									<i class="table-icon"></i>
									Tabela
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-show="!isLoadingWorkflow">
					<div class="projectDetailing__bodyCard" v-if="!dataWorkflowDetails.length && viewDataCard">
						<InfoBox>
							<template #content>
								<p>
									{{ emptyListWorkflow }}
								</p>
							</template>
						</InfoBox>
					</div>

					<CardsAndTableDetailing
						:form="form"
						:viewDataCard="viewDataCard"
						:projectActive="projectActive"
						:workflowData="dataWorkflowDetails"
						@reload="getWorkflowsDetail(true)"
						@pagination="paginations()"
						@selectAllWorkflows="getWorkflowsDetail(false, true)"
					/>
				</div>

				<div class="container-loading" v-show="isLoadingWorkflow">
					<div class="loading-spin"></div>
				</div>
			</div>
		</div>

		<AlertNotificationModal
			v-if="showAlert"
		>
			<template v-slot:icon>
				<i class="equivalenceCreateSuccessIcon"></i>
			</template>

			<template v-slot:title>
				{{ 'Exclusão de Projeto' }}
			</template>
			<template v-slot:firstText>
				<p> {{ $locale.baseText('project.modalDelete.modal__DeleteBody') }}</p>
			</template>

			<template v-slot:footer>
				<ModalButtonSuccess
					:titleButton="'Manter Projeto'"
					width="300"
					@click="showAlert = false"
					alwaysActive
					plusIcon
				/>
				<ModalButtonCancel
					width="300"
					:loadingAction="isLoadingAction"
					:titleButton="'Excluir Projeto'"
					@click="deleteDetail()"
				/>
			</template>
		</AlertNotificationModal>

		<ScrollInfinite v-if="scrollInfinite && this.dataWorkflowDetails?.length && viewDataCard" @endOfScroll="paginations()" />

	</BackgroundView>
</template>

<script>
import InfoBox from '@/components/InfoBox.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import MenuOptions from '@/components/MenuOptions.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import CardsAndTableDetailing from '@/components/CardsAndTableDetailing.vue';

import moment from 'moment';
import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { filter } from '@/components/helpers';
import { previousRoute } from '../router/index.ts';
import { restApi } from '@/components/mixins/restApi';
import { CREATE_EDIT_PROJECT_MODAL_KEY } from '@/constants';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { projectPermission } from '@/components/mixins/permissions/project';
import { workflowPermission } from '@/components/mixins/permissions/workflow';

export default mixins(
	restApi,
	projectPermission,
	workflowPermission,
	genericHelpers,
).extend({
	name: 'ProjectDetailing',
	components: {
		InfoBox,
		FilterBar,
		FilterIcon,
		MenuOptions,
		SelectFilter,
		BackgroundView,
		ScrollInfinite,
		ModalButtonCancel,
		ModalButtonSuccess,
		CardsAndTableDetailing,
		AlertNotificationModal,
	},
	data() {
		return {
			selectMode: false,
			show: false,
			filtering: false,
			showAlert: false,
			viewDataCard: true,
			projectActive: false,
			scrollInfinite: true,
			clearfiltering: false,
			isLoadingDetail: true,
			isLoadingAction: false,
			notResultFilter: false,
			isLoadingWorkflow: true,

			page: 1,
			pageSize: 10,
			projectId: '',
			dataWorkflowDetails: [],
			tenantId: WebStorage.getItem("tenantId"),

			form: [],
			filtersActive: {},
			intervalTimer: null,
			organization: JSON.parse(WebStorage.getItem('cardOrganization')),
			accountInfo: JSON.parse(WebStorage.getItem('accountInfo')),

			statusOptions: [
				{ label: 'Ativo', value: true },
        { label: 'Inativo', value: false }
      ],
			queryStatus: this.$route.query.ativo || '',
			queryResults: typeof this.$route.query.resultadoExecucao == 'string' ? [this.$route.query.resultadoExecucao] : this.$route.query.resultadoExecucao || [],
		};
	},
	beforeCreate() {
		const applyPage = WebStorage.getItem('applyPage');

		if ((previousRoute.name == 'NodeViewNew' || previousRoute.name == 'NodeViewExisting') && applyPage == 'projectDetailling' ) {
			this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});
		};
	},
	async mounted() {
		setupHeaderAliareTenant(this.tenantId);
		this.isLoadingDetail = true;

		await this.getDetailsId();
		await this.initPageDetailling();

		this.intervalTimer = window.setInterval(() => {
			this.getDetailsId();
		}, 300000);

		this.$root.$on("reloadDetail", this.eventHandler);
		this.isLoadingDetail = false;
	},
	destroyed () {
		if (this.intervalTimer !== null) {
			clearInterval(this.intervalTimer);
		}
	},
	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
		emptyListWorkflow() {
			return this.notResultFilter ? this.$locale.baseText('projectDetailing.emptyListFilter') : this.$locale.baseText('projectDetailing.emptyList')
		},
		resultTextStatus() {
			const statusMap = {
				true: 'Ativo',
				false: 'Inativo'
			};
			return this.queryStatus.length ? (statusMap[this.queryStatus] || this.queryStatus) : 'Status';
		},
		resultText() {
			if (typeof this.queryResults === 'string') {
				return this.queryResults;
			}
			const length = this.queryResults.length;
			return length === 1 ? this.queryResults[0] : length === 0 ? 'Resultados' : `${this.queryResults[0]} +${length - 1}`;
		},
	},
	methods: {
		...mapActions('project', ['patchActive', 'delete']),
		...mapActions('workflows', ['patchWorkflowsWorkflowActive',	'patchWorkflowsWorkflowAutomatic']),

		async initPageDetailling() {
			this.isLoadingDetail = true;
			this.isLoadingWorkflow = true;

			if(Object.keys(this.$route.query).length) {
				await this.filterTable();
			} else {
				await this.getWorkflowsDetail(true);
			}

			this.isLoadingDetail = false;
			this.isLoadingWorkflow = false;
		},

		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async filterByStatus(query) {
			this.queryStatus = !query ? 'Inativo' : 'Ativo';
			this.addOrRemoveFilter(this.filtersActive, 'filterStatus', query);
			this.filtering = !this.queryStatus.length;
		},
		async filterByResult(query) {
			const replaceQuery = query.map(item => item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '_'))

			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', replaceQuery);
			this.filtering = !this.queryResults.length;
		},

		async filterTable(filters) {
			try {
				this.isLoadingWorkflow = true;
				this.scrollInfinite = false;
				const routeQuery = this.$route.query;
				let isFilterStatusActive;

				if (filters && filters.filterStatus !== undefined) {
					isFilterStatusActive = filters.filterStatus;
				} else if (routeQuery?.ativo) {
					isFilterStatusActive = routeQuery?.ativo == "true";
				}

				const payload = {
					size: 10,
					ativo: isFilterStatusActive,
					projetoId: this.projectId,
					searchNome: filters?.filterSearch || routeQuery?.searchNome,
					resultadoExecucao: filters?.filterResults || routeQuery?.resultadoExecucao,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getWorkflowsDetails(this.workspace.id, payload);
				await this.applyFilter(dataFilter, payload);

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});

				this.clearFilter();
			} finally {
				this.isLoadingWorkflow = false;
			}
		},
		async applyFilter(dataFiltered, filters) {
			this.filtering = true;
			this.clearfiltering = true;
			this.dataWorkflowDetails = dataFiltered;
			this.$store.commit('setFilters', filters);
			WebStorage.setItem('applyPage', 'projectDetailling');

			if(this.dataWorkflowDetails?.length < 10) {
				this.scrollInfinite = false;
			} else {
				this.scrollInfinite = true;
			}
		},

		async clearFilter() {
			this.page = 1;
			this.filtering = false;
			this.queryStatus = [];
			this.queryResults = [];
			this.filtersActive = {};
			this.clearfiltering = false;

			await this.$router.replace({'query': null}).catch(() => {});

			this.$store.commit('setFilters', this.filtersActive);
			WebStorage.removeItem('applyPage');
			this.initPageDetailling();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		async getWorkflowsDetail(resetPage, sizeInfinite) {
			if (sizeInfinite) {
				this.pageSize = 99999;
				this.scrollInfinite = false;
			}
			this.isLoadingWorkflow = true;

			if (resetPage) {
				this.page = 1;
			}

			const payload = {
				...this.$route.query,
				page: this.page,
				size: this.pageSize,
				projetoId: this.$route.params.id,
			};

			await this.restApi().getWorkflowsDetails(this.workspace.id, payload)
			.then((response) => {
				if (sizeInfinite) {
					response.forEach((workflow) => {
						workflow.selectedWorkflow = true;
					});
					this.dataWorkflowDetails = [];
					this.dataWorkflowDetails = response;
					this.status = 'active';

				} else {
					this.scrollInfinite = true;
					this.dataWorkflowDetails = response;
					this.status = 'active';


					if(this.dataWorkflowDetails?.length < 10) {
						this.scrollInfinite = false;
					} else {
						this.scrollInfinite = true;
					}
				}

				this.isLoadingWorkflow = false;
			});
		},
		async getDetailsId() {
			await this.restApi().getProjectDetailingById(this.workspace.id, this.$route.params.id).then((aux) => {
				this.form = aux;
				this.projectActive = aux.ativo;
				this.projectId = aux.id;
			})
			.catch((error) => {
				const ErrorStatus = error.notifications;
				this.$store.commit('activeAlert', {
							message:  ErrorStatus,
							status: 'error',
				});
			});
		},
		async paginations() {
			if (this.dataWorkflowDetails?.length % this.pageSize === 0) {
				this.page++;
				const payload = {
					...this.$route.query,
					page: this.page,
					size: this.pageSize,
					projetoId: this.$route.params.id,
				};

				await this.restApi().getWorkflowsDetails(this.workspace.id, payload).then((response) => {

					if (!response?.length) {
						this.page = 1;
						this.scrollInfinite = false;
						return false;
					}

					for (const index in response) {
						this.dataWorkflowDetails.push(response[index]);
					}
				});
			} else {
				this.page = 1;
				this.scrollInfinite = false;
				return false;
			}
		},

		async deleteDetail() {
			this.isLoadingAction = true;

			await this.delete({ workspaceId: this.workspace.id, projetoId: this.projectId })
				.then((aux) => {
					this.showAlert = false;
					const deleteProjectName = this.form.nome;
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('project.modalDelete.projectDeleteSuccess', {
							interpolate: { deleteProjectName },
						}),
					});
					setTimeout(() => {
						this.$router.push({ name: 'project' });
					}, 2000);
				})
				.catch((error) => {
					const deleteError = error;
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('project.modalDelete.deleteError', {
							interpolate: { deleteError },
						}),
						status: 'error',
					});
				});
			
			this.isLoadingAction = false;
		},
		changeActiveProject(active, projetoId) {
			if (active === this.projectActive) {
					return;
			}
			const inactive = { workspaceId: this.workspace.id, projetoId, ativo: active };

			this.patchActive(inactive).then((result) => {
				const isActive = result.data.ativo;
				const successMessageKey = isActive ? 'project.sucessActive' : 'project.sucessInactive';

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText(successMessageKey, { interpolate: { ProjectName: this.form.nome } }),
				});

				this.$root.$emit('closeSelectItemsComponent');
				this.projectActive = isActive;
				this.dataWorkflowDetails = [];
				this.getDetailsId();
				this.getWorkflowsDetail(true);
				
			})
			.catch((error) => {
				const ErrorStatus = error;

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('project.errorsActiveOrInactive', { interpolate: { ErrorStatus } }),
					status: 'error',
			  });
			});
		},
		redirectWorkflowNew(id) {
			this.$router.push({
				name: 'NodeViewNew',
				params: { projectid: id },
			});
		},

		showModalsProject(form, key) {
			if (key === 'createEditProject') {
				this.$store.dispatch('ui/openCreateEditProjectModal', {	data: form, mode: 'edit' });
			}
		},
		openModalAlert() {
			this.showAlert = true;
		},
		eventHandler() {
			this.getWorkflowsDetail(true);
			this.getDetailsId();
    },
	},
	beforeDestroy() {
    this.$root.$off("reloadDetail", this.eventHandler);
  },
});
</script>

<style scoped lang="scss">
.divider {
	width: 1px;
	height: 24px;
	margin: 0px 32px;
	align-self: center;
	background-color: #4a638f;
}
.division {
	background-color: #4a638f;
	height: 1px;
	width: 100%;
}

.container-header {
	display: flex;
	padding: 16px 32px;
	align-items: center;
	background-color: #233149;
	justify-content: space-between;
	border-bottom: 1px solid #34476a;

  .header-detailing {
		display: flex;
		flex-direction: column;

		.pageTitle {
			color: #00c3dd;
			font-size: 20px;
			font-weight: 500;
			line-height: 23px;
			margin-bottom: 4px;
		}
		.pageSubtitle {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: rgba(255, 255, 255, 0.88);
		}
	}

	.container-options {
		display: flex;

		.header-options {
			width: 40px;
			height: 40px;
			cursor: pointer;
			border-radius: 3px;
			margin: 0 4px 0 16px;
			border: 1px solid #00c3dd;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('../assets/threeBlueDots.svg');

			&:hover {
				background-color: rgba(255, 255, 255, 0.08);
			}
		}

		.header-options-body {
			padding: 8px 17px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-bottom: 1px solid #46587c;

			:deep {
				.switch_box {
					cursor: pointer;
					.input_wrapper {
						pointer-events: none;
						width: 173px;
						height: 48px;

						.is_checked {
							left: 26px;
						}

						.is_unchecked {
							right: 21px;
						}

						input {
							width: 173px;
							height: 28px;
							&:after {
								width: 83px;
								height: 25px;
								left: calc(100% - 85px);
								background: #FF495F;

							}

							&:checked:after{
								width: 84px;
								height: 25px;
								left: 3px;
								background: #00C3DD;

							}
						}
					}

					&--disableButton {
						cursor: not-allowed;
						.input_wrapper {
							input {
								color: #bbb;
								background: #141722;

								&:after {
									background: #696969;

								}

								&:checked:after{
									background: #696969;
								}
							}
						}
					}
				}
			}
		}

		.header-options-footer {
			display: flex;
			color: #ff495f;
			margin: 3px 1px;
			font-size: 12px;
			cursor: pointer;
			font-weight: 400;
			padding: 6px 17px;
			line-height: 14px;
			align-items: center;
			border: 1px solid transparent;

			.delete-option {
				width: 18px;
				height: 18px;
				margin-right: 17px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url('../assets/delete.svg');
			}
		}
	}
}

.container-buttons {
	display: flex;
	align-content: center;
	position: sticky;

	&:hover {
		.tooltipAliare {
			display: flex;
			width: auto;
		}
	}

	&.button-edit {
		margin-right: 16px;
	}
}

.projectDetailing__body {
	padding: 32px 32px;

	.projectDetailing__bodyTitle {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
	}

	.projectDetailing__bodyLine {
		height: 1px;
		width: 100%;
		background-color: #456298;
		margin: 8px 0 16px 0;
	}

	.projectDetailing__bodyHeader {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 0px 16px;

		.projectDetailing__bodyHeaderLeft {
			display: flex;
			width: 70%;
			justify-content: space-between;

			@media (max-width: 1536px) {
				width: 60%;
			}
		}

		.projectDetailing__bodyHeaderButtons {
			width: 33%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@media (max-width: 1536px) {
				width: 40%;
			}
		}

		.projectDetailing__bodyHeaderRight {
			display: flex;
			justify-content: space-between;
		}
	}

	.projectDetailing__bodyCard {
		padding: 0px 16px;
		display: flex;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		row-gap: 17px;
		flex-wrap: wrap;
		-moz-column-gap: 17px;
		column-gap: 17px;
		justify-content: flex-start;
		margin-top: 1.66%;
	}
}


:deep {
	.filter-bar {
		width: 100%;
		margin: 0px;
	}
	.container-info {
    width: 100%;
		padding: 20px 16px;
	}
	.table-empty {
    margin: 20px 0px 0px 0px;
	}
	.menuOptions__background {
		z-index:14;
		width: 205px;
	}

	.el-dropdown-menu__item.is-disabled {
    pointer-events: auto;
	}

	.el-dropdown-menu__item.is-disabled .activeDeactiveButton__select .activeDeactiveButton__selectAux .activeDeactiveButton__labelActive {
		background: #696969;
		color: rgba(255, 255, 255, 0.48);
		border: 1px solid #000;
		pointer-events: none;
	}

	.el-dropdown-menu__item.is-disabled .activeDeactiveButton__select .activeDeactiveButton__selectAux .activeDeactiveButton__labelInactive {
		background: #696969;
		color: rgba(255, 255, 255, 0.48);
		border: 1px solid #000;
		pointer-events: none;
	}
}

.container-loading {
	display: flex;
  align-items: center;
  align-self: center;
	padding: 22px 0px 18px 0px;
  justify-content: center;
}
.loading-spin {
  width: 120px;
  height: 120px;
  background-image: url('../assets/loading-modal.png');
  background-repeat: no-repeat;
  background-size: cover;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.container__filter {
	position: absolute;
	width: 107px;
  z-index: 99;
	top: 33px;
}

.filter-space {
	display: flex;
	margin-left: 15px;
}
</style>
