
import { HISTORY_VERSION_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import AliareInput from '@/components/AliareInput.vue';

import moment from 'moment';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'ExecutionListModal',
	components: {
		Modal,
		ModalButtonCancel,
		ModalButtonSuccess,
		AliareInput,
		ScrollInfinite,
	},
	data() {
		return {
			modalHistory_title: 'projectDetailing.modalHistory_title',
			modalHistory_today: 'projectDetailing.modalHistory_today',
			modalHistory_at: 'projectDetailing.modalHistory_at',
			modalHistory_modifyBy: 'projectDetailing.modalHistory_modifyBy',
			modalHistory_access: 'projectDetailing.modalHistory_access',
			modalHistory_currentVersion: 'projectDetailing.modalHistory_currentVersion',
			modalBus: new Vue(),
			HISTORY_VERSION_MODAL_KEY,
			isLoadingTable: false,
			isLoadingPage: false,
			dataModalHistoryVersion: [],
			responseDataPagination: [],
			dataModalHistoryActiveVersion: [],
			infoMessageModal: '<div class="loading"><div>',
			pageSize: 10,
		};
	},
	computed: {
		workflowId(): number {
			return this.$store.getters.workflowDataId;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async mounted () {
		this.getHistoryVersion(this.workflowId);
	},
	created() {
	},
	methods: {
		async getHistoryVersion(data) {
			this.responseDataPagination = [];
			(this.dataModalHistoryVersion = []),
				(this.dataModalHistoryActiveVersion = []),
			this.page = 1;
			await this.restApi().getWorkflow(this.workspace.id, data)
				.then((res) => {
					res.versaoAtiva = true;
					this.dataModalHistoryActiveVersion = res;
				})
				.catch((error) => {
					console.error('error', error);
				});
			const payload = { size: this.pageSize, page: 1 };
			await this.restApi().getWorkflowHistoryVersion(this.workspace.id, data, payload)
				.then((response) => {
					response.unshift(this.dataModalHistoryActiveVersion);
					this.responseDataPagination = response;

					this.dataModalHistoryVersion = this.groupByDate(response);

					if (!this.dataModalHistoryVersion.length) {
						this.infoMessageModal = 'Não há histórico';
					}
				})
				.catch((error) => {
					console.error('error', error);
				});
		},
		redirectWorkflowVersion(versionId, workflowId) {
			const route = this.$router.resolve({ name: 'VersionById', params: { versionid: versionId, workflowid: workflowId } });
			window.open(route.href, '_blank');
		},
		redirectWorkflowExisting(workflowId) {
			this.$router.push({
				name: 'NodeViewExisting',
				params: { name: workflowId, mode: 'Visualizar'},
			});
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},

		formatDateGroup(date, full) {
			const dateString = new Date(date).toLocaleDateString().substring(0, 10);
			let timeString = '';

			if (full)
				timeString =
					` ${this.$locale.baseText(this.modalHistory_at)} ` +
					new Date(date).toLocaleString().substring(11, 16) +
					'min';

			let stringReturn = `${dateString}` + `${timeString}`;
			return `${stringReturn}`;
		},
		groupByDate(data) {
			const result = data.reduce((acc, val) => {
				const date = this.formatDateGroup(val.modificadoEmDataHora, false);
				const today = this.formatDateGroup(new Date(), false);

				const item = acc.find(
					(item) =>
						item.dateString === date ||
						(date === today && item.dateString === this.$locale.baseText(this.modalHistory_today))
				);

				if (!item) {
					acc.push({
						dateString: date === today ? this.$locale.baseText(this.modalHistory_today) : date,
						dataWorkflow: [val],
					});
				} else {
					item.dataWorkflow.push(val);
				}

				return acc;
			}, []);

			result.sort((a, b) => {
				const dateA = new Date(a.dateString.split('/').reverse().join('-'));
				const dateB = new Date(b.dateString.split('/').reverse().join('-'));
				return dateB - dateA;
			});

			return result;
		},
		pagination() {
			// Esse +1 é devido à versão ativa que não eh contabilizada na API
			if (this.responseDataPagination.length % (this.pageSize + 1) !== 0) {
				return false;
			} else {
				this.page++;
				const payload = { size: this.pageSize, page: this.page };
				this.restApi().getWorkflowHistoryVersion(this.workspace.id, this.workflowId, payload)
					.then((response) => {
						if (!response.length) {
							return false;
						}
						for (var index in response) {
							this.responseDataPagination.push(response[index]);
						}

						this.dataModalHistoryVersion = this.groupByDate(this.responseDataPagination);
					})
					.catch((error) => {
						console.error('error', error);
					});
			}
		},
	},
});
