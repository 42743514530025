
import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import debounce from 'lodash.debounce';

import { restApi } from '@/components/mixins/restApi';

export default mixins(
	restApi,
).extend({
	name: 'SearchBar',
	data() {
		return {
			queryInput: '',
			workflowListSearch: [],
			projectListSearch: [],
			credentialsListSearch: [],
			equivalencesListSearch: [],
			isSearching: true,
			inputHasFocus: false,
			timeOut: '',
		};
	},
	props: {
		details: {
			type: String,
		},
		buttonLoading: {
			type: Boolean,
			default: false,
		},
		listPreview: {
			type: Boolean,
			default: true,
		},
		clickableIcon: {
			type: Boolean,
			default: true,
		},
		placeholder: {
			type: String,
		},
	},
	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		clickIconSearch() {
			this.$emit('search', this.queryInput);
		},
		goToCredential(id) {
			this.$router.push({
				name: 'credentialDetailing',
				params: { id },
			}).catch(()=>{});
		},
		goToEquivalence(id) {
			this.$router.push({
				name: 'equivalencesDetailing',
				params: { id },
			}).catch(()=>{});
		},
		searchInfoByName:
			_.debounce(async function (e) {
				if (this.queryInput.length == 0) {
					this.$emit('clearInput');
				}
				if (this.queryInput.length > 2 && this.listPreview) {
					this.isSearching = true;
					const payload = {
						nome: this.queryInput,
						size: 4,
					};
					const payloadSearch = {
						searchNome: this.queryInput,
						size: 4,
					};

					try {
						await Promise.all([
							this.restApi().getWorkflows(this.workspace.id, payload),
							this.restApi().getProjects(this.workspace.id, payload),
							this.restApi().getCredentials(this.workspace.id, payloadSearch),
							this.restApi().getEquivalences(this.workspace.id, payloadSearch),
						]).then(([workflowList, projectList, credentialsList, equivalencesList]) => {
							this.workflowListSearch = workflowList;
							this.projectListSearch = projectList;
							this.credentialsListSearch = credentialsList;
							this.equivalencesListSearch = equivalencesList;
						}).catch((err) => {
								console.log(err);
						});

						this.highlightBackground();
					} catch (e) {
						console.log(e);
					} finally{
						this.isSearching = false;
					}
				} else {
					// Limpar o data quando menor que 2 caracteres no input
					this.workflowListSearch = [];
					this.projectListSearch = [];
					this.credentialsListSearch = [];
					this.equivalencesListSearch = [];
				}
			},
		500),

		setFalseInputFocus() {
			// Para dar tempo de clicar em alguma opção da pesquisa
			setTimeout(() => {
				this.inputHasFocus = false;
			}, 100);
		},
		highlightBackground() {
			// Find all text nodes in the main element.
			const main = document.querySelectorAll('.searchBar--container__itemList');
			let allRanges = [];

			main.forEach(element => {
				const treeWalker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT);
				const allTextNodes = [];
				let currentNode = treeWalker.nextNode();

				while (currentNode) {
					allTextNodes.push(currentNode);
					currentNode = treeWalker.nextNode();
				}

				// @ts-ignore
				CSS.highlights.clear();

				const str = this.queryInput.trim().toLowerCase();

				if (!str) {
					return;
				}

				const ranges = allTextNodes
					.map((el) => {
						return { el, text: el.textContent.toLowerCase() };
					})
					.filter(({ text }) => text.includes(str))
					.map(({ text, el }) => {
						// Find all instances of str in el.textContent
						const indices = [];
						let startPos = 0;
						while (startPos < text.length) {
							const index = text.indexOf(str, startPos);
							if (index === -1) break;
							indices.push(index);
							startPos = index + str.length;
						}

						return indices.map((index) => {
							const range = new Range();
							range.setStart(el, index);
							range.setEnd(el, index + str.length);
							return range;
						});
					});
					allRanges.push(...ranges);
			});

			// @ts-ignore
			const highlight = new Highlight(...allRanges.flat());

			// @ts-ignore
			CSS.highlights.set("search-result-highlight", highlight);
		},
		redirectWorkflowExisting(id) {
			this.inputHasFocus = true;
			this.$router.push({
				name: 'NodeViewExisting',
				params: { name: id, mode: 'Visualizar' },
			});
		},
	},
	mounted() {

	},
});
