
import Vue from 'vue';
import { VALIDATION_RESOURCE_MODAL_KEY } from '../constants';
import mixins from 'vue-typed-mixins';
import Modal from './Modal.vue';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';


export default mixins(
	nodeHelpers,
).extend({
	name: 'ValidationResource',

	components: {
		Modal,
	},

	props: {
		activeId: {
			type: String,
			required: true,
		},
		dataResource: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			modalBus: new Vue(),
			VALIDATION_RESOURCE_MODAL_KEY,
		};
	},
	computed: {


	},
	async created() {
	},

	methods: {

  },


});
