<template>
  <div class="filter-bar">
    <div class="searchBar">
      <input
				type="text"
				v-model="searchQuery"
				@input="applySearch"
				:placeholder="$props.placeholder"
				class="searchBar--input"
			/>
			<i class="searchBar--input__icon"></i>
    </div>

    <div class="filter-options">
      <slot name="filters"></slot>
    </div>

		<div class="divider"></div>

    <div class="filter-buttons">
			<ModalButtonSuccess
				class="apply-button"
				@click="applyFilters"
				:titleButton="'Aplicar'"
				:alwaysActive="canApplyFilters"
				:width="'68'"
				applyIcon
			/>

			<ModalButtonCancel
				class="clear-button"
				:titleButton="'Limpar'"
				:alwaysActive="clearfiltering"
				@click="clearFilter"
				:width="'68'"
				shovelIcon
			/>
    </div>
  </div>
</template>

<script>
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { mapActions } from 'vuex';

export default {
	name: 'FilterBar',
	props: {
		placeholder: String,
		filtering: {
			type: Boolean,
			default: false,
		},
		clearfiltering: {
			type: Boolean,
			default: false,
		},
		defaultInitialSearch: {
			type: String,
			default: '',
		},
		activeFilters: Object,
	},
	components: {
		ModalButtonSuccess,
		ModalButtonCancel,
	},
  data() {
    return {
      searchQuery: '',
    };
  },
	created() {
		this.searchQuery = JSON.parse(JSON.stringify(this.defaultInitialSearch));
	},
	computed: {
		canApplyFilters() {
			return !this.filtering && Object.keys(this.activeFilters).length > 0;
		},
	},
  methods: {
		applySearch: _.debounce(async function (e) {
      this.$emit('search-applied', this.searchQuery);
		}, 500),
    applyFilters() {
      this.$emit('filters-applied', this.activeFilters);
    },
    clearFilter() {
      this.searchQuery = '';
			this.$emit('clearFilter');
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  display: flex;
	z-index: 98;
  align-items: center;
  margin: 0 32px 10px 30px;
	height: 49px;
	flex-shrink: 0;
  background-color: #253048;
  border-radius: 4px;
	border: 1px solid #4A638F;

	@media (max-width: 1536px) {
		margin: 0 29px 10px 21px;
	}
}

.filter-options {
  width: 90%;
  display: flex;
  padding: 0px;
  align-items: center;
	justify-content: flex-end;
}

.filter-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.apply-button,
.clear-button {
	height: 28px;
  margin-right: 10px;
	font-size: 10px;
  cursor: pointer;
}

.divider {
	width: 1px;
	height: 24px;
	background-color: #4a638f;
	margin: 0px 32px;
	align-self: center;
}

.searchBar {
	position: relative;
	display: flex;
	width: 750px;
  height: 49px;
  font-size: 14px;

	.searchBar--input:focus ~ .searchBar--input__icon {
		background-image: url('../assets/icon/lupaBlue.svg');

		&:before {
			width: 2px;
			background: #00C3DD;
		}
	}
	&--input {
		width: 100%;
		padding: 9px 40px 9px 9px;
		background: #223049;
		border: 1px solid #4A638F;
		border-radius: 4px;
		outline: none;
		color: #ffffff;

		&:focus {
			border: 2px solid #00C3DD;
		}

		&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.56);
			opacity: 1; /* Firefox */
		}

		&__icon {
			background-image: url('../assets/icon/lupa.svg');
			background-size: 100%;
			position: absolute;
			top: 50%;
			right: 9px;
			transform: translateY(-50%);
			width: 18px;
			height: 18px;

			&:before {
				content: '';
				width: 1px;
				height: 16px;
				display: block;
				background: #4A638F;
				position: absolute;
				left: -9px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

:deep {
	.modal-default-cancel {
		padding: 0;
	}
}
</style>
