import { render, staticRenderFns } from "./ProfileDetailing.vue?vue&type=template&id=fb4f8398&scoped=true&"
import script from "./ProfileDetailing.vue?vue&type=script&lang=ts&"
export * from "./ProfileDetailing.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileDetailing.vue?vue&type=style&index=0&id=fb4f8398&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb4f8398",
  null
  
)

export default component.exports