import { coreApi } from "@/api/internalApis";

class CoreService {
  constructor () {
    this.tenantsUrl = '/api/tenants/api/v1/me/tenants';
  }

  async getTenant() {
    return await coreApi.get(this.tenantsUrl);
  }
}

export default new CoreService();
