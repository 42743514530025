
import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import AliareInput from '@/components/AliareInput.vue';
import mixins from 'vue-typed-mixins';

export default mixins(
	nodeHelpers,
).extend({
	components: {
		AliareInput,
	},
	props: {
		dataInput: {
			type: Object,
		},
	},
	data() {
		return {
			isLoadingWorkflow: false,
			flowSelected: '',
			workflowList: '',
			oldSearch: '',
			projectSelected: '',
			searched: false,
			showSelect: false,
			searchWorkflow: '',
		};
	},
	computed: {
		isEdit() {
			if (this.dataInput.item?.workflow?.id) {
				return true;
			} else {
				return;
			}
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		disableInput() {
			this.showSelect = false;
		},

		searchInfoByName:
		_.debounce(async function (e)  {
			let firstFocus = false;
			this.isLoadingWorkflow = true;

			if (!this.workflowList.length) {
				firstFocus = true;
			}

			if (firstFocus || this.searchWorkflow.length > 2 && this.oldSearch !== this.searchWorkflow || !firstFocus && this.searchWorkflow.length < 2) {
				try {
					this.workflowList = await this.restApi().getWorkflows(this.workspace.id, {
						nome: this.searchWorkflow,
						size: 5,
						BuscarFluxosFonteDeDados: true,
						Ativo: true,
						Automatico: false,
					});
					this.oldSearch = JSON.parse(JSON.stringify(this.searchWorkflow))

					this.searched = true;
				} catch (e) {
					console.log(e);
				}
			}

			this.isLoadingWorkflow = false;
		}, 500),
		activeSearch() {
			this.showSelect = !this.showSelect;
			this.$nextTick(() => {
				this.$refs.aliareInput.$refs.input.focus();
			});
		},
		selectedWorkflow(info) {
			this.showSelect = !this.showSelect;
			this.flowSelected = info;
			this.projectSelected = info.projeto.nome;
			if (this.dataInput) {
				this.$emit('selected', {id: info.id, item: JSON.parse(JSON.stringify(this.dataInput))});
			}
		},
		async loadCurrentWorkflow() {
			if (this.isEdit) {
				this.isLoadingWorkflow = true;

				const currentWorkflow = await this.restApi().getWorkflows(this.workspace.id, {
					id: this.dataInput.item.workflow.id,
				});

				this.flowSelected = currentWorkflow[0];
				this.projectSelected = currentWorkflow[0].projeto.nome;
				this.$emit('selected', {id: currentWorkflow[0].id, item: JSON.parse(JSON.stringify(this.dataInput))});

				this.isLoadingWorkflow = false;
			} else {
				return;
			}
		},
	},
	created() {
		this.loadCurrentWorkflow();
	},
});
