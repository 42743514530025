<template>
	<div class="header__header" :class="{'onlyClear': onlyClearFilter }">
		<div class="header__headerTitle" v-if="!onlyClearFilter">
			{{ $locale.baseText(title) }}
		</div>
		<div class="header__headerSearch" v-if="showSearch">
			<el-row>
				<el-col :span="8">
					<el-dropdown trigger="click">
						<span>
							<div class="header__headerDropdown" v-show="false">
								<div class="header__headerDropdownPlaceHolder">
									{{ $locale.baseText(placeHolder) }}
								</div>
								<div class="header__headerDropdownIcon"></div>
							</div>
						</span>
						<template #dropdown>
							<el-dropdown-menu
								v-show="false"
								class="el-dropdown-menu el-popper header__dropdownOptions"
								v-on:click.stop
							>
								<el-dropdown-item class="header__createFlowOptions">
									<div class="header__iconCreateOptions"></div>
									<div class="header__textOptions">teste</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</template>
					</el-dropdown>
				</el-col>
			</el-row>
			<div v-if="!onlyClearFilter" class="header__headerLine"></div>
			<router-link
				class="header__headerRegisterVariable"
				:to="{ name: link }"
				v-if="buttonToRegisterVariableBoolean"
			>
				<div class="header__headerRegisterVariable__hoverTooltip">
					<div class="header__headerRegisterVariableButton" :class="{'active-btn': active && !alwaysActive, 'disable-btn': !active && !alwaysActive, 'alwaysActive': alwaysActive}" @click="$emit('clickEvent')">
						<div class="header__headerRegisterVariableButtonIcon"></div>
						<div class="header__headerRegisterVariableButtonTitle">
							{{ $locale.baseText(ButtonToRegisterVariable) }}
						</div>
						<span v-if="!alwaysActive" class="tooltipAliare">{{	$locale.baseText('permission.noPermissionFuncionality')	}}</span>
					</div>
				</div>
			</router-link>
			<router-link
				class="header__headerRegisterVariable"
				:to="{ name: link }"
				v-if="buttonToRegisterEquivalenceBoolean"
			>
				<div class="header__headerRegisterVariable__hoverTooltip">
					<div class="header__headerRegisterVariableButton" :class="{'active-btn': active && !alwaysActive, 'disable-btn': !active && !alwaysActive, 'alwaysActive': alwaysActive}" @click="$emit('clickEvent')">
						<div class="header__headerRegisterVariableButtonIcon"></div>
						<div class="header__headerRegisterVariableButtonTitle">
							{{ $locale.baseText(ButtonToRegisterVariable) }}
						</div>
						<span v-if="!alwaysActive" class="tooltipAliare">{{	$locale.baseText('permission.noPermissionFuncionality')	}}</span>
					</div>
				</div>
			</router-link>
			<div class="header__headerInputSearch" v-if="placeHolderSearch">
				<input
					type="text"
					:placeholder="$locale.baseText(placeHolder)"
					class="header__headerSearchInput"
				/>
				<div class="header__headerSearchInputDivision"></div>
				<div class="header__headerSearchInputButton"></div>
			</div>
			<div
				class="header__headerSearchButton"
				v-if="viewClearFilter"
				@click="clearFilter"
				v-bind:class="{
					header__headerSearchButtonFilterActive: interactionMenuFilter,
					header__headerSearchButtonFilterInactive: !interactionMenuFilter,
				}"
			>
				<div class="header__headerSearchButtonIcon"></div>
				<div class="header__headerSearchButtonTitle">
					{{ $locale.baseText(buttonToCleanFilter) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Header',
	emits: ['clickEvent'],
	props: {
		InteractionMenuTitle: String,
		onlyClearFilter: Boolean,
		viewClearFilter: {
			type: Boolean,
			default: true,
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
		interactionMenuPlaceHolder: String,
		interactionMenuplaceHolderSearch: Boolean,
		interactionMenuButtonToRegisterVariable: String,
		interactionMenuButtonToCleanFilter: String,
		interactionMenuButtonToRegisterVariableBoolean: {
			type: Boolean,
			default: false,
		},
		interactionMenuButtonToRegisterEquivalenceBoolean: {
			type: Boolean,
			default: false,
		},
		interactionMenuPlaceHolderSearch: Boolean,
		interactionMenuFilter: Boolean,
		interactionMenuLink: String,
		active: {
			type: Boolean,
			default: false,
		},
		alwaysActive: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			link: this.interactionMenuLink,
			title: this.InteractionMenuTitle,
			placeHolder: this.interactionMenuPlaceHolder,
			placeHolderSearch: this.interactionMenuPlaceHolderSearch,
			ButtonToRegisterVariable: this.interactionMenuButtonToRegisterVariable,
			buttonToCleanFilter: this.interactionMenuButtonToCleanFilter,
			buttonToRegisterVariableBoolean: this.interactionMenuButtonToRegisterVariableBoolean,
			buttonToRegisterEquivalenceBoolean: this.interactionMenuButtonToRegisterEquivalenceBoolean,
		};
	},
	methods: {
		clearFilter() {
			this.$emit('clearFilter');
		},
	},
};
</script>

<style lang="scss">
.header__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 17px;
	padding: 1.62% 1.62% 0px 1.62%;

	&.onlyClear {
		justify-content: end;
	}
	.header__headerTitle {
		width: 221px;
		height: 19px;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
	.header__headerSearch {
		display: flex;
		flex-direction: row;
		align-items: center;

		.header__headerDropdown {
			height: 40px;
			width: 276px;
			border-radius: 4px;
			background: #223049;
			border: 1px solid #4a638f;
			margin: 0px 16px 0px 0px;
			padding: 0px 9px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.header__headerDropdownPlaceHolder {
				font-size: 14px;
				font-weight: 400;
				line-height: 16px;
				letter-spacing: 0em;
				text-align: left;
				color: rgba(255, 255, 255, 0.88);
			}
			.header__headerDropdownIcon {
				background-image: url('./../assets/cardArrow.svg');
				background-position: center;
				background-repeat: no-repeat;
				width: 7px;
				height: 12px;
				transform: rotate(90deg);
			}
		}

		.header__headerInputSearch {
			display: flex;
			width: 276px;
			height: 40px;
			background: #223049;
			border: 1px solid #4a638f;
			border-radius: 4px;
			margin: 0px 16px;
			align-items: center;
			padding: 0px 9px;
			justify-content: space-between;
			.header__headerSearchInputButton {
				background-image: url('./../assets/magnifierIcon.svg');
				background-position: center;
				background-repeat: no-repeat;
				height: 30px;
				width: 30px;
				border-radius: 4px;
				cursor: pointer;
				transition: all 0.1s;
			}
			.header__headerSearchInputButton:hover {
				transform: scale(1.15);
				background-color: #1c2638;
			}
			.header__headerSearchInputButton:active {
				transform: scale(1);
			}
			.header__headerSearchInputDivision {
				width: 1px;
				height: 16px;
				background: #4a638f;
				margin: 0px 8px;
			}
			.header__headerSearchInput::placeholder {
				color: rgba(255, 255, 255, 0.48);
			}
			.header__headerSearchInput:focus {
				border: 0 none;
				box-shadow: 0 0 0 0;
				outline: 0;
			}
			.header__headerSearchInput {
				display: flex;
				flex-direction: row;
				align-items: center;
				background-color: transparent;
				border: none;
				color: rgba(255, 255, 255, 0.88);
			}
		}
		.header__headerSearchInputText::placeholder {
			color: rgba(255, 255, 255, 0.48);
		}

		.header__headerSearchInputText {
			width: 290px;
			height: 18px;
			border-style: none;
			background: #223049;
			color: rgba(255, 255, 255, 0.88);
			margin: 12px 17px 12px 9px;
		}
		.header__headerSearchInputText:focus {
			border: 0 none;
			box-shadow: 0 0 0 0;
			outline: 0;
		}
		.header__headerSearchInputDivision {
			width: 1px;
			height: 16px;
			background: #4a638f;
		}
		.header__headerSearchInputButton {
			background-image: url('./../assets/magnifierIcon.svg');
			background-position: center;
			background-repeat: no-repeat;
			height: 37px;
			width: 37px;
		}
		.header__headerSearchInputButton:hover {
			cursor: pointer;
		}

		.header__headerLine {
			width: 1px;
			height: 24px;
			background: #34476a;
			margin: 0px 16px;
		}
		.header__headerRegisterVariable {
			text-decoration: none;

			&__hoverTooltip {

					&:hover {
					position: relative;

					.tooltipAliare {
						display: flex;
						width: 375px;
						z-index: 9999;
					}
				}
			}
		}
		.header__headerRegisterVariableButton {
			width: auto;
			min-width: 169px;
			height: 40px;
			background: #1c2638;
			border: 1px solid #00c3dd;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
			border-radius: 4px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0px 16px;
			margin: 0px 0px 0px 16px;


			&.alwaysActive {
				background: #24324C;
				border: 1px solid #00C3DD;
				color: #00C3DD;

				&:hover {
					background: rgba(0, 195, 221, 0.08);
				}
			}

			&.disable-btn {
				background: rgba(255, 255, 255, 0.08);
				color: rgba(255, 255, 255, 0.4);
				pointer-events: none !important;
				box-shadow: none;
				border: none;

				.header__headerRegisterVariableButtonIcon {
					background-image: url('./../assets/cardPlusGrey.svg');
				}
				.header__headerRegisterVariableButtonTitle {
					color: rgba(255, 255, 255, 0.4);
				}
			}
			.header__headerRegisterVariableButtonIcon {
				background-image: url('./../assets/cardPlus.svg');
				margin-right: 8px;
				width: 14px;
				height: 14px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
			.header__headerRegisterVariableButtonTitle {
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				text-transform: capitalize;
				color: #00c3dd;
			}
		}
		.header__headerRegisterVariableButton:hover {
			background: rgba(0, 195, 221, 0.08);
		}
		.header__headerSearchButton {
			width: 142px;
			height: 40px;

			border-radius: 4px;
			margin-left: 16px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 0px 16px;
			cursor: pointer;
		}
		.header__headerSearchButton:hover {
			cursor: pointer;
		}
	}
}
.header__headerSearchButtonFilterActive {
	background: #1c2638;
	border: 1px solid #ff495f;
}

.header__headerSearchButtonFilterActive .header__headerSearchButtonTitle{
	color: #ff495f;
}
.header__headerSearchButtonFilterActive .header__headerSearchButtonIcon{
	background-image: url('./../assets/shovelIconRed.svg');
}
.header__headerSearchButtonFilterInactive {
	background: rgba(255, 255, 255, 0.08);
	pointer-events: none;
}

.header__headerSearchButtonTitle {
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: capitalize;
	color: rgba(255, 255, 255, 0.4);
}

.header__headerSearchButtonIcon {
	background-image: url('./../assets/shovelIcon.svg');
	width: 14.73px;
	height: 18px;
}

.el-dropdown-menu {
	border-radius: 3px;
	width: 154px;
	background: #223049;
	border: 1px solid #4a638f;
}
.el-dropdown-menu__item {
	list-style: none;
	line-height: 45px;
	padding: 0 0px;
	margin: 0;
	font-size: var(--font-size-s);
	color: #00C3DD;
	cursor: pointer;
	outline: 0;

	&:not(.is-disabled):hover {
		background: #223049;
	}
}
.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-color: transparent;
}
.el-popper[x-placement^='bottom'] {
	margin-top: 0px;
}
.el-dropdown-menu {
	padding: 0px 0;
	margin: 0px 0;
}
.header__dropdownOptions {
	position: absolute;
	width: 154px;
	max-height: 180px;
	background: #223049;
	border: 1px solid #4a638f;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	padding: none;
	.header__createFlowOptions {
		height: 36px;
		width: 152;
		border-bottom: 1px solid #4a638f;
		display: flex;
		align-items: center;
		cursor: pointer;
		color: rgba(255, 255, 255, 0.88);
	}
	.header__createFlowOptions:hover {
		background: rgba(0, 195, 221, 0.08);
	}
}
</style>
