<template>
  <main class="header-filter">
    <div class="date-info">
        <h3 class="date-info__date">{{ feedbackDate }}</h3>
        <span class="date-info__hour">{{ feedbackHour }}</span>
    </div>
    <div class="date-filter">
        <div class="date-filter__item" @click="changeDateFilter('today')" :class="{ active: todaySelected }">
            <i class="dateIcon todayIcon"></i>
            <span>Hoje</span>
        </div>
        <div class="date-filter__item" @click="changeDateFilter('lastSeven')" :class="{ active: lastSevenSelected }">
            <i class="dateIcon lastSevenIcon"></i>
            <span>Últimos 7 dias</span>
        </div>
        <div class="date-filter__item" @click="changeDateFilter('lastThirty')" :class="{ active: lastThirtySelected }">
            <i class="dateIcon lastThirtyIcon"></i>
            <span>Últimos 30 dias</span>
        </div>
        <div class="date-filter__item" @click="changeDateFilter('lastSixty')" :class="{ active: lastSixtySelected }">
            <i class="dateIcon lastSixtyIcon"></i>
            <span>Últimos 60 dias</span>
        </div>
    </div>
    <hr class="separator">
    <div class="container-btn">
        <ModalButtonSuccess
            :titleButton="$locale.baseText('Abrir Filtros')"
            width="138"
            @click="openFilters()"
            alwaysActive
            filterIcon
        />
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import { FILTER_PANEL_MODAL_KEY } from '../constants';

moment.locale('pt')
moment.updateLocale('pt', {
    months : [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
        "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]
});
export default {
    name: 'HeaderFilterIntegration',
    data() {
        return {
        };
    },
    components: {
		ModalButtonSuccess,
        FILTER_PANEL_MODAL_KEY,
	},
  computed: {
    dateFilterSelected() {
        return this.$store.getters.dateFilterPanel.dateString;
    },
    todaySelected() {
        if (this.dateFilterSelected == 'today') {
            return true
        } else {
            return
        }
    },
    lastSevenSelected() {
        if (this.dateFilterSelected == 'lastSeven') {
            return true
        } else {
            return
        }
    },
    lastThirtySelected() {
        if (this.dateFilterSelected == 'lastThirty') {
            return true
        } else {
            return
        }
    },
    lastSixtySelected() {
        if (this.dateFilterSelected == 'lastSixty') {
            return true
        } else {
            return
        }
    },
    feedbackDate() {
        let localDate;
        if (this.todaySelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY');
        } else if (this.lastSevenSelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY');
        } else if (this.lastThirtySelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY');
        } else if (this.lastSixtySelected) {
            localDate = moment().local().format('DD [de] MMMM [de] YYYY');
        }
        return localDate;
    },
    feedbackHour() {
        let localHour
        if (this.todaySelected) {
            localHour = '0h às 24h';
        } else if (this.lastSevenSelected) {
            localHour = '0h às 24h';
        } else if (this.lastThirtySelected) {
            localHour = '0h às 24h';
        } else if (this.lastSixtySelected) {
            localHour = '0h às 24h';
        }
        return localHour
    }
  },
  watch: {
    
  },
  created() {
  
  },
  methods: {
    changeDateFilter(dateString) {
        this.$store.commit('setDateFilterPanel', {
            dateString,
        });
    },
    openFilters() {
        this.$store.dispatch('ui/openModal', FILTER_PANEL_MODAL_KEY);
    },

   
  },
  watch: {

  },
};
</script>



<style scoped lang="scss">
h1, h2, h3, h4, p {
	margin: 0;
}
.header-filter {
    padding: 16px;
    background: #1c2638;
	display: flex;
    align-items: center;
    border: 1px solid #34476A;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    &:before, &:after {
        pointer-events: none;
        width: 150px;
        content: '';
        height: 150px;
        position: absolute;
    }

    &:before {
        left: -60px;
        top: -66px;

        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(0, 195, 221, 0.4) 0%,
            rgba(22, 30, 44, 0) 100%
        );
    }

    &:after {
        right: -63px;
        top: 9px;
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(89, 214, 76, 0.32) 0%,
            rgba(22, 30, 44, 0) 100%
        );
    }

	.date-info, .date-filter{
		display: flex;
	}
    .date-info {
        margin-top: -6px;
        flex-direction: column;
        row-gap: 6px;

        * { color: #FFF; }
        

        &__date {
            font-size: 20px;
            font-weight: 300;
        }
        &__hour {
            font-size: 14px;
        }
    }

    .date-filter {
        margin: 0 10px 0 auto;
        

        &__item {
            column-gap: 8px;
            display: flex;
            align-items: center;
            background: #223049;
            padding: 8px 16px;
            outline: 1px solid #4A638F;
            color: #FFFFFFA3;
            font-size: 14px;
            margin-left: 1px;
            cursor: pointer;

            &:hover {
                color: #00C3DD; 
            }

            &.active {
                color: #00C3DD; 
                border: 1px solid #00C3DD;

                .dateIcon {
                    filter: invert(53%) sepia(77%) saturate(754%) hue-rotate(143deg) brightness(124%) contrast(103%);
                }
            }
            

            &:first-child {
                border-radius: 3px 0 0 3px;
            }
            &:last-child {
                border-radius: 0 3px 3px 0;
            }
            .dateIcon {
                width: 24px;
                height: 24px;
                display: inline-block;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
            .todayIcon {
                background-image: url('../assets/calendarSimpleIcon.svg');
            }
            .lastSevenIcon {
                background-image: url('../assets/calendarSevenIcon.svg');
            }
            .lastThirtyIcon {
                background-image: url('../assets/calendarLastThirty.svg'); 
            }
            .lastSixtyIcon {
                background-image: url('../assets/calendarLastSixty.svg'); 
            }
            
        }
    }
    .container-btn {
        margin-left: 10px;
        z-index: 9;
    }
}
</style>
