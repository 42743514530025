<template>
	<div class="filter-table" v-click-outside="clickOutside">
		<i class="filter-icon" :class="{ activedFilterIcon: applyedFilter}" @click="activeFilter = !activeFilter"></i>
		<div v-show="activeFilter">
			<slot name="filter"></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		applyedFilter: Boolean,
		closeOnApply: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			activeFilter: false,
		};
	},
	methods: {
		clickOutside() {
			this.activeFilter = false;
		},
	},
	watch: {
    applyedFilter(newValue) {
			if (newValue === true && this.closeOnApply) {
				this.activeFilter = false;
			}
    },
  },
};
</script>

<style lang="scss" scoped>

.filter-table {
	margin-left: auto;
}
.filter-icon {
	background: url('./../assets/funnelIcon.svg') center no-repeat;
	background-size: 16px;
	width: 28px;
	height: 28px;
	cursor: pointer;
	display: block;
	border-radius: 4px;
	transition: all .5s;
	position: relative;

	&:hover {
		background: url('./../assets/funnelIconBlue.svg') center no-repeat, rgba(0, 195, 221, 0.16);
	}

	&.activedFilterIcon {
		background: url('./../assets/funnelIconBlue.svg') center no-repeat, rgba(0, 195, 221, 0.16);

		&:after {
			content: '';
			background: #7B1818;
			width: 8px;
			height: 8px;
			position: absolute;
			border-radius: 50%;
			right: -4px;
			top: -4px;
		}
	}
}
</style>
