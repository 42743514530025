import axios from 'axios';
import { appConfig } from '@/config/appConfig';
import { defaultApi } from '@/api/internalApis';

axios.defaults.withCredentials = appConfig('auth.setCookies');
const variable = 'Variaveis';
class VariableService {
    async getVariableId(id) {
        const res = await defaultApi.get(`Workspaces/${workspaceId}/${variable}/${id}`);
        return res;
    }
    async patchVariavleId(workspaceId, id, workflowId, nome, valor) {
        let payload = { 'workflowId': workflowId, 'nome': nome, 'valor': valor }
        const res = await defaultApi.patch(`Workspaces/${workspaceId}/${variable}/${id}`, payload);
        return res;
    }
    async deleteVariableId(query) {
        try {
            const res = await defaultApi.delete(`Workspaces/${query.workspaceId}/${variable}/${query.variableId}`);
            return res;
        } catch (error) {
            console.log('error service', error);
            throw error;
        }
    }
    async postVariable(query) {
        const res = await defaultApi.post(`Workspaces/${query.workspaceId}/${variable}`, query);
        return res;
    }
}

export default new VariableService();
