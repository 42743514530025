
import ScrollInfinite from '../components/ScrollInfinite.vue';

export default {
	name: 'Table',
	components: {
		ScrollInfinite,
	},
	props: {
		filtering: {
			type: Boolean,
			default: false,
		},
		tableVariable: String,
		tableValue: String,
		tableActions: String,
		dropdownData: [],
		scrollInfinite: Boolean,
		tableData: [],
		filterIcon: Boolean,
		lastColumnWidth__Boolean: Boolean,
		tableOverflow: Boolean,
		maxheight: String,
		textIfEmpty: String,
		isLoading: Boolean,
		notDisplayFooter: {
			default: false,
			type: Boolean,
		},
	},
	emits: ['showModalEdit', 'showModalDelete', 'scrollEvent'],
	data() {
		return {
			variable: this.tableVariable,
			value: this.tableValue,
			actions: this.tableActions,
			data: this.dropdownData,
			dataTable: this.tableData,
			iconFilter: this.filterIcon,
			lastColumnWidth: this.lastColumnWidth__Boolean,
			overflow: this.tableOverflow,
			maxHeight: `max-height:${this.maxheight} !important;`,
		};
	},
	computed: {
		hasContentSlot() {
			return !!this.$slots.columns;
		},
	},
	mounted() {
		this.$root.$on('reloadList', () => {
			this.scrollToTop();
		});
	},
	methods: {
		endScroll(){
			this.$emit('endScroll');
		},
		scrollToTop() {
			setTimeout(() => {
				const content = this.$refs.content as Element;
				if (content) {
					content.scrollTop = 0;
				}
			}, 0);
		},
	},
};
