// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';

import './plugins';
import 'prismjs';
import 'prismjs/themes/prism.css';
import 'vue-prism-editor/dist/VuePrismEditor.css';
import 'vue-json-pretty/lib/styles.css';
import './n8n-theme.scss';

import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";

import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';

import { runExternalHook } from './components/mixins/externalHooks';
import { TelemetryPlugin } from './plugins/telemetry';
import { I18nPlugin } from './plugins/i18n';

import { BootstrapVue, AlertPlugin } from "bootstrap-vue";

import { setupHeaderBearerAuthorization } from "@/config/axiosConfig";

import { authorizationApi, coreApi, defaultApi } from "@/api/internalApis";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { store } from './store';

import Vuelidate from "vuelidate";
import { defaultAliareApi } from './api/aliareApi';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/pt-br';

Vue.use(Vuelidate);

Vue.use(VueRouter);

Vue.use(BootstrapVue);

Vue.use(AlertPlugin);

Vue.use(ElementUI, { locale });
setupHeaderBearerAuthorization([authorizationApi, coreApi, defaultApi, defaultAliareApi], store);

Vue.config.productionTip = false;
router.afterEach((to, from) => {
	runExternalHook('main.routeChange', store, { from, to });
});
Vue.use(TelemetryPlugin);
Vue.use((vue) => I18nPlugin(vue, store));

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
	if (!size) size = 20;
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

if (process.env.NODE_ENV !== 'production') {
	// Make sure that we get all error messages properly displayed
	// as long as we are not in production mode
	window.onerror = (message, source, lineno, colno, error) => {
		if (message.toString().includes('ResizeObserver')) {
			// That error can apparently be ignored and can probably
			// not do anything about it anyway
			return;
		}
		console.error('error caught in main.ts'); // eslint-disable-line no-console
		console.error(message); // eslint-disable-line no-console
		console.error(error); // eslint-disable-line no-console
	};
}
