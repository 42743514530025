import { render, staticRenderFns } from "./ModalAliare.vue?vue&type=template&id=7dcc6d23&"
import script from "./ModalAliare.vue?vue&type=script&lang=js&"
export * from "./ModalAliare.vue?vue&type=script&lang=js&"
import style0 from "./ModalAliare.vue?vue&type=style&index=0&id=7dcc6d23&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports