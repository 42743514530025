import axios from 'axios';
import { appConfig } from '@/config/appConfig';
import { defaultApi } from '@/api/internalApis';

axios.defaults.withCredentials = appConfig('auth.setCookies');

class WorkflowsService {
	async postAlert(workspaceId) {
		const res = await defaultApi.post(`Workspaces/${workspaceId}/Alertas`);
		return res;
	}
}

export default new WorkflowsService();
