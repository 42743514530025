
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import Modal from '@/components/ModalAliare.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import AliareInput from '@/components/AliareInput.vue';
import CredentialIcon from '../components/CredentialIcon.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import NodeIcon from '@/components/NodeIcon.vue';
import CardIntegration from '@/components/CardIntegration.vue';


import { mapActions } from 'vuex';


import moment from 'moment';
import mixins from 'vue-typed-mixins';

import WebStorage from '@/common/WebStorage';


export default mixins(
	nodeHelpers,
	showMessage,
	genericHelpers,
).extend({
	name: 'KnowMoreIntegration',
	components: {
		Modal,
		BackgroundView,
		CredentialIcon,
		ModalButtonSuccess,
		CardIntegration,
		NodeIcon,
		ModalButtonCancel,
		AliareInput,
		AlertNotificationModal,
	},
	data() {
		return {
			isLoadingPage: true,
			isLoadingNode: true,
			requestForm: false,
			form: {
				nome: '',
				email: '',
				telefone: '',
				mensagem: '',
				integracaoId: '',
			},
			currentIntegration: {},
		};
	},
	computed: {
		canRequestIntegration() {
			if (this.form.nome && this.form.email) {
				return true;
			} else {
				return;
			}
		},
		integrationId() {
			return this.$route.params.id;
		},
		solicitacaoAprovada() {
			return this.currentIntegration.statusUltimaSolicitacao == 'Aprovado' ? true : false;
		},
		integracaoImportada() {
			return this.currentIntegration.statusUltimaSolicitacao == 'Importado' ? true : false;
		},
		solicitacaoAguardando() {
			return this.currentIntegration.statusUltimaSolicitacao == 'Solicitado' ? true : false;
		},
		previewMode() {
			return this.$route.name == 'integrationPreview';
		},
		alertNotificationTitle() {
			return 'Excluir Credencial';
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	created() {
		this.initPageKnowMoreIntegration();
	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadCurrentIntegration();
		});
	},

	methods: {
		async openImportIntegration(integrationId) {
			const data = await this.restApi().getIntegrationById(integrationId);
			const mode = data?.projetos.length <= 1 ? 'unique' : 'multiple';
			this.$store.dispatch('ui/openImportIntegrationModal', {	data, mode });
		},
		async requestIntegration() {
			this.isLoadingPage = true;
			try {
				this.form.integracaoId = this.integrationId;
				const requestIntegration = await this.restApi().postRequestIntegrations(this.form);


				this.$store.commit('activeAlert', {
					message: 'Sua solicitação foi feita com sucesso.',
				});

				this.requestForm = false;

				this.$root.$emit('reloadList');

			} catch(e) {
				this.$store.commit('activeAlert', {
					message: e.response?.data?.notifications,
					status: 'error',
				});
			}

			this.isLoadingPage = false;
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY');
			return localDate;
		},
		toggleRequestForm() {
			if (this.previewMode || this.solicitacaoAguardando) return;

			this.requestForm = !this.requestForm;
		},
		async initPageKnowMoreIntegration() {
			await this.loadNodeTypes();

			if (!this.previewMode) {
				await this.loadCurrentIntegration();
			} else {
				const integration = WebStorage.getItem('dataPreview');
				this.currentIntegration = integration;
				await this.loadNodesOfIntegration(integration?.conectores);

				if (this.currentIntegration == null) {
					this.$router.push({ name: 'project' });
				}
				WebStorage.removeItem('dataPreview');
				this.isLoadingPage = false;
			}
		},
		async loadNodeTypes(): Promise<void> {
			const nodeTypes = await this.restApi().getNodeTypes();
			this.$store.commit('setNodeTypes', nodeTypes);
		},
		async loadCurrentIntegration() {
			const integration = await this.restApi().getPublishedIntegrationById(this.integrationId);
			this.currentIntegration = integration;
			await this.loadNodesOfIntegration(integration?.conectores);
			this.isLoadingPage = false;
		},
		async loadNodesOfIntegration(nodes) {
			if (!nodes) return;
			this.isLoadingNode = true;
			let nodesList = [];
			nodes.forEach(node => {
				let nodeData = this.$store.getters.getAllNodeByName(node);
				nodesList.push(nodeData);
			});

			this.currentIntegration.conectores = nodesList;
			this.isLoadingNode = false;
		},
		backToStore() {
			if (this.previewMode) return;
			this.$router.push({
				name: 'integrationStore',
			});
		},

	},
	beforeDestroy() {
		this.$root.$off("reloadList");
	},
});
