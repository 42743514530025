<template>
	<div class="menuOptions__position">
		<div class="menuOptions__background" :style="position">
			<slot name="top"></slot>
			<slot name="middle"></slot>
			<slot name="end"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuOptions',
	props: {
		Right: String,
		Top: String,
	},
	data() {
		return {
			position: `right: ${this.Right}; top: ${this.Top};`,
		};
	},
};
</script>

<style>
.menuOptions__position{
    position: relative;
}
.menuOptions__background {
	display: inline-block;
	position: absolute;
	background-color: #223049;
	border: 1px solid #4a638f;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
}
</style>