
import mixins from "vue-typed-mixins";

import {
	AUTOMATION_CONSULTING_INDUSTRY,
	CODING_SKILL_KEY,
	COMPANY_INDUSTRY_KEY,
	COMPANY_SIZE_100_499,
	COMPANY_SIZE_1000_OR_MORE,
	COMPANY_SIZE_20_OR_LESS,
	COMPANY_SIZE_20_99,
	COMPANY_SIZE_500_999,
	COMPANY_SIZE_KEY,
	COMPANY_SIZE_PERSONAL_USE,
	E_COMMERCE_INDUSTRY,
	EXECUTIVE_WORK_AREA,
	FINANCE_INDUSTRY,
	FINANCE_WORK_AREA,
	GOVERNMENT_INDUSTRY,
	HEALTHCARE_INDUSTRY,
	HR_WORK_AREA,
	IT_ENGINEERING_WORK_AREA,
	LEGAL_INDUSTRY,
	LEGAL_WORK_AREA,
	MARKETING_WORK_AREA,
	NOT_APPLICABLE_WORK_AREA,
	OPS_WORK_AREA,
	OTHER_COMPANY_INDUSTRY_KEY,
	OTHER_INDUSTRY_OPTION,
	OTHER_WORK_AREA_KEY,
	OTHER_WORK_AREA_OPTION,
	PERSONALIZATION_MODAL_KEY,
	PRODUCT_WORK_AREA,
	SAAS_INDUSTRY,
	SALES_BUSINESSDEV_WORK_AREA,
	SECURITY_INDUSTRY,
	SECURITY_WORK_AREA,
	SUPPORT_WORK_AREA,
	SYSTEM_INTEGRATION_INDUSTRY,
	WORK_AREA_KEY,
} from "../constants";
import { workflowHelpers } from "@/components/mixins/workflowHelpers";
import { showMessage } from "@/components/mixins/showMessage";
import Modal from "./Modal.vue";
import { IPersonalizationSurveyAnswers, IPersonalizationSurveyKeys } from "@/Interface";
import Vue from "vue";
import { mapGetters } from "vuex";

export default mixins(showMessage, workflowHelpers).extend({
	components: { Modal },
	name: "PersonalizationModal",
	data() {
		return {
			submitted: false,
			isSaving: false,
			PERSONALIZATION_MODAL_KEY,
			otherWorkAreaFieldVisible: false,
			otherCompanyIndustryFieldVisible: false,
			showAllIndustryQuestions: true,
			modalBus: new Vue(),
			values: {
				[WORK_AREA_KEY]: [],
				[COMPANY_SIZE_KEY]: null,
				[CODING_SKILL_KEY]: null,
				[OTHER_WORK_AREA_KEY]: null,
				[COMPANY_INDUSTRY_KEY]: [],
				[OTHER_COMPANY_INDUSTRY_KEY]: null,
			} as IPersonalizationSurveyAnswers,
			FINANCE_WORK_AREA,
			HR_WORK_AREA,
			IT_ENGINEERING_WORK_AREA,
			LEGAL_WORK_AREA,
			MARKETING_WORK_AREA,
			PRODUCT_WORK_AREA,
			SALES_BUSINESSDEV_WORK_AREA,
			SECURITY_WORK_AREA,
			EXECUTIVE_WORK_AREA,
			SUPPORT_WORK_AREA,
			OPS_WORK_AREA,
			OTHER_WORK_AREA_OPTION,
			NOT_APPLICABLE_WORK_AREA,
			COMPANY_SIZE_20_OR_LESS,
			COMPANY_SIZE_20_99,
			COMPANY_SIZE_100_499,
			COMPANY_SIZE_500_999,
			COMPANY_SIZE_1000_OR_MORE,
			COMPANY_SIZE_PERSONAL_USE,
			E_COMMERCE_INDUSTRY,
			AUTOMATION_CONSULTING_INDUSTRY,
			SYSTEM_INTEGRATION_INDUSTRY,
			GOVERNMENT_INDUSTRY,
			LEGAL_INDUSTRY,
			HEALTHCARE_INDUSTRY,
			FINANCE_INDUSTRY,
			SECURITY_INDUSTRY,
			SAAS_INDUSTRY,
			OTHER_INDUSTRY_OPTION,
			WORK_AREA_KEY,
			COMPANY_SIZE_KEY,
			CODING_SKILL_KEY,
			COMPANY_INDUSTRY_KEY,
			OTHER_WORK_AREA_KEY,
			OTHER_COMPANY_INDUSTRY_KEY,
		};
	},
	computed: {
		...mapGetters({
			baseUrl: 'getBaseUrl',
		}),
	},
	methods: {
		closeDialog() {
			this.modalBus.$emit('close');
		},
		onMultiInput(name: IPersonalizationSurveyKeys, value: string[]) {
			if (name === WORK_AREA_KEY) {
				this.otherWorkAreaFieldVisible = value.includes(OTHER_WORK_AREA_OPTION);
				this.showAllIndustryQuestions = !value.includes(NOT_APPLICABLE_WORK_AREA);
				this.values[OTHER_WORK_AREA_KEY] = value.includes(OTHER_WORK_AREA_OPTION) ? this.values[OTHER_WORK_AREA_KEY] : null;
				this.values[WORK_AREA_KEY] = value;
			}
			if (name === COMPANY_INDUSTRY_KEY) {
				this.otherCompanyIndustryFieldVisible = value.includes(OTHER_INDUSTRY_OPTION);
				this.values[OTHER_COMPANY_INDUSTRY_KEY] = value.includes(OTHER_INDUSTRY_OPTION) ? this.values[OTHER_COMPANY_INDUSTRY_KEY] : null;
				this.values[COMPANY_INDUSTRY_KEY] = value;
			}

		},
		async save(): Promise<void> {
			this.$data.isSaving = true;

			try {
				await this.$store.dispatch('settings/submitPersonalizationSurvey', this.values);

				if (this.values[WORK_AREA_KEY] === null && this.values[COMPANY_SIZE_KEY] === null && this.values[CODING_SKILL_KEY] === null) {
					this.closeDialog();
				}

				this.submitted = true;
			} catch (e) {
				this.$showError(e, 'Error while submitting results');
			}

			this.$data.isSaving = false;
		},
	},
});
