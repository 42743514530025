<template>
	<div class="tree-functionality" :class="{ parentTree: depth == 0 }">

		<div class="tree-label clickable" :class="{ highlightLabel: selectedLabel || partialSelectedLabel}">
			<i v-if="hasChildren" @click="toggleShowChildren" class="arrow-icon" :class="{ active: showChildren}"></i>
			<div @click="blockedSelected ? toggleShowChildren() : toggleSelectedFunctionality()" class="select-functionality">
				<i class="select-icon square" v-if="!blockedSelected" :class="{ selected: selectedLabel, partialSelected: partialSelectedLabel}"></i>
				{{ label }}
			</div>
		</div>

		<b-collapse v-model="showChildren">
			<div class="container-tree-functionality" ref="parent" :style="{'--heightOfLastElement': heightOfLastElement}" :class="{ oneChild: numberOfDirectChildren == 1 }">
				<TreeFunctionality
					:parentSelected="selectedParent"
					:showTree="showChildren"
					v-for="functionality,index in functionalities"
					v-if="blockedSelected && functionality.isSelected || !blockedSelected"
					:functionalities="functionality?.childrens"
					:isSelected="functionality.isSelected"
					:label="functionality?.functionalityDescription || functionality?.description"
					:key="index"
					:id="functionality.offeredFunctionalityId"
					@childrenCreated="partialSelectIncrease();"
					@directChildrenCreated="directChildren();"
					@childrenSelected="childrenSelected"
					@childrenDeselected="childrenDeselected"
					:watchParentSelected="trigger"
					:blockedSelected="blockedSelected"
				/>

			</div>
		</b-collapse>
	</div>
</template>

<script>

import TreeFunctionality from '@/components/TreeFunctionality.vue';

export default {
	name: 'TreeFunctionality',
	components: {
		TreeFunctionality,
	},
	props: {
		functionalities: Array,
		parentSelected: Boolean,
		watchParentSelected: Number,
		depth: Number,
		showTree: Boolean,
		id: String,
		label: String,
		blockedSelected: Boolean,
		editMode: Boolean,
		isSelected: Boolean,
	},
	data() {
		return {
			showChildren: false,
			selectedFunctionality: false,
			selectedParent: false,
			dataFunctionalitySelected: [],
			numberOfChildrens: 0,
			numberOfDirectChildren: 0,
			numberOfChildrensSelected: 0,
			trigger: 0,
			triggerHeight: 0,
			heightOfLastElement: 0,
		};
	},
	computed: {
		selectedLabel() {
			return this.selectedFunctionality && this.numberOfChildrensSelected == this.numberOfChildrens;
		},
		partialSelectedLabel() {
			return this.selectedFunctionality && this.numberOfChildrensSelected != this.numberOfChildrens;
		},
		hasChildren() {
			if (!this.functionalities) {
				return false;
			} else {
				return true;
			}
		},
	},


	methods:{
		childrenSelected() {
			this.numberOfChildrensSelected += 1;
			this.$emit("childrenSelected");

			if (this.selectedFunctionality) {
				return;
			}
			this.selectedFunctionality = true;
			this.$store.commit('updateSelectedFunctionality', this.id);

		},



		childrenDeselected() {
			this.numberOfChildrensSelected -= 1;
			this.$emit("childrenDeselected");
		},

		directChildren() {
			this.numberOfDirectChildren += 1;
		},
		partialSelectIncrease() {
			this.$emit("childrenCreated");
			this.numberOfChildrens += 1;
		},

		toggleShowChildren() {
			this.showChildren = !this.showChildren;
		},
		toggleSelectedFunctionality() {
			this.selectedFunctionality = !this.selectedFunctionality;

			this.selectedParent = this.selectedFunctionality;

			this.trigger += 1;
			this.$store.commit('updateSelectedFunctionality', this.id);
		},

	},
	watch: {
		watchParentSelected: function(newVal, oldVal) {

			if (this.parentSelected == this.selectedFunctionality) {
				return;
			}

			this.selectedFunctionality = this.parentSelected;
			this.selectedParent = this.parentSelected;

			this.$store.commit('updateSelectedFunctionality', this.id);

			this.trigger += 1;

		},


		selectedFunctionality() {
			if (this.selectedFunctionality) {
				this.$emit("childrenSelected");
			} else {
				this.$emit("childrenDeselected");
			}
		},

	},
	mounted() {
		this.$emit("childrenCreated");
		this.$emit("directChildrenCreated");
		if (this.isSelected) {
			this.selectedFunctionality = true;
			this.$store.commit('updateSelectedFunctionality', this.id);
		}
	},

	beforeDestroy() {
		this.$store.commit('resetSelectedFunctionalities');
	},
};
</script>

<style scoped lang="scss">
.tree-functionality {
	position: relative;
	margin: 4px 0 0px 50px;
	&.parentTree {
		max-height: initial;
		margin: 0 0 16px 0;

		& > .tree-label {
			font-size: 16px;
			height: 40px;
			background: #273A5B;
			border: 1px solid #677CA1;

			&:before {
				display: none;
			}
		}
	}


	.container-tree-functionality {
		position: relative;

		.tree-functionality:last-child {

			& > .tree-label {
				&:before {
					background: url('./../assets/curvedLineFunctionalities.svg');
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
					background-size: 100%;
					width: 32px;
					left: -33px;
					border-left: 2px solid transparent;
					height: 28px;
					top: -9px;
				}
			}

			&:after {
				content: '';
				width: 20px;
				left: -47px;
				top: 13px;
				height: calc(100% - 10px);
				display: block;
				background: #1c2638;
				position: absolute;
			}
		}


		&:before {
			content: '';
			width: 1.5px;
			left: 20px;
			height: calc(100% - 17px);
			display: block;
			background: #4A638F;
			position: absolute;
		}



		&.oneChild {
			&:before {
				display: none;
			}
		}
	}

	.tree-label {
		padding: 6px 9px;
		height: 32px;
		font-size: 14px;
		font-weight: 500;
		background: #223049;
		border: 1px solid #4A638F;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
		border-radius: 4px;
		display: flex;
		align-items: center;
		column-gap: 10px;
		position: relative;



		&.highlightLabel {
			background: linear-gradient(0deg, rgba(0, 195, 221, 0.04), rgba(0, 195, 221, 0.04)), #223049 !important;
			border: 1px solid #00C3DD !important;
			color: #00C3DD !important;
		}

		&:before {
			content: '';
			width: 29px;
			left: -30px;
			height: 2px;
			display: block;
			background: #4A638F;
			position: absolute;
		}

		.select-functionality {
			display: flex;
			column-gap: 10px;
			width: initial;
			align-items: center;
		}

		.arrow-icon {
			background-image: url('./../assets/cardArrow.svg');
			background-position: center;
			background-repeat: no-repeat;
			width: 24px;
			height: 24px;
			transform: rotate(90deg);
			transition: 0.2s transform;



			&.active {
				background: rgba(0, 195, 221, 0.08);
				border-radius: 4px;
				background-image: url('./../assets/arrowIconBlue.svg');
				background-position: center;
				background-repeat: no-repeat;
				transform: rotate(0deg);
			}
		}

		.select-icon {
			margin: 0;
		}
		&.clickable {
			cursor: pointer;
		}
	}
}
</style>
