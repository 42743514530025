import WebStorage from "../common/WebStorage";

class TokenService {
	constructor() {
		this.storageKeyword = ['aliare', 'tokens', process.env.NODE_ENV].join(':');
	}

	getTokens() {
		return WebStorage.getItem(this.storageKeyword);
	}

	setTokens(tokens) {
		WebStorage.setItem(this.storageKeyword, tokens);
	}

	removeTokens() {
		WebStorage.removeItem(this.storageKeyword);
	}

	getStoredRefreshToken() {
		const tokens = this.getTokens();
		return tokens?.refresh_token;
	}

	getStoredAccessToken() {
		const tokens = this.getTokens();
		return tokens?.access_token;
	}

	updateStoredAccessToken(access_token) {
		const tokens = { ...this.getTokens(), access_token };
		this.setTokens(tokens);

		return tokens;
	}
}

export default new TokenService();
