import axios from 'axios';
import { appConfig } from '@/config/appConfig';
import WebStorage from '@/common/WebStorage';
import { defaultAliareApi } from '@/api/aliareApi';
import TokenService from "@/services/token.service";

axios.defaults.withCredentials = appConfig('auth.setCookies');
const UserAccount = 'UserAccount'
const tenantAccount = 'Tenant'
const userURL = 'Users'
const peopleURL = 'People'
const UserPermission = 'UserPermission'
const SolutionId = 'aca76e07-280f-4e1f-a4df-f8a94757e0fc'
const tenantId = WebStorage.getItem('tenantId');
class AliareAccountService {
    async getUserAccountId(id) {
        const res = await defaultAliareApi.get(`/v1/${UserAccount}?UserId=${id}&SolutionId=${SolutionId}`);
        return res;
    }
		async getUserAccountBasic(userId) {
        const res = await defaultAliareApi.get(`/v1/${UserAccount}/Basic/${userId}/${tenantId}`);
        return res;
    }
		async getAccountData(id) {
			const res = await defaultAliareApi.get(`/v1/${tenantAccount}/${id}`);
			return res;
		}
		async getAccountDataV2(id) {
			const res = await defaultAliareApi.get(`/v2/${tenantAccount}/${id}`, {
				headers: {
					version:2
				}
			});
			return res;
		}
		async getUserId(id) {
			const { data } = await defaultAliareApi.get(`/v2/${userURL}/${id}`, {
				headers: {
					version:2
				}
			});
			return data;
		}
		async disableUser(permissionId) {
			const { data } = await defaultAliareApi.post(`/v1/${UserPermission}/${permissionId}/Disable`);
			return data;
		}
		async activeUser(permissionId) {
			const { data } = await defaultAliareApi.post(`/v1/${UserPermission}/${permissionId}/Active`);
			return data;
		}

		async disableProfile(query) {
			const res = await defaultAliareApi.post(`/v2/Permission/${query?.permissionId}/Disable`, {
				headers: {
					version: 2,
				},
				params:{
					tenantId: WebStorage.getItem('tenantId'),
				},
			});

			return res;
		}



		async activeProfile(query) {
			const res = await defaultAliareApi.post(`/v2/Permission/${query?.permissionId}/Active`, {
				headers: {
					version: 2,
				},
				params:{
					tenantId: WebStorage.getItem('tenantId'),
				},
			});

			return res;
		}

		async getUsers(query) {
			const { data } = await defaultAliareApi.get(`/v2/${userURL}`, {
				headers: {
					version:2,
				},
				params:{
					page: query?.page,
					email: query?.email,
					search: query?.search,
					pageSize: query?.pageSize,
					personId: query?.personId,
				},
			});
			return data;
		}

		async getPeople(query) {
			const { data } = await defaultAliareApi.get(`/v2/${peopleURL}`, {
				headers: {
					version:2
				},
				params:{
					page: query?.page,
					search: query?.search,
					pageSize: query?.pageSize,
				},
			});
			return data;
		}

		async getPeopleId(personId) {
			const { data } = await defaultAliareApi.get(`/v1/${peopleURL}/${personId}`);
			return data;
		}

		async putPeopleId(query) {
			const { data } = await defaultAliareApi.put(`/v2/${peopleURL}/${query.personId}`, query.payload, {
				headers: {
					version: 2,
				},
			});
			return data;
		}

		async blockUserInPermission(payload) {
			const { data } = await defaultAliareApi.post(`/v1/${UserPermission}/${payload.userId}/Block`);
			return data;
		}

		async unblockUserInPermission(payload) {
			const { data } = await defaultAliareApi.post(`/v1/${UserPermission}/${payload.userId}/Unblock`);
			return data;
		}

		async getPermissionFromUser(query) {
			const res = await defaultAliareApi.get(`/v1/${UserPermission}/GetPermissionFromUser`, {
				params:{
					clientId: query?.clientId,
					userId: query?.userId,
					tenantId: query?.tenantId,
				},
			});
			return res;
		}

		async getOfferSolution(query) {
			const res = await defaultAliareApi.get(`/v2/OfferSolution/${query.offerSolutionId}`, {
				headers: {
					version: 2,
				},
			});
			return res;
		}
		async getUsersByTenantId(query) {
			const res = await defaultAliareApi.get(`/v2/${userURL}/GetByTenantId`, {
				headers: {
					version:2
				},
				params:{
					page: query?.page,
					search: query?.search,
					solutionId: SolutionId,
					pageSize: query?.pageSize,
					tenantId: WebStorage.getItem('tenantId'),
				},
			});
			return res;
		}
		async getProfilesByTenantId(query) {
			const res = await defaultAliareApi.get(`/v1/Permission`, {
				params:{
					page: query?.page,
					pageSize: query?.pageSize,
					search: query?.search,
					productProfileId: query?.productProfileId,
					tenantId: WebStorage.getItem('tenantId'),
				},
			});

			return res;
		}

		async getProfileDetailingById(query) {
			const res = await defaultAliareApi.get(`/v1/Permission/${query?.permissionId}`, {
				params:{
					tenantId: WebStorage.getItem('tenantId'),
				},
			});

			return res;
		}

		async getUsersProfileDetailingById(query) {
			const res = await defaultAliareApi.get(`/v2/Permission/${query?.permissionId}/Users`, {
				headers: {
					version: 2,
				},
				params:{
					tenantId: WebStorage.getItem('tenantId'),
					page: query?.page,
				},
			});

			return res;
		}


		async getUserPermission(query) {
			const res = await defaultAliareApi.get(`/v2/UserAccess`, {
				headers: {
					version: 2,
				},
				params:{
					ClientId: 'aliare-integra',
					TenantId: query?.tenantId || WebStorage.getItem('tenantId'),
					UserId: query?.userId,
				},
			});

			return res;
		}

		async getProductProfilesByTenantId(query) {
			const res = await defaultAliareApi.get(`/v2/ProductProfile`, {
				headers: {
					version: 2,
				},
				params:{
					productId: SolutionId,
					tenantId: WebStorage.getItem('tenantId'),
					search: query?.search,
				},

			});

			return res;
		}

		async putProductProfileDescription(query) {
			const res = await defaultAliareApi.put(`/v2/ProductProfile/${query.productProfileId}/Description`, query.payload, {
				headers: {
					version: 2,
				},
			});

			return res;
		}

		async deleteProductProfile(id) {
			const res = await defaultAliareApi.delete(`/v2/ProductProfile/${id}/Delete`, {
				headers: {
					version: 2,
				},
			});

			return res;
		}

		async createProductProfile(payload) {
			payload.tenantId = WebStorage.getItem('tenantId');
			payload.productId = SolutionId;
			const res = await defaultAliareApi.post(`/v2/ProductProfile`, payload);

			return res;
		}

		async savePermission(payload) {
			payload.typePermission = "DEFAULT_ACCOUNT";
			payload.tenantId = WebStorage.getItem('tenantId');
			const res = await defaultAliareApi.post(`/v1/Permission`, payload);

			return res;
		}

		async putPermission(payload) {
			payload.tenantId = WebStorage.getItem('tenantId');
			const res = await defaultAliareApi.put(`/v1/Permission/${payload.permissionId}`, payload);

			return res;
		}

		async linkUsersToPermission(payload) {
			const res = await defaultAliareApi.post(`/v2/UserPermission`, payload, {
				headers: {
					version: 2,
				},
			});

			return res;
		}

		async getAccountTenant(query) {
      const res = await defaultAliareApi.get(`/v1/${tenantAccount}`, {
        params:{
          page: query?.page,
					search: query?.search,
					pageSize: query?.pageSize,
          solutionId: SolutionId,
        },
      });
      return res;
    }

		async getAccountTenantV2(query) {
      const res = await defaultAliareApi.get(`/v2/${tenantAccount}`, {
				headers: {
					version: 2,
				},
        params:{
          page: query?.page,
					search: query?.search,
					pageSize: query?.pageSize,
          solutionId: SolutionId,
        },
      });
      return res;
    }

		async getGroupPartner() {
      const res = await defaultAliareApi.get(`/v2/GroupPartner`, {
				headers: {
					version: 2,
				},
				params:{
					tenantId: WebStorage.getItem('tenantId'),
				},
			});

			return res;
    }

		async getSubscription() {
      const res = await defaultAliareApi.get(`/v2/Subscription`, {
				headers: {
					version: 2,
				},
				params:{
					tenantId: WebStorage.getItem('tenantId'),
					solutionId: "aca76e07-280f-4e1f-a4df-f8a94757e0fc",
				},
			});

			return res;
    }

		async getSegment() {
			const res = await defaultAliareApi.get(`/v2/Segment`, {
				headers: {
					version: 2,
				},
			});
			return res;
		}

		async createUser(payload) {
			const res = await defaultAliareApi.post(`/v2/${UserAccount}/complete`, payload, {
				headers: {
					version: 2,
				},
			});
			return res;
		}

		async linkUsersToPermissionV1(payload) {
			const res = await defaultAliareApi.post(`/v1/UserPermission`, payload);
			return res;
		}

		async deletePermission(id) {
			const res = await defaultAliareApi.delete(`/v2/UserPermission/${id}/Delete`, {
				headers: {
					version: 2,
				},
			});

			return res;
		}

		async createWithPerson(payload) {
			const res = await defaultAliareApi.post(`/v1/${userURL}/CreateWithPerson`, payload);
			return res;
		}

		async addUserTenant(payload) {
			const res = await defaultAliareApi.post(`/v1/${UserAccount}/AddUserTenant`, payload);
			return res;
		}

		async getProfilesByTenantIdV2(query) {
			const res = await defaultAliareApi.get(`/v2/Permission`, {
				headers: {
					version: 2,
				},
				params:{
					page: query?.page,
					pageSize: query?.pageSize,
					search: query?.search,
					productProfileId: query?.productProfileId,
					productProfileDescription: query?.productProfileDescription,
					tenantId: WebStorage.getItem('tenantId'),
				},
			});

			return res;
		}

		async getProfilesByIdV2(query) {
			const res = await defaultAliareApi.get(`/v2/Permission/${query.id}`, {
				headers: {
					version: 2,
				},
				params:{
					tenantId: WebStorage.getItem('tenantId'),
				},
			});

			return res;
		}

}

export default new AliareAccountService();
