import AuthService from '../services/auth.service';
import OidcService from '../services/oidc.service';
import TokenService from '../services/token.service';

const MOCKED_PERMISSIONS = ['admin', 'moderator'];

export default {
	namespaced: true,

	state: {
		actionStatus: {
			authenticating: false,
			creatingAccount: false,
			loadingUserInfo: false,
		},
		tokens: null,
		userInfo: null,
		userPermissions: MOCKED_PERMISSIONS,
	},

	getters: {
		loggedIn: state => Boolean(state.tokens?.access_token),
		accessToken: state => state.tokens?.access_token,
		refreshToken: state => state.tokens?.refresh_token,
		userInfo: state => state.userInfo,
		userPermissions: state => state.userPermissions,
		authenticatingStatus: state => state.actionStatus.authenticating,
		recoverPassword: state => state.recoverPassword,
		creatingAccountStatus: state => state.actionStatus.creatingAccount,
		loadingUserInfoStatus: state => state.actionStatus.loadingUserInfo,
		sendConfirmationEmail: state => state.sendConfirmationEmail,

	},

	actions: {
		async initialize({ commit, dispatch }) {
			const tokens = TokenService.getTokens();

			if (!tokens?.access_token) return;

			try {
				commit('setTokens', { tokens });
				const userinfo = await dispatch('getUserInfo');

				return userinfo;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		async authenticateOidc({ commit }) {
			commit('turnOnAuthenticatingStatus');

			try {
				const { data: tokens } = await OidcService.authenticateOidc();
				commit('setTokens', { tokens });
				return Promise.resolve(tokens);
			} catch (error) {
				return Promise.reject(error);
			} finally {
				commit('turnOffAuthenticatingStatus');
			}
		},

		async authenticateUser({ commit }, userCredentials) {
			const { username, password } = userCredentials;

			commit('turnOnAuthenticatingStatus');

			try {
				const { data: tokens } = await AuthService.login({ username, password });
				commit('setTokens', { tokens });
				return Promise.resolve(tokens);
			} catch (error) {
				return Promise.reject(error);
			} finally {
				commit('turnOffAuthenticatingStatus');
			}
		},

		async recoverPassword({ commit }, email) {
			commit("turnOnForgotPasswordStatus");

			try {
			  return await AuthService.recoverPassword(email);
			} catch {
			  // eslint-disable-next-line no-throw-literal
			  throw "Erro ao enviar a recuperação de senha para o usuário.";
			} finally {
			  commit("turnOffForgotPasswordStatus");
			}
		},

		async sendConfirmationEmail({ commit }, email) {
			commit("turnOnSendConfirmationEmailStatus");

			try {
			  return await AuthService.sendConfirmationEmail(email);
			} catch {
				// eslint-disable-next-line no-throw-literal
			  throw "Erro ao enviar email de confirmação para o usuário.";
			} finally {
			  commit("turnOffSendConfirmationEmailStatus");
			}
		},

		async createAccount({ commit }, accountInfo) {
			const { username, email, password } = accountInfo;

			commit('turnOnCreatingAccountStatus');

			try {
				const { data: result } = await AuthService.register({ username, email, password });
				return Promise.resolve(result);
			} catch (error) {
				return Promise.reject(error);
			} finally {
				commit('turnOffCreatingAccountStatus');
			}
		},

		async getUserInfo({ commit }) {
			commit('turnOnUserInfoStatus');

			try {
				const { data: userInfo } = await AuthService.getUserInfo();
				commit('setUserInfo', { userInfo });
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				commit('turnOffUserInfoStatus');
			}

			return true;
		},

		updateTokens({ commit }, tokens) {
			commit('setTokens', { tokens });
		},

		async revokeTokens({ commit }) {
			commit('turnOnAuthenticatingStatus');

			try {
				const result = await AuthService.logout();
				commit('setTokens', { tokens: null });

				return Promise.resolve(result);
			} catch (error) {
				return Promise.reject(error);
			} finally {
				commit('turnOffAuthenticatingStatus');
			}
		},

		async logout({ commit }) {
			commit('turnOnAuthenticatingStatus');

			try {
				const result = await AuthService.logout();

				return Promise.resolve(result);
			} catch (error) {
				return Promise.reject(error);
			} finally {
				commit('turnOffAuthenticatingStatus');
			}
		},
	},

	mutations: {
		setTokens(state, { tokens }) {
			state.tokens = tokens;
		},
		setUserInfo(state, { userInfo }) {
			state.userInfo = userInfo;
		},
		turnOnAuthenticatingStatus(state) {
			state.actionStatus.authenticating = true;
		},
		turnOnRecoverPassword(state) {
			state.recoverPassword = true;
		},
		turnOnSendConfirmationEmailStatus(state) {
			state.sendConfirmationEmail = true;
		},
		turnOffSendConfirmationEmailStatus(state) {
			state.sendConfirmationEmail = false;
		},
		turnOffAuthenticatingStatus(state) {
			state.actionStatus.authenticating = false;
		},
		turnOnCreatingAccountStatus(state) {
			state.actionStatus.creatingAccount = true;
		},
		turnOffCreatingAccountStatus(state) {
			state.actionStatus.creatingAccount = false;
		},
		turnOnUserInfoStatus(state) {
			state.actionStatus.loadingUserInfo = true;
		},
		turnOffUserInfoStatus(state) {
			state.actionStatus.loadingUserInfo = false;
		},
	},
};
