<template>
	<div>
		<Modal
			:name="modalName"
			:eventBus="modalBus"
			:title="'Renomear Fluxo'"
			width="520px"
			minHeight="224px"
			footerOut
		>
			<template v-slot:content>
				<div class="container-loading" v-if="isLoading">
					<div class="loading-spin"></div>
				</div>

				<div v-else>
					<AliareInput
						required
						:labelText="'Titulo do Fluxo'"
						v-model="inputModalName"
						@input="verifyNameWorkflow"
					/>

					<div v-if="messageNameError || messageNameSuccess">
						<p class="message" :class="{ error: messageNameError, success: messageNameSuccess }">
							{{ messageNameError || messageNameSuccess }}
						</p>
						<p class="icon"	:class="{ errorIcon: messageNameError, successIcon: messageNameSuccess }"	/>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<div class="footer-buttons">
					<ModalButtonSuccess
						@click="patchNameWorkflow"
						:active="activeBtn"
						:titleButton="'Salvar'"
						width="111"
					/>

					<ModalButtonCancel
						@click="modalBus.$emit('close')"
						width="111"
					/>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>

import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import AliareInput from '@/components/AliareInput.vue';
import Modal from './Modal.vue';

import { showMessage } from '@/components/mixins/showMessage';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';
import Vue from 'vue';

export default mixins(
	restApi,
	showMessage,
).extend({
	name: 'RenameWorkflow',
	components: {
		Modal,
		AliareInput,
		ModalButtonSuccess,
		ModalButtonCancel,
	},
	props: ['modalName', 'isActive'],
	data() {
		return {
			modalBus: new Vue(),

			isLoading: false,
			dataWorkflow: [],
			inputModalName: '',
			actualName: '',
			activeBtn: false,
			messageNameSuccess: '',
			messageNameError: '',
		};
	},
	async created() {
		this.isLoading = true;
		await this.getFlowId();
		this.isLoading = false;
	},
	mounted() {
	},
	computed: {
		workflowId() {
			return this.$store.getters.workflowId;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
  },
	methods: {
		...mapActions('workflows', ['getWorkflowsId']),

		async getFlowId() {
			try {
				const { data } = await this.getWorkflowsId({ workspaceId: this.workspace.id, id: this.workflowId });

				this.dataWorkflow = data;
				this.inputModalName = data.nome;
				this.actualName = data.nome;
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error?.notifications[0],
					status: 'error'
				});
			}
		},

		verifyNameWorkflow:
		_.debounce(async function (e) {
			if (this.inputModalName !== '' && this.inputModalName !== this.actualName) {
				if (this.inputModalName?.length > 3) {
					const search = await this.restApi().checkAvailableNameWorkflows(this.workspace.id, this.dataWorkflow.projeto.id, {
						nome: this.inputModalName,
					});

					if (search) {
						this.messageNameError = this.$locale.baseText('project.edit.inlineProjectNameMessageUnavailable', { interpolate: { savedProjectName: this.inputModalName } });
						this.messageNameSuccess = '';
						this.activeBtn = false;

					} else {
						this.messageNameSuccess = this.$locale.baseText('project.edit.inlineProjectNameMessageAvailable', { interpolate: { savedProjectName: this.inputModalName } });
						this.messageNameError = '';
						this.activeBtn = true;
					}
				}
			} else {
				this.messageNameSuccess = '';
				this.messageNameError = '';
				this.activeBtn = false;
			}
		},500),
		async patchNameWorkflow() {
			try {
				this.dataWorkflow.projetoId = this.dataWorkflow.projeto.id;
				const data = this.cleanWorkflowObject(this.dataWorkflow);

				await this.restApi().updateWorkflow(this.workspace.id, this.workflowId, this.dataWorkflow);
				this.modalBus.$emit('close');
				this.$root.$emit('reloadDetail');

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('O Nome do fluxo foi alterado com sucesso'),
				});
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error?.notifications[0],
					status: 'error'
				});
			}
		},
		cleanWorkflowObject(data) {
			delete data.modificadoEmDataHora;
			delete data.modificadoPorUsuario;
			delete data.triggerError;
			delete data.projeto;
			delete data.tenant;
			data.nome = this.inputModalName;

			return data;
		},
	},
});
</script>

<style lang="scss" scoped>
.footer-buttons {
	display: flex;
	margin: 15px;
  float: right;
}
.message {
	position: absolute;
  font-size: 12px;
  left: 19px;
  overflow-wrap: anywhere;

	&.success {
		color: #59D64C;
	}
	&.error {
		color: #FF495F;
	}
}
.icon {
	position: absolute;
  width: 20px;
  height: 20px;
  right: 28px;
  bottom: 45px;

	&.successIcon {
		background: url('../assets/icon/greenCheckIcon.svg') no-repeat;
	}

	&.errorIcon {
		background: url('../assets/closeIconRed.svg') no-repeat;
	}
}

.container-loading {
	display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 140px;

	.loading-spin {
		width: 120px;
		height: 120px;
		background-image: url('../assets/loading-modal.png');
		background-repeat: no-repeat;
		background-size: cover;
		animation: spin 2s linear infinite;
	}
	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
}

:deep {
	.modal-default-success {
		margin: -1px 20px 0px 0px;
	}

	.modal-content {
		background: #1C2638;
	}
}
</style>

