
import { RESULT_EXECUTION_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import InfoAliare from '@/components/InfoAliare.vue';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue'
import WebStorage from '@/common/WebStorage';


import { executionLogPermission } from '@/components/mixins/permissions/executionLog';

import { capitalized, removeItemFromArrayByValue, searchPermissions } from '@/components/helpers';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { copyPaste } from '@/components/mixins/copyPaste';
import InteractionMenu from '@/components/InteractionMenu.vue';

export default mixins(
	restApi,
	workflowHelpers,
	executionLogPermission,
    copyPaste,
).extend({
	name: 'ResultExecutionModal',
	components: {
		Modal,
		ModalButtonCancel,
        InfoAliare,
		ModalButtonSuccess,
		InteractionMenu,
	},
    props: {
		dataModal: {
			type: Object,
		},
	},
	data() {
		return {
			modalBus: new Vue(),
			RESULT_EXECUTION_MODAL_KEY,
			isLoadingPage: true,

            resultData: {
                "execucaoId": '',
                "workflows": {
                    "id": "",
                    "nome": ""
                },
                "error": "",
                "resultado": "",
                "ultimoConectorExecutado": {
                    "nome": "",
                    "rotulo": "",
                    "owner": "",
                    "isAliare": null,
                }
            },
            extendedMessageText: false,
            copiedMessageText: false,			
		};
	},
	computed: {
        dynamicTextInfo() {
            if (this.failMode) {
                const stringText = `<strong>A execução resultou em Falha!</strong><br><br>A falha ocorreu devido a um erro na operação <b><i>"${ this.resultData.ultimoConectorExecutado.rotulo }"</i></b>, 
                    realizada pelo conector <b><i>"${ this.resultData.ultimoConectorExecutado.nome }"</i></b>`
                
                const helpText = this.resultData.ultimoConectorExecutado.isAliare ? `. Para resolver, contate o suporte técnico do 
                    <b><i>"${ this.resultData.ultimoConectorExecutado.owner }"</i></b> e informe a mensagem de erro exibida e o link da execução.` : ` Para resolver, contate o suporte técnico do <b><i>"${ this.resultData.ultimoConectorExecutado.nome }"</i></b> e informe a mensagem de erro exibida e link da execução.`;

                return stringText + helpText;
            }
            return `<b>A execução resultou em Alerta!</b>`
        },
        failMode() {
            if (this.resultData.resultado == 'FALHA') {
                return true
            }
        },
		workflowId() {
			return this.$route.params.name === undefined ? this.$store.getters.workflowId : this.$route.params.name;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
        organizationId() {
            return WebStorage.getItem('tenantId');
        }
	},
	async mounted () {

	},
	async created() {
		this.initPageExecution();
	},
	methods: {
        openExecutionDetail(id) {
            const route = this.$router.resolve({
				name: 'ExecutionById',
				params: { id },
			});
            window.open(route.href, '_blank');
            this.modalBus.$emit('close');
        },
        copyLinkExecution() {
            const linkExecution = `${window.location.origin}/organizacao/${this.organizationId}/espacos-de-trabalho/${this.workspace.id}/execucoes/${this.resultData.execucaoId}`
            this.copyToClipboard(linkExecution);
        },
        copyMessageText() {
            const textToCopy = this.failMode ? this.resultData.error : this.resultData.alertas.mensagem;
            this.copyToClipboard(JSON.stringify(textToCopy));

            this.copiedMessageText = true;
        },
		async initPageExecution() {
            try {
                this.resultData = await this.restApi().executionResultInfos(this.workspace.id, this.dataModal.executionId);
            } catch (e) {
                this.resultData.noData = true;
            }
            this.isLoadingPage = false;
		},
	},
});
