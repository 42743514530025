
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { workspacePermission } from '@/components/mixins/permissions/workspace';

import MenuOptions from '@/components/MenuOptions.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

export default mixins(
	restApi,
	workspacePermission
).extend({
	components: {
		MenuOptions,
		ScrollInfinite,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	data() {
		return {
			page: 1,
			size: 10,
			isLoading: true,
			dataWorkspace: [],
			scrollInfinite: true,
			show: '',
			showAlert: false,
			showDel: false,
			inactiveData: {},
			deleteData: '',
		};
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),
		alertNotificationTitle() {
			if(!this.showDel){
				return 'Inativação do Espaço de Trabalho';
			}
			return 'Exclusão de Espaço de Trabalho';
		},
		alertNotificationDescription() {
			if(!this.showDel){
				return `Ao Inativar o Espaço de Trabalho todos os recursos do Espaço ficarão em <b>Modo Leitura</b>, ficando <b>impossibilitado</b> de efetuar <b>ações</b> como: <b>executar</b>, <b>criar</b>, <b>editar</b>, <b>compartilhar</b> ou <b>excluir</b>. O que você deseja fazer?`;
			}
			return `O Espaço de Trabalho será <b>excluído</b> e você <b>não poderá recuperá-lo</b>. Caso você queira, poderá <b>inativá-lo</b> nesse momento e <b>reativá-lo</b> no futuro, evitando perder as parametrizações feitas. O que você deseja fazer?`;
		},
		textMaintain() {
			if(!this.showDel){
				return 'Manter Ativo';
			}
			return 'Manter Espaço de Trabalho';
		},
	},
	async created() {
		this.$store.commit('setWorkspace', {});
		WebStorage.setItem('workspace', JSON.stringify({}));
		
		await this.validationAccessWorkspace();
		this.loadWorkspaces();
	},
	methods: {
		goToCreateWorkspace() {
			this.$router.push({
				name: 'createWorkspace',
			});
		},
		goToEditWorkspace(id) {
			this.$router.push({
				name: 'editWorkspace',
				params: { id },
			});
		},
		async goToAcessWorkspace(workspace) {
			WebStorage.setItem('workspace', JSON.stringify(workspace));

			this.$router.push({
				name: 'project',
				params: {
					workspaceId: JSON.parse(JSON.stringify(workspace.id)),
				}
			});


			this.$store.commit('setWorkspace', JSON.parse(JSON.stringify(workspace)));
		},
		v_show(res) {
			if (this.show === res) {
				this.show = '';
			} else {
				this.show = res;
			}
		},

		async loadWorkspaces() {
			this.isLoading = true;
			try {
				this.dataWorkspace = await this.restApi().getWorkspaces({ page: 1, size: this.size });

				if (this.dataWorkspace.length < 10) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.response?.data?.notifications || error?.notifications[0],
					status: 'error',
				});
				this.scrollInfinite = false;
			}
			this.isLoading = false;
		},
		async pagination() {
			this.page++;
			this.scrollInfinite = true;
			const payload = { page: this.page, size: this.size };

			try {
				const response = await this.restApi().getWorkspaces(payload);
				this.dataWorkspace.push(...response);

				if (response.length < 10) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				console.error(error);
			}
		},
		openModalDelete(data, index) {
			this.showDel = true;
			this.showAlert = true;
			this.deleteData = { data, index };
		},
		openModalActive(active, data) {
			this.inactiveData = data;

			if(active !== data.ativo) {
				if(!active) {
					this.showDel = false;
					this.showAlert = true;
				} else {
					this.changeActive(this.inactiveData);
				}
			} else {
				this.$store.commit('activeAlert', {
					message: `O Espaço de Trabalho já se encontra com o status ${ data.ativo ? 'ativo' : 'inativo'} `,
					status: 'alert'
				});
			}
		},
		async changeActive(data) {
			this.inactiveData = data;

			this.showAlert = false;
			this.$store.commit('activeAlert', {
				message: `Iniciamos a ${!data.ativo ? 'ativação' : 'inativação'} do Espaço de Trabalho, isso poderá levar alguns minutos. Informaremos quando concluirmos.`,
			});

			await this.restApi().updateWorkspaces(data.id, { ativo: !data.ativo })
			.then((response) => {

				this.$store.commit('activeAlert', {
					message: `O Espaço de Trabalho "${data.nome}" foi ${!data.ativo ? 'ativado' : 'inativado'} com sucesso.`,
					status: !data.ativo ? '' : 'alert',
				});

				this.inactiveData.ativo = !data.ativo;

			}).catch((error) => {
				this.$store.commit('activeAlert', {
					message: `Erro ao ${!data.ativo ? 'ativar' : 'inativar'} Espaço de Trabalho, por favor tente novamente`,
					status: 'error'
				});

				this.loadWorkspaces();
			});
		},
		async deleteWorkspace() {
			this.showAlert = false;

			this.restApi().deleteWorkspaces(this.deleteData.data.id).then((response) => {
				this.dataWorkspace.splice(this.deleteData.index, 1);

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('deleteWorkspace.successDelete', { interpolate: { workspaceDeleted: this.deleteData.data.nome }}),
				});
			}).catch((error) => {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('deleteWorkspace.errorDelete'),
					status: 'error',
				});
				return;
			});

			this.$store.commit('activeAlert', {
				message: this.$locale.baseText('deleteWorkspace.initDelete'),
			});
		},
		getInitials(text) {
			if (!text) return;

			const nome = text?.split(' ');
			return (nome[0]?.charAt(0) + (nome[1] ? nome[1]?.charAt(0) : ''))?.toUpperCase();
		},
	},
});
