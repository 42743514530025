const crypto = require('crypto');

export function encodeToBase64(str) {
	return str && Buffer.from(str, 'utf8').toString('base64');
}

export function decodeFromBase64(str) {
	return str && Buffer.from(str, 'base64').toString('utf8');
}

export class PKCE {
	constructor() {
		this.storageKey = ['pkce_keys', process.env.NODE_ENV].join(':');
	}

	// Dependency: Node.js crypto module
	// https://nodejs.org/api/crypto.html#crypto_crypto
	base64URLEncode(str) {
		return str.toString()
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=/g, '');
	}

	// Dependency: Node.js crypto module
	// https://nodejs.org/api/crypto.html#crypto_crypto
	sha256(buffer) {
		return crypto.createHash('sha256').update(buffer).digest();
	}

	generateAuthorizationKeys() {
		const code_verifier = this.base64URLEncode(crypto.randomBytes(32));
		const code_challenge = this.base64URLEncode(this.sha256(code_verifier));

		return { code_challenge, code_verifier };
	}
}

export default new PKCE();
