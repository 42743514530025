
import SvgAnimation from '@/components/SvgAnimation.vue';

export default {
	name: 'AlertNotificationModal',
	components: {
		SvgAnimation,
	},

	data() {
		return {
			visible: true,
		};
	},
	computed: {
	},
	methods: {


  },
};
