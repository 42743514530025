
import TimePickerAliare from '@/components/TimePickerAliare.vue';
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
	components: {
		TimePickerAliare,
	},
	props: {
		defaultInitialDate: {
			type: String,
			default: '',
		},
		defaultEndDate: {
			type: String,
			default: '',
		},
		trashIcon: {
			type: Boolean,
			default: false,
		},
		clearInput: Boolean,
	},
	data() {
		return {
			startSelectedDate: '',
			endSelectedDate: '',
			startSelectedTime: '00:00',
			endSelectedTime: '00:00',
			startTime: '',
			endTime: '',
		};
	},
	created() {
		this.loadCurrentValues();

	},
	methods: {
		loadCurrentValues() {
			this.startSelectedDate = this.splitDateTimeToUTC(this.defaultInitialDate)[0];
			this.startSelectedTime = this.splitDateTimeToUTC(this.defaultInitialDate)[1];
			this.endSelectedDate = this.splitDateTimeToUTC(this.defaultEndDate)[0];
			this.endSelectedTime = this.splitDateTimeToUTC(this.defaultEndDate)[1];
			this.startTime = this.convertToUTC(this.startSelectedDate, this.startSelectedTime);
			this.endTime = this.convertToUTC(this.endSelectedDate, this.endSelectedTime);
		},
		changeValueStart() {
			const startTimeTemp = this.convertToUTC(this.startSelectedDate, this.startSelectedTime);
			if (startTimeTemp > this.endTime && this.endTime !== '') {
				this.startSelectedDate = '';
				this.$store.commit('activeAlert', {
					message: 'A data inicial não pode ser maior que data de conclusão!',
					status: 'error',
				});
				return;
			}

			this.startTime = this.convertToUTC(this.startSelectedDate, this.startSelectedTime);
			this.emitIntervalSelected();
		},

		changeValueEnd() {
			const endTimeTemp = this.convertToUTC(this.endSelectedDate, this.endSelectedTime);
			if (endTimeTemp < this.startTime && this.startTime !== '') {
				this.endSelectedDate = '';
				this.$store.commit('activeAlert', {
					message: 'A data de conclusão não pode ser menor que a data inicial!',
					status: 'error',
				});
				return;
			}

			this.endTime = this.convertToUTC(this.endSelectedDate, this.endSelectedTime);
			this.emitIntervalSelected();
		},

		convertToUTC(date, time) {
			let onlyDate = moment(date).format('YYYY-MM-DD[T]');
			let timeDate = moment(onlyDate + time);
			return moment.utc(timeDate).format();
		},

		updateTimeStart(newValue) {
			this.startSelectedTime = newValue;
			this.changeValueStart();
		},

		updateTimeEnd(newValue) {
			this.endSelectedTime = newValue;
			this.changeValueEnd();
		},

		emitIntervalSelected() {
			this.$emit('intervalSelected', this.startTime, this.endTime);
		},

		clearFilter() {
			this.startSelectedDate = '';
			this.endSelectedDate = '';
			this.startSelectedTime = '00:00';
			this.endSelectedTime = '00:00';
			this.startTime = '';
			this.endTime = '';
		},

		splitDateTimeToUTC(dateTime) {
			if (dateTime == '') return ['', '00:00'];
			const momentDateTime = moment.utc(dateTime);

			// Obtém o offset de fuso horário local em minutos
			const offsetMinutes = moment().utcOffset();

			// Adicionar ou subtrair o offset fornecido
			const adjustedMoment = momentDateTime.clone().add(offsetMinutes, 'minutes');

			const formattedDate = momentDateTime.format('MM/DD/YYYY');
			const formattedTime = adjustedMoment.format('HH:mm');

			return [formattedDate, formattedTime];
		},
	},
	watch: {
    clearInput(newValue) {
			if (newValue !== true) this.clearFilter();
    },
  },
};
