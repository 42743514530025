
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';

import BackgroundView from '@/components/BackgroundView.vue';
import AliareInput from '@/components/AliareInput.vue';
import SearchWorkflowInput from '@/components/SearchWorkflowInput.vue';
import InfoAliare from '@/components/InfoAliare.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import Modal from '@/components/ModalAliare.vue';


import { VALIDATION_RESOURCE_MODAL_KEY } from '../constants';

import { ref } from 'vue';
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';


export default mixins(
	restApi,
	nodeHelpers,
	showMessage,
).extend({
	components: {
		BackgroundView,
		AliareInput,
		ModalButtonSuccess,
		ModalButtonCancel,
		Modal,
		NotificationPage,
		InfoAliare,
		AlertNotificationModal,
		SearchWorkflowInput,
	},
	name: 'CreateEditDataCenter',
	data() {
		return {
			isLoadingImport: false,
			isLoadingTable: false,
			dataCenterName: '',
			message: '',
			objectRegister: [],
			forms: ref([
				{ titulo: '', workflowId: '', isValid: null},
			]),
			dataCenterValidName: '',
			validate: false,
			modeNotification: 'active',
			showNotification: false,
			showAlert: false,
			notificationCancel: true,
			dataResourceActive: '',
			activeSavedDataCenter: '',

			VALIDATION_RESOURCE_MODAL_KEY,
		};
	},
	computed: {
		discardModalText() {
			if (this.notificationCancel) {
				return this.isEdit ? this.$locale.baseText('Cancelar Edição') : this.$locale.baseText('Cancelar Cadastro');
			} else {
				return this.$locale.baseText('Excluir Recurso');
			}
		},
		keepModalText() {
			if (this.notificationCancel) {
				return this.isEdit ? this.$locale.baseText('Manter Edição') : this.$locale.baseText('Manter Cadastro');
			} else {
				return this.$locale.baseText('Manter Recurso');
			}
		},
		dataCenterEditId() {
			if (this.isEdit) {
				return this.$route.params.id;
			} else {
				return '';
			}
		},
		isEdit() {
			return this.$route.name === 'editDataCenter';
		},
		alertNotificationTitle() {
			return !this.notificationCancel ? 'Exclusão do Recurso' : !this.isEdit ?  'Cancelamento do Cadastro' : 'Cancelamento da Edição';
		},
		alertNotificationDescription() {
			return this.notificationCancel ? 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?' : `Todos as informações do recurso <b>${ this.dataResourceActive.titulo }</b> serão excluídos e você não poderá recuperá-las. O que você deseja fazer?`;
		},

		firstSectionInfo() {
			return 'Para o título da nova <b>Fonte de Dados</b>, recomendamos que utilize o próprio nome do sistema, plataforma, aplicativo ou similar, que os dados estão armazenados.';
		},
		secondSectionInfo() {
			return 'Adicione pelo menos um Recurso. Cada Fluxo pode ser vinculado apenas à um Recurso. Caso queira, você poderá validar o uso o Fluxo clicando no botão Validar.';
		},
		canCreateDataCenter() {
			return (!this.isEdit && this.validate && this.dataCenterValidName != '') || (this.isEdit && this.validate && (this.dataCenterValidName === '' || !!this.dataCenterValidName) && this.dataCenterName != '');
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		selectedWorkflow(data) {
			this.forms[data.item.index].workflowId = data.id;
			this.isValid(data.item.index);
		},
		openModalAlert() {
			this.notificationCancel = true;
			this.showAlert = true;
		},
		goToCreateDataCenter() {
			if(this.$route.name == 'editDataCenter') {
				this.$router.push({
					name: 'createDataCenter',
				});
			} else {
				window.location.reload();
			}
		},
		goToDataCenterDetailing(id) {
			this.$router.push({
				name: 'dataCenterDetailing',
				params: { id },
			});
		},
		goToDataCenterList() {
			this.$router.push({
				name: 'dataSource',
			});
		},
		async validateResource(id) {
			this.isLoadingImport = true;
			try {
				const dataResource = await this.restApi().runWorkflowById(this.workspace.id, id);

				this.$store.dispatch('ui/openValidateResource', { id, data: dataResource });

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e,
					status: 'error',
				});
			};

			this.isLoadingImport = false;
		},

		isValid(index) {
			if (index < 0 || index >= this.forms.length) return false; // retorna false se o índice estiver fora dos limites



			let values = this.forms
			.filter(form => form.titulo !== '').map(form => form.titulo); // diferente de vazio para não considerar inputs vazios duplicatas

			let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index);

			let valuesEmpty = this.forms
			.filter(form => form.titulo == '' || form.workflowId == '');

			let valuesDuplicates = findDuplicates(values);
			let hasDuplicates = valuesDuplicates.length > 0 ? true : false;
			let hasEmptyValue = valuesEmpty.length > 0 ? true : false;
			let thisInputIsValid = !(valuesDuplicates.includes(this.forms[index].titulo));


			if (!hasDuplicates && !hasEmptyValue) {
				this.forms.forEach(form => {
					form.isValid = true;
				});
			} else {
				this.forms[index].isValid = thisInputIsValid;
			}

			this.validate = !hasDuplicates && !hasEmptyValue;
			return !hasDuplicates;
		},

		verifyNameDataCenter:
			_.debounce(async function (e)  {
				if(this.dataCenterName != '' && this.dataCenterName !== this.oldName) {

					const savedEquivalence = await this.restApi().checkAvailableNameDataCenter(this.workspace.id, {	nome: this.dataCenterName });

					if (savedEquivalence) {
						this.dataCenterValidName = false;
					} else {
						this.dataCenterValidName = true;
					}
				} else if (this.isEdit && this.dataCenterName === this.oldName) {
					this.dataCenterValidName = true;
				} else if (this.dataCenterName == '') {
					this.dataCenterValidName = '';
				};
			},
		500),
		async saveDataCenter() {
			try {
				let putDataCenter = '';
				let savedDataCenter = '';
				const payload = {
					titulo: this.dataCenterName,
					descricao: this.message,
					recursos: this.forms,
					workspaceId: this.workspace.id,
				};

				if (this.isEdit) {
					savedDataCenter = await this.restApi().editDataCenter(this.workspace.id, this.dataCenterEditId, payload);
				} else {
					savedDataCenter = await this.restApi().createNewDataCenter(this.workspace.id, payload);
				}

				this.activeSavedDataCenter = savedDataCenter;


				this.showNotification = true;
				//this.validateResource('23123121');
			} catch (error) {
				console.error(error);
			}


		},
		addFormRegister() {
			this.forms.push({titulo: '', workflowId: '', isValid: null});
		},
		openDeleteResourceModal(data) {
			this.dataResourceActive = data;
			this.notificationCancel = false;

			this.showAlert = true;
		},
		removeRow(index) {
			if (this.forms.length > 1) {
				this.forms.splice(index,1);
			}
			this.showAlert = false;
		},

		async loadCurrentDataCenter() {
			this.isLoadingTable = true;
			const vm = this;

			try {
				const currentDataCenter = await this.restApi().getDataCenterById(this.workspace.id, this.dataCenterEditId);
				this.dataCenterName = currentDataCenter.titulo;
				this.oldName = currentDataCenter.titulo;
				this.message = currentDataCenter.descricao;
				this.forms = currentDataCenter.recursos;
				this.validate = true;
				vm.valid = new Array(vm.forms.length).fill(false);
				// Validar inputs
				vm.forms.forEach((_, index) => {
					vm.valid[index] = vm.isValid(index);
				});
				this.isLoadingTable = false;

			} catch (error) {
				this.$router.push({
					name: 'dataSource',
				});
				return;
			}
		},
		async verifyMode() {
			if (this.isEdit) {
				this.loadCurrentDataCenter();
			}
		},
	},
	created() {
		this.verifyMode();
	},
});
