<template>
	<div class="searchBar">
		<input
			class="searchBar--inputFilter"
			type="text"
			:placeholder="$props.placeholder"
			v-model="queryInput"
			@input="searchInfoByName()"
			@focus="inputHasFocus = true"
		>

		<div class="searchBar--container" :class="{'visible': queryInput.length > 0 && inputHasFocus }">

			<!-- Equivalencias -->
			<div class="searchBar--container__item" v-if="$props.filter == 'originValue'">
				<div
					v-if="originValueListSearch.length"
					class="searchBar--container__itemList"
				>
					<div
						v-for="item in originValueListSearch"
						:key="item.id"
						class="searchBar--container__item--link"
					>
						<OptionFilter
							:selected="selectedArray"
							:value="item.id"
							:title="item.valorOrigem"
							@click="selectedOption"
						/>
					</div>
				</div>
				<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
					{{ $locale.baseText('searchBar.equivalences.filters.valueOriginNotFound') }}
				</div>
			</div>

			<div class="searchBar--container__item" v-if="$props.filter == 'equivalentValue'">
				<div
					v-if="equivalentValueListSearch.length"
					class="searchBar--container__itemList"
				>
					<div
						v-for="item in equivalentValueListSearch"
						:key="item.id"
						class="searchBar--container__item--link"
					>
						<OptionFilter
							:selected="selectedArray"
							:value="item.id"
							:title="item.valorEquivalente"
							@click="selectedOption"
						/>
					</div>
				</div>
				<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
					{{ $locale.baseText('searchBar.equivalences.filters.valueEquivalentNotFound') }}
				</div>
			</div>

			<div class="searchBar--container__item" v-if="$props.filter == 'description'">
				<div
					v-if="descriptionListSearch.length"
					class="searchBar--container__itemList"
				>
					<div
						v-for="item in descriptionListSearch"
						:key="item.id"
						class="searchBar--container__item--link"
					>
						<OptionFilter
							:selected="selectedArray"
							:value="item.id"
							:title="item.descricao"
							@click="selectedOption"
						/>
					</div>
				</div>
				<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
					{{ $locale.baseText('searchBar.equivalences.filters.descriptionNotFound') }}
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import mixins from 'vue-typed-mixins';

import { restApi } from '@/components/mixins/restApi';
import OptionFilter from '@/components/OptionFilter';

export default mixins(
	restApi,
).extend({
	components: {
		OptionFilter,
	},
	name: "EquivalenceFilter",
	props: {
		filter: String,
		placeholder: String,
		equivalenceId: '',
	},
	data() {
		return {
			queryInput: '',
			isSearching: false,
			originValueListSearch: [],
			descriptionListSearch: [],
			equivalentValueListSearch: [],
			selectedArray: [],
		};
	},
	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
	},

	methods:{
		selectedOption(id) {
			this.filterArraySelected(id);
			this.$emit('selectFilter', this.selectedArray);
		},
		filterArraySelected(id) {
			let removed = false;
			this.selectedArray.forEach((el, index) => {
				if (el.id === id) {
					removed = true;
					this.selectedArray = this.selectedArray.filter(function(ele){
						return ele.id != id;
					});
				}
			});

			if(!removed) {
				if (this.filter == 'originValue') {
					this.originValueListSearch.forEach(el => {
						if (el.id === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'equivalentValue') {
					this.equivalentValueListSearch.forEach(el => {
						if (el.id === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'description') {
					this.descriptionListSearch.forEach(el => {
						if (el.id === id) {
							this.selectedArray.push(el);
						}
					});
				}
			}
		},

		async searchInfoByName() {
			if (this.queryInput.length > 0) {
				this.isSearching = true;

				const payloadOrigin = {
					searchValorOrigem: this.queryInput,
					size: 9999,
				};
				const payloadEquivalent = {
					searchValorEquivalente: this.queryInput,
					size: 9999,
				};

				const payloadDescription = {
					descricao: this.queryInput,
					size: 9999,
				};

				if (this.filter == 'originValue') {
					try {
						await Promise.all([
							this.restApi().getEquivalenceRegister(this.workspace.id, this.equivalenceId, payloadOrigin),
						]).then((originList) => {
							this.originValueListSearch = originList[0];
						}).catch((err) => {
								console.log(err);
						});
					} catch (e) {
						console.log(e);
					}

				} else if (this.filter == 'equivalentValue') {
					try {
						await Promise.all([
							this.restApi().getEquivalenceRegister(this.workspace.id, this.equivalenceId, payloadEquivalent),
						]).then((equivalentList) => {
							this.equivalentValueListSearch = equivalentList[0];
						}).catch((err) => {
								console.log(err);
						});
					} catch (e) {
						console.log(e);
					}

				} else if (this.filter == 'description') {
					try {
						await Promise.all([
							this.restApi().getEquivalenceRegister(this.workspace.id, this.equivalenceId, payloadDescription),
						]).then((descriptionList) => {
							this.descriptionListSearch = descriptionList[0];
						}).catch((err) => {
								console.log(err);
						});
					} catch (e) {
						console.log(e);
					}
				};

			} else {
				// Limpar o data quando menor que 2 caracteres no input
				this.originValueListSearch = [];
				this.equivalentValueListSearch = [];
				this.descriptionListSearch = [];

				if(this.queryInput.length == 0) {
					this.selectedArray = [];
					this.$emit('selectFilter', this.selectedArray);
				}
			}
			this.isSearching = false;
		},
	},
});
</script>

<style scoped lang="scss">
.searchBar {
	position: absolute;
	right: 10px;
	left: 17PX;
	bottom: -30px;


	* {
		color: rgba(255, 255, 255, 0.88);
		text-decoration: none;
		line-height: 1;
	}

	&--container {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		top: 39px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		background: #223049;
		border: 1px solid #4A638F;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.32);
		border-radius: 4px;
		padding: 16px;
		width: 100%;
		max-height: 369px;
		overflow-y: scroll;


		&.visible {
			visibility: visible;
			opacity: 1;
		}

		&__item {
			cursor: default;

			&--link {
				font-size: 14px;
				text-transform: capitalize;
				min-height: 40px;
				display: flex;
				align-items: center;
				word-break: break-all;
				cursor: pointer;
			}
			&List {
				display: flex;
				flex-direction: column;
			}
			&--notFound {
				min-height: 40px;
				display: flex;
				align-items: center;
				padding-left: 15px;
				padding-top: 16px;
				padding-bottom: 8px;
				font-size: 14px;
				font-style: italic;
				color: rgba(255, 255, 255, 0.56);
			}
			&--title {
				margin: 0;
				font-weight: 400;
				font-size: 16px;
			}
		}
	}
	&--inputFilter {
		width: 100%;
		padding: 9px 40px 9px 9px;
		background: #223049;
		border: 1px solid #4A638F;
		border-radius: 4px;
		outline: none;

		&:focus {
			border: 1px solid #00C3DD;
		}

		&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.56);
			opacity: 1; /* Firefox */
		}
	}
}

</style>
