<template>
	<div>
		<Modal
			:name="CREATE_EDIT_PROJECT_MODAL_KEY"
			:eventBus="modalBus"
			:title="mode == 'create' ? $locale.baseText('project.titleCreate') : $locale.baseText('project.edit.title')"
			width="520px"
			minHeight="224px"
			footerOut
		>
			<template v-slot:content>
				<div class="container-loading" v-if="isLoading">
					<div class="loading-spin"></div>
				</div>

				<div v-else>
					<AliareInput
						required
						:labelText="$locale.baseText('project.edit.titleInputModal')"
						v-model="inputModalName"
						@input="verifyNameProject"
					/>

					<div v-if="messageNameError || messageNameSuccess">
						<p class="message" :class="{ error: messageNameError, success: messageNameSuccess }">
							{{ messageNameError || messageNameSuccess }}
						</p>
						<p class="icon"	:class="{ errorIcon: messageNameError, successIcon: messageNameSuccess }"	/>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<div class="footer-buttons">
					<ModalButtonSuccess
						@click="mode == 'create' ? projectPost() : projectPatch()"
						:active="activeBtn"
						:titleButton="$locale.baseText('modalButtonDefaultSuccess.titleButton')"
						width="111"
					/>

					<ModalButtonCancel
						@click="modalBus.$emit('close')"
						width="111"
					/>
				</div>
			</template>
		</Modal>

		<NotificationPage
			mode="active"
			v-if="showNotification"
		>
			<template v-slot:icon>
				<i class="projectCreateSuccessIcon"></i>
			</template>

			<template v-slot:title>
				{{ notificationPageTitle }}
			</template>
			<template v-slot:firstText>
				<p v-html="notificationPageDescription"></p>
			</template>

			<template v-slot:footer>
				<ModalButtonSuccess
					:titleButton="$locale.baseText('Visualizar Detalhamento do Projeto')"
					width="365"
					@click="gotToProjectDetailing()"
					alwaysActive
					plusIcon
				/>
				<ModalButtonSuccess
					:titleButton="$locale.baseText('Voltar para Projetos Cadastrados')"
					width="365"
					@click="goToProjects()"
					alwaysActive
					redoIcon
				/>
			</template>
		</NotificationPage>
	</div>
</template>

<script>

import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import AliareInput from '@/components/AliareInput.vue';
import Modal from './Modal.vue';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import { showMessage } from '@/components/mixins/showMessage';
import { CREATE_EDIT_PROJECT_MODAL_KEY } from '@/constants';
import { restApi } from '@/components/mixins/restApi';
import WebStorage from '@/common/WebStorage';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';
import Vue from 'vue';

export default mixins(
	restApi,
	showMessage,
).extend({
	name: 'CreateEditProject',
	components: {
		Modal,
		AliareInput,
		NotificationPage,
		ModalButtonCancel,
		ModalButtonSuccess,
	},
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
			modalBus: new Vue(),
			CREATE_EDIT_PROJECT_MODAL_KEY,

			inputModalName: '',
			actualName: '',
			activeBtn: false,
			messageNameSuccess: '',
			messageNameError: '',
			accountInfo: JSON.parse(WebStorage.getItem('accountInfo')),
			tokenData: WebStorage.getItem('tokenData'),
			showNotification: false,
			savedTenantData: '',
			savedProjectName: '',
			responseData: '',
		};
	},
	async created() {
		this.isLoading = true;
		await this.getProjectId();
		this.isLoading = false;
	},
	mounted() {
	},
	computed: {
		notificationPageTitle() {
			return this.mode == 'create' ? 'Projeto Criado' : 'Projeto Alterado';
		},
		notificationPageDescription() {
			return this.mode == 'create' ? `O projeto <b>${ this.inputModalName }</b>, da Empresa <b>${ this.savedTenantData.name }</b> foi criado com sucesso.` : `Projeto editado com sucesso.`
		},
		workspace() {
			return this.$store.getters.workspace;
		},
  },
	methods: {
		...mapActions('aliareAccount', ['getAccountData']),

		async getProjectId() {
			if(this.mode == 'create') return;

			this.inputModalName = this.dataModal.nome;
			this.actualName = this.dataModal.nome;
		},
		verifyNameProject:
		_.debounce(async function (e) {
			if (this.inputModalName === '' || this.inputModalName === this.actualName) {
					this.messageNameSuccess = '';
					this.messageNameError = '';
					this.activeBtn = false;
					return;
			}
			if (this.inputModalName.length <= 3) {
					return;
			}
			const isAvailable = await this.restApi().checkAvailableNameProject(this.workspace.id, {
					nome: this.inputModalName,
			});

			if (!isAvailable) {
					this.messageNameSuccess = this.$locale.baseText('project.edit.inlineProjectNameMessageAvailable', { interpolate: { savedProjectName: this.inputModalName } });
					this.messageNameError = '';
					this.activeBtn = true;
			} else {
					this.messageNameError = this.$locale.baseText('project.edit.inlineProjectNameMessageUnavailable', { interpolate: { savedProjectName: this.inputModalName } });
					this.messageNameSuccess = '';
					this.activeBtn = false;
			}
		},
		500),

		async projectPost() {
			try {
				this.savedTenantData = await this.getAccountData(this.accountInfo.tenantId);
				setupHeaderAliareTenant(this.accountInfo.tenantId);

				const response = await this.restApi().postProject(this.workspace.id, {
					nome: this.inputModalName,
					ativo: true
				});

				this.responseData = response;
				this.showNotification = true;
			} catch (error) {
				const ErrorPost = error?.notifications;
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('project.projectCreateError', { interpolate: { ErrorPost } }),
					status: 'error',
				});
			}
		},
		async projectPatch() {
			try {
				const response = await this.restApi().patchProject(this.workspace.id, this.dataModal.id, {
					nome: this.inputModalName,
					ativo: this.dataModal.ativo
				});

				this.responseData = response;
				this.showNotification = true;
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText(error?.notifications),
					status: 'error',
				});
			}
		},
		gotToProjectDetailing() {
			if(this.$route.name === 'project') {
				this.$router.push({
					name: 'projectDetailing',
					params: { id: this.responseData.id },
				})

			} else if(this.$route.name === 'projectDetailing') {
				this.$root.$emit("reloadDetail");
			}
			this.showNotification = false;
			this.modalBus.$emit('close');
		},
		goToProjects() {
			if(this.$route.name === 'projectDetailing') {
				this.$router.push({ name: 'project' });

			} else {
				this.$root.$emit("reloadProject");
			}
			this.showNotification = false;
			this.modalBus.$emit('close');
		},
	},
});
</script>

<style lang="scss" scoped>
.footer-buttons {
	display: flex;
	margin: 15px;
  float: right;
}
.projectCreateSuccessIcon {
	background-image: url('../assets/projectSuccessIcon.svg');
	height: 140px;
	width: 163px;
	display: block;
}
.message {
	position: absolute;
	font-size: 12px;
	left: 19px;
	overflow-wrap: anywhere;

	&.success {
		color: #59D64C;
	}
	&.error {
		color: #FF495F;
	}
}
.icon {
	position: absolute;
  width: 20px;
  height: 20px;
  right: 28px;
  bottom: 50px;

	&.successIcon {
		background: url('../assets/icon/greenCheckIcon.svg') no-repeat;
	}

	&.errorIcon {
		background: url('../assets/closeIconRed.svg') no-repeat;
	}
}

.container-loading {
	display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 140px;

	.loading-spin {
		width: 120px;
		height: 120px;
		background-image: url('../assets/loading-modal.png');
		background-repeat: no-repeat;
		background-size: cover;
		animation: spin 2s linear infinite;
	}
	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
}

:deep {
	.modal-default-success {
		margin: -1px 20px 0px 0px;
	}

	.modal-content {
		background: #1C2638;
	}
}
</style>

