
import { EXECUTIONS_LIST_MODAL_KEY, RETRY_EXECUTION_MODAL_KEY, PERMISSION_IDS, ATTACHED_EXECUTION_MODAL_KEY } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import SearchUser from '@/components/SearchUser.vue';
import Table from '@/components/Table.vue';
import moment from 'moment';
import WebStorage from '@/common/WebStorage';

import SelectFilter from '@/components/SelectFilter.vue';
import { workflowPermission } from '@/components/mixins/permissions/workflow';
import { executionLogPermission } from '@/components/mixins/permissions/executionLog';

import FilterTable from '@/components/FilterTable.vue';
import DatePicker from '@/components/DatePicker2.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';
import { capitalized, removeItemFromArrayByValue, searchPermissions } from '@/components/helpers';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';

export default mixins(
	restApi,
	workflowHelpers,
	executionLogPermission,
	workflowPermission,
).extend({
	name: 'ExecutionListModal',
	components: {
		Modal,
		ModalButtonCancel,
		ModalButtonSuccess,
		InteractionMenu,
		Table,
		SelectFilter,
		FilterTable,
		DatePicker,
		SearchUser,
		FilterIcon,
		FilterBar,
	},
	data() {
		return {
			modalBus: new Vue(),
			EXECUTIONS_LIST_MODAL_KEY,
			ATTACHED_EXECUTION_MODAL_KEY,
			isLoadingTable: false,
			isLoadingPage: false,
			scrollInfinite: true,
			dataTable: '',
			dataTableTemp: '',
			filtering: false,
			pageSize: 10,
			page: 1,
			applyedFilters: [],
			lastFilterDate: '',
			componentKey: 0,
			interactionMenu__titleHeader: 'executionLog.interactionMenu__titleHeader',
			interactionMenu__headerDropdownPlaceHolder:
				'executionLog.interactionMenu__headerDropdownPlaceHolder',
			interactionMenu__buttonToRegisterVariable:
				'executionLog.interactionMenu__buttonToRegisterVariable',
			interactionMenu__buttonToCleanFilter: 'executionLog.interactionMenu__buttonToCleanFilter',
			tableTitle__1: 'executionLog.tableTitle__lastExecutions',
			tableTitle__2: 'executionLog.tableTitle__durations',
			tableTitle__3: 'executionLog.tableTitle__identifiers',
			tableTitle__4: 'executionLog.tableTitle__results',
			tableTitle__5: 'executionLog.tableTitle__flowsAndTheirProjects',
			tableTitle__6: 'executionLog.tableTitle__actions',
			tableTitle__7: 'executionLog.tableTitle__attached',
			executionLog__tableActionsButtonLeftDropdown: 'executionLog.executionLog__tableActionsButtonLeftDropdown',
			executionLog__tableActionsButtonRightDropdown: 'executionLog.executionLog__tableActionsButtonRightDropdown',
			executionLog__tableActionsRetryExecution: 'executionLog.executionLog__tableActionsRetryExecution',

			permissionIds: PERMISSION_IDS,
			acessDetails: false,
			openExecution: false,
			reprocessExecution: false,
			dataHystory: [],
			data: [],

			queryResults: [],
			queryDate: '',
			filtersActive: {},
			clearfiltering: false,
		};
	},
	computed: {
		workflowId() {
			return this.$route.params.name === undefined ? this.$store.getters.workflowId : this.$route.params.name;
		},
		resultText() {
      const length = this.queryResults.length;
      if (length === 1) {
        return this.queryResults[0];
      } else if (length === 0) {
        return 'Resultados';
      } else {
        return this.queryResults[0] + ' +' + (length - 1);
      }
    },
		intervalDateText() {
			if (this.queryDate === '') {
					return 'Datas e Horários';
			}

			const inicioFormatado = this.queryDate.inicio ? this.formatDate(this.queryDate.inicio) : 'Data Inicial';
			const fimFormatado = this.queryDate.fim ? this.formatDate(this.queryDate.fim) : 'Data Atual';

			return `De: ${inicioFormatado}\nAté: ${fimFormatado}`;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async mounted () {

	},
	async created() {
		this.initPageExecution();
	},
	methods: {
		async initPageExecution() {
			this.isLoadingTable = true;
			await this.getExecutionsLog();
			this.isLoadingTable = false;
		},

		openModalAttachedExecution(dataExecution) {
			this.$store.commit('setActiveExecutionId', dataExecution.id);
			this.modalBus.$emit('close');
			this.$store.dispatch('ui/openModal', ATTACHED_EXECUTION_MODAL_KEY);
		},

		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async filterByResult(query) {
			const replaceQuery = query.map(item => item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '_'))
			
			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', replaceQuery);
			this.filtering = !this.queryResults.length;
		},
		async filterByDate(start, end) {
			if (start === 'Invalid date') start = '';
			if (end === 'Invalid date') end = '';

			this.queryDate = (start === '' && end === '') ? '' : { inicio: start, fim: end };
			this.filtering = (this.queryDate === '');

			this.addOrRemoveFilter(this.filtersActive, 'filterDate', this.queryDate);
		},

		async filterTable(filters) {
			try {
				this.isLoadingTable = true;

				const payload = {
					inicio: filters?.filterDate?.inicio,
					fim: filters?.filterDate?.fim,
					size: 10,
					searchNomeOuId: filters?.filterSearch,
					resultado: filters?.filterResults,
					workflowId: this.workflowId,
					excetoExecucoesAnexadas: false,
				};


				const dataFilter = await this.restApi().getExecutions(this.workspace.id, payload);
				await this.applyFilter(dataFilter, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, filters) {
			this.page = 1;
			this.filtering = true;
			this.clearfiltering = true;
			this.scrollInfinite = true;
			this.dataTable = dataFiltered;
			this.$store.commit('setFilters', filters);
		},

		clearFilter() {
			this.page = 1;
			this.filtering = false;
			this.clearfiltering = false;
			this.filtersActive = {};
			this.queryResults = [];
			this.queryDate = '';
			this.scrollInfinite = true;

			this.$store.commit('setFilters', this.filtersActive);
			this.initPageExecution();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},


		openModalRetryExecution(executionId) {
			this.$store.dispatch("ui/openModal", RETRY_EXECUTION_MODAL_KEY);
			this.$store.commit('setActiveExecutionId', executionId);
		},
		capitalizeString(string) {
			if (!string) return;
			return capitalized(string);
		},
		formatDuration(timestamp) {
			if (timestamp < '00:00:01') {
				return '00h:00min:01s';
			} else {
				let hours = timestamp.substring(0, 2) + 'h:';
				let minutes = timestamp.substring(3, 5) + 'min:';
				let seconds = timestamp.substring(6, 8) + 's';

				return hours + minutes + seconds;
			}
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},

		async loadExecutionsScroll() {
			if (this.dataTable?.length % this.pageSize === 0) {
				this.page++;

				const payload = {
					...this.$store.getters?.filters,
					size: this.pageSize,
					page: this.page,
					workflowId: this.workflowId,
					excetoExecucoesAnexadas: false,
				};
				this.restApi().getExecutions(this.workspace.id, payload).then((response) => {
					for (const index in response) {
						this.dataTable.push(response[index]);
					}
					this.dataTableTemp = this.dataTable;
				});
			} else {
				this.scrollInfinite = false;
			}
		},
		async getExecutionsLog() {
			const payload = {
				size: this.pageSize,
				page: 1,
				workflowId: this.workflowId,
				excetoExecucoesAnexadas: false,
			};
			await this.restApi().getExecutions(this.workspace.id, payload)
				.then((resultDetail) => {
					if (resultDetail.length !== 10) {
						this.scrollInfinite = false;
					}
					this.dataTable = resultDetail;
					this.dataTableTemp = this.dataTable;
				})
				.catch((error) => {
					console.log('error', error);
				})
		},
		accessDetailExecution (id) {
			this.$router.push({
				name: 'executionDetail',
				params: { id },
			});
			this.modalBus.$emit('close');
		},
		displayExecution(executionId: Object, e: PointerEvent) {
			if (e.metaKey || e.ctrlKey) {
				const route = this.$router.resolve({ name: 'ExecutionById', params: { id: executionId } });
				window.open(route.href, '_blank');

				return;
			} else {
				this.$router.push({
					name: 'ExecutionById',
					params: { id: executionId },
				});

				this.modalBus.$emit('close');

			}
		},
	},
	beforeDestroy() {
		this.$store.commit('setFilters', {});
	},
});
