
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import Table from '@/components/Table.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import moment from 'moment';
import FilterBar from '@/components/FilterBar.vue';

import DatePicker from '@/components/DatePicker2.vue';
import QuantityFilter from '@/components/QuantityFilter.vue';
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { executionLogPermission } from '@/components/mixins/permissions/executionLog';
import { removeItemFromArrayByValue, searchPermissions } from '@/components/helpers';

import WebStorage from '@/common/WebStorage';
import { RETRY_EXECUTION_MODAL_KEY, PERMISSION_IDS, ATTACHED_EXECUTION_MODAL_KEY, RESULT_EXECUTION_MODAL_KEY } from '@/constants';

import { mapActions } from 'vuex';
import { previousRoute } from '../router/index.ts';

export default mixins(
	restApi,
	executionLogPermission,
)
.extend({
	components: {
		BackgroundView,
		QuantityFilter,
		InteractionMenu,
		Table,
		SelectFilter,
		DatePicker,
		FilterIcon,
		FilterBar,
	},
	name: 'ExecutionLog',
	data() {
		return {
			isLoadingTable: false,
			isLoadingPage: false,
			scrollInfinite: true,
			componentKey: 0,
			maxheight: '60vh',
			interactionMenu__titleHeader: 'executionLog.interactionMenu__titleHeader',
			interactionMenu__headerDropdownPlaceHolder:
				'executionLog.interactionMenu__headerDropdownPlaceHolder',
			interactionMenu__buttonToRegisterVariable:
				'executionLog.interactionMenu__buttonToRegisterVariable',
			interactionMenu__buttonToCleanFilter: 'executionLog.interactionMenu__buttonToCleanFilter',

			tableTitle__1: 'executionLog.tableTitle__dateAndTime',
			tableTitle__2: 'executionLog.tableTitle__durations',
			tableTitle__3: 'executionLog.tableTitle__identifiers',
			tableTitle__4: 'executionLog.tableTitle__results',
			tableTitle__5: 'executionLog.tableTitle__flowsAndTheirProjects',
			tableTitle__6: 'executionLog.tableTitle__actions',
			tableTitle__7: 'executionLog.tableTitle__attached',

			executionLog__tableActionsButtonLeftDropdown: 'executionLog.executionLog__tableActionsButtonLeftDropdown',
			executionLog__tableActionsButtonRightDropdown: 'executionLog.executionLog__tableActionsButtonRightDropdown',
			executionLog__tableActionsRetryExecution: 'executionLog.executionLog__tableActionsRetryExecution',
			dataTable: '',
			filtering: false,
			pageSize: 10,
			page: 1,
			applyedFilters: [],
			dataTableFilteredById: [],
			dataTableFilteredByName: [],
			lastFilterDate: '',
			lastFilterAttached: '',

			queryDate: {
				inicio: this.$route.query?.inicio || '',
				fim: this.$route.query?.fim || '',
			},
			queryResults: this.$route.query.resultado || [],
			filtersActive: {},
			previousRoute: previousRoute,
			clearfiltering: false,
		};
	},
	async created() {
		await this.initPageExecution();
	},
	computed: {
   	resultText() {
			if (typeof this.queryResults === 'string') {
				return this.queryResults;
			}
			const length = this.queryResults.length;
			return length === 1 ? this.queryResults[0] : length === 0 ? 'Resultados' : `${this.queryResults[0]} +${length - 1}`;
		},

		intervalDateText() {
			if (this.queryDate.inicio == '' && this.queryDate.fim == '') {
					return 'Datas e Horários';
			}

			const inicioFormatado = this.queryDate.inicio ? this.formatDate(this.queryDate.inicio) : 'Data Inicial';
			const fimFormatado = this.queryDate.fim ? this.formatDate(this.queryDate.fim) : 'Data Atual';
			return `De: ${inicioFormatado}\nAté: ${fimFormatado}`;
		},

		resultQuery() {
			if (typeof this.$route.query?.resultado === 'string') {
				return [this.$route.query?.resultado];
			}
			return this.$route.query?.resultado;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		...mapActions('aliareAccount', ['getUserAccountId']),

		openResultModal(dataExecution) {
			if (dataExecution.resultado != 'Falha' && dataExecution.resultado != 'Alerta') {
				return;
			}
			this.$store.dispatch('ui/openModalWithData', { modalKey: RESULT_EXECUTION_MODAL_KEY, data: {executionId: dataExecution.id}});
		},
		async initPageExecution() {
			this.isLoadingPage = true;
			const applyPage = WebStorage.getItem('applyPage');

			if ((this.previousRoute.name == 'executionDetail' || this.previousRoute.name == 'ExecutionById')
				&& applyPage == 'execution' ) {

				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});
				
				this.loadPlaceholders(this.$route.query);
			};
			if (!this.$route.query?.excetoExecucoesAnexadas) {
				this.$route.query.excetoExecucoesAnexadas = true;
			}

			if(Object.keys(this.$route.query).length) {
				this.filterTable();
			} else {
				await this.getExecutionsLog();
			}
			this.isLoadingPage = false;
		},
		loadPlaceholders(query) {
			this.queryDate.inicio = query?.inicio ?? '';
			this.queryDate.fim = query?.fim ?? '';
			this.queryResults = query?.resultado?.map(item => {
				if (item === 'Nao_Armazenado') {
					return 'Não Armazenado';
				} else if (item === 'Nao_Iniciado') {
					return 'Não Iniciado';
				} else {
					return item;
				}
			}) ?? [];
		},
		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async filterByResult(query) {
			const replaceQuery = query.map(item => item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '_'))

			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', replaceQuery);
			this.filtering = !this.queryResults.length;
		},
		async filterByDate(start, end) {
			if (start === 'Invalid date') start = '';
			if (end === 'Invalid date') end = '';

			this.queryDate = (start === '' && end === '') ? '' : { inicio: start, fim: end };
			this.filtering = (this.queryDate === '');

			this.addOrRemoveFilter(this.filtersActive, 'filterDate', this.queryDate);
		},

		async filterTable(filters) {
			try {
				this.isLoadingTable = true;
				this.scrollInfinite = false;

				const routeQuery = this.$route.query;
				const payload = {
					inicio: filters?.filterDate?.inicio || routeQuery?.inicio,
					fim: filters?.filterDate?.fim || routeQuery?.fim,
					size: 10,
					searchNomeOuId: filters?.filterSearch || routeQuery?.searchNomeOuId,
					resultado: filters?.filterResults || routeQuery?.resultado,
					ultimaExecucao: JSON.parse(filters?.ultimaExecucao ?? routeQuery?.ultimaExecucao ?? false),
					excetoExecucoesAnexadas: JSON.parse(filters?.excetoExecucoesAnexadas ?? routeQuery?.excetoExecucoesAnexadas),
					fluxoAtivos: JSON.parse(filters?.fluxoAtivos ?? routeQuery?.fluxoAtivos ?? false),
				};


				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getExecutions(this.workspace.id, payload);
				await this.applyFilter(dataFilter, payload);

			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.page = 1;
			this.filtering = true;
			this.clearfiltering = true;
			this.dataTable = dataFiltered;

			if(this.dataTable.length >= 10) {
				this.scrollInfinite = true;
			}
			WebStorage.setItem('applyPage', 'execution');
			this.$store.commit('setFilters', query);
		},
		async clearFilter() {
			this.page = 1;
			this.filtering = false;
			this.clearfiltering = false;
			this.filtersActive = {};
			this.queryResults = [];
			this.scrollInfinite = true;
			this.queryDate = {
				inicio: '',
				fim: '',
			};

			await this.$router.replace({'query': null}).catch(() => {});

			this.$store.commit('setFilters', this.filtersActive);
			WebStorage.removeItem('applyPage');
			this.initPageExecution();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		openModalAttachedExecution(dataExecution) {
			this.$store.commit('setActiveExecutionId', dataExecution.id);
			this.$store.dispatch('ui/openModal', ATTACHED_EXECUTION_MODAL_KEY);
		},
		openModalRetryExecution(executionId) {
			this.$store.dispatch("ui/openModal", RETRY_EXECUTION_MODAL_KEY);
			this.$store.commit('setActiveExecutionId', executionId);
		},
		async loadExecutionsScroll() {
			if (this.dataTable?.length % this.pageSize === 0) {
				this.page++;
				const payload = { ...this.$route.query, page: this.page };
				this.restApi().getExecutions(this.workspace.id, payload).then((response) => {
					if(!response.length){
						this.scrollInfinite = false;
					}

					for (const index in response) {
						this.dataTable.push(response[index]);
					}
				});
			} else {
				this.scrollInfinite = false;
			}
		},
		async getExecutionsLog() {
			const payload = { ...this.$route.query, page: 1 };
			await this.restApi().getExecutions(this.workspace.id, payload)
				.then((resultDetail) => {
					this.dataTable = resultDetail;
				})
				.catch((error) => {
					console.log('error', error);
				});
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		formatDuration(timestamp) {
			let hours = timestamp?.substring(0, 2) + 'h:';
			let minutes = timestamp?.substring(3, 5) + 'min:';
			let seconds = timestamp?.substring(6, 8) + 's';

			return hours + minutes + seconds;
		},
		displayExecution(executionId: Object, e: PointerEvent) {
			if (e.metaKey || e.ctrlKey) {
				const route = this.$router.resolve({ name: 'ExecutionById', params: { id: executionId } });
				window.open(route.href, '_blank');

				return;
			}

			this.$router.push({
				name: 'ExecutionById',
				params: { id: executionId },
			});
		},
		accessDetailExecution (id) {
			this.$router.push({
				name: 'executionDetail',
				params: { id },
			});
		},
	},
});
