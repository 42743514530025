
import Vue from 'vue';

import ExecutionTime from '@/components/ExecutionTime.vue';
import WorkflowActivator from '@/components/WorkflowActivator.vue';
import Modal from '@/components/Modal.vue';

import { externalHooks } from '@/components/mixins/externalHooks';
import { WAIT_TIME_UNLIMITED, EXECUTIONS_MODAL_KEY } from '@/constants';

import { restApi } from '@/components/mixins/restApi';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import {
	IExecutionsCurrentSummaryExtended,
	IExecutionDeleteFilter,
	IExecutionsListResponse,
	IExecutionShortResponse,
	IExecutionsSummary,
	IWorkflowShortResponse,
} from '@/Interface';

import { convertToDisplayDate } from './helpers';

import { IDataObject } from 'n8n-workflow';

import { range as _range } from 'lodash';

import mixins from 'vue-typed-mixins';

export default mixins(externalHooks, genericHelpers, restApi, showMessage).extend({
	name: 'ExecutionsList',
	components: {
		ExecutionTime,
		WorkflowActivator,
		Modal,
	},
	data() {
		return {
			finishedExecutions: [] as IExecutionsSummary[],
			finishedExecutionsCount: 0,
			finishedExecutionsCountEstimated: false,

			checkAll: false,
			autoRefresh: true,
			autoRefreshInterval: undefined as undefined | NodeJS.Timer,

			filter: {
				status: 'ALL',
				workflowId: 'ALL',
			},

			isDataLoading: false,

			requestItemsPerRequest: 10,

			selectedItems: {} as { [key: string]: boolean },

			stoppingExecutions: [] as string[],
			workflows: [] as IWorkflowShortResponse[],
			modalBus: new Vue(),
			EXECUTIONS_MODAL_KEY,
		};
	},
	async created() {
		await this.loadWorkflows();
		await this.refreshData();
		this.handleAutoRefreshToggle();

		this.$externalHooks().run('executionsList.openDialog');
		this.$telemetry.track('User opened Executions log', {
			workflow_id: this.$store.getters.workflowId,
		});
	},
	beforeDestroy() {
		if (this.autoRefreshInterval) {
			clearInterval(this.autoRefreshInterval);
			this.autoRefreshInterval = undefined;
		}
	},
	computed: {
		statuses() {
			return [
				{
					id: 'ALL',
					name: this.$locale.baseText('executionsList.anyStatus'),
				},
				{
					id: 'error',
					name: this.$locale.baseText('executionsList.error'),
				},
				{
					id: 'running',
					name: this.$locale.baseText('executionsList.running'),
				},
				{
					id: 'success',
					name: this.$locale.baseText('executionsList.success'),
				},
				{
					id: 'waiting',
					name: this.$locale.baseText('executionsList.waiting'),
				},
			];
		},
		activeExecutions(): IExecutionsCurrentSummaryExtended[] {
			return this.$store.getters.getActiveExecutions;
		},
		combinedExecutions(): IExecutionsSummary[] {
			const returnData: IExecutionsSummary[] = [];

			if (['ALL', 'running'].includes(this.filter.status)) {
				returnData.push.apply(returnData, this.activeExecutions);
			}
			if (['ALL', 'error', 'success', 'waiting'].includes(this.filter.status)) {
				returnData.push.apply(returnData, this.finishedExecutions);
			}

			return returnData;
		},
		combinedExecutionsCount(): number {
			return 0 + this.activeExecutions.length + this.finishedExecutionsCount;
		},
		numSelected(): number {
			if (this.checkAll === true) {
				return this.finishedExecutionsCount;
			}

			return Object.keys(this.selectedItems).length;
		},
		isIndeterminate(): boolean {
			if (this.checkAll === true) {
				return false;
			}

			if (this.numSelected > 0) {
				return true;
			}
			return false;
		},
		workflowFilterCurrent(): IDataObject {
			const filter: IDataObject = {};
			if (this.filter.workflowId !== 'ALL') {
				filter.workflowId = this.filter.workflowId;
			}
			return filter;
		},
		workflowFilterPast(): IDataObject {
			const filter: IDataObject = {};
			if (this.filter.workflowId !== 'ALL') {
				filter.workflowId = this.filter.workflowId;
			}
			if (this.filter.status === 'waiting') {
				filter.waitTill = true;
			} else if (['error', 'success'].includes(this.filter.status)) {
				filter.finished = this.filter.status === 'success';
			}
			return filter;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		closeDialog() {
			this.modalBus.$emit('close');
		},
		convertToDisplayDate,
		displayExecution(execution: IExecutionShortResponse, e: PointerEvent) {
			if (e.metaKey || e.ctrlKey) {
				const route = this.$router.resolve({ name: 'ExecutionById', params: { id: execution.id } });
				window.open(route.href, '_blank');

				return;
			}

			this.$router.push({
				name: 'ExecutionById',
				params: { id: execution.id },
			});
			this.modalBus.$emit('closeAll');
		},
		handleAutoRefreshToggle() {
			if (this.autoRefreshInterval) {
				// Clear any previously existing intervals (if any - there shouldn't)
				clearInterval(this.autoRefreshInterval);
				this.autoRefreshInterval = undefined;
			}

			if (this.autoRefresh) {
				this.autoRefreshInterval = setInterval(this.loadAutoRefresh, 4 * 1000); // refresh data every 4 secs
			}
		},
		handleCheckAllChange() {
			if (this.checkAll === false) {
				Vue.set(this, 'selectedItems', {});
			}
		},
		handleCheckboxChanged(executionId: string) {
			if (this.selectedItems[executionId]) {
				Vue.delete(this.selectedItems, executionId);
			} else {
				Vue.set(this.selectedItems, executionId, true);
			}
		},
		async handleDeleteSelected() {
			const deleteExecutions = await this.confirmMessage(
				this.$locale.baseText('executionsList.confirmMessage.message', {
					interpolate: { numSelected: this.numSelected.toString() },
				}),
				this.$locale.baseText('executionsList.confirmMessage.headline'),
				'warning',
				this.$locale.baseText('executionsList.confirmMessage.confirmButtonText'),
				this.$locale.baseText('executionsList.confirmMessage.cancelButtonText'),
			);

			if (deleteExecutions === false) {
				return;
			}

			this.isDataLoading = true;

			const sendData: IExecutionDeleteFilter = {};
			if (this.checkAll === true) {
				sendData.deleteBefore = this.finishedExecutions[0].startedAt as Date;
			} else {
				sendData.ids = Object.keys(this.selectedItems);
			}

			sendData.filters = this.workflowFilterPast;

			try {
				await this.restApi().deleteExecutions(sendData);
			} catch (error) {
				this.isDataLoading = false;
				this.$showError(
					error,
					this.$locale.baseText('executionsList.showError.handleDeleteSelected.title'),
					this.$locale.baseText('executionsList.showError.handleDeleteSelected.message'),
				);

				return;
			}
			this.isDataLoading = false;

			this.$showMessage({
				title: this.$locale.baseText('executionsList.showMessage.handleDeleteSelected.title'),
				message: this.$locale.baseText('executionsList.showMessage.handleDeleteSelected.message'),
				type: 'success',
			});

			Vue.set(this, 'selectedItems', {});
			this.checkAll = false;

			this.refreshData();
		},
		handleFilterChanged() {
			this.refreshData();
		},
		handleRetryClick(commandData: { command: string; row: IExecutionShortResponse }) {
			let loadWorkflow = false;
			if (commandData.command === 'currentlySaved') {
				loadWorkflow = true;
			}

			this.retryExecution(commandData.row, loadWorkflow);
		},
		getRowClass(data: IDataObject): string {
			const classes: string[] = [];
			if ((data.row as IExecutionsSummary).stoppedAt === undefined) {
				classes.push('currently-running');
			}

			return classes.join(' ');
		},
		getWorkflowName(workflowId: string): string | undefined {
			const workflow = this.workflows.find((data) => data.id === workflowId);
			if (workflow === undefined) {
				return undefined;
			}

			return workflow.name;
		},
		async loadActiveExecutions(): Promise<void> {
			const activeExecutions = await this.restApi().getCurrentExecutions(
				this.workflowFilterCurrent,
			);
			for (const activeExecution of activeExecutions) {
				if (
					activeExecution.workflowId !== undefined &&
					activeExecution.workflowName === undefined
				) {
					activeExecution.workflowName = this.getWorkflowName(activeExecution.workflowId);
				}
			}

			this.$store.commit('setActiveExecutions', activeExecutions);
		},
		async loadAutoRefresh(): Promise<void> {
			const filter = this.workflowFilterPast;
			// We cannot use firstId here as some executions finish out of order. Let's say
			// You have execution ids 500 to 505 running.
			// Suppose 504 finishes before 500, 501, 502 and 503.
			// iF you use firstId, filtering id >= 504 you won't
			// ever get ids 500, 501, 502 and 503 when they finish
			const pastExecutionsPromise: Promise<IExecutionsListResponse> =
				this.restApi().getPastExecutions(filter, 30);
			const currentExecutionsPromise: Promise<IExecutionsCurrentSummaryExtended[]> =
				this.restApi().getCurrentExecutions({});

			const results = await Promise.all([pastExecutionsPromise, currentExecutionsPromise]);

			for (const activeExecution of results[1]) {
				if (
					activeExecution.workflowId !== undefined &&
					activeExecution.workflowName === undefined
				) {
					activeExecution.workflowName = this.getWorkflowName(activeExecution.workflowId);
				}
			}

			this.$store.commit('setActiveExecutions', results[1]);

			// execution IDs are typed as string, int conversion is necessary so we can order.
			const alreadyPresentExecutionIds = this.finishedExecutions.map((exec) =>
				parseInt(exec.id, 10),
			);
			let lastId = 0;
			const gaps = [] as number[];
			for (let i = results[0].results.length - 1; i >= 0; i--) {
				const currentItem = results[0].results[i];
				const currentId = parseInt(currentItem.id, 10);
				if (lastId !== 0 && isNaN(currentId) === false) {
					// We are doing this iteration to detect possible gaps.
					// The gaps are used to remove executions that finished
					// and were deleted from database but were displaying
					// in this list while running.
					if (currentId - lastId > 1) {
						// We have some gaps.
						const range = _range(lastId + 1, currentId);
						gaps.push(...range);
					}
				}
				lastId = parseInt(currentItem.id, 10) || 0;

				// Check new results from end to start
				// Add new items accordingly.
				const executionIndex = alreadyPresentExecutionIds.indexOf(currentId);
				if (executionIndex !== -1) {
					// Execution that we received is already present.

					if (
						this.finishedExecutions[executionIndex].finished === false &&
						currentItem.finished === true
					) {
						// Concurrency stuff. This might happen if the execution finishes
						// prior to saving all information to database. Somewhat rare but
						// With auto refresh and several executions, it happens sometimes.
						// So we replace the execution data so it displays correctly.
						this.finishedExecutions[executionIndex] = currentItem;
					}

					continue;
				}

				// Find the correct position to place this newcomer
				let j;
				for (j = this.finishedExecutions.length - 1; j >= 0; j--) {
					if (currentId < parseInt(this.finishedExecutions[j].id, 10)) {
						this.finishedExecutions.splice(j + 1, 0, currentItem);
						break;
					}
				}
				if (j === -1) {
					this.finishedExecutions.unshift(currentItem);
				}
			}
			this.finishedExecutions = this.finishedExecutions.filter(
				(execution) =>
					!gaps.includes(parseInt(execution.id, 10)) && lastId >= parseInt(execution.id, 10),
			);
			this.finishedExecutionsCount = results[0].count;
			this.finishedExecutionsCountEstimated = results[0].estimated;
		},
		async loadFinishedExecutions(): Promise<void> {
			if (this.filter.status === 'running') {
				this.finishedExecutions = [];
				this.finishedExecutionsCount = 0;
				this.finishedExecutionsCountEstimated = false;
				return;
			}
			const data = await this.restApi().getPastExecutions(
				this.workflowFilterPast,
				this.requestItemsPerRequest,
			);
			this.finishedExecutions = data.results;
			this.finishedExecutionsCount = data.count;
			this.finishedExecutionsCountEstimated = data.estimated;
		},
		async loadMore() {
			if (this.filter.status === 'running') {
				return;
			}

			this.isDataLoading = true;

			const filter = this.workflowFilterPast;
			let lastId: string | number | undefined;

			if (this.finishedExecutions.length !== 0) {
				const lastItem = this.finishedExecutions.slice(-1)[0];
				lastId = lastItem.id;
			}

			let data: IExecutionsListResponse;
			try {
				data = await this.restApi().getPastExecutions(filter, this.requestItemsPerRequest, lastId);
			} catch (error) {
				this.isDataLoading = false;
				this.$showError(
					error,
					this.$locale.baseText('executionsList.showError.loadMore.title'),
					this.$locale.baseText('executionsList.showError.loadMore.message') + ':',
				);
				return;
			}

			data.results = data.results.map((execution) => {
				// @ts-ignore
				return { ...execution, mode: execution.mode };
			});

			this.finishedExecutions.push.apply(this.finishedExecutions, data.results);
			this.finishedExecutionsCount = data.count;
			this.finishedExecutionsCountEstimated = data.estimated;

			this.isDataLoading = false;
		},
		async loadWorkflows() {
			try {
				const workflows = await this.restApi().getWorkflows(this.workspace.id);
				workflows.sort((a, b) => {
					if (a.name.toLowerCase() < b.name.toLowerCase()) {
						return -1;
					}
					if (a.name.toLowerCase() > b.name.toLowerCase()) {
						return 1;
					}
					return 0;
				});

				// @ts-ignore
				workflows.unshift({
					id: 'ALL',
					name: this.$locale.baseText('executionsList.allWorkflows'),
				});

				Vue.set(this, 'workflows', workflows);
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('executionsList.showError.loadWorkflows.title'),
					this.$locale.baseText('executionsList.showError.loadWorkflows.message') + ':',
				);
			}
		},
		async retryExecution(execution: IExecutionShortResponse, loadWorkflow?: boolean) {
			this.isDataLoading = true;

			try {
				const retrySuccessful = await this.restApi().retryExecution(execution.id, loadWorkflow);

				if (retrySuccessful === true) {
					this.$showMessage({
						title: this.$locale.baseText('executionsList.showMessage.retrySuccessfulTrue.title'),
						message: this.$locale.baseText(
							'executionsList.showMessage.retrySuccessfulTrue.message',
						),
						type: 'success',
					});
				} else {
					this.$showMessage({
						title: this.$locale.baseText('executionsList.showMessage.retrySuccessfulFalse.title'),
						message: this.$locale.baseText(
							'executionsList.showMessage.retrySuccessfulFalse.message',
						),
						type: 'error',
					});
				}

				this.isDataLoading = false;
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('executionsList.showError.retryExecution.title'),
					this.$locale.baseText('executionsList.showError.retryExecution.message'),
				);

				this.isDataLoading = false;
			}
		},
		async refreshData() {
			this.isDataLoading = true;

			try {
				const activeExecutionsPromise = this.loadActiveExecutions();
				const finishedExecutionsPromise = this.loadFinishedExecutions();
				await Promise.all([activeExecutionsPromise, finishedExecutionsPromise]);
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('executionsList.showError.refreshData.title'),
					this.$locale.baseText('executionsList.showError.refreshData.message') + ':',
				);
			}

			this.isDataLoading = false;
		},
		statusTooltipText(entry: IExecutionsSummary): string {
			if (entry.waitTill) {
				const waitDate = new Date(entry.waitTill);
				if (waitDate.toISOString() === WAIT_TIME_UNLIMITED) {
					return this.$locale.baseText(
						'executionsList.statusTooltipText.theWorkflowIsWaitingIndefinitely',
					);
				}

				return this.$locale.baseText('executionsList.statusTooltipText.theWorkflowIsWaitingTill', {
					interpolate: {
						waitDateDate: waitDate.toLocaleDateString(),
						waitDateTime: waitDate.toLocaleTimeString(),
					},
				});
			} else if (entry.stoppedAt === undefined) {
				return this.$locale.baseText(
					'executionsList.statusTooltipText.theWorkflowIsCurrentlyExecuting',
				);
			} else if (entry.finished === true && entry.retryOf !== undefined) {
				return this.$locale.baseText(
					'executionsList.statusTooltipText.theWorkflowExecutionWasARetryOfAndItWasSuccessful',
					{ interpolate: { entryRetryOf: entry.retryOf } },
				);
			} else if (entry.finished === true) {
				return this.$locale.baseText(
					'executionsList.statusTooltipText.theWorkflowExecutionWasSuccessful',
				);
			} else if (entry.retryOf !== undefined) {
				return this.$locale.baseText(
					'executionsList.statusTooltipText.theWorkflowExecutionWasARetryOfAndFailed',
					{ interpolate: { entryRetryOf: entry.retryOf } },
				);
			} else if (entry.retrySuccessId !== undefined) {
				return this.$locale.baseText(
					'executionsList.statusTooltipText.theWorkflowExecutionFailedButTheRetryWasSuccessful',
					{ interpolate: { entryRetrySuccessId: entry.retrySuccessId } },
				);
			} else if (entry.stoppedAt === null) {
				return this.$locale.baseText(
					'executionsList.statusTooltipText.theWorkflowExecutionIsProbablyStillRunning',
				);
			} else {
				return this.$locale.baseText('executionsList.statusTooltipText.theWorkflowExecutionFailed');
			}
		},
		async stopExecution(activeExecutionId: string) {
			try {
				// Add it to the list of currently stopping executions that we
				// can show the user in the UI that it is in progress
				this.stoppingExecutions.push(activeExecutionId);

				await this.restApi().stopCurrentExecution(activeExecutionId);

				// Remove it from the list of currently stopping executions
				const index = this.stoppingExecutions.indexOf(activeExecutionId);
				this.stoppingExecutions.splice(index, 1);

				this.$showMessage({
					title: this.$locale.baseText('executionsList.showMessage.stopExecution.title'),
					message: this.$locale.baseText('executionsList.showMessage.stopExecution.message', {
						interpolate: { activeExecutionId },
					}),
					type: 'success',
				});

				this.refreshData();
			} catch (error) {
				this.$showError(
					error,
					this.$locale.baseText('executionsList.showError.stopExecution.title'),
					this.$locale.baseText('executionsList.showError.stopExecution.message'),
				);
			}
		},
	},
});
