
import Vue from 'vue';
import mixins from 'vue-typed-mixins';



export default mixins(
).extend({
	name: 'SwitchAliare',
	components: {
	},
	props: {
		doubleValue: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		textLeftToggle: {
			type: String,
			default: 'Sim'
		},
		textRightToggle: {
			type: String,
			default: 'Não'
		},
		value: {
			type: Boolean,
			default: false,
		}

	},
	data() {
		return {
			valueBoolean: JSON.parse(JSON.stringify(this.value)),
		};
	},
	async created() {

	},
	computed: {

	},
	methods: {
		changeValue() {
			if (this.disabled) return;
			this.valueBoolean = !this.valueBoolean;
			this.$emit('change', this.valueBoolean);

		},
	},
	mounted() {
	},
});
