
import Vue from 'vue';
import { NOTIFICATION_MODAL_KEY } from '../constants';
import WebStorage from '@/common/WebStorage';
import { capitalized} from '@/components/helpers';


export default {
	name: 'NotificationPage',



	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			modalBus: new Vue(),
			organizationName: '',
			NOTIFICATION_MODAL_KEY,
		};
	},
	beforeCreate() {
			document.body.className = 'hideScroll';
	},
	beforeDestroy() {
		document.body.className = '';
	},
};
