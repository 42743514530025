import ExecutionService from '../services/executions.service';

export default {
	namespaced: true,

	state: {},

	getters: {},

	actions: {
		async getExecution({ commit }, payload: {size: Number, page: Number}) {
			try {
				const { data } = await ExecutionService.getExecution(payload.size, payload.page);
				commit('setExecution', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
		async getExecutionId({ commit }, id: String) {
			try {
				const { data } = await ExecutionService.getExecutionId(id);
				commit('setExecutionId', { data });
				return Promise.resolve(data);
			} catch (error) {
				console.error(`Erro ao buscar tags.\n${error}`);
			} finally {
				// commit('turnOffProjectDetailsStatus')
			}

			return true;
		},
	},

	mutations: {
		setExecution(state, { Execution }) {
			state.Execution = Execution;
		},
		setExecutionId(state, { ExecutionId }) {
			state.ExecutionId = ExecutionId;
		},
	},
}
