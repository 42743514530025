import { render, staticRenderFns } from "./CardIntegration.vue?vue&type=template&id=49b2bfce&scoped=true&"
import script from "./CardIntegration.vue?vue&type=script&lang=ts&"
export * from "./CardIntegration.vue?vue&type=script&lang=ts&"
import style0 from "./CardIntegration.vue?vue&type=style&index=0&id=49b2bfce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49b2bfce",
  null
  
)

export default component.exports