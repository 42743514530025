
import Vue from 'vue';

import {
	ICredentialsDecryptedResponse,
	ICredentialsResponse,
} from '@/Interface';

import {
	CredentialInformation,
	ICredentialDataDecryptedObject,
	ICredentialNodeAccess,
	ICredentialsDecrypted,
	ICredentialType,
	INodeParameters,
	INodeProperties,
	INodeTypeDescription,
	NodeCredentialTestResult,
	NodeHelpers,
} from 'n8n-workflow';
import CredentialIcon from '../CredentialIcon.vue';

import mixins from 'vue-typed-mixins';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '../mixins/showMessage';

import CredentialConfig from './CredentialConfig.vue';
import CredentialInfo from './CredentialInfo.vue';
import SaveButton from '../SaveButton.vue';
import Modal from '../Modal.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import infoAliare from '@/components/InfoAliare.vue';
import InlineNameEdit from '../InlineNameEdit.vue';
import Alert from '@/components/Alert.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import NotificationPage from '@/components/NotificationPage2.vue';

interface NodeAccessMap {
	[nodeType: string]: ICredentialNodeAccess | null;
}

export default mixins(
	showMessage,
	nodeHelpers,
).extend({
	name: 'CredentialsDetail',
	components: {
		CredentialConfig,
		CredentialIcon,
		CredentialInfo,
		InlineNameEdit,
		Modal,
		Alert,
		SaveButton,
		ModalButtonSuccess,
		ModalButtonCancel,
		infoAliare,
		NotificationPage,
		AlertNotificationModal,
	},
	props: {
		modalName: {
			type: String,
			required: true,
		},
		activeId: {
			type: String,
			required: true,
		},
		mode: {
			type: String,
		},
	},
	data() {
		return {
			activeTab: 'connection',
			authError: '',
			credentialId: '',
			credentialName: '',
			credentialData: {} as ICredentialDataDecryptedObject,
			modalBus: new Vue(),
			nodeAccess: {} as NodeAccessMap,
			isDeleting: false,
			isSaving: false,
			isTesting: false,
			hasUnsavedChanges: false,
			loading: true,
			showValidationWarning: false,
			testedSuccessfully: false,
			isRetesting: false,
			camposPassword: [],
			hasSecretValue: false,
			currentCredential: '',
			credentialTypeName: '',
			credentialType: '',
			nameCredentialAvailable: false,

			showAlert: false,
			showNotification: false,

			savedCredentialName: '',
			idCredential: '',
		};
	},
	async created() {
		this.$root.$on('toggleVisibilityInputCredentials', (inputName) => {
        this.listPassword(inputName);
    });

		this.nodeAccess = this.nodesWithAccess.reduce(
			(accu: NodeAccessMap, node: { name: string }) => {
				if (this.mode === 'new') {
					accu[node.name] = { nodeType: node.name }; // enable all nodes by default
				} else {
					accu[node.name] = null;
				}

				return accu;
			},
			{},
		);

		if (this.mode === 'new') {
			this.loadCredentialTypeName();
			this.loadCredentialType();
			this.credentialName = this.credentialType.displayName;
		} else {
			await this.loadCurrentCredential();
		}

		if (this.credentialType) {
			for (const property of this.credentialType.properties) {
				if (!this.credentialData.hasOwnProperty(property.name)) {
					Vue.set(this.credentialData, property.name, property.default as CredentialInformation);
				}
			}
		}

		this.$externalHooks().run('credentialsEdit.credentialModalOpened', {
			credentialType: this.credentialTypeName,
			isEditingCredential: this.mode === 'edit',
			activeNode: this.$store.getters.activeNode,
		});

		setTimeout(() => {
			if (this.credentialId) {
				if (!this.requiredPropertiesFilled) {
					this.showValidationWarning = true;
				}
				else {
					this.retestCredential();
				}
			}
		}, 0);

		this.loading = false;
		this.verifyHasSecretValue();
	},
	computed: {
		isCredentialTestable (): boolean {
			if (this.isOAuthType || !this.requiredPropertiesFilled) {
				return false;
			}

			const hasExpressions = Object.values(this.credentialData).reduce((accu: boolean, value: CredentialInformation) => accu || (typeof value === 'string' && value.startsWith('=')), false);
			if (hasExpressions) {
				return false;
			}

			const nodesThatCanTest = this.nodesWithAccess.filter(node => {
				if (node.credentials) {
					// Returns a list of nodes that can test this credentials
					const eligibleTesters = node.credentials.filter(credential => {
						return credential.name === this.credentialTypeName && credential.testedBy;
					});
					// If we have any node that can test, return true.
					return !!eligibleTesters.length;
				}
				return false;
			});

			return !!nodesThatCanTest.length;
		},

		// Diferente da funcao 'isCredentialTestable' esse metodo apenas retorna se é possivel testar
		// a credencial, o outro verifica se os campos estao preenchidos
		isCanTest (): boolean {
			const nodesThatCanTest = this.nodesWithAccess.filter(node => {
				if (node.credentials) {
					// Returns a list of nodes that can test this credentials
					const eligibleTesters = node.credentials.filter(credential => {
						return credential.name === this.credentialTypeName && credential.testedBy;
					});
					// If we have any node that can test, return true.
					return !!eligibleTesters.length;
				}
				return false;
			});

			return !!nodesThatCanTest.length;
		},

		nodesWithAccess(): INodeTypeDescription[] {
			if (this.credentialTypeName) {
				return this.$store.getters['credentials/getNodesWithAccess'](
					this.credentialTypeName,
				);
			}

			return [];
		},
		parentTypes(): string[] {
			if (this.credentialTypeName) {
				return this.getParentTypes(this.credentialTypeName);
			}

			return [];
		},
		isOAuthType(): boolean {
			return !!this.credentialTypeName && (
				['oAuth1Api', 'oAuth2Api'].includes(this.credentialTypeName) ||
				this.parentTypes.includes('oAuth1Api') ||
				this.parentTypes.includes('oAuth2Api')
			);
		},
		isOAuthConnected(): boolean {
			return this.isOAuthType && !!this.credentialData.oauthTokenData;
		},
		credentialProperties(): INodeProperties[] {
			if (!this.credentialType) {
				return [];
			}

			return this.credentialType.properties.filter(
				(propertyData: INodeProperties) => {
					if (!this.displayCredentialParameter(propertyData)) {
						return false;
					}
					return (
						!this.credentialType!.__overwrittenProperties ||
						!this.credentialType!.__overwrittenProperties.includes(
							propertyData.name,
						)
					);
				},
			);
		},
		requiredPropertiesFilled(): boolean {
			for (const property of this.credentialProperties) {
				if (property.required !== true) {
					continue;
				}

				if (property.type === 'string' && !this.credentialData[property.name]) {
					return false;
				}

				if (property.type === 'number' && typeof this.credentialData[property.name] !== 'number') {
					return false;
				}
			}
			return true;
		},

		alertNotificationTitle() {
			if(this.isOAuthType && !this.isOAuthConnected){
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose2.headline');

			} else {
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose1.headline');
			}
		},
		alertNotificationDescription() {
			if(this.isOAuthType && !this.isOAuthConnected) {
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose2.message');

			} else if(this.mode === 'new'){
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose1.messageSave');

			} else {
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose1.messageEdit');
			}
		},
		alertNotificationTitleCancel() {
		 if(this.mode === 'new'){
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose1.cancelButtonText');

			}  else {
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose1.cancelButtonEditText');
			}
		},
		alertNotificationTitleConfirm() {
			if(this.isOAuthType && !this.isOAuthConnected) {
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose2.confirmButtonText');

			} else {
				return this.$locale.baseText('credentialEdit.credentialEdit.confirmMessage.beforeClose1.confirmButtonText');
			}
		},

		notificationPageTitle() {
			return this.mode === 'new' ? 'Cadastro de Credencial'
			: 'Edição de Credencial'
		},
		notificationPageDescription() {
			return this.mode === 'new' ? `A Credencial ${ this.savedCredentialName } foi cadastrada com sucesso.`
			: `A Credencial ${ this.savedCredentialName } foi alterada com sucesso.`
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		verifyNameAvailable(nameAvailable) {
			this.nameCredentialAvailable = nameAvailable;
		},
		loadCredentialType() {
			if (!this.credentialTypeName) {
				this.credentialType = null;
			}

			const type = this.$store.getters['credentials/getCredentialTypeByName'](
				this.credentialTypeName,
			);

			this.credentialType = {
				...type,
				properties: this.getCredentialProperties(this.credentialTypeName),
			};
		},
		loadCredentialTypeName() {
			if (this.mode === 'edit') {
				if (this.currentCredential) {
					this.credentialTypeName = this.currentCredential.tipo;
				}
			} else {
				this.credentialTypeName = this.activeId;
			}

		},
		listPassword(inputName) {
			if (this.camposPassword.indexOf(inputName) > -1) {
				this.camposPassword = this.arrayRemove(this.camposPassword, inputName);
			} else {
				this.hasUnsavedChanges = true;
				this.camposPassword.push(inputName)
			};
		},

		arrayRemove(arr, value) {
			return arr.filter(function(ele){
					return ele != value;
			});
		},

		async beforeClose() {
			let keepEditing = false;

			if (this.hasUnsavedChanges) {
				keepEditing = false;
			} else if(this.isOAuthType && !this.isOAuthConnected) {
				keepEditing = false;
			}

			if (!keepEditing) {
				return true;
			}
			else if (!this.requiredPropertiesFilled) {
				this.showValidationWarning = true;
				this.scrollToTop();
			}
			else if (this.isOAuthType) {
				this.scrollToBottom();
			}

			return false;
		},
		async closeDialog() {
			if (this.beforeClose) {
				const shouldClose = await this.beforeClose();
				if (shouldClose === false) {
					// must be strictly false to stop modal from closing
					return;
				} else {
					// Resetar campos Password
					this.camposPassword = [];
				}
			}

			this.modalBus.$emit('close');
			this.$store.commit('ui/closeModal', this.$props.modalName);
		},
		displayCredentialParameter(parameter: INodeProperties): boolean {
			if (parameter.type === 'hidden') {
				return false;
			}

			if (parameter.displayOptions == undefined) {
				// If it is not defined no need to do a proper check
				return true;
			}

			return this.displayParameter(
				this.credentialData as INodeParameters,
				parameter,
				'',
			);
		},
		getCredentialProperties(name: string): INodeProperties[] {
			const credentialsData =
				this.$store.getters['credentials/getCredentialTypeByName'](name);

			if (!credentialsData) {
				throw new Error(
					this.$locale.baseText('credentialEdit.credentialEdit.couldNotFindCredentialOfType') + ':' + name,
				);
			}

			if (credentialsData.extends == undefined) {
				return credentialsData.properties;
			}

			const combineProperties = [] as INodeProperties[];
			for (const credentialsTypeName of credentialsData.extends) {
				const mergeCredentialProperties =
					this.getCredentialProperties(credentialsTypeName);
				NodeHelpers.mergeNodeProperties(
					combineProperties,
					mergeCredentialProperties,
				);
			}

			// The properties defined on the parent credentials take presidence
			NodeHelpers.mergeNodeProperties(
				combineProperties,
				credentialsData.properties,
			);

			return combineProperties;
		},

		async loadCurrentCredential() {
			this.credentialId = this.activeId;

			try {
				const currentCredentials: ICredentialsDecryptedResponse =
					await this.$store.dispatch('credentials/getCredentialData', {
						id: this.credentialId,
						workspaceId: this.workspace.id,
					});
				if (!currentCredentials) {
					throw new Error(
						this.$locale.baseText('credentialEdit.credentialEdit.couldNotFindCredentialWithId') + ':' + this.credentialId,
					);
				}

				this.currentCredential = currentCredentials;
				this.credentialData = currentCredentials.credencialData.data || {};
				this.credentialName = currentCredentials.credencialData.name;
				this.nameCredentialAvailable = true;

				if (currentCredentials.credencialData.nodesAccess !== undefined) {
					currentCredentials.credencialData.nodesAccess.forEach(
						(access: { nodeType: string }) => {
							// keep node access structure to keep dates when updating
							this.nodeAccess[access.nodeType] = access;
						},
					);
				}

				this.loadCredentialTypeName();
				this.loadCredentialType();

			} catch (error) {
				console.log(error)
				this.closeDialog();

				return;
			}
		},
		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
		onNodeAccessChange({name, value}: {name: string, value: boolean}) {
			this.hasUnsavedChanges = true;

			if (value) {
				this.nodeAccess = {
					...this.nodeAccess,
					[name]: {
						nodeType: name,
					},
				};
			} else {
				this.nodeAccess = {
					...this.nodeAccess,
					[name]: null,
				};
			}
		},
		onDataChange({ name, value }: { name: string; value: any }) { // tslint:disable-line:no-any
			this.hasUnsavedChanges = true;
			this.testedSuccessfully = false;

			const { oauthTokenData, ...credData } = this.credentialData;

			this.credentialData = {
				...credData,
				[name]: value,
			};
		},
		verifyHasSecretValue() {
			const data = NodeHelpers.getNodeParameters(
				this.credentialType!.properties,
				this.credentialData as INodeParameters,
				false,
				false,
			);

			for(const [item, value] of Object.entries(data)) {
				if (value == '[SecretValue]') {
					this.hasSecretValue = true;
					this.camposPassword.push(item);
				}
			}
		},

		getParentTypes(name: string): string[] {
			const credentialType =
				this.$store.getters['credentials/getCredentialTypeByName'](name);

			if (
				credentialType == undefined ||
				credentialType.extends == undefined
			) {
				return [];
			}

			const types: string[] = [];
			for (const typeName of credentialType.extends) {
				types.push(typeName);
				types.push.apply(types, this.getParentTypes(typeName));
			}

			return types;
		},

		onNameEdit(text: string) {
			this.nameCredentialAvailable = false;
			this.hasUnsavedChanges = true;
			this.credentialName = text;
		},

		scrollToTop() {
			setTimeout(() => {
				const content = this.$refs.content as Element;
				if (content) {
					content.scrollTop = 0;
				}
			}, 0);
		},

		scrollToBottom() {
			setTimeout(() => {
				const content = this.$refs.content as Element;
				if (content) {
					content.scrollTop = content.scrollHeight;
				}
			}, 0);
		},

		async retestCredential() {
			if (!this.isCredentialTestable) {
				this.authError = '';
				this.testedSuccessfully = false;

				return;
			}

			const nodesAccess = Object.values(this.nodeAccess).filter(
				(access) => !!access,
			) as ICredentialNodeAccess[];

			const details: ICredentialsDecrypted = {
				id: this.credentialId,
				name: this.credentialName,
				type: this.credentialTypeName!,
				data: this.credentialData,
				nodesAccess,
			};

			this.isRetesting = true;
			await this.testCredential(details);
			this.isRetesting = false;
		},

		async testCredential(credentialDetails: ICredentialsDecrypted) {
			const result: NodeCredentialTestResult = await this.$store.dispatch('credentials/testCredential', credentialDetails);
			let payloadAlert = {};
			if (result.status === 'Error') {
				this.authError = result.message;
				this.testedSuccessfully = false;

				payloadAlert = {
					message: this.$locale.baseText('credentialEdit.credentialEdit.failTest'),
					status: 'error',
				};

			}
			else {
				this.authError = '';
				this.testedSuccessfully = true;
				this.hasUnsavedChanges = false;

				payloadAlert = {
					message: this.$locale.baseText('credentialEdit.credentialEdit.successTest'),
					status: 'success',
				};
			}

			this.$store.commit('activeAlert', payloadAlert);
			this.scrollToTop();
		},

		async saveCredential(): Promise<ICredentialsResponse | null> {
			if (!this.requiredPropertiesFilled) {
				this.showValidationWarning = true;
				this.scrollToTop();
			}
			else {
				this.showValidationWarning = false;
			}

			this.isSaving = true;

			const nodesAccess = Object.values(this.nodeAccess).filter(
				(access) => !!access,
			) as ICredentialNodeAccess[];

			// Save only the none default data
			const data = NodeHelpers.getNodeParameters(
				this.credentialType!.properties,
				this.credentialData as INodeParameters,
				false,
				false,
			);

			const credentialDetailsN8n: ICredentialsDecrypted = {
				//id: this.credentialId,
				name: this.credentialName,
				type: this.credentialTypeName!,
				data: data as unknown as ICredentialDataDecryptedObject,
				nodesAccess,
			};

			const credentialDetails: ICredentialsDecrypted = {
				camposPassword: this.camposPassword,
				nome: this.credentialName,
				tipo: this.credentialTypeName!,
				credencialData: credentialDetailsN8n,
			};

			let credential;

			if (this.mode === 'new' && !this.credentialId) {
				credential = await this.createCredential(
					credentialDetails,
				);
			} else {
				credential = await this.updateCredential(
					credentialDetails,
				);
			}

			this.isSaving = false;
			if (credential) {
				this.credentialId = credential.id as string;

				if (this.isCredentialTestable) {
					this.isTesting = true;

					// Add the full data including defaults for testing
					credentialDetailsN8n.data = this.credentialData;

					await this.testCredential(credentialDetailsN8n);
					this.isTesting = false;
				}
				else {
					this.authError = '';
					this.testedSuccessfully = false;
				}
			}

			if (this.$route.name === 'credentials' || this.$route.name === 'credentialDetailing') {
				const { parentTypes, credentialTypeName, mode } = this;

				if (
					(credentialTypeName === 'oAuth2Api' || parentTypes.includes('oAuth2Api')) ||
					(credentialTypeName === 'oAuth1Api' || parentTypes.includes('oAuth1Api'))
				) {
					const successMessageKey = mode === 'new'
						? 'credentialEdit.credentialEdit.successSave'
						: 'credentialEdit.credentialEdit.successModified';

					const payloadAlert = {
						message: this.$locale.baseText(successMessageKey, { interpolate: { savedCredentialName: credential.nome } }),
						status: 'success',
					};

					this.$store.commit('activeAlert', payloadAlert);
				} else if(credential !== null) {
					this.showNotification = true;
				}
			}

			return credential;
		},
		async createCredential(
			credentialDetails: ICredentialsDecrypted,
		): Promise<ICredentialsResponse | null> {
			let credential;
			try {
				credential = (await this.$store.dispatch(
					'credentials/createNewCredential',{
						workspaceId: this.workspace.id,
						data: credentialDetails
					},
				)) as ICredentialsResponse;
				this.hasUnsavedChanges = false;

				this.idCredential = credential.id;
				this.savedCredentialName = credential.nome;

				if(this.$route.name !== 'credentials') {
					const { parentTypes, credentialTypeName } = this;

					const payloadAlert = {
						message: this.$locale.baseText('credentialEdit.credentialEdit.successSave', { interpolate: { savedCredentialName: credential.nome }}),
						status: 'success',
					};
					this.$store.commit('activeAlert', payloadAlert);

					const isOAuthApiCredential = ['oAuth2Api', 'oAuth1Api'].includes(credentialTypeName) ||
        	parentTypes.some(type => ['oAuth2Api', 'oAuth1Api'].includes(type));

					if (!isOAuthApiCredential) {
							this.modalBus.$emit('close');
					}
				}

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications[0],
					status: 'error',
				});

				return null;
			}

			this.$externalHooks().run('credentials.create', {
				credentialTypeData: this.credentialData,
			});

			this.$telemetry.track('User created credentials', { credential_type: credentialDetails.type, workflow_id: this.$store.getters.workflowId });

			return credential;
		},

		async updateCredential(
			credentialDetails: ICredentialsDecrypted,
		): Promise<ICredentialsResponse | null> {

			let credential;
			try {
				credential = (await this.$store.dispatch(
					'credentials/updateCredential',
					{ id: this.credentialId, workspaceId: this.workspace.id, data: credentialDetails },
				)) as ICredentialsResponse;
				this.hasUnsavedChanges = false;

				this.idCredential = credential.id;
				this.savedCredentialName = credential.nome;

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications[0],
					status: 'error',
				});

				return null;
			}

			// Now that the credentials changed check if any nodes use credentials
			// which have now a different name
			this.updateNodesCredentialsIssues();

			return credential;
		},

		async oAuthCredentialAuthorize() {
			let url;

			const credential = await this.saveCredential();
			if (!credential) {
				return;
			}

			const types = this.parentTypes;

			try {
				if (
					this.credentialTypeName === 'oAuth2Api' ||
					types.includes('oAuth2Api')
				) {
					url = (await this.$store.dispatch('credentials/oAuth2Authorize', {
						...this.credentialData,
						id: parseInt(credential.credencialData.id),
					})) as string;
				} else if (
					this.credentialTypeName === 'oAuth1Api' ||
					types.includes('oAuth1Api')
				) {
					url = (await this.$store.dispatch('credentials/oAuth1Authorize', {
						...this.credentialData,
						id: parseInt(credential.credencialData.id),
					})) as string;
				}
			} catch (error) {
				console.log(error);

				return;
			}

			const params = `scrollbars=no,resizable=yes,status=no,titlebar=noe,location=no,toolbar=no,menubar=no,width=500,height=700`;
			const oauthPopup = window.open(url, 'OAuth2 Authorization', params);
			Vue.set(this.credentialData, 'oauthTokenData', null);

			const receiveMessage = (event: MessageEvent) => {
				// // TODO: Add check that it came from n8n
				// if (event.origin !== 'http://example.org:8080') {
				// 	return;
				// }
				if (event.data === 'success') {
					window.removeEventListener('message', receiveMessage, false);

					// Set some kind of data that status changes.
					// As data does not get displayed directly it does not matter what data.
					Vue.set(this.credentialData, 'oauthTokenData', {});
					this.$store.commit('credentials/enableOAuthCredential', credential);

					// Close the window
					if (oauthPopup) {
						oauthPopup.close();
					}
				}
			};

			window.addEventListener('message', receiveMessage, false);

		},

		goToCredential() {
			this.$router.push({
				name: 'credentialDetailing',
				params: { id: this.idCredential },
			}).catch(()=>{});

			this.modalBus.$emit('close');
			this.$store.commit('ui/closeModal', this.$props.modalName);
			this.showNotification = false;
		},

		redirectCredential(){
			this.modalBus.$emit('close');
			this.$store.commit('ui/closeModal', this.$props.modalName);
			this.showNotification = false;
			this.$root.$emit('reloadList');
		},

		openModalAlert() {
			if (this.hasUnsavedChanges || this.isOAuthType && !this.isOAuthConnected) {
				return this.showAlert = true;
			}

			this.modalBus.$emit('close');
			this.$store.commit('ui/closeModal', this.$props.modalName);
			this.$root.$emit('reloadList');
		},
	},

});
