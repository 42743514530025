
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
	props: {
		defaultTimer: {
			type: String,
			default: '',
		},
		clearInput: Boolean,
	},
	data() {
		return {
			selectedTime: null,
		};
	},
	created() {
		this.loadCurrentValues();

	},
	methods: {
		handleTimeChange(newValue) {
			this.$emit('timeSelected', newValue);
    },

		loadCurrentValues() {
			this.selectedTime = this.defaultTimer;
		},

		clearFilter() {
			this.selectedTime = null;
		},
	},
	watch: {
    clearInput(newValue) {
			if (newValue !== true) this.clearFilter();
    },
  },
};
