import { CONTACT_PROMPT_MODAL_KEY, CREDENTIAL_EDIT_MODAL_KEY, DUPLICATE_MODAL_KEY, ATTACHED_EXECUTION_MODAL_KEY, DUPLICATE_WORKFLOW_MODAL_KEY, SELECT_NODE_MODAL_KEY, MANAGER_VARIABLE_MODAL_KEY, VALIDATION_RESOURCE_MODAL_KEY, PERSONALIZATION_MODAL_KEY, SHARE_MODAL_KEY, NOTIFICATION_MODAL_KEY, RETRY_EXECUTION_MODAL_KEY, TAGS_MANAGER_MODAL_KEY, WORKFLOW_OPEN_MODAL_KEY, HISTORY_VERSION_MODAL_KEY, CREATE_EQUIVALENCE_OPTIONS_MODAL, ATTACHED_WORKFLOW_MODAL_KEY, RESULT_EXECUTION_MODAL_KEY, CREDENTIAL_SELECT_MODAL_KEY, EXECUTIONS_LIST_MODAL_KEY, TIMEOUT_EXECUTION_MODAL_KEY, TIMEZONE_SELECT_MODAL_KEY, WORKFLOW_SETTINGS_MODAL_KEY, CREDENTIAL_LIST_MODAL_KEY, RENAME_WORKFLOW_MODAL_KEY, VALUE_SURVEY_MODAL_KEY, EXECUTIONS_MODAL_KEY, WORKFLOW_ACTIVE_MODAL_KEY, CREATE_EDIT_PROJECT_MODAL_KEY, INACTIVE_USER_MODAL_KEY, MODALS_REGISTER_USER_MODAL_KEY, FILTER_PANEL_MODAL_KEY, DEFINE_FAILURE_FLOW_MODAL_KEY, DUPLICATE_PROJECT_MODAL_KEY, IMPORT_INTEGRATION_MODAL_KEY } from '@/constants';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import {
	IRootState,
	IUiState,
} from '../Interface';
import WebStorage from "@/common/WebStorage";
WebStorage.setItem('sidebarMenuCollapsed', true);

const module: Module<IUiState, IRootState> = {
	namespaced: true,
	state: {
		modals: {
			[CONTACT_PROMPT_MODAL_KEY]: {
				open: false,
			},
			[CREDENTIAL_EDIT_MODAL_KEY]: {
				open: false,
				mode: '',
				activeId: null,
			},
			[CREDENTIAL_LIST_MODAL_KEY]: {
				open: false,
			},
			[CREDENTIAL_SELECT_MODAL_KEY]: {
				open: false,
			},
			[RESULT_EXECUTION_MODAL_KEY]: {
				open: false,
			},
			[TIMEZONE_SELECT_MODAL_KEY]: {
				open: false,
			},
			[EXECUTIONS_LIST_MODAL_KEY]: {
				open: false,
			},
			[TIMEOUT_EXECUTION_MODAL_KEY]: {
				open: false,
			},
			[ATTACHED_WORKFLOW_MODAL_KEY]: {
				open: false,
			},
			[CREATE_EQUIVALENCE_OPTIONS_MODAL]: {
				open: false,
			},
			[ATTACHED_EXECUTION_MODAL_KEY]: {
				open: false,
			},
			[SHARE_MODAL_KEY]: {
				open: false,
			},
			[SELECT_NODE_MODAL_KEY]: {
				open: false,
			},
			[VALIDATION_RESOURCE_MODAL_KEY]: {
				open: false,
			},
			[NOTIFICATION_MODAL_KEY]: {
				open: false,
			},
			[HISTORY_VERSION_MODAL_KEY]: {
				open: false,
			},
			[RETRY_EXECUTION_MODAL_KEY]: {
				open: false,
			},
			[DUPLICATE_MODAL_KEY]: {
				open: false,
			},
			[DUPLICATE_WORKFLOW_MODAL_KEY]: {
				open: false,
			},
			[PERSONALIZATION_MODAL_KEY]: {
				open: false,
			},
			[TAGS_MANAGER_MODAL_KEY]: {
				open: false,
			},
			[WORKFLOW_OPEN_MODAL_KEY]: {
				open: false,
			},
			[VALUE_SURVEY_MODAL_KEY]: {
				open: false,
			},
			[WORKFLOW_SETTINGS_MODAL_KEY]: {
				open: false,
			},
			[EXECUTIONS_MODAL_KEY]: {
				open: false,
			},
			[WORKFLOW_ACTIVE_MODAL_KEY]: {
				open: false,
			},
			[MANAGER_VARIABLE_MODAL_KEY]: {
				open: false,
			},
			[RENAME_WORKFLOW_MODAL_KEY]: {
				open: false,
			},
			[CREATE_EDIT_PROJECT_MODAL_KEY]: {
				open: false,
			},
			[INACTIVE_USER_MODAL_KEY]: {
				open: false,
			},
			[MODALS_REGISTER_USER_MODAL_KEY]: {
				open: false,
			},
			[DEFINE_FAILURE_FLOW_MODAL_KEY]: {
				open:false,
			},
			[FILTER_PANEL_MODAL_KEY]: {
				open:false,
			},
			[DUPLICATE_PROJECT_MODAL_KEY]: {
				open: false,
			},
			[IMPORT_INTEGRATION_MODAL_KEY]: {
				open: false,
			}
		},
		modalStack: [],
		sidebarMenuCollapsed: true,
		isPageLoading: true,
	},
	getters: {
		isModalOpen: (state: IUiState) => {
			return (name: string) => state.modals[name].open;
		},
		isModalActive: (state: IUiState) => {
			return (name: string) => state.modalStack.length > 0 && name === state.modalStack[0];
		},
		getModalActiveId: (state: IUiState) => {
			return (name: string) => state.modals[name].activeId;
		},
		getModalMode: (state: IUiState) => {
			return (name: string) => state.modals[name].mode;
		},
		getModalData: (state: IUiState) => {
			return (name: string) => state.modals[name].data;
		},
		getModalOwner: (state: IUiState) => {
			return (name: string) => state.modals[name].owner;
		},
		getModalViewMode: (state: IUiState) => {
			return (name: string) => state.modals[name].viewMode;
		},
		sidebarMenuCollapsed: (state: IUiState): boolean => state.sidebarMenuCollapsed,
	},
	mutations: {
		setMode: (state: IUiState, params: {name: string, mode: string}) => {
			const { name, mode } = params;
			Vue.set(state.modals[name], 'mode', mode);
		},
		setActiveId: (state: IUiState, params: {name: string, id: string|[]}) => {
			const { name, id } = params;
			Vue.set(state.modals[name], 'activeId', id);
		},

		setDataModal: (state: IUiState, params: {name: string, data: object}) => {
			const { name, data } = params;
			Vue.set(state.modals[name], 'data', data);
		},
		setOwner: (state: IUiState, params: {name: string, owner: string}) => {
			const { name, owner } = params;
			Vue.set(state.modals[name], 'owner', owner);
		},
		setViewMode: (state: IUiState, params: {name: string, viewMode: boolean}) => {
			const { name, viewMode } = params;
			Vue.set(state.modals[name], 'viewMode', viewMode);
		},
		openModal: (state: IUiState, name: string) => {
			Vue.set(state.modals[name], 'open', true);
			state.modalStack = [name].concat(state.modalStack);
		},
		closeModal: (state: IUiState, name: string) => {
			Vue.set(state.modals[name], 'open', false);
			state.modalStack = state.modalStack.filter((openModalName: string) => {
				return name !== openModalName;
			});
		},
		closeAllModals: (state: IUiState) => {
			Object.keys(state.modals).forEach((name: string) => {
				if (state.modals[name].open) {
					Vue.set(state.modals[name], 'open', false);
				}
			});
			state.modalStack = [];
		},
		toggleSidebarMenuCollapse: (state: IUiState) => {
			WebStorage.setItem('sidebarMenuCollapsed', !state.sidebarMenuCollapsed);
			state.sidebarMenuCollapsed = !state.sidebarMenuCollapsed;
		},
	},
	actions: {
		openModal: async (context: ActionContext<IUiState, IRootState>, modalKey: string) => {
			context.commit('openModal', modalKey);
		},
		openModalWithData: async (context: ActionContext<IUiState, IRootState>, {modalKey, data}: {modalKey: string, data: object}) => {
			context.commit('openModal', modalKey);
			context.commit('setDataModal', {name: modalKey, data});
		},
	openExisitngCredential: async (context: ActionContext<IUiState, IRootState>, { id }: {id: string}) => {
			context.commit('setActiveId', {name: CREDENTIAL_EDIT_MODAL_KEY, id});
			context.commit('setMode', {name: CREDENTIAL_EDIT_MODAL_KEY, mode: 'edit'});
			context.commit('openModal', CREDENTIAL_EDIT_MODAL_KEY);
		},
		openNewCredential: async (context: ActionContext<IUiState, IRootState>, { type }: {type: string}) => {
			context.commit('setActiveId', {name: CREDENTIAL_EDIT_MODAL_KEY, id: type});
			context.commit('setMode', {name: CREDENTIAL_EDIT_MODAL_KEY, mode: 'new'});
			context.commit('openModal', CREDENTIAL_EDIT_MODAL_KEY);
		},

		openShareModal: async (context: ActionContext<IUiState, IRootState>, { id, mode, owner, viewMode }: {id: [string], mode: string, owner: string, viewMode: boolean}) => {
			context.commit('setActiveId', {name: SHARE_MODAL_KEY, id});
			context.commit('setMode', {name: SHARE_MODAL_KEY, mode});
			context.commit('setViewMode', {name: SHARE_MODAL_KEY, viewMode});
			context.commit('setOwner', {name: SHARE_MODAL_KEY, owner});
			context.commit('openModal', SHARE_MODAL_KEY);
		},

		openValidateResource: async (context: ActionContext<IUiState, IRootState>, { id, data }: {id: string, data: object}) => {
			context.commit('setActiveId', {name: VALIDATION_RESOURCE_MODAL_KEY, id});
			context.commit('setDataModal', {name: VALIDATION_RESOURCE_MODAL_KEY, data});
			context.commit('openModal', VALIDATION_RESOURCE_MODAL_KEY);
		},

		openNotificationModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: {data: object, mode: string}) => {
			context.commit('setDataModal', {name: NOTIFICATION_MODAL_KEY, data});
			context.commit('setMode', {name: NOTIFICATION_MODAL_KEY, mode});
			context.commit('openModal', NOTIFICATION_MODAL_KEY);
		},
		openInactiveModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: {data: object, mode: string}) => {
			context.commit('setDataModal', {name: INACTIVE_USER_MODAL_KEY, data});
			context.commit('setMode', {name: INACTIVE_USER_MODAL_KEY, mode});
			context.commit('openModal', INACTIVE_USER_MODAL_KEY);
		},
		openSettingsExecutionModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: { data: object, mode: string }) => {
			context.commit('setDataModal', { name: DEFINE_FAILURE_FLOW_MODAL_KEY, data });
			context.commit('setMode', { name: DEFINE_FAILURE_FLOW_MODAL_KEY, mode });
			context.commit('openModal', DEFINE_FAILURE_FLOW_MODAL_KEY);
		},
		openDuplicateProjectModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: { data: object, mode: string }) => {
			context.commit('setDataModal', { name: DUPLICATE_PROJECT_MODAL_KEY, data });
			context.commit('setMode', { name: DUPLICATE_PROJECT_MODAL_KEY, mode });
			context.commit('openModal', DUPLICATE_PROJECT_MODAL_KEY);
		},
		openManageVariableModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: { data: object, mode: string }) => {
			context.commit('setDataModal', { name: MANAGER_VARIABLE_MODAL_KEY, data });
			context.commit('openModal', MANAGER_VARIABLE_MODAL_KEY);
		},
		openDuplicateWorkflowModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: { data: object, mode: string }) => {
			context.commit('setDataModal', { name: DUPLICATE_WORKFLOW_MODAL_KEY, data });
			context.commit('setMode', { name: DUPLICATE_WORKFLOW_MODAL_KEY, mode });
			context.commit('openModal', DUPLICATE_WORKFLOW_MODAL_KEY);
		},
		openImportIntegrationModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: { data: object, mode: string }) => {
			context.commit('setDataModal', { name: IMPORT_INTEGRATION_MODAL_KEY, data });
			context.commit('setMode', { name: IMPORT_INTEGRATION_MODAL_KEY, mode });
			context.commit('openModal', IMPORT_INTEGRATION_MODAL_KEY);
		},
		openCreateEditProjectModal: async (context: ActionContext<IUiState, IRootState>, { data, mode }: { data: object, mode: string }) => {
			context.commit('setDataModal', { name: CREATE_EDIT_PROJECT_MODAL_KEY, data });
			context.commit('setMode', { name: CREATE_EDIT_PROJECT_MODAL_KEY, mode });
			context.commit('openModal', CREATE_EDIT_PROJECT_MODAL_KEY);
		},
	},
};

export default module;
