import axios from 'axios';
import { appConfig } from '@/config/appConfig';
import { defaultApi } from '@/api/internalApis';

axios.defaults.withCredentials = appConfig('auth.setCookies');
const execution = 'Execucoes'
class ExecutionService {
	async getExecution(size, page) {
		const res = await defaultApi.get(`/${execution}?size=${size}&page=${page}`);
		return res;
	}
	async getExecutionId(id) {
		const res = await defaultApi.get(`/${execution}/${id}`);
		return res;
	}
	async getExecutionMessage() {
		const res = await defaultApi.get(`/${execution}/Mensagem`);
		return res;
	}
	async getExecutionMessageId(id) {
		const res = await defaultApi.get(`/${execution}/Mensagem/${id}`);
		return res;
	}
}

export default new ExecutionService();
