
import Vue from "vue";

export default Vue.extend({
	name: "ModalRoot",
	props: ["name", "keepAlive"],
	methods: {
		isActive(name: string) {
			return this.$store.getters['ui/isModalActive'](name);
		},
		isOpen(name: string) {
			return this.$store.getters['ui/isModalOpen'](name);
		},
		getMode(name: string) {
			return this.$store.getters['ui/getModalMode'](name);
		},
		getModalOwner(name: string) {
			return this.$store.getters['ui/getModalOwner'](name);
		},
		getModalViewMode(name: string) {
			return this.$store.getters['ui/getModalViewMode'](name);
		},
		getActiveId(name: string) {
			return this.$store.getters['ui/getModalActiveId'](name);
		},
		getData(name: string) {
			return this.$store.getters['ui/getModalData'](name);
		},
	},
});
