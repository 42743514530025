<template>
	<div>
		<Modal
			:name="DUPLICATE_WORKFLOW_MODAL_KEY"
			:eventBus="modalBus"
			:title="$locale.baseText('duplicateWorkflowDialog.duplicateWorkflow')"
			minWidth="868px"
			maxWidth="1302px"
			width="min-content"
			footerOut
		>
			<template v-slot:content>
				<InfoBox>
					<template #content>
						<p>
							A <b>duplicação</b>  deste Fluxos <b>não reaproveitará</b>
							as configurações dos seguintes itens: 1. <b>Conector: Equivalência</b>; 2. <b>Conector: Executar Fluxo</b>;
							3. <b>Credências dos Conectores</b> e 4. <b>Fluxos de Falha</b>. Além disso os <b>valores</b>  das Variáveis também <b>não serão reaproveitados</b>.
						</p>
					</template>
				</InfoBox>

				<div class="container-cards" v-if="!isLoading">

					<div class="step-wrapper" v-if="mode === 'multiAccount'">
						<div class="step-container">
							<div class="step-header step-header--one">
								<div class="title-container">
									<div class="title-number">
										1
									</div>
									<div class="title-text">
										Escolha a Organização de Destino *
									</div>
								</div>

								<div class="searchBar">
									<input
										type="text"
										v-model="searchInput"
										class="searchBar--input"
										@input="filteredOrganization()"
										:placeholder="'Pesquisar uma Organização'"
									>
									<i class="searchBar--input__icon"></i>
								</div>
							</div>

							<div class="step-body">
								<ul class="step-list">
									<li v-for="(item, index) in organizations" :key="index" :class="{ pickedItem: item === picked }">
										<input type="radio" :id="'item-' + index" :name="'item'" :value="item" v-model="picked">
										<div class="container-labels">
											<label :for="'item-' + index">{{ item.description }}</label>

											<label :for="'item-' + index"	v-if="item?.owner?.identificationDocument?.lenght > 11" class="step-text">
												{{ maskCnpj(item?.owner?.identificationDocument) }}
											</label>

											<label :for="'item-' + index" v-else class="step-text">
												{{ maskCpf(item?.owner?.identificationDocument) }}
											</label>
										</div>
									</li>
								<ScrollInfinite v-if="scrollInfinite" @endOfScroll="pagination()" />
								</ul>
							</div>
						</div>
					</div>

					<div class="step-wrapper" v-if="mode === 'multiAccount' && !picked">
						<div class="step-container">
							<div class="step-header step-header--two">
								<div class="title-container">
									<div class="title-number">
										2
									</div>
									<div class="title-text">
										Escolha o Projeto de Destino *
									</div>
								</div>
							</div>

							<div class="step-body">
								<div class="input-body">

									<div v-if="!picked" class="container-infoBox">
										<i class="icon-infoBox"></i>
										<div class="infoBox-message">
											<p>
												Escolha uma <b>Organização</b> para poder visualizar seus  <b>Projeto</b>.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="step-wrapper" v-else>
						<div class="step-container">
							<div class="step-header step-header--one">
								<div class="title-container">
									<div class="title-number">
										{{ mode === 'multiAccount' ? 2 : 1 }}
									</div>
									<div class="title-text">
										Escolha o Projeto de Destino *
									</div>
								</div>

								<div class="searchBar">
									<input
										class="searchBar--input"
										type="text"
										:placeholder="'Pesquisar um Espaço de Trabalho'"
										v-model="searchWorkspace"
									>
									<i class="searchBar--input__icon"></i>
								</div>
							</div>

							<div class="step-body">
								<div class="dropdown__bodyBox" v-if="workspaceFilter.length > 0 && !isLoadingWorkspaces">
									<div class="dropdown__dropdownRowShow" v-for="(dropdown, index) in workspaceFilter" :key="dropdown.id">
										<div class="dropdown__dropdownRow">
											<div
												class="dropdown__dropdownRowTitle"
												@click="triggerDropdown(dropdown), getProjectsDuplicate(dropdown)"
											>
												<div
													class="dropdown__dropdownRowTitleArrowIcon"
													:class="{
														dropdown__dropdownRowTitleActiveArrowIcon: showDropdown === dropdown,
													}"
												></div>
												<div>
													<div class="dropdown__dropdownRowTitleText">
														{{ dropdown.nome }}
													</div>
												</div>
											</div>
										</div>
										<div v-if="showDropdown === dropdown" class="dropdown__dropdownRowShowTable">
											<ul class="step-list dropdown_list" v-if="projects.length > 0 && !isLoadingProjects">
												<li v-for="(project, index) in projects" :key="index" :class="{ pickedItem: project === pickedProject }">
													<input type="radio" :id="'project-' + index" :name="'project'" :value="project" v-model="pickedProject">
													<div class="container-labels">
														<label :for="'project-' + index">{{ project.nome }}</label>
													</div>
												</li>
											</ul>

											<div v-else-if="isLoadingProjects" class="container-loading">
												<div class="loading-spin"></div>
											</div>

											<div v-else class="container-infoBox mini-infoBox">
												<i class="icon-infoBox"></i>
												<div class="infoBox-message">
													<p>
														Nenhum <b>Projeto</b> encontrado.
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-else-if="isLoadingWorkspaces" class="container-loading">
									<div class="loading-spin"></div>
								</div>

								<div v-else class="container-infoBox medium-infoBox">
									<i class="icon-infoBox"></i>
									<div class="infoBox-message">
										<p>
											Não existe <b>Espaço de Trabalho</b> criado na Organização selecionada.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="step-wrapper">
						<div class="step-container">
							<div class="step-header step-header--two">
								<div class="title-container">
									<div class="title-number">
										{{ mode === 'multiAccount' ? 3 : 2 }}
									</div>
									<div class="title-text">
										Defina o Título do Fluxo
									</div>
								</div>
							</div>

							<div class="step-body">
								<div class="input-body">

									<div v-if="!pickedProject" class="container-infoBox">
										<i class="icon-infoBox"></i>
										<div class="infoBox-message">
											<p>
												Selecione o <b>Projeto </b>de destino do Fluxo.
											</p>
										</div>
									</div>

									<AliareInput
										v-else
										required
										:labelText="titleInput"
										v-model="inputModalName"
										@input="verifyNameFlow"
									/>
									<div v-if="messageNameError || messageNameSuccess">
										<p class="messageProject" :class="{ error: messageNameError, success: messageNameSuccess }">
											{{ messageNameError || messageNameSuccess }}
										</p>
										<p class="iconProject"	:class="{ errorIcon: messageNameError, successIcon: messageNameSuccess }"	/>
									</div>

									<div v-if="!duplicateOneWorkflow" class="workflows-preview">
										<span v-for="workflow in dataModal.selectedWorkflows" :key="workflow.id">{{ inputModalName + (inputModalName ? ' - ' : '') +  workflow.nome}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-loading" v-else>
					<div class="loading-spin"></div>
				</div>
			</template>
			<template v-slot:footer>
				<div class="footer-buttons">
				<ModalButtonSuccess
					@click="duplicateWorkflow"
					:active="activeBtn"
					:titleButton="'Concluir'"
					width="111"
				/>

				<ModalButtonCancel
					@click="closeModal"
					width="111"
				/>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import SearchBar from '@/components/SearchBar/SearchBar.vue';
import AliareInput from '@/components/AliareInput.vue';
import InfoBox from '@/components/InfoBox.vue';
import Modal from './Modal.vue';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import { PLACEHOLDER_EMPTY_WORKFLOW_ID, DUPLICATE_WORKFLOW_MODAL_KEY } from '@/constants';
import { restApi } from '@/components/mixins/restApi';
import TokenService from '@/services/token.service';
import WebStorage from '@/common/WebStorage';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';
import Vue from 'vue';
import _Vue from 'vue';

export default mixins(
	restApi,
).extend({
	name: 'DuplicateFlow',
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},
	components: {
		Modal,
		InfoBox,
		SearchBar,
		AliareInput,
		ScrollInfinite,
		ModalButtonCancel,
		ModalButtonSuccess,
	},
	data() {
		return {
			DUPLICATE_WORKFLOW_MODAL_KEY,
			modalBus: new Vue(),
			isLoading: false,
			isLoadingWorkspaces: false,
			isLoadingProjects: false,
			scrollInfinite: false,

			page: 1,
			userId: '',
			picked: null,
			tenantId: '',
			tokenData: '',
			pickedProject: null,

			searchInput: "",
			searchWorkspace: "",

			projects: [],
			workspaces: [],
			accountData: [],
			organizations: [],
			organizationsTemp: [],
			selectedWorkspace: '',

			showDropdown: '',
			activeBtn: false,
			inputModalName: '',
			messageNameError: '',
			messageNameSuccess: '',

			payload: {
				projetoId: '',
				tenantId: '',
				nome: '',
			}
		};
	},
	async created() {
		this.isLoading = true;
		this.tenantId = WebStorage.getItem("tenantId");
		this.tokenData = WebStorage.getItem('tokenData');

		await this.getOrganizations();
		this.getWorkspaces();
		this.isLoading = false;
	},
	mounted() {
	},
	computed: {
		workspaceFilter() {
		return this.workspaces.filter(workspaces => {
			return workspaces.nome?.toLowerCase().includes(this.searchWorkspace.toLowerCase());
		});
    },
		workspace() {
			return this.$store.getters.workspace;
		},
		titleInput() {
			if (this.duplicateOneWorkflow) {
				return 'Titulo do Fluxo';
			} else {
				return 'Prefixo do Workflow';
			}
		},
		duplicateOneWorkflow() {
			if (this.dataModal?.selectedWorkflows && this.dataModal?.selectedWorkflows.length == 1 || this.dataModal?.workflowId) {
				return true;
			} else {
				return;
			}
		},
  },
	watch: {
		picked(newValue, oldValue) {
			this.getWorkspaces();

			if(newValue !== oldValue) {
				this.activeBtn = false;
				this.inputModalName = '';
				this.messageNameSuccess = '';
				this.messageNameSuccess = '';
				this.pickedProject = null;
				this.project = [];
			}
		}
	},
	methods: {
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser', 'getAccountData', 'getAccountTenantV2', 'getAccountTenant', 'getUserPermissionById']),
		...mapActions('project', ['getProjectDetails']),

		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},
		maskCpf(cpf) {
      return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    maskCnpj(cnpj) {
      return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
		async getOrganizations() {
			if(this.mode === 'multiAccount') {
				this.organizations = JSON.parse(WebStorage.getItem('cardOrganization'));
				this.organizations.sort((x, y) => x?.name?.toUpperCase().localeCompare(y?.name?.toUpperCase()));
				this.organizationsTemp = this.organizations;

				if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0' ) {
					this.scrollInfinite = true;
				}
			}
		},
		async getWorkspaces() {
			this.isLoadingWorkspaces = true;
			if (this.mode === 'multiAccount' && this.picked !== null) {
				WebStorage.setItem('tenantId', this.picked.id);
			}

			const response = await this.restApi().getWorkspaces({ size: 300, ativo: true });
			this.workspaces = response?.sort((x, y) => x.nome.localeCompare(y.nome, 'pt-BR', { sensitivity: 'base' }));

			WebStorage.setItem('tenantId', this.tenantId);
			this.isLoadingWorkspaces = false;
		},
		async getProjectsDuplicate(workspace) {
			this.selectedWorkspace = workspace;
			this.isLoadingProjects = true;

			if (this.mode === 'multiAccount' && this.picked !== null) {
				WebStorage.setItem('tenantId', this.picked.id);
			}

			const response = await this.getProjectDetails({workspaceId: workspace.id, size: 300, ativo: true });
			this.projects = response.data.filter(projeto => projeto.permissao === "Editar");
			await this.projects.sort((x, y) => x.nome.localeCompare(y.nome, 'pt-BR', { sensitivity: 'base' }));

			WebStorage.setItem('tenantId', this.tenantId);
			this.isLoadingProjects = false;
		},

		verifyNameFlow:
		_.debounce(async function (e) {
			if (!this.duplicateOneWorkflow) {
				this.activeBtn = true;
				return;
			}
			if(this.picked !== null) {
				WebStorage.setItem('tenantId', this.picked.id);
			}
			if (this.inputModalName !== '') {
				if (this.inputModalName?.length > 1) {
					const search = await this.restApi().checkAvailableNameWorkflows(this.selectedWorkspace.id, this.pickedProject.id, {
						nome: this.inputModalName,
					});

					if (search) {
						this.messageNameError = this.$locale.baseText('Este título já está em uso para esta organização.');
						this.messageNameSuccess = '';
						this.activeBtn = false;

					} else {
						this.messageNameSuccess = this.$locale.baseText('Este título está disponível para esta organização.');
						this.messageNameError = '';
						this.activeBtn = true;
					}
				}
			} else {
				this.messageNameSuccess = '';
				this.messageNameError = '';
				this.activeBtn = false;
			}
			WebStorage.setItem('tenantId', this.tenantId);
		},500),

		duplicateWorkflow() {
			const route = 'duplicateWorkflow';
			const isMultiAccount = this.mode === 'multiAccount' && this.picked !== null;



			try {
				if (this.duplicateOneWorkflow) {
					const payload = {
						projetoId: this.pickedProject.id,
						tenantId: isMultiAccount ? this.picked.id : this.tenantId,
						workspaceId: this.selectedWorkspace.id,
						nome: this.inputModalName,
					};
					const workflowId = this.dataModal.workflowId || this.dataModal.selectedWorkflows[0].id;

					let	workflowDuplicated = this.restApi().duplicateWorkflow(this.workspace.id, workflowId, payload)
					.then((cloneWorkflow) => {
						this.$root.$emit("reloadDetail");
						cloneWorkflow.route = route;
						cloneWorkflow.workspace = this.selectedWorkspace;

						if (isMultiAccount) {
							cloneWorkflow.multi = true;
							cloneWorkflow.account = this.picked;
						} else {
							cloneWorkflow.multi = false;
						}

						this.$root.$emit('closeSelectItemsComponent');
						this.$store.commit('setLoadingData', cloneWorkflow);
						this.showSuccessAlert(cloneWorkflow);

					}).catch((error) => {
						return this.$store.commit('activeAlert', {
							message: `Erro na Duplicação do Fluxo. Tente Novamente.`,
							status: 'error',
						});
					});


				} else {
					const selectedWorkflowsIds = this.dataModal.selectedWorkflows.map(item => item.id);

					let	workflowsDuplicated = this.restApi().duplicateMultipleWorkflow(this.workspace.id, {
						projetoId: this.pickedProject.id,
						tenantId: isMultiAccount ? this.picked.id : this.tenantId,
						workspaceId: this.selectedWorkspace.id,
						workflowIds: selectedWorkflowsIds,
						prefixoWorkflow: this.inputModalName,
					}).then((response) => {
						this.$root.$emit("reloadDetail");

						this.$store.commit('activeAlert', {
							message: `Múltiplos fluxos duplicados com sucesso na conta ${this.picked.description}`,

						});
						this.$root.$emit('closeSelectItemsComponent');

					}).catch((error) => {
						this.$store.commit('activeAlert', {
							message: `Erro na duplicação de múltiplos fluxos da conta ${this.picked.description}. Tente Novamente.`,
							status: 'error',
						});
					});

				}

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('duplicateWorkflowDialog.initDuplicate'),
				});

				this.modalBus.$emit('close');
			} catch(e) {
				this.$store.commit('activeAlert', {
					message: e?.notifications[0],
					status: 'error'
				});
			}
		},
		showSuccessAlert(cloneWorkflow) {
			const message = this.$locale.baseText('duplicateWorkflowDialog.successDuplicate', {
				interpolate: {
					savedWorkflowName: cloneWorkflow.nome,
					ProjectName: this.pickedProject.nome,
					savedAccountName: WebStorage.getItem('accountName'),
				},
			});

			const link = { 
				message: 'Acessar Fluxo', 
				src: `/organizacao/${cloneWorkflow.tenantId}/espacos-de-trabalho/${cloneWorkflow.workspace.id}/fluxo/${cloneWorkflow.id}/Visualizar`,
			};
			this.$store.commit('activeAlert', { message, link });
		},
		closeModal() {
			this.modalBus.$emit('close');
		},
		triggerDropdown(dropdown) {
			if (this.showDropdown == dropdown) {
				this.showDropdown = '';
			} else {
				this.showDropdown = dropdown;
			}
		},

		async pagination() {
			if (this.tokenData.Level === 'N1' || this.tokenData.Level === 'N0') {
				this.page++;
				this.scrollInfinite = true;

				try {
					const response = await this.getAccountTenantV2({ page: this.page, pageSize: 15 });
					const filteredData = response.data.filter(account => account.active && !account.blocked);

					this.organizations.push(...filteredData);
					if (response.data.length < 15) {
						this.scrollInfinite = false;
					}
				} catch (error) {
					console.error('Error fetching data:', error);
				}
			}
		},
		filteredOrganization:
		_.debounce(async function (e) {
			this.scrollInfinite = false;
			const canFilterByPermission = this.tokenData.Level === 'N2' || (this.tokenData.Level !== 'N2' && !this.tokenData.hasPermission);

			if (!this.searchInput.length) {
				this.organizations = this.organizationsTemp;
				this.scrollInfinite = canFilterByPermission ? false : true;

			} else {
				if (canFilterByPermission) {
					this.organizations = this.organizationsTemp?.filter(item => item?.description?.toLowerCase().includes(this.searchInput.toLowerCase()));

				} else {
					this.organizations = [];
					const response = await this.getAccountTenantV2({ search: this.searchInput });
					this.organizations = response.data.filter(item => item.active && !item.blocked);
				}
			}
		}, 500),
	},
	beforeDestroy() {
		this.$store.commit('setWorkflowId', PLACEHOLDER_EMPTY_WORKFLOW_ID);
	},
});
</script>

<style lang="scss" scoped>
.workflows-preview {
	height: 238px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	font-size: 13px;
	margin-top: 33px;
	color: rgba(255, 255, 255, 0.88);
}
.title-container {
  display: flex;
  align-items: center;
	margin: 0 0 10px 0px;

	.title-number {
		border-radius: 50%;
		background-color: rgba(0, 195, 221, 0.16);;
		color: #FFFFFFE0;
		font-size: 16px;
		font-weight: bold;
		width: 28px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1rem;
	}

	.title-text {
		font-size: 16px;
		font-weight: bold;
	}
}

.duplicateModal {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	.duplicateModalIcon {
		background-image: url('../assets/closeIcon.svg');
		background-position: center;
		background-repeat: no-repeat;
		height: 32px;
		width: 32px;
		cursor: pointer;
		border-radius: 3px;
	}
	.duplicateModalIcon:hover {
		border: 1px solid #4a638f;
	}
}

.container-cards {
	display: flex;
  margin: 3px 0px;
	justify-content: space-around;

	.step-wrapper {
		margin: 8px 10px 8px 10px;
		vertical-align: middle;
		align-self: start;

		.step-container {
			min-width: 368px;
			max-width: 401px;
			height: 401px;
			margin: 0px auto;
			background-color: #1c2638;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
			border: 1px solid #34476A;
			border-radius: 4px;

			.step-header {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
				padding: 15px 16px;
				color: rgba(255, 255, 255, 0.88);
				font-family: 'Roboto', sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;

				&--one {
					background-color: #233149;
					border-bottom: 1px solid #34476a;
				}

				&--two {
					background-color: #1c2638;
				}
			}

			.step-body {
				margin: 0px 0px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.step-list {
					color: #FFFFFFE0;
					width: 100%;
					height: 277px;
					padding: 0px 10px 0px 10px;
					overflow: auto;
					font-size: 14px;

					.container-labels {
						display: flex;
						flex-direction: column;

						.step-text {
							font-size: 12px;
							color:#FFFFFFA3;
						}
					}
				}
			}
		}
	}
}

.input-body {
	width: 90%;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 10px 0;
  padding: 10px;
	text-overflow: ellipsis;
  background: #1C2638;
	border: 1px solid #4A638F;
	border-radius: 4px;
	display: flex;
  align-items: center;

	&:hover {
		background: linear-gradient(0deg, rgba(0, 195, 221, 0.08), rgba(0, 195, 221, 0.08)), #1C2638;
		border: 1px solid #00C3DD;
		color: #00C3DD;

		.step-text {
			color: #00C3DD;
		}

		input[type="radio"] {
			appearance: none;
			margin-right: 10px;

			border: 3px solid #00C3DD;
		}

		input[type="radio"]:checked {
			background-image: radial-gradient(#00C3DD 35%, black 40%);
		}
	}
}
.dropdown_list {
	height: 190px !important;

}
.dropdown_list li {
	height: 35px;
}

li.pickedItem {
  background: linear-gradient(0deg, rgba(0, 195, 221, 0.08), rgba(0, 195, 221, 0.08)), #1C2638;
	border: 1px solid #00C3DD;
	color: #00C3DD;

	.step-text {
		color: #00C3DD;
	}

	input[type="radio"] {
		appearance: none;
		margin-right: 10px;
		border: 3px solid #00C3DD;
	}

	input[type="radio"]:checked {
		background-image: radial-gradient(#00C3DD 35%, black 40%);
	}
}

input[type="radio"] {
  appearance: none;
	margin-right: 10px;

  border: 3px solid #749BDE;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  background-color: #1C2638;
}

input[type="radio"]:checked {
  background-image: radial-gradient(#749BDE 35%, black 40%);
}

.searchBar {
	position: relative;
  width: 1012px;
  height: 40px;
	display: flex;
  margin: auto;

	.searchBar--input:focus ~ .searchBar--input__icon {
		background-image: url('../assets/icon/lupaBlue.svg');

		&:before {
			width: 2px;
			background: #00C3DD;
		}
	}
	&--input {
		width: 100%;
		padding: 9px 40px 9px 9px;
		background: #223049;
		border: 1px solid #4A638F;
		border-radius: 4px;
		outline: none;
		color: #ffffff;

		&:focus {
			border: 2px solid #00C3DD;
		}

		&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.56);
			opacity: 1; /* Firefox */
		}

		&__icon {
			background-image: url('../assets/icon/lupa.svg');
			background-size: 100%;
			position: absolute;
			top: 50%;
			right: 9px;
			transform: translateY(-50%);
			width: 18px;
			height: 18px;

			&:before {
				content: '';
				width: 1px;
				height: 16px;
				display: block;
				background: #4A638F;
				position: absolute;
				left: -9px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}

.container-infoBox {
	padding: 14px 8px;
	background: #1C2D69;
	border-radius: 8px;
  min-width: 329px;
	max-width: 367px;
	height: 307px;
	display: flex;
	column-gap: 8px;
	align-items: center;

	* {
		line-height: 19px;
		color: rgba(255, 255, 255, 0.88);
		font-size: 14px;
	}

	p { margin: 0; }

	b {
		font-weight: 700;
	}
	.infoBox-message {
		flex: 1;
	}

	.icon-infoBox {
		background-image: url('../assets/roundWhiteExclamation.svg');
		width: 20px;
		height: 20px;
		margin: 20px;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.medium-infoBox {
	height: 265px;
	margin-top: 13px;
}

.mini-infoBox {
	height: 165px;
	margin-top: 13px;
}

.messageProject {
	position: absolute;
  font-size: 12px;
  overflow-wrap: anywhere;
	&.success {
		color: #59D64C;
	}
	&.error {
		color: #FF495F;
	}
}
.iconProject {
	position: absolute;
  width: 23px;
  height: 20px;
  right: 54px;
  bottom: 310px;
	&.successIcon {
		background: url('../assets/icon/greenCheckIcon.svg') no-repeat;
	}
	&.errorIcon {
		background: url('../assets/icon/alert.svg') no-repeat;
	}
}

.footer-buttons {
	display: flex;
	margin: 15px;
  float: right;
}

.container-loading {
	display: flex;
  align-items: center;
  align-self: center;
	padding: 22px 0px 18px 0px;
  justify-content: center;
	width: 100%;
	height: 277px;

	.loading-spin {
		width: 120px;
		height: 120px;
		background-image: url('../assets/loading-modal.png');
		background-repeat: no-repeat;
		background-size: cover;
		animation: spin 2s linear infinite;
	}
	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
}

:deep {
	.dialog-wrapper .el-dialog {
		width: min-content !important;
	}

	.container-info {
		background: #5C321B;
		padding: 14px 12px;
		* {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
		}
		p {
			margin: 0px 0 0 7px;
		}
		.icon-info {
			background-image: url('../assets/warningIcon.svg');
		}
		.list-duplicate {
			margin: 15px 0 15px 15px;
		}
	}

	.modal-default-success {
		margin: -1px 20px 0px 0px;
	}
}

.dropdown__bodyBox {
	max-height: 288px;
	overflow-y: auto;
	width: 100%;

	.dropdown__dropdownRowShow {
		border-bottom: 1px solid #34476a;
		padding: 16px 0px;

		.dropdown__dropdownRow {
			display: flex;
			justify-content: space-between;
			padding-right: 16px;

			.dropdown__dropdownRowTitle {
				display: flex;
				cursor: pointer;
				align-items: center;
				color: rgba(255, 255, 255, 0.88);

				.dropdown__dropdownRowTitleArrowIcon {
					background-image: url('./../assets/cardArrow.svg');
					background-position: center;
					background-repeat: no-repeat;
					width: 28px;
					height: 28px;
					transform: rotate(90deg);
					margin: 0px 16px 0px 15px;
					transition: 0.2s transform;
				}

				.dropdown__dropdownRowTitleActiveArrowIcon {
					width: 28px;
					height: 28px;
					background: rgba(0, 195, 221, 0.08);
					border-radius: 4px;
					background-image: url('./../assets/arrowIconBlue.svg');
					background-position: center;
					background-repeat: no-repeat;
					margin: 0px 16px 0px 15px;
					transform: rotate(0deg);
				}
				.dropdown__dropdownRowTitleText {
					font-weight: 500;
					font-size: 16px;
					line-height: 19px;
				}
			}
		}

		.dropdown__dropdownRowShowTable {
			margin: 5px 0px 0px 30px;
		}
	}
}
</style>
