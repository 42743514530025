
import Vue from 'vue';
import { NOTIFICATION_MODAL_KEY } from '../constants';
import Modal from './Modal.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import WebStorage from '@/common/WebStorage';
import { capitalized} from '@/components/helpers';


export default {
	name: 'NotificationPage',

	components: {
		ModalButtonCancel,
		ModalButtonSuccess,
		Modal,
	},

	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			modalBus: new Vue(),
			NOTIFICATION_MODAL_KEY,
			organizationName: WebStorage.getItem('accountName'),
		};
	},
	computed: {
		userModal() {
			if (this.mode === 'activeUser' || this.mode === 'inactiveUser' || this.mode == 'registerUserSuccess' || this.mode == 'editedUserSuccess') {
				return true;
			} else {
				return false;
			}
		},
		pageTitleUserMode() {
			if (this.mode === 'registerUserSuccess') {
				return 'Usuário Cadastrado';
			} else if (this.mode == 'inactiveUser') {
				return 'Inativação do Usuário';
			} else if (this.mode == 'activeUser') {
				return 'Usuário Ativado';
			} else {
				return 'Usuário Editado';
			}
		},
		firstPageDescriptionUserMode() {
			if (this.mode === 'registerUserSuccess') {
				return `O usuário <b>${ this.dataModal?.people }</b>, de e-mail <b>${ this.dataModal?.email }</b>, foi cadastrado com sucesso.`;
			} else if (this.mode == 'inactiveUser') {
				return `O usuário <b>${ this.dataModal?.people?.legalName }</b>, de e-mail <b>${ this.dataModal?.email }</b>, foi inativado.`;
			} else if (this.mode == 'activeUser') {
				return `O usuário <b>${ this.dataModal?.people?.legalName }</b>, de e-mail <b>${ this.dataModal?.email }</b>, foi ativado e passou a ter acesso à
							<b>${ this.organizationName }</b>.`;
			} else {
				return `O usuário <b>${ this.dataModal?.nome }</b>, de e-mail <b>${ this.dataModal?.email }</b>, foi editado e seus dados foram atualizados na base de dados da Aliare.`;
			}

		},
		secondPageDescriptionUserMode() {
			if (this.mode === 'registerUserSuccess') {
				return `${ this.dataModal?.people } receberá um e-mail de <b>Primeiro Acesso</b>, com orientações de acesso à <b>Aliare Integra</b>.`;
			} else if (this.mode == 'inactiveUser') {
				return `<b>${ this.dataModal?.people?.legalName }</b> não terá mais acesso à <b>${ this.organizationName }</b>, até que seja ativado novamente. Caso o usuário tenha acesso à outras organizações, ele(a) não perderá suas permissões relacionadas a elas.`;
			} else {
				return '';
			}
		},


		profileModal() {
			if (this.mode == 'registerProfileSuccess' || this.mode == 'inactiveProfile' || this.mode == 'activeProfile' || this.mode == 'changeProfileSuccess') {
				return true;
			} else {
				return false;
			}
		},
		pageTitleProfileMode() {
			if (this.mode === 'registerProfileSuccess') {
				return 'Perfil de Usuário Cadastrado';
			} else if (this.mode == 'inactiveProfile') {
				return 'Perfil de Usuário Inativado';
			} else if (this.mode == 'activeProfile') {
				return 'Perfil de Usuário Ativado';
			} else {
				return 'Perfil de Usuário Modificado';
			}
		},
		firstPageDescriptionProfileMode() {
			if (this.mode === 'registerProfileSuccess') {
				return `O perfil <b>${ this.dataModal.nome }</b> foi cadastrado com sucesso`;
			} else if (this.mode == 'inactiveProfile') {
				return `O perfil de usuário <b>"${this.dataModal.nome}"</b>, foi inativado.`;
			} else if (this.mode == 'activeProfile') {
				return `O perfil de usuário <b>"${this.dataModal.nome}"</b>, foi ativado e passou a ter acesso à <b>${ this.organizationName }</b>.`;
			} else {
				return 'Perfil de Usuário Modificado com sucesso.';
			}

		},
		secondPageDescriptionProfileMode() {
			if (this.mode === 'registerProfileSuccess') {
				return `Visualiza as informações do perfil cadastrado acessando a tela de <b>detalhamento</b>`;
			} else {
				return '';
			}
		},
	},
	async created() {
	},
	mounted() {
	},
	methods: {

		goToCreateUser() {
			this.$router.push({
				name: 'registerUser',
			}).catch(()=>{});

			this.modalBus.$emit('close');
			this.$root.$emit("resetRegister");
		},
		goToUserDetailing() {
			if(this.$route.name == 'userdetailing') {
				window.location.reload();
			} else {
				this.$router.push({
					name: 'userdetailing',
					params: { id: this.dataModal.id },
				}).catch(()=>{});
			}

			this.modalBus.$emit('close');
		},
		goToUsers() {
			this.$router.push({
				name: 'users',
			}).catch(()=>{});

			this.modalBus.$emit('close');
		},
		goToCreateProfile() {
			this.$router.push({
				name: 'createProfile',
			}).catch(()=>{});

			this.modalBus.$emit('close');

		},
		goToProfileDetailing() {
			if(this.$route.name == 'profileDetailing') {
				window.location.reload();
			} else {
				this.$router.push({
					name: 'profileDetailing',
					params: { permissionId: this.dataModal.permissionId },
				}).catch(()=>{});
			}

			this.modalBus.$emit('close');
		},

		goToProfiles() {
			this.$router.push({
				name: 'user-profiles',
			}).catch(()=>{});

			this.modalBus.$emit('close');

		},
  },
	beforeDestroy() {
		this.$store.commit('setUserData', {});
		this.$store.commit('setUserModalName', 'empty');
	},
};
