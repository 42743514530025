import { IRestApiContext, IWorkflowTemplate } from '@/Interface';
import WebStorage from "@/common/WebStorage";
import { makeRestApiRequest, get } from './helpers';
import { N8N_IO_BASE_URL } from '@/constants';

export async function getNewWorkflow(context: IRestApiContext, name?: string) {
	return await makeRestApiRequest(context, 'GET', `/workflows/new`, name ? { name } : {});
}
export async function getNameWorkflow(context: IRestApiContext, projetoId?: string, name?: string) {
	return await makeRestApiRequest(context, 'GET', `Workspaces/${JSON.parse(WebStorage.getItem('workspace')).id}/Workflows/${projetoId}/VerificarNomeDisponivel`, {
		Nome: name,
	});
}

export async function getWorkflowTemplate(templateId: string): Promise<IWorkflowTemplate> {
	return await get(N8N_IO_BASE_URL, `/workflows/templates/${templateId}`);
}
