
import { VALID_EMAIL_REGEX, VALUE_SURVEY_MODAL_KEY } from '@/constants';
import { IN8nPromptResponse } from '@/Interface';

import ModalDrawer from './ModalDrawer.vue';

import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import Vue from 'vue';

const DEFAULT_TITLE = `How likely are you to recommend n8n to a friend or colleague?`;
const GREAT_FEEDBACK_TITLE = `Great to hear! Can we reach out to see how we can make n8n even better for you?`;
const DEFAULT_FEEDBACK_TITLE = `Thanks for your feedback! We'd love to understand how we can improve. Can we reach out?`;

export default mixins(workflowHelpers).extend({
	name: 'ValueSurvey',
	props: ['isActive'],
	components: {
		ModalDrawer,
	},
	watch: {
		isActive(isActive) {
			if (isActive) {
				this.$telemetry.track('User shown value survey', {
					instance_id: this.$store.getters.instanceId,
				});
			}
		},
	},
	computed: {
		getTitle(): string {
			if (this.form.value !== '') {
				if (Number(this.form.value) > 7) {
					return GREAT_FEEDBACK_TITLE;
				} else {
					return DEFAULT_FEEDBACK_TITLE;
				}
			} else {
				return DEFAULT_TITLE;
			}
		},
		isEmailValid(): boolean {
			return VALID_EMAIL_REGEX.test(String(this.form.email).toLowerCase());
		},
	},
	data() {
		return {
			form: {
				email: '',
				value: '',
			},
			showButtons: true,
			VALUE_SURVEY_MODAL_KEY,
			modalBus: new Vue(),
		};
	},
	methods: {
		closeDialog(): void {
			if (this.form.value === '') {
				this.$telemetry.track('User responded value survey score', {
					instance_id: this.$store.getters.instanceId,
					nps: '',
				});
			} else {
				this.$telemetry.track('User responded value survey email', {
					instance_id: this.$store.getters.instanceId,
					email: '',
				});
			}
		},
		onInputChange(value: string) {
			this.form.email = value;
		},
		async selectSurveyValue(value: string) {
			this.form.value = value;
			this.showButtons = false;

			const response: IN8nPromptResponse = await this.$store.dispatch(
				'settings/submitValueSurvey',
				{ value: this.form.value },
			);

			if (response.updated) {
				this.$telemetry.track('User responded value survey score', {
					instance_id: this.$store.getters.instanceId,
					nps: this.form.value,
				});
			}
		},
		async send() {
			if (this.isEmailValid) {
				const response: IN8nPromptResponse = await this.$store.dispatch(
					'settings/submitValueSurvey',
					{
						email: this.form.email,
						value: this.form.value,
					},
				);

				if (response.updated) {
					this.$telemetry.track('User responded value survey email', {
						instance_id: this.$store.getters.instanceId,
						email: this.form.email,
					});
					this.$showMessage({
						title: 'Thanks for your feedback',
						message: `If you’d like to help even more, answer this <a target="_blank" href="https://n8n-community.typeform.com/quicksurvey#nps=${this.form.value}&instance_id=${this.$store.getters.instanceId}">quick survey.</a>`,
						type: 'success',
						duration: 15000,
					});
				}

				setTimeout(() => {
					this.form.value = '';
					this.form.email = '';
					this.showButtons = true;
				}, 1000);
				this.modalBus.$emit('close');
			}
		},
	},
});
