
import { nodeHelpers } from '@/components/mixins/nodeHelpers';

import moment from 'moment';
import NodeIcon from '@/components/NodeIcon.vue';
import WebStorage from '@/common/WebStorage';
import { capitalized} from '@/components/helpers';

import mixins from 'vue-typed-mixins';

export default mixins(
 nodeHelpers,
).extend({
	name: 'CardIntegration',
	components: {
		NodeIcon,
	},
	props: {
		cardData: {
			type: Object,
		},
	},

	data() {
		return {
			formIsOpen: false,
		};
	},
	computed: {
		solicitacaoAprovada() {
			return this.cardData.statusUltimaSolicitacao == 'Aprovado' ? true : false;
		},
		solicitacaoReprovada() {
			return this.cardData.statusUltimaSolicitacao == 'Reprovado' ? true : false;
		},
		integracaoImportada() {
			return this.cardData.statusUltimaSolicitacao == 'Importado' ? true : false;
		},
		solicitacaoAguardando() {
			return this.cardData.statusUltimaSolicitacao == 'Solicitado' ? true : false;
		},
	},
	async created() {
		if (this.cardData?.conectores.length > 0) {
			await this.loadNodesOfIntegration(this.cardData?.conectores);
		}
	},
	methods: {
		async loadNodesOfIntegration(nodes) {
			if (!nodes) return;
			let nodesList = [];
			nodes.forEach(node => {
				let nodeData = this.$store.getters.getAllNodeByName(node);
				nodesList.push(nodeData);
			});

			this.cardData.conectores = nodesList;
		},
		async openImportIntegration(integrationId) {
			const data = await this.restApi().getIntegrationById(integrationId);
			const mode = data?.projetos.length <= 1 ? 'unique' : 'multiple';
			this.$store.dispatch('ui/openImportIntegrationModal', {	data, mode });
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY');
			return localDate;
		},
		goToIntegration(card) {
			this.$router.push({
				name: 'knowMoreIntegration',
				params: { id: card.integracaoId },
			});
		},
	},

});
