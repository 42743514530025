<template>
	<div class="wrapper">
		<div v-show="!isLoading" class="card card-container">
			<alert v-if="showAlert" :error="true">
				{{ message }}
			</alert>
			<img id="logo-img" src="../assets/logo.svg" class="logo-img-card" />
			<img id="solucao-img" src="../assets/solucao_aliare.svg" class="solucao-img-card" />
			<p>Acessar Plataforma</p>
			<b-form :validation-schema="schema" @keyup.enter="handleLogin">
				<div class="label-float form-group">
					<b-form-group>
						<b-form-input
							data-testid="input-username"
							autofocus
							id="username"
							name="username"
							type="email"
							placeholder=""
							autocomplete="off"
							v-model.trim="$v.form.email.$model"
							:value.trim="$v.form.email.$model"
							@change="validateEmail"
							@focusin="validateEmail"
							:state="getValidation('email')"
						>
						</b-form-input>
						<label for="username">{{ $locale.baseText('login.username') }}</label>
						<b-form-invalid-feedback id="username-feedback">
							{{ $locale.baseText('login.alertEmailInvalid') }}
						</b-form-invalid-feedback>
					</b-form-group>
				</div>

				<div class="label-float form-group">
					<b-form-group :state="getValidation('password')">
						<b-form-input
							data-testid="input-password"
							name="password"
							type="password"
							id="password"
							placeholder=""
							v-model.trim="$v.form.password.$model"
							:state="getValidation('password')"
						>
						</b-form-input>
						<input type="checkbox" id="input_1_0" class="input_1_" v-on:click="show()" />
						<label for="password">{{ $locale.baseText('login.password') }}</label>
						<b-form-invalid-feedback id="password-feedback">
							{{ $locale.baseText('login.alertPasswordIncorrect') }}.
						</b-form-invalid-feedback>
					</b-form-group>
				</div>

				<div class="pos-input">
					<label class="container1">
						Guardar Acesso
						<input type="checkbox" v-model="keepAccess" id="guardar" />
						<span class="checkmark"></span>
					</label>

					<label class="container1 esqueceu">
						<router-link to="/recoverpassword" class="nav-link"> Esqueceu sua senha? </router-link>
					</label>
				</div>
				<div class="form-group form-group-btn">
					<div
						class="btn btn-primary btn-block"
						:disabled="true"
						style="pointer-events: none"
						@click="handleLogin"
					>
						<span v-show="loading" class="spinner-border spinner-border-sm"></span>
						<span v-show="!loading">{{ $locale.baseText('login.loginButton') }}</span>
					</div>
				</div>
			</b-form>
		</div>
		<loading v-show="isLoading" />
	</div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import * as yup from 'yup';
import { mapActions, mapGetters } from 'vuex';
import WebStorage from '@/common/WebStorage';
import Loading from '@/components/Loading.vue';
import { searchPermissions } from '@/components/helpers';


import { appConfig } from '@/config/appConfig';
import OidcService from '@/services/oidc.service';
import Alert from '@/components/Alert.vue';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import TokenService from '@/services/token.service';
import { hasPermission } from '../components/helpers';

export default {
	components: {
		Alert,
		Loading,
	},
	async created() {
		await OidcService.signinSilent().then(async (result) => {
			this.loginByOidc = true;

			TokenService.setTokens(result);
			const token = TokenService.getTokens();
			this.userId = this.parseJwt(token.access_token);

			if(this.userId.Level === 'N2') {
				await this.getAccountId();

			} else {
				await this.verifyPermission();
			}
		}).catch(async () => {
			await OidcService.signinRedirect();
		});
	},
	data() {
		const schema = yup.object().shape({
			username: yup.string().required(this.$locale.baseText('validations.requiredUsername')),
			password: yup.string().required(this.$locale.baseText('validations.requiredPassword')),
		});

		return {
			showAlert: false,
			error: '',
			message: '',
			form: {
				email: '',
				password: '',
			},
			schema,
			dismissSecs: 500000000000,
			dismissCountDown: 0,
			show_Alert: false,
			loginByOidc: false,
			keepAccess: false,
			account: [],
			dataPermission: [],
			listPermission: [],
			accountData: [],
			isLoading: false,
			userId: '',
		};
	},
	validations: {
		form: {
			email: {
				required,
				email,
			},

			password: {
				required,
				minLength: minLength(8),
			},
		},
	},

	computed: {
		...mapGetters('auth', ['authenticatingStatus', 'loggedIn']),

		loading() {
			return this.authenticatingStatus;
		},
	},

	mounted() {
		this.isLoading = true;
		this.$v.form.email.$model = WebStorage.getItem('loginEmail');
		this.keepAccess = WebStorage.getItem('keepAccess');
	},

	methods: {
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser', 'getAccountData', 'getAccountTenant', 'getUserPermissionById']),
		...mapActions('auth', ['authenticateUser', 'getUserInfo', 'revokeTokens']),

		handleLogin() {
			const user = {
				username: this.$v.form.email.$model,
				password: this.$v.form.password.$model,
			};

			if (this.keepAccess) {
				this.handleLoginConfig(user, user.username);
			} else {
				this.handleLoginConfig(user, '');
			}
		},

		async handleLoginConfig(user, username) {
			this.isLoading = true;
			this.authenticateUser(user)
				.then(() => {
					if (username === '') {
						if (user.username === WebStorage.getItem('loginEmail')) {
							WebStorage.setItem('loginEmail', '');
							WebStorage.setItem('keepAccess', false);
						}
					} else {
						// eslint-disable-next-line no-lonely-if
						if (user.username !== WebStorage.getItem('loginEmail')) {
							WebStorage.setItem('loginEmail', user.username);
							WebStorage.setItem('keepAccess', true);
						}
					}
				})
				.then(() => {
					const token = TokenService.getTokens();
					this.userId = this.parseJwt(token.access_token);

					if(this.userId.Level === 'N2') {
						this.getAccountId();

					} else {
						this.verifyPermission();
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.errorLogin'),
						status: 'error',
					});
				});
		},

		async getAccountId() {
			this.getUserAccountId(this.userId.sub)
			.then((resultUserAccount) => {
				this.data = [];
				this.accountData = [];
				this.dataPermission = [];
				this.listPermission = [];

				for (const index in resultUserAccount.data) {
					if (resultUserAccount.data[index].active === true && resultUserAccount.data[index].blocked === false) {
						this.accountData.push(resultUserAccount.data[index]);
					}
				}
				if (this.accountData.length === 0) {
					this.isLoading = false;
					this.logOut();

					return this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.noPermission'),
						status: 'error',
					});
				} else {
					this.verifyAccount();
				}
			})
		},

		async verifyAccount() {
			if (this.accountData.length === 0) {
				this.isLoading = false;
				this.logOut();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			} else if (this.accountData.length >= 2) {
				this.getMultipleAccounts();
			} else {
				this.verifyPermission();
			}
		},

		async getMultipleAccounts() {
			const multiAccount = [];

			try {
				for (const index in this.accountData) {
					await this.getAccountData(this.accountData[index]?.tenantId)
					.then((response) => {
						response.account = this.accountData[index];

						if(response.active && !response.blocked) {
							multiAccount.push(response);
						}
					});
				}
				const activeAccounts = await this.verifyOrganizationsActive(multiAccount);

				WebStorage.setItem('tokenData', this.userId);
				WebStorage.setItem('cardOrganization', JSON.stringify(activeAccounts));
				this.$router.push('/organizacao');
				this.verifyOriginAndAlert();
				this.isLoading = false;

			} catch (e) {
				this.isLoading = false;

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('Ocorreu um erro ao carregar as organizações tente novamente em alguns instantes'),
					status: 'error',
				});
			}
		},

		async verifyOrganizationsActive(orgs, pushValue) {
			if (!orgs.length) return;

			const actualTenant = WebStorage.getItem('tenantId');
			let orgsVerified = [];
			let orgsActive = [];

			for (const organization of orgs) {
				WebStorage.setItem('tenantId', organization.id);
				const response = await this.getUserPermissionById({ userId: organization.account.userId });
				const indexUser = orgs.findIndex(orgList => orgList.account.userId === organization.account.userId && orgList.account.tenantId === organization.id);
				orgsVerified.push(orgs[indexUser]);

				const activePermission = response.some(permission => !permission.userProfile.blocked && permission.userProfile.active);
				orgs[indexUser].activePermission = activePermission;

				if (!activePermission) {
					orgsVerified.pop(); // Remove a organização se activePermission for false
				}
			}

			if (pushValue) {
				orgsActive.push(...orgsVerified);
			} else {
				orgsActive = orgsVerified;
			}
			WebStorage.setItem('tenantId', actualTenant);

			return orgsActive;
		},

		async verifyPermission() {
			let userIds = '';
			let tenantId = '';

			if(this.userId.Level === 'N2' ||
				((this.userId.Level === 'N1' || this.userId.Level === 'N0') && this.userId.hasPermission === false)) {
				userIds = this.accountData[0].userId;
				tenantId = this.accountData[0].tenantId;

			} else {
				userIds = this.userId.sub;
				tenantId = this.userId.Tenant;
			}

			const result = await searchPermissions(userIds, tenantId);

			if (result === true) {
				setupHeaderAliareTenant(tenantId);
				WebStorage.setItem('tenantId', tenantId);

				if(this.userId.Level === 'N2' ||
					((this.userId.Level === 'N1' || this.userId.Level === 'N0') && this.userId.hasPermission === false)) {

					WebStorage.setItem('tokenData', this.userId);
					WebStorage.setItem('accountName', this.accountData[0].accountName);
					WebStorage.setItem('accountInfo', JSON.stringify(this.accountData[0]));
					WebStorage.setItem('cardOrganization', JSON.stringify(this.accountData));

					this.$router.push({
						name: 'workspace',
					});
					this.verifyOriginAndAlert();
					this.isLoading = false;

				} else {
					this.userId.hasPermission = true;
					this.getUserN1OrN0();
				}
			} else if(result === false && this.userId.Level === 'N1' || this.userId.Level === 'N0') {
				this.userId.hasPermission = false;
				this.getAccountId();

			} else {
				this.isLoading = false;
				this.logOut();
				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			}
		},

		async getUserN1OrN0() {
			const data = [];
			const payload = { pageSize: 15, page: 1};
			await this.getAccountTenant(payload).then((response) => {
				for (const index in response.data) {
					if(response.data[index].active === true && response.data[index].blocked === false) {
						data.push(response.data[index]);
					}
				}
				WebStorage.setItem('tokenData', this.userId);
				WebStorage.setItem('cardOrganization', JSON.stringify(data));

				this.$router.push('/organizacao');
				this.verifyOriginAndAlert();
				this.isLoading = false;
			});
		},

		verifyOriginAndAlert() {
			if (this.loginByOidc) {
				this.$store.commit('activeAlert', {
					message: 'Você já esta logado na aliare, portanto o redirecionamos para a tela de seleção de organização.',
				});
			}
		},
		logOut() {
			this.revokeTokens().catch((error) => {
				console.log(error);
			});
		},

		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},

		show() {
			const senha = document.getElementById('password');
			if (senha.type === 'password') {
				senha.type = 'text';
			} else {
				senha.type = 'password';
			}
		},
		validateEmail() {
			const email = document.getElementById('username').value;
			const re = /\S+@\S+\.\S+/;
			if (re.test(email)) {
				document.getElementsByClassName('btn btn-primary btn-block')[0].disabled = false;
				document.getElementsByClassName('btn btn-primary btn-block')[0].style.pointerEvents =
					'auto';
			} else {
				document.getElementsByClassName('btn btn-primary btn-block')[0].disabled = true;
				document.getElementsByClassName('btn btn-primary btn-block')[0].style.pointerEvents =
					'none';
			}
			return re.test(email);
		},

		getValidation(field) {
			if (this.$v.form.$dirty === false) {
				return null;
			}

			return !this.$v.form[field].$error;
		},
	},
};
</script>

<style scoped lang="scss">
.wrapper {
	background-image: url('../assets/imagens/img-login.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: flex;
	height: 100vh;
	align-items: center;
	justify-content: flex-end;
}

label {
	display: block;
	margin-top: 10px;
}

:deep {
	.was-validated .form-control:invalid,
	.form-control.is-invalid {
		border-color: #dc3545;
		border: 1px solid #dc3545 !important;
		background-image: url('../assets/icon/alert.svg');
	}

	.was-validated .form-control:invalid,
	.form-control.is-invalid + input#input_1_0 {
		background-image: url('../assets/icon/eye-error.svg');
	}

	.was-validated .form-control:invalid,
	.form-control.is-invalid + input#input_1_0 + label {
		color: #dc3545;
	}

	.was-validated .form-control:invalid,
	input#password.form-control.is-invalid {
		border-color: #dc3545;
		border: 1px solid #dc3545 !important;
		background-image: url('../assets/icon/alert.svg');
		background-position-x: 290px;
	}

	.was-validated .form-control:valid,
	input#password.form-control.is-valid {
		background-position-x: 290px;
	}

	.was-validated .form-control:valid,
	input#username.form-control.is-invalid + label {
		color: #dc3545;
	}
}

.invalid-feedback {
	margin-top: 0.25rem;
	font-size: 0.8em;
}

.btn-block {
	width: 349px;
	height: 40px;
	display: block;
}

.pos-input {
	display: flex;
}

input {
	background-color: #223049 !important;
	border: 1px solid #00e0ff !important;
	color: #ffffff !important;
	width: 349px;
	height: 40px;
}

input[type='checkbox'] {
	background-color: #223049;
	border: 1px solid #00e0ff !important;
	color: #ffffff;
	width: 18px;
}

.input_1_ {
	border: 0px !important;
	border-radius: 0px !important;
	outline: none !important;
	min-width: 0px !important;
	padding: 0px !important;
	-webkit-appearance: none !important;
}

input[type='checkbox'] {
	display: none;
}

input[type='checkbox'] {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 25px;
	width: 25px;
	display: inline-block;
}

input#input_1_0 {
	position: absolute;
	right: 8px;
	margin-top: -32px;
	height: 25px;
	border: 0px !important;
	background-size: auto !important;
	background-image: url('../assets/icon/eye.svg');
}

::input:-internal-autofill-selected {
	background-color: #223049 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #223049 inset;
	-webkit-text-fill-color: white !important;
}

p {
	color: #ffffff;
	justify-content: center;
	display: flex;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	text-align: center;
	padding-top: 24px;
}

.acesso {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	padding-left: 6px;
	margin-top: 5px;
}

.nav-link {
	padding: 0rem;
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 0.75rem 1.25rem;
	color: inherit;
}

a {
	color: #00ea00 !important;
	text-decoration: none;
	background-color: transparent;
	margin-left: 20px;
}

.esqueceu {
	padding-left: 77px !important;
}

.card-container.card {
	width: 433px;
	height: 520px;
	padding: 40px 40px;
	margin-right: 32px;
	margin-left: 32px;


}

.card {
	background-color: #1c2638;
	padding: 20px 25px 30px;
	border-radius: 5px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.logo-img-card {
	width: 143.49px;
	height: 40px;
	margin: 0 auto 10px;
	display: block;
}

.solucao-img-card {
	width: 120.48px;
	height: 16px;
	margin: 0 auto 10px;
	display: block;
}

.label-float {
	position: relative;
	padding-top: 13px;
}

.label-float input {
	border: 1px solid lightgrey;
	border-radius: 5px;
	outline: none;
	min-width: 250px;
	font-size: 16px;
	transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	-webkit-appearance: none;
}

.label-float input:focus {
	border: 2px solid #00e0ff;
}

.label-float input::placeholder {
	color: transparent;
}

.label-float label {
	pointer-events: none;
	position: absolute;
	top: calc(50% - 22px);
	left: 10px;
	transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	color: #ffffff;
	padding: 5px;
	box-sizing: border-box;
}

.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
	font-size: 13px;
	top: -12px;
	color: #00e0ff;
	background: linear-gradient(to bottom, #1c2638 0%, #1c2638 50%, #223049 51%, #223049 100%);
}

.form-group-btn {
	padding-top: 35px !important;
	width: 349px;
}

.form-group {
	margin-bottom: 1.2rem;
}

.container1 {
	display: block;
	position: relative;
	padding-left: 28px;
	margin-bottom: 12px;
	font-size: 14px;
	cursor: pointer;
	font-style: normal;
	font-weight: normal;
	color: #ffffff;
}

.btn-primary {
	background-color: #00e0ff;
	border-color: #00e0ff;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	color: #070707;
}

/* Esconder o checkbox padrão */
.container1 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Criar o checkbox customizado */
.checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 18px;
	width: 18px;
	border: 1px solid #00e0ff !important;
	border-radius: 2px;
}

/* Adicionar uma cor de fundo cinza ao passar o mouse */
.container1:hover input ~ .checkmark {
	background-color: #ccc;
}

/* Quando o checkbox é clicado, adicionar um fundo rosa */
.container1 input:checked ~ .checkmark {
	background-color: #00e0ff;
}

/* Criar o ícone de check, escondido quando não estiver selecionado */
.container1 .checkmark:after {
	content: '';
	position: absolute;
	display: none;
	left: 6px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* Exibir o ícone quando selecionado */
.container1 input:checked ~ .checkmark:after {
	display: block;
}
</style>
