import AliareAccountService from '../services/aliareAccount.service';
import WebStorage from '@/common/WebStorage';
import AuthService from '@/services/auth.service';

export default {
    namespaced: true,

    state: {},

    getters: {},

    actions: {
       async getUserAccountId({commit}, id) {
         try {
          const { data } = await AliareAccountService.getUserAccountId(id);
          commit('setUserAccountId', { data });
          return data;
        } catch (error) {
          console.error(`Erro ao buscar tags.\n${error}`);
          return error;
        }
        finally {
          //commit('turnOffProjectDetailsStatus')
        }
      },
			async getUserAccountBasic({commit}, id) {
         try {
          const { data } = await AliareAccountService.getUserAccountBasic(id);
          commit('setUserAccountBasic', { data });
          return data;
        } catch (error) {
          console.error(`Erro ao buscar tags.\n${error}`);
          return error;
        }
        finally {
          //commit('turnOffProjectDetailsStatus')
        }
      },
			async getAccountData({commit}, id) {
				try {
						const { data } = await AliareAccountService.getAccountData(id);
						return data;
				} catch (error) {
						console.error(`Erro ao buscar tags.\n${error}`);
						return error;
				}
			},
			async getAccountDataV2({commit}, id) {
				try {
						const { data } = await AliareAccountService.getAccountDataV2(id);
						return data;
				} catch (error) {
						console.error(`Erro ao buscar tags.\n${error}`);
						return error;
				}
			},
			async getUserId({ commit }, id) {
				try {
					const res = await AliareAccountService.getUserId(id);
					commit("setUserInfo", { res });
					return res;
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return error;
				}
			},
			async disableUser({ commit }, id) {
				try {
					const { data } = await AliareAccountService.disableUser(id);
					commit('setPost', { data });
					return Promise.resolve(data);
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return Promise.reject(error);
				}
			},
			async activeUser({ commit }, id) {
				try {
					const { data } = await AliareAccountService.activeUser(id);
					commit('setPostActive', { data });
					return Promise.resolve(data);
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return Promise.reject(error);
				}
			},
			async getUsers({ commit }, payload) {
				try {
					const res = await AliareAccountService.getUsers(payload);
					commit("setUserInfo", { res });
					return res;
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return error;
				}
			},

			async getPeople({commit}, payload) {
				try {
						const { data } = await AliareAccountService.getPeople(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async peopleId({ commit }, data) {
				try {
					const res = await AliareAccountService.getPeopleId(data);
					commit("setPeopleInfo", { res });
					return res;
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return error;
				}
			},

			async putPeopleId({ commit }, payload) {
				try {
					const res = await AliareAccountService.putPeopleId(payload);
					return res;
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return Promise.reject(error);
				}
			},

			async getPermissionFromUser({commit}, payload) {
					try {
							const { data } = await AliareAccountService.getPermissionFromUser(payload);
							commit('setPermissionFromUser', { data });
							return data;
					} catch (error) {
						await AuthService.logout();
						return error;
					}
					finally {
							//commit('turnOffProjectDetailsStatus')
					}
			},

			async getUsersByTenantId({commit}, payload) {
				try {
						const { data } = await AliareAccountService.getUsersByTenantId(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},
			async disableProfile({commit}, payload) {
				try {
						const { data } = await AliareAccountService.disableProfile(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async activeProfile({commit}, payload) {
				try {
						const { data } = await AliareAccountService.activeProfile(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getProfilesByTenantId({commit}, payload) {
				try {
					const { data } = await AliareAccountService.getProfilesByTenantId(payload);
					let profiles = [];

					data.data.forEach(profile => {
						if(payload?.returnReducedProfile) {
							if (!!profiles.find(profileArray => profileArray.id == profile.productProfile.id) || profile.productProfile.productId !== 'aca76e07-280f-4e1f-a4df-f8a94757e0fc') {
								return;
							}
							profiles.push({
								id: profile.productProfile.id,
								name: profile.productProfile.description,
							});
						} else {
							if (!profile.productProfile.active || profile.productProfile.productId !== 'aca76e07-280f-4e1f-a4df-f8a94757e0fc') {
								return;
							}
							profiles.push({
								id: profile.id,
								label: profile.productProfile.description,
							});
						}
					});

					return profiles;
				} catch (error) {
								return error;
				}
				finally {
								//commit('turnOffProjectDetailsStatus')
				}
			},

			async getProfileDetailingById({commit}, payload) {
				try {
					const { data } = await AliareAccountService.getProfileDetailingById(payload);
					if(payload?.returnReducedResponse) {
						const { id, functionalities, productProfile, usersProfiles } = data;
						const { description } = productProfile;
						const userProfileId = usersProfiles[0]?.id; // Obtém o ID do primeiro item do array userProfile
      			return { id, functionalities, description, userProfileId };

					} else {
						return data;
					}
				} catch (error) {
					return error;
				}
				finally {
								//commit('turnOffProjectDetailsStatus')
				}
			},

			async getUsersProfileDetailingById({commit}, payload) {
				try {
					const { data } = await AliareAccountService.getUsersProfileDetailingById(payload);
					return data;
				} catch (error) {
								return error;
				}
				finally {
								//commit('turnOffProjectDetailsStatus')
				}
			},

			async getProductProfilesByTenantId({commit}, payload) {
				try {
					const { data } = await AliareAccountService.getProductProfilesByTenantId(payload);
					return data.data;
				} catch (error) {
						return error;
				}
				finally {
								//commit('turnOffProjectDetailsStatus')
				}
			},

			async putProductProfileDescription({commit}, payload) {
				try {
					const { data } = await AliareAccountService.putProductProfileDescription(payload);
					return data;
				} catch (error) {
						return error;
				}
				finally {
								//commit('turnOffProjectDetailsStatus')
				}
			},

			async deleteProductProfile({ commit }, id) {
				try {
					const { data } = await AliareAccountService.deleteProductProfile(id);
					return data;
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return Promise.reject(error);
				}
			},

			async getProfiles({commit}, payload) {
				try {
						const { data } = await AliareAccountService.getProfilesByTenantId(payload);
						let profiles = [];
						data.data.forEach(profile => {
							profiles.push({
								id: profile.id,
								productProfile: profile.productProfile,
								usersProfiles: profile.usersProfiles,
							});
						});
						return profiles;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getAccountTenant({commit}, payload) {
					try {
							const { data } = await AliareAccountService.getAccountTenant(payload);
							commit('setTenant', { data });
							return data;
					} catch (error) {
							console.error(`Erro ao buscar tags.\n${error}`);
							return error;
					}
					finally {
							//commit('turnOffProjectDetailsStatus')
					}
			},

			async getAccountTenantV2({commit}, payload) {
					try {
							const { data } = await AliareAccountService.getAccountTenantV2(payload);
							commit('setTenant', { data });
							return data;
					} catch (error) {
							console.error(`Erro ao buscar tags.\n${error}`);
							return error;
					}
					finally {
							//commit('turnOffProjectDetailsStatus')
					}
			},

			async verifyUserActive({commit}, payload) {
				try {
						const { data } = await AliareAccountService.getUserPermission(payload);
						let userActive = 0;
						let profiles = [];
						let userProfile = [];

						data.forEach((permission) => {
							if(permission.userProfile.active) {
								if(payload.returnUserProfile) {
									userProfile.push(permission.userProfile);
								}

								profiles.push(permission.productProfile.description);
								userActive += 1;
							}
						});

						if (userActive != 0) {
							if(payload.returnProductProfile) {
								return {profiles, active: true};

							} else if(payload.returnUserProfile) {
								return {userProfile, profiles, active: true};

							} else {
								return true;
							}
						} else {
							if(payload.returnUserProfile) {
								return {userProfile, profiles, active: false};
							} else {
								return false;
							}
						}
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getGroupPartner({commit}) {
				try {
						const { data } = await AliareAccountService.getGroupPartner();
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getUserPermissionById({commit}, payload) {
				try {
						const { data } = await AliareAccountService.getUserPermission(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async blockUserInPermission({commit}, payload) {
				try {
						const { data } = await AliareAccountService.blockUserInPermission(payload);
						return data;
				} catch (error) {
						return Promise.reject(error);
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async unblockUserInPermission({commit}, payload) {
				try {
						const { data } = await AliareAccountService.unblockUserInPermission(payload);
						return data;
				} catch (error) {
						return Promise.reject(error);
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getSubscription({commit}) {
				try {
						const { data } = await AliareAccountService.getSubscription();
						let aliareIntegraSubscription = data.data[0];

						return aliareIntegraSubscription;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getOfferSolution({commit}, payload) {
				try {
						const { data } = await AliareAccountService.getOfferSolution(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async createProductProfile({commit}, payload) {
				try {
						const { data } = await AliareAccountService.createProductProfile(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async savePermission({commit}, payload) {
				try {
						const { data } = await AliareAccountService.savePermission(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async putPermission({commit}, payload) {
				try {
						const { data } = await AliareAccountService.putPermission(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async linkUsersToPermission({commit}, payload) {
				try {
						const { data } = await AliareAccountService.linkUsersToPermission(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getSegment({commit}) {
				try {
						const { data } = await AliareAccountService.getSegment();
							return data.map(item => {
								const { id, description } = item;
								return { id, label: description };
							});
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async createUser({commit}, payload) {
				try {
						const { data } = await AliareAccountService.createUser(payload);
						return data;
				} catch (error) {
						return error;
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async linkUsersToPermissionV1({commit}, payload) {
				try {
						const { data } = await AliareAccountService.linkUsersToPermissionV1(payload);
						return data;
				} catch (error) {
						return Promise.reject(error);
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async deletePermission({ commit }, id) {
				try {
					const { data } = await AliareAccountService.deletePermission(id);
					commit('setDelete', { data });
					return data;
				} catch (error) {
					console.error(`Erro ao buscar tags.\n${error}`);
					return Promise.reject(error);
				}
			},

			async createWithPerson({commit}, payload) {
				try {
						const { data } = await AliareAccountService.createWithPerson(payload);
						return data;
				} catch (error) {
						return Promise.reject(error);
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async addUserTenant({commit}, payload) {
				try {
						const { data } = await AliareAccountService.addUserTenant(payload);
						return data;
				} catch (error) {
						return Promise.reject(error);
				}
				finally {
						//commit('turnOffProjectDetailsStatus')
				}
			},

			async getProfilesByTenantIdV2({commit}, payload) {
				try {
					const { data } = await AliareAccountService.getProfilesByTenantIdV2(payload);
					
					return data;

				} catch (error) {
					return error;
				}
				finally {
								//commit('turnOffProjectDetailsStatus')
				}
			},
			async getProfilesByIdV2({commit}, payload) {
				try {
					const { data } = await AliareAccountService.getProfilesByIdV2(payload);
					return data;

				} catch (error) {
					return error;
				}
				finally {
								//commit('turnOffProjectDetailsStatus')
				}
			},

    },

    mutations: {
        setUserAccountId(state, {userAccountId}){
            state.userAccountId = userAccountId;
        },
				setUserAccountBasic(state, {userAccountBasicId}){
            state.userAccountBasicId = userAccountBasicId;
        },
				setUserInfo(state, { userInfo }) {
					state.userInfo = userInfo;
				},
				setPeopleInfo(state, { peopleInfo }) {
					state.peopleInfo = peopleInfo;
				},
				setPermissionFromUser(state, { setPermissionFromUser }) {
					state.setPermissionFromUser = setPermissionFromUser;
				},
				setTenant(state, { setTenant }) {
					state.setTenant = setTenant;
				},
				setPost(state, { post }) {
					state.post = post;
				},
				setPostActive(state, { postActive }) {
					state.postActive = postActive;
				},
				setDelete(state, { id }) {
					state.delete = id;
				},
    },
}
