
import moment from 'moment';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import {
	TIMEZONE_SELECT_MODAL_KEY,
} from '@/constants';
import { INodeUi } from '@/Interface';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';

export default mixins(
	genericHelpers,
	restApi,
).extend({
	name: 'TimezoneHeaderNode',
	props: {
		keyName: String,
	},
	computed: {
		node (): INodeUi {
			return this.$store.getters.activeNode;
		},
		currentValue (): string {
			const getDescendantProp = (obj: object, path: string): string => {
				// @ts-ignore
				return path.split('.').reduce((acc, part) => acc && acc[part], obj);
			};

			if (this.keyName === 'name' && this.node.type.startsWith('n8n-nodes-base.')) {
				const shortNodeType = this.$locale.shortNodeType(this.node.type);

				return this.$locale.headerText({
					key: `headers.${shortNodeType}.displayName`,
					fallback: getDescendantProp(this.node, this.keyName),
				});
			}

			return getDescendantProp(this.node, this.keyName);
		},
	},
	data() {
		return {
			showTimezoneSelect: false,
			timezones: '',
			activeTimezone: '',
		};
	},
	created() {
		this.verifyNodeTimezone();
	},
	mounted() {
		this.$root.$on('reload', () => {
			this.verifyNodeTimezone();
		});
	},
	methods: {
		openModalTimezone() {
			this.$store.dispatch('ui/openModal', TIMEZONE_SELECT_MODAL_KEY);
		},
		formatMomentToString(timezone) {
			if (!timezone.length) return;
			const GMT = timezone.slice(4, 10);
			moment.locale('pt');
			let stringFormated = moment().utcOffset(GMT).format('ZZ, HH:mm');

			const city = timezone.slice(11);
			const gmtString = 'GMT' + stringFormated.slice(0,3);
			stringFormated = city + ', ' + gmtString + stringFormated.slice(5);
			return stringFormated;
		},
		async verifyNodeTimezone() {
			if(this.currentValue === 'Agendar Execução') {
				this.showTimezoneSelect = true;
				this.timezones = await this.restApi().getTimezones();


				const workflowSettings = JSON.parse(JSON.stringify(this.$store.getters.workflowSettings));

				if (workflowSettings.timezone === undefined || workflowSettings.timezone === 'padrao') {
					workflowSettings.timezone = {
						key: 'padrao',
						value: 'Padrão - (GMT-03:00) Fortaleza',
					};
				} else {
					this.activeTimezone = this.timezones[workflowSettings.timezone];
				}
			}
		},
	},
});
