import axios from 'axios';
import { appConfig } from '@/config/appConfig';
import { defaultApi } from '@/api/internalApis';

axios.defaults.withCredentials = appConfig('auth.setCookies');
const project = 'Projetos';
const workspaces = 'Workspaces';
class ProjectService {
	async patch(workspaceId, id, name, active) {
		let payload = { 'nome': name, 'ativo': active }
		const res = await defaultApi.patch(`${workspaces}/${workspaceId}/${project}/${id}`, payload);
		return res;
	}
	async patchActive(query) {
		let ativo = { 'ativo': query.ativo }
		const res = await defaultApi.patch(`${workspaces}/${query.workspaceId}/${project}/${query.projetoId}/Ativo`, ativo);
		return res;
	}
	async delete(query) {
		const res = await defaultApi.delete(`${workspaces}/${query.workspaceId}/${project}/${query.projetoId}`);
		return res;
	}
	async getProjectDetailsId(query) {
		const res = await defaultApi.get(`${project}/Detalhes/${query.id}` , {
      params:{
				sucesso: query?.sucesso,
				alerta: query?.alerta,
        ativo: query?.ativo,
				page: query?.page,
				size: query?.size,
      },
    });
		return res;
	}
	async getProjectDetails(query) {
    const res = await defaultApi.get(`${workspaces}/${query.workspaceId}/${project}/Detalhes`, {
      params:{
				resultadoExecucao: query?.resultadoExecucao,
				page: query?.page,
				size: query?.size,
        ativo: query?.ativo,
				nome: query?.nome,
      },
    });
    return res;
  }
	async getProjectTotalizers(query) {
    const res = await defaultApi.get(`${workspaces}/${query.workspaceId}/${project}/Totalizadores`, {
      params:{
				tenant: query?.tenant,
      },
    });
    return res;
  }
	async getProjectFlow(result) {
		const res = await defaultApi.get(`${project}/${result}/Fluxos`);
		return res;
	}
	async postDuplicateProjectId(id) {
		const res = await defaultApi.post(`${project}/${id}/Duplicar`);
		return res;
	}
	async postProject(result) {
		const res = await defaultApi.post(`${workspaces}/${result.workspaceId}/${project}`, result);
		return res;
	}
}

export default new ProjectService();
