
import DataSource from '@/views/DataSource.vue';
import EquivalencesList from '@/views/EquivalencesList.vue';

export default {
	components: {
		DataSource,
		EquivalencesList,
	},
	name: 'Equivalences',
	data() {
		return {
			activeTab: 'equivalences',
		};
	},
	async mounted() {
		this.verifyMode();
	},
	methods: {
		verifyMode() {
			this.activeTab = this.$route.name == 'dataSource' ? 'dataSource' : 'equivalences';
		},
		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
	},
};
