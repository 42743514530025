import Login from '@/views/Login.vue';
import SSO from '@/views/SSO.vue';
import Logout from '@/views/Logout.vue';

export default [
  {
    path: '/login',
		name: 'Login',
		components: {
			default: Login,
		},
  },
	{
    path: '/auth/sso',
		name: 'authorizeLogin',
		components: {
			default: SSO,
		},
  },
  // {
  //   path: '/register',
  //   component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
  // },
  {
    path: '/recoverpassword',
    component: () => import(/* webpackChunkName: "recoverpassword" */ '@/components/RecoverPassword.vue'),
  },
];
