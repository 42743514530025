
import { mapActions, mapGetters } from 'vuex';
import WebStorage from '@/common/WebStorage';
import Vue from 'vue';

import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import SvgAnimation from '@/components/SvgAnimation.vue';
import AliareInput from '@/components/AliareInput.vue';
import Modal from './Modal.vue';


export default {
	name: 'ModalsRegisterUser',
	components: {
		Modal,
		AliareInput,
		SvgAnimation,
		ModalButtonCancel,
		ModalButtonSuccess,
	},
	props: ['modalName', 'isActive'],
	data() {
		return {
			organizationName: WebStorage.getItem('accountName'),
			modalBus: new Vue(),
			userModalName: '',
			userResponse: [],
			isLoading: true,
		};
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		userData() {
			return this.$store.getters.userData;
		},
	},
	async created() {
		this.isLoading = true;
		await this.verifyRegisteredUser();
		this.isLoading = false;
	},
	methods: {
		...mapActions('aliareAccount', ['getUsersByTenantId', 'verifyUserActive']),

		async verifyRegisteredUser() {
			await this.getUsersByTenantId({search: this.userData}).then(async (response) => {
				if(response.data?.length === 0) {
					this.userModalName = 'userNotFound';
					this.userResponse.mode = 'newUser';

				} else {
					await this.existsPermission(response.data);
				}
			});
		},

		async existsPermission(user) {
			let returnUserProfile = true;

			await this.verifyUserActive({userId: user[0].id, returnUserProfile}).then((verify) => {
				if(verify.userProfile) {
					this.userModalName = 'userExists';
					this.userResponse = user[0];

				} else {
					this.userModalName = 'userNotFound';
					this.userResponse.mode = 'newUser';
				}
			});
		},

		goToUsers() {
			this.modalBus.$emit('close');
			this.$router.push({	name: 'users' }).catch(()=>{});
		},
		goToUserDetail() {
			const id = this.userResponse.id;
			this.modalBus.$emit('close');

			this.$router.push({
				name: 'userdetailing',
				params: { id },
			}).catch(()=>{});
		},
		registerNewUser() {
			this.modalBus.$emit('close');
			window.location.reload();
		},
		sendDataUser() {
			this.$store.commit('setSendUserData', this.userResponse);
			this.modalBus.$emit('close');
			this.$root.$emit("sendUser");
		},

  },
	beforeDestroy() {
    this.$store.commit('setUserData', {});
  },
};
