
import Table from '@/components/Table.vue';
import InfoBox from '@/components/InfoBox.vue';
import Modal from '@/components/ModalAliare.vue';
import SelectTree from '@/components/SelectTree.vue';
import AliareInput from '@/components/AliareInput.vue';
import SvgAnimation from '@/components/SvgAnimation.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import TreeFunctionality from '@/components/TreeFunctionality.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalNotificationUser from '@/components/ModalNotificationUser.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { showMessage } from '@/components/mixins/showMessage';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { removeItemFromArrayByValue } from '@/components/helpers';
import { userPermission } from '@/components/mixins/permissions/user';
import { PERMISSION_IDS, MODALS_REGISTER_USER_MODAL_KEY, nacionalidades, typePerson} from '@/constants';
import { isValidCPF, isValidCNPJ, formatIdentification, findModifiedObjects, formatPhoneFixed, formatPhoneMobile } from '@/components/helpers';

export default mixins(
	restApi,
	nodeHelpers,
	showMessage,
	userPermission,
).extend({
	components: {
		Modal,
		Table,
		InfoBox,
		SelectTree,
		AliareInput,
		SvgAnimation,
		BackgroundView,
		RoundPhotoFrame,
		NotificationPage,
		TreeFunctionality,
		ModalButtonCancel,
		ModalButtonSuccess,
		ModalNotificationUser,
		AlertNotificationModal,
	},
	name: 'CreateEditUser',
	data() {
		return {
			showAlert: false,
			isLoading: false,
			userExists: false,
			invalidFixed: false,
			invalidMobile: false,
			isLoadingPage: false,
			scrollInfinite: true,
			isLoadingEmail: false,
			canRegisterUser: false,
			showNotification: false,
			showAlertValidate: false,
			validationDocument: false,
			isLoadingWorkspace: false,

			mode: 'validateEmail',

			page: 1,
			size: 20,
			partnerId: '',
			sucessUser: '',
			currentUser: '',
			messageNameError: '',
			messageNameSuccess: '',
			messageDocumentError: '',
			messageDocumentSuccess: '',

			email: '',
			forms: {
				name: '',
				document: '',
				phoneFixed: '',
				blockEmail: '',
				mobilePhone: '',
				fantasyName: '',
				imagePreview: null,
			},

			currentStep: 'user',

			selected: null,
			selectedPerson: null,
			selectedSegment: null,
			selectedProfile: null,

			permissions: [],
			userResponse: [],
			optionSegment: [],
			listWorkspaces: [],
			optionProfiles: [],
			workspacesSelected: [],
			permissionsFromUser: [],
			optionPerson: typePerson,
			optionNacionality: nacionalidades,

			organizationInfo: JSON.parse(WebStorage.getItem('accountInfo')),
		};
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),

		allWorkspacesSelected() {
			return this.workspacesAbleToSelect == this.workspacesSelected.length;
		},
		partialWorkspacesSelected() {
			return this.workspacesAbleToSelect != this.workspacesSelected.length && this.workspacesSelected.length > 0;
		},
		workspacesAbleToSelect() {
			return this.listWorkspaces.length;
		},

		isEdit() {
			return this.$route.name === 'editUser';
		},
		keepModalText() {
			return this.isEdit ? this.$locale.baseText('Continuar Edição') : this.$locale.baseText('Manter Cadastro');
		},
		cancelModalText() {
			return this.isEdit ? this.$locale.baseText('Cancelar Edição') : this.$locale.baseText('Cancelar Cadastro');
		},
		textTitleButton() {
			return this.userExists ? this.$locale.baseText('Visualizar Detalhamento do Usuário') : this.$locale.baseText('Preencher Dados');
		},
		stepUserTitle() {
			return this.isEdit ? this.$locale.baseText('Edite o Usuário') : this.$locale.baseText('Identifique o Usuário');
		},
		stepUserSubTitle() {
			if(this.mode == 'validateEmail' && !this.isEdit) {
				return this.$locale.baseText('Informe o e-mail do Usuário');

			} else if(this.mode !== 'validateEmail' && !this.isEdit) {
				return this.$locale.baseText('Insira os dados para o cadastro do usuário.');

			} else {
				return this.$locale.baseText('Altere os Dados Desejados');
			}
		},

		alertNotificationTitle() {
			return this.isEdit ? 'Cancelamento da Edição' : 'Cancelamento do Cadastro';
		},
		alertNotificationDescription() {
			return 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
		},
		notificationUserTitle() {
			if(this.isLoadingEmail) {
				return 'Verificando o e-mail';
			}
			return this.userExists ? 'Usuário Já Cadastrado' : 'Usuário não encontrado';
		},
		notificationUserDescription() {
			if(this.userExists) {
				return `O usuário <b>${ this.userResponse?.person }</b>, de e-mail <b>${ this.userResponse?.email }</b>, já se encontra cadastrado na Organização <b>${ this.organizationInfo?.name }</b>. O que você gostaria de fazer?`;
			}

			return `O usuário <b>${ this.forms.blockEmail }</b>, não está cadastrado. Por favor informe os dados do usuário.`;
		},

		notificationFirstText() {
			return this.isEdit ? `O usuário <b>${ this.sucessUser?.nome }</b>, de e-mail <b>${ this.sucessUser?.email }</b>, foi editado e seus dados foram atualizados na base de dados da Aliare.` : `O usuário <b>${ this.sucessUser?.people }</b>, de e-mail <b>${ this.sucessUser?.email }</b>, foi cadastrado com sucesso.`;
		},
		notificationTitleText() {
			return this.isEdit ? 'Usuário Editado' : 'Usuário Cadastrado';
		},
	},

	methods: {
		...mapActions('aliareAccount', ['getSegment', 'getProfilesByTenantId', 'getProfileDetailingById', 'getGroupPartner', 'addUserTenant', 'linkUsersToPermissionV1', 'createWithPerson', 'getUsersByTenantId', 'verifyUserActive', 'getUserId', 'peopleId', 'putPeopleId', 'getUserPermissionById', 'deletePermission', 'unblockUserInPermission']),

		addWorkspaceToPermission(workspace) {
			if (this.verifyIfOwnerUser(workspace)) return;

			if (this.verifyWorkspaceSelected(workspace.id)) {
				removeItemFromArrayByValue(this.workspacesSelected, workspace);

				if(this.isEdit) this.removeWorkspace(workspace);
			} else {
				this.workspacesSelected.push(workspace);
			}
		},
		verifyWorkspaceSelected(verifyWorkspaceId) {
			return this.workspacesSelected.find(workspace => workspace.id === verifyWorkspaceId);
		},
		selectAllWorkspaces() {
			if (this.verifyIfAdminUser()) return;

			if (this.partialWorkspacesSelected || this.allWorkspacesSelected) {
				this.listWorkspaces.forEach((workspace) =>{
					if (!this.verifyIfOwnerUser(workspace)) {
						removeItemFromArrayByValue(this.workspacesSelected, workspace);
					}
				});
				if(this.isEdit) this.removeWorkspace(this.workspacesSelected);
			} else {
				this.listWorkspaces.forEach((workspace) =>{
					this.addWorkspaceToPermission(workspace);
				});
			}
		},
		verifyIfAdminUser() {
			if (this.currentUser.adminUser) {
				return true;
			} else {
				return
			}
		},
		verifyIfOwnerUser(workspace) {
			if (workspace?.usuarioCriador?.id == this.currentUser.id) {
				return true;
			} else {
				return
			}	
		},

		openModalAlert() {
			this.showAlert = true;
		},
		goToCreateUser() {
			window.location.reload();
			this.showNotification = false;
			this.showAlertValidate = false;
			this.workspacesSelected = [];
		},
		goToUserDetailing(id) {
			this.$router.push({
				name: 'userdetailing',
				params: { id },
			}).catch(()=>{});

			this.showNotification = false;
			this.showAlertValidate = false;
			this.workspacesSelected = [];
		},
		goToUsers() {
			this.$router.push({
				name: 'users',
			}).catch(()=>{});

			this.showNotification = false;
			this.showAlertValidate = false;
			this.workspacesSelected = [];
		},
		dragOverHandler(event) {
    	event.preventDefault();
    },
    dropHandler(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.forms.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
		findNameInObject(objectList, name) {
			const objectFound = objectList.find(obj => obj.label === name);
			return objectFound || null; // Retorna null se o objeto não for encontrado
		},
		removeAccent(word) {
			const accentMap = {
				'Física': 'Fisica',
				'Jurídica': 'Juridica'
			};

			if (word in accentMap) {
				return accentMap[word];
			}
			return word;
		},

		onSelect(type) {
			this.selected = type;
		},
		async onSelectPerson(type) {
			if (this.forms.document !== '' && this.selectedPerson !== null && this.selectedPerson !== type) {
				this.forms.document = '';
				this.messageDocumentError = '';
				this.messageDocumentSuccess = '';
			}

			this.selectedPerson = type;
		},
		onSelectSegment(type) {
			this.selectedSegment = type;
		},
		onSelectProfile(type) {
			this.selectedProfile = type;
			this.getSelectProfile(this.selectedProfile);
		},
		async getSelectProfile(profile) {
			this.isLoading = true;

			const returnReducedResponse = true;
			const permissionId = profile.id;
			this.permissions = await this.getProfileDetailingById({ permissionId, returnReducedResponse });

			this.isLoading = false;
		},
		changeStep(step) {
			this.currentStep = step;
		},
		closeAlertValidate() {
			this.messageError = '';
			this.messageSuccess = '';
			this.showAlertValidate = false;
		},

		verifyUserEmail:
		_.debounce(async function (e) {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const validation = emailRegex.test(this.email);

			if(this.email != '') {
				if (validation === false) {
					this.messageNameError = this.$locale.baseText('Por favor, informe um e-mail válido.');
					this.messageNameSuccess = '';

				} else {
					this.messageNameSuccess = this.$locale.baseText('O e-mail informado é válido.');
					this.messageNameError = '';

					setTimeout(async () => {
						this.showAlertValidate = true;
						await this.verifyRegisteredUser(this.email);
					}, 1000);
				}
			} else {
				this.messageNameSuccess = '';
				this.messageNameError = '';
			}
		},
		500),
		async verifyRegisteredUser(email) {
			this.isLoadingEmail = true;
			await this.getUsersByTenantId({search: email}).then(async (response) => {
				if(response.data?.length === 0) {
					this.userExists = false;
					this.mode = 'newUser';
					this.forms.blockEmail = email;
				} else {
					await this.existsPermission(response.data);
				}
			});
			this.isLoadingEmail = false;
		},
		async existsPermission(user) {
			let returnUserProfile = true;

			await this.verifyUserActive({userId: user[0].id, returnUserProfile}).then((verify) => {
				if(verify.userProfile) {
					this.userExists = true;
					this.userResponse = user[0];

				} else {
					this.userExists = false;
					this.mode = 'newUser';
					this.forms.blockEmail = user[0].email;
				}
			});
		},

		async validateDocument(identification) {
			this.messageDocumentSuccess = '';
			this.messageDocumentError = '';

			if (identification === '') {
				this.validationDocument = false;
				return;
			}

			if (this.selectedPerson?.label === 'Estrangeira') {
				// Validação para documentos estrangeiros
				if (identification.length < 3) {
					this.validationDocument = false;
					return;
				}

				this.forms.document = this.forms.document.replace(/\D/g, '');
			} else {
				this.forms.document = formatIdentification(identification);

				const isValidDocument =
					(this.selectedPerson?.label === 'Física' && isValidCPF(identification)) ||
					(this.selectedPerson?.label === 'Jurídica' && isValidCNPJ(identification));

				if (!isValidDocument) {
					this.messageDocumentError = this.$locale.baseText('Por favor, informe um documento válido.');
					this.validationDocument = false;
					return;
				}
			}

			this.messageDocumentSuccess = this.$locale.baseText('O documento informado é válido.');
			this.validationDocument = true;
		},
		formatePhoneMobile(phone) {
			if (!/^[0-9()+\-\s]+$/.test(phone)) {
				return this.invalidMobile = true;
			}

			phone = phone?.replace(/\D/g, '');
			if (!phone.startsWith('55')) {
				phone = `55${phone}`;
			}

			if (phone.length > 2) {
					// Formatação para telefone celular
					phone = `${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 9)}-${phone.slice(9)}`;
					this.forms.mobilePhone = phone;
			}
			return this.invalidMobile = false;
		},
		formatePhoneFixed(phone) {
			if (!/^[0-9()+\-\s]+$/.test(phone) && !this.isEdit) {
				return this.invalidFixed = true;
			}

			phone = phone?.replace(/\D/g, '');
			if (!phone?.startsWith('55')) {
				phone = `55${phone}`;
			}

			if (phone.length > 2) {
				phone = `+${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(4, 8)}-${phone.slice(8)}`;
				this.forms.phoneFixed = phone;
			}
			return this.invalidFixed = false;
		},
		isAllInputComplete() {
			if (this.isEdit) {
				this.canRegisterUser = true;
				return;
			}

			const basicInfoComplete =
					this.forms.name?.trim() !== '' &&
					this.forms.document?.trim() !== '' &&
					this.forms.blockEmail?.trim() !== '';

			const additionalInfoComplete =
					this.selected !== null &&
					this.selectedPerson !== null &&
					this.validationDocument;

			const profileComplete =
					this.currentStep === 'profile' ? this.selectedProfile !== null : true;

			const mobilePhoneValid =
					!this.invalidMobile || this.forms.mobilePhone === '';

			const phoneFixedValid =
					!this.invalidFixed || this.forms.phoneFixed === '';

			this.canRegisterUser =
					basicInfoComplete &&
					additionalInfoComplete &&
					profileComplete &&
					mobilePhoneValid &&
					phoneFixedValid;
		},

		async registerUser() {
			const tenantId = WebStorage.getItem('tenantId');
			this.isLoadingPage = true;
			try {
				const payload = await this.createPayload();
				const createdUser = await this.createWithPerson(payload);
				const linkUserToTenant = await this.addUserTenant({ userId: createdUser.id, tenantId: tenantId, partnerId: this.partnerId });
				const linkUserToPermission = await this.linkUsersToPermissionV1({ permissionId: this.permissions.id, tenantId: tenantId, userId: createdUser.id });

				if(this.workspacesSelected.length > 0) {
					await this.saveWorkspaces(createdUser.id);
				}

				this.sucessUser = createdUser;
				this.showNotification = true;
			} catch (error) {
				const registerError = error?.response?.data?.notifications[0];

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('user.erroRegisterUser', { interpolate: { registerError } }),
					status: 'error',
				});
			}
			this.isLoadingPage = false;
		},
		async editUser() {
			this.isLoadingPage = true;
			try {
				const payload = await this.createPayload();
				const alterUser = await this.putPeopleId({ personId: this.currentUser.personId, payload: payload});

				if(this.currentUser.permissionId !== this.permissions.id) {
					await this.deletePermission(this.currentUser.userProfileId);
					await this.verifyUserBlockedInPermissionAndAdd(this.currentUser.id);
				}

				if(this.workspacesSelected.length > 0) {
					await this.saveWorkspaces(this.$route.params.id);
				}

				this.sucessUser = { nome: alterUser.legalName, id: this.currentUser.id, email: this.forms.blockEmail };
				this.showNotification = true;
			} catch (error) {
				const editError = error?.response?.data?.notifications[0];

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('user.errorEditUser', { interpolate: { editError } }),
					status: 'error',
				});
			}
			this.isLoadingPage = false;
		},
		async verifyUserBlockedInPermissionAndAdd(userId) {
			try {
				const tenantId = WebStorage.getItem('tenantId');

				const permissionBlocked = this.permissionsFromUser.some(permission => {
					if (permission.productProfile.description === this.permissions.description && permission.userProfile.blocked) {
						this.unblockUserInPermission({ userId: permission.userProfile.id });
						return true;
					}
					return false;
				});

				if (!permissionBlocked) {
					await this.linkUsersToPermissionV1({ permissionId: this.permissions.id, tenantId, userId });
				}
			} catch (error) {
				console.error(error);

				const errorMessage = error?.response?.data?.notifications?.[0];
				if (errorMessage) {
					this.$store.commit('activeAlert', { message: errorMessage, status: 'error' });
				}
			}
		},
		async createPayload() {
			this.forms.document = this.forms.document.replace(/\D/g, '');

			const commonPayload = {
				legalName: this.forms.name,
				phoneMobile: this.forms.mobilePhone.replace(/\D/g, ''),
				phoneFixed: this.forms.phoneFixed.replace(/\D/g, ''),
				notificationSMS: true,
				notificationPush: true,
				picture: this.forms.imagePreview,
				address: {
					country: this.selected?.label
				},
				identificationDocument: this.forms.document,
				...(this.forms.typePerson === 'Juridica' && {
					description: this.forms.fantasyName,
					segmentId: this.selectedSegment.id
				})
			};

			if (!this.isEdit) {
				return {
					email: this.forms.blockEmail,
					person: {
						...commonPayload,
						typePeople: this.removeAccent(this.selectedPerson.label),
					}
				};
			} else {
				return commonPayload;
			}
		},
		async saveWorkspaces(userId) {
			try {
				await this.workspacesSelected?.forEach(async (workspace) => {
					if (!workspace.usuarios.includes(userId)) {
						workspace.usuarios.push(userId);
					}

					const payload = {
						nome: workspace.nome,
						descricao: workspace.descricao,
						usuarios: workspace.usuarios,
					};

					await this.restApi().editWorkspace(workspace.id, payload);
				});
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error?.response?.data?.notifications[0],
					status: 'error',
				});
				console.error(error);
			}
		},
		async removeWorkspace(workspace) {
			try {
				const userId = this.$route.params.id;
				const workspaces = Array.isArray(workspace) ? workspace : [workspace];

				await Promise.all(workspaces.map(async (ws) => {
					const userIndex = ws.usuarios.indexOf(userId);
					if (userIndex !== -1) {
						ws.usuarios.splice(userIndex, 1);
					}

					const payload = {
						nome: ws.nome,
						descricao: ws.descricao,
						usuarios: ws.usuarios,
					};

					if (this.verifyIfOwnerUser(ws)) return;

					await this.restApi().editWorkspace(ws.id, payload);
				}));
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error?.response?.data?.notifications[0],
					status: 'error',
				});
				console.error(error);
			}
		},

		async loadPage() {
			this.isLoadingPage = true;

			await this.getGroupPartner().then(response => {
				this.partnerId = response[0].partnerId;
			});

			await this.getProfilesByTenantId().then((response) => {
				this.optionProfiles.push(...response);
			});

			await this.getSegment().then((response) => {
				this.optionSegment.push(...response);
			});

			if (this.isEdit) {
				this.mode = 'editUser';
				await this.loadCurrentUser();
			}

			await this.loadWorkspaces();


			this.isLoadingPage = false;
		},
		async loadWorkspaces() {
			this.isLoadingWorkspace = true;
			const currentUserId = this.$route.params.id;

			try {
				this.listWorkspaces = await this.restApi().getWorkspaces({ page: 1, size: this.size });

				if (!this.verifyIfAdminUser()) {
					this.listWorkspaces.forEach(workspace => {
						if (workspace.usuarios.includes(currentUserId) || this.verifyIfOwnerUser(workspace)) {
							this.workspacesSelected.push(workspace);
						}
					});
				} else {
					this.listWorkspaces.forEach(workspace => {						
						this.workspacesSelected.push(workspace);
					});
				}
				

				if (this.listWorkspaces.length < 20) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.response?.data?.notifications,
					status: 'error',
				});
				this.scrollInfinite = false;
			}
			this.isLoadingWorkspace = false;
		},

		async loadCurrentUser() {
			try {
				const userId = this.$route.params.id;
				const userInfo = await this.getUserId(userId);
				const peopleInfo = userInfo.personId && await this.peopleId(userInfo.personId);
				userInfo.people = peopleInfo;
				this.currentUser = userInfo;

				await this.getUserPermission();
				if(this.permissions) {
					await this.fillData(userInfo);
				}

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error?.notifications,
					status: 'error',
				});
			}
		},
		async getUserPermission() {
			const returnReducedResponse = true;
			let userPermissionActive = '';

			this.permissionsFromUser = await this.getUserPermissionById({ userId: this.currentUser.id});

			this.permissionsFromUser.forEach((permission) => {
				if (!permission.userProfile.blocked) {
					userPermissionActive = permission;
				}
			});
			const permissionId = userPermissionActive.permission.id;
			this.permissions = await this.getProfileDetailingById({ permissionId, returnReducedResponse });

			this.currentUser.permissionId = permissionId;
			this.currentUser.permission = userPermissionActive;
			this.currentUser.userProfileId = userPermissionActive.userProfile.id;
		},
		async fillData(data) {
			this.forms = {
				name: data?.people?.legalName,
				blockEmail: data?.email,
				document: formatIdentification(data.identificationDocument),
				phoneFixed: formatPhoneFixed(data.people?.phoneFixed) ?? '',
				typePerson: data?.typePerson,
				mobilePhone: formatPhoneMobile(data.people?.phoneMobile) ?? '',
				imagePreview: data?.viewLinkPicture ?? '',
			};

			const person = data.typePerson == 'Fisica' ? 'Física' : 'Jurídica';
			this.selectedPerson =  this.findNameInObject(this.optionPerson, person);
			this.selected = this.findNameInObject(this.optionNacionality, data.people.address?.country);
			this.selectedProfile = this.findNameInObject(this.optionProfiles, this.currentUser?.permission.productProfile.description);

			if(data.typePerson == 'Juridica') {
				this.forms.fantasyName = data?.people?.description;
				const { id, description: label } = data?.people?.segment;
				this.selectedSegment = { id, label };
			}
		},
		async pagination() {
			this.page++;
			const payload = { page: this.page, size: this.size };

			try {
				const response = await this.restApi().getWorkspaces(payload);
				response.forEach(workspace => {
					if (workspace.usuarios.includes(currentUserId)) {
						this.workspacesSelected.push(workspace);
					}
				});
				this.listWorkspaces.push(...response);

				if (response.length < 20) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
	async created() {
		await this.loadPage();
	},
	mounted() {
		const interval = setInterval(() => {
			this.isAllInputComplete();
		}, 500);
	},
	watch: {
		imagePreview(newValue, oldValue) {
			if(newValue !== oldValue) {
				this.forms.imagePreview = newValue;
			}
		},
	},
});
