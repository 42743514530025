
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { removeItemFromArrayByValue } from '@/components/helpers';
import { showMessage } from '@/components/mixins/showMessage';
import WebStorage from '@/common/WebStorage';

import BackgroundView from '@/components/BackgroundView.vue';
import AliareInput from '@/components/AliareInput.vue';
import SearchWorkflowInput from '@/components/SearchWorkflowInput.vue';
import InfoAliare from '@/components/InfoAliare.vue';
import InfoBox from '@/components/InfoBox.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import NotificationPage from '@/components/NotificationPage2.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import Modal from '@/components/ModalAliare.vue';
import Table from '@/components/Table.vue';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';

import { VALIDATION_RESOURCE_MODAL_KEY } from '../constants';

import { ref } from 'vue';
import mixins from 'vue-typed-mixins';
import { mapActions } from 'vuex';


export default mixins(
	nodeHelpers,
	showMessage,
).extend({
	components: {
		BackgroundView,
		AliareInput,
		ModalButtonSuccess,
		InfoBox,
		ModalButtonCancel,
		Modal,
		NotificationPage,
		InfoAliare,
		AlertNotificationModal,
		Table,
		RoundPhotoFrame,
		SearchWorkflowInput,
	},
	name: 'CreateEditWorkspace',
	data() {
		return {
			isLoadingWorkspace: true,
			isLoadingUser: true,
			form: {
				message: '',
				workspaceName: '',
				photoData: '',
			},
			currentImagem: '',
			currentUser: JSON.parse(WebStorage.getItem('accountInfo')),
			activeSavedWorkspace: '',
			currentStep: 'workspace',
			modeNotification: 'active',
			showNotification: false,
			showAlert: false,
			usersSelected: [],
			usersAdmin: [],
			creatorUser: '',
			scrollInfinite: true,
			listUsers: [],
			organizationInfo: JSON.parse(WebStorage.getItem('accountInfo')),
			page: 1,
			workspaceValidName: '',
		};
	},
	computed: {
		allUsersSelected() {
			return this.usersAbleToSelect == this.usersSelected.length;
		},

		partialUsersSelected() {
			return this.usersAbleToSelect != this.usersSelected.length && this.usersSelected.length > 0;
		},
		usersAbleToSelect() {
			return this.listUsers.filter(user => !user.adminUser).length;
		},
		discardModalText() {
			return this.isEdit ? this.$locale.baseText('Cancelar Edição') : this.$locale.baseText('Cancelar Cadastro');
		},
		keepModalText() {
			return this.isEdit ? this.$locale.baseText('Manter Edição') : this.$locale.baseText('Manter Cadastro');
		},
		workspaceEditId() {
			if (this.isEdit) {
				return this.$route.params.id;
			} else {
				return '';
			}
		},
		isEdit() {
			return this.$route.name === 'editWorkspace';
		},
		alertNotificationTitle() {
			return this.isEdit ? 'Cancelamento da Edição' : 'Cancelamento do Cadastro';
		},
		alertNotificationDescription() {
			return 'Todas as parametrizações que estão sendo feitas serão <b>excluídas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
		},

		secondSectionInfo() {
			return 'Adicione pelo menos um Recurso. Cada Fluxo pode ser vinculado apenas à um Recurso. Caso queira, você poderá validar o uso o Fluxo clicando no botão Validar.';
		},
		canCreateWorkspace() {
			return (!this.isEdit && this.workspaceValidName != '') || (this.isEdit && (this.workspaceValidName === '' || !!this.workspaceValidName) && this.form.workspaceName != '');
		},
	},
	methods: {
		...mapActions('aliareAccount', ['getUsersByTenantId', 'verifyUserActive', 'getUserPermissionById']),

		verifyUserAdmin(user) {
			return user.adminUser || user.id == this.currentUser.userId;
		},

		verifyCreatorUser(user) {
			return user.id == this.creatorUser?.id;
		},

		async loadCurrentWorkspace() {
			this.isLoadingWorkspace = true;

			try {
				const currentWorkspace = await this.restApi().getWorkspaceById(this.workspaceEditId);

				this.creatorUser = currentWorkspace.usuarioCriador;

				this.form = {
					message: currentWorkspace.descricao,
					workspaceName: currentWorkspace.nome,
					photoData: currentWorkspace.imagemUrl == 'NULL' ? '' : currentWorkspace.imagemUrl,
				};

				this.currentImagem = currentWorkspace.imagemUrl;
				this.usersSelected.push(...currentWorkspace.usuarios);

				this.isLoadingWorkspace = false;

			} catch (error) {
				this.$router.push({
					name: 'workspace',
				});
				return;
			}
		},
		addUserToPermission(user) {
			if (this.verifyUserAdmin(user)) return;
			if (this.verifyUserSelected(user.id)) {
				removeItemFromArrayByValue(this.usersSelected, user.id);
			} else {
				this.usersSelected.push(user.id);
			}
		},
		verifyUserSelected(verifyUserId) {
			return this.usersSelected.find(userId => userId === verifyUserId);
		},
		selectAllUsers() {
			if (this.partialUsersSelected || this.allUsersSelected) {
				this.usersSelected = [];
			} else {
				this.listUsers.forEach((user) =>{
					this.addUserToPermission(user);
				});
			}
		},

		async pagination() {
			this.page++;
			const payload = { page: this.page };
			this.getUsersByTenantId(payload).then((response) => {
				this.verifyUsersActive(response, true);

				for (const index in response?.data) {
					this.listUsers.push(response?.data[index]);
				}

				if (response?.data?.length < 25) {
					this.scrollInfinite = false;
				} else {
					this.scrollInfinite = true;
				}
			});
		},

		async verifyUsersActive(users, pushValue) {
			if (!users.length) return;
			let returnProductProfile = true;
			let usersVerified = [];
			for await (const user of users) {
				await this.getUserPermissionById({userId: user.id}).then((response) => {
					let indexUser = users.findIndex(userTable => userTable.id === user.id);
					usersVerified.push(users[indexUser]);

					if (response.length) {
						response.forEach((permission) => {
							if (!permission.userProfile.blocked && permission.userProfile.active) {
								users[indexUser].activePermission = true;
								users[indexUser].profiles = permission.productProfile.description;
							} else if (!users[indexUser].activePermission) {
								users[indexUser].profiles = permission.productProfile.description;
								users[indexUser].activePermission = false;
							}
						});
					} else {
						// Caso o array response esteja vazio
						users[indexUser].profiles = false;
						users[indexUser].activePermission = false;
					}
				});
			}
			if (pushValue) {
				this.listUsers.push(...usersVerified);
			} else {
				this.listUsers = usersVerified;
			}

			this.isLoadingUser = false;
		},

		dragOverHandler(event) {
			event.preventDefault();
    },
    dropHandler(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
		handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.photoData = e.target.result;
      };
      reader.readAsDataURL(file);
    },
		openModalAlert() {
			this.showAlert = true;
		},
		goToCreateWorkspace() {
			window.location.reload();
		},
		goToWorkspace(workspace) {
			WebStorage.setItem('workspace', JSON.stringify(workspace));
			this.$store.commit('setWorkspace', workspace);

			this.$router.push({
				name: 'project',
			});
		},
		goToWorkspaceList() {
			this.$router.push({
				name: 'workspace',
			});
		},

		verifyNameWorkspace:
			_.debounce(async function (e)  {

				if(this.form.workspaceName != '' && this.form.workspaceName !== this.oldName) {

					const nameUnavailable = await this.restApi().verifyNameAvailableWorkspace(this.form.workspaceName);


					if (nameUnavailable) {
						this.workspaceValidName = false;
					} else {
						this.workspaceValidName = true;
					}
				} else if (this.isEdit && this.form.workspaceName === this.oldName) {
					this.workspaceValidName = true;
				} else if (this.form.workspaceName == '') {
					this.workspaceValidName = '';
				};
			},
		500),
		async saveWorkspace() {
			try {
				let savedWorkspace = '';

				const payload = {
					nome: this.form.workspaceName,
					descricao: this.form.message,
					imagem: this.form.photoData,
					usuarios: this.usersSelected,
				};

				if (this.isEdit && payload.imagem === this.currentImagem) delete payload.imagem;

				if (this.isEdit) {
					savedWorkspace = await this.restApi().editWorkspace(this.workspaceEditId, payload);
				} else {
					savedWorkspace = await this.restApi().createWorkspace(payload);
				}

				this.activeSavedWorkspace = savedWorkspace;


				this.showNotification = true;
			} catch (error) {
				this.$store.commit('activeAlert', {
					message: `Erro ao ${ this.isEdit ? 'editar' : 'criar'} de trabalho, tente novamente.`,
					status: 'error',
				});
				console.error(error);
			}
		},
		async loadUsers() {
			this.isLoadingUser = true;
			const payload = { page: 1 };
			await this.getUsersByTenantId(payload).then((response) => {
				this.listUsers = response.data;

				if (response.length < 25) {
					this.scrollInfinite = false;
				}

				this.verifyUsersActive(this.listUsers);
			});
		},
		async isFirstWorkspaceInOrganization() {
			const isNotFirstWorkspaceInOrganization = await this.restApi().verifyNameAvailableWorkspace('Meu Espaço de Trabalho');

			if (isNotFirstWorkspaceInOrganization) {
				return;
			} else {
				return true;
			}
		},
		async loadPage() {
			this.loadUsers();

			if (await this.isFirstWorkspaceInOrganization() && !this.isEdit) {
				this.form.workspaceName = 'Meu Espaço de Trabalho';
				this.verifyNameWorkspace();
			}

			if (this.isEdit) {
				this.loadCurrentWorkspace();
			} else {
				this.isLoadingWorkspace = false;
			}
		},
	},
	created() {
		this.loadPage();
	},
	watch: {
		photoData(newValue, oldValue) {
			if(newValue !== oldValue) {
				this.form.photoData = newValue;
			}
		},
	},
});
