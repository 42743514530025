
import { CREATE_EQUIVALENCE_OPTIONS_MODAL } from '../constants';
import Vue from 'vue';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import moment from 'moment';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';

import Modal from './Modal.vue';

export default mixins(
	restApi,
	workflowHelpers,
).extend({
	name: 'CreateEquivalenceOptions',
	components: {
		Modal,
		ModalButtonSuccess,
	},
	data() {
		return {
			modalBus: new Vue(),
			CREATE_EQUIVALENCE_OPTIONS_MODAL,
			isLoadingTable: false,
			isLoadingPage: false,
		};
	},
	computed: {
	},
	async mounted () {
	},
	created() {

	},
	methods: {
		createEquivalenceWithMode(mode) {
			this.$router.push({
				name: 'createEquivalence',
				params: { mode },
			});

			this.modalBus.$emit('close');
		},
	},
});
