var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card__display"},_vm._l((_vm.forms),function(form){return _c('div',{key:form.id,staticClass:"card__back"},[_c('el-tooltip',{attrs:{"placement":"bottom","content":"Acessar Detalhamento"}},[_c('router-link',{staticClass:"card__link",attrs:{"to":_vm.path(form.type)}},[_c('div',{staticClass:"card",class:{
						card__flowSuccess: form.type === 'successFlow',
						card__flow: form.type === 'flow',
						card__flowAlert: form.type === 'alertFlow',
						card__flowError: form.type === 'errorFlow',
						card__Others: form.type === 'othersFlow',
					}},[_c('div',{staticClass:"card__box"},[_c('div',{staticClass:"card__IconDiv"}),_c('div',{staticClass:"card__verticalLine"}),_c('div',{staticClass:"card__data"},[_c('div',{staticClass:"card__description"},[_vm._v(_vm._s(form.description))]),(form.type == 'flow')?_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(_vm.infoData.totalFluxos)+" ")]):_vm._e(),(form.type == 'successFlow')?_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(_vm.infoData.totalSucessos)+" ")]):_vm._e(),(form.type == 'alertFlow')?_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(_vm.infoData.totalAlertas)+" ")]):_vm._e(),(form.type == 'errorFlow')?_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(_vm.infoData.totalFalhas)+" ")]):_vm._e(),(form.type == 'othersFlow')?_c('div',{staticClass:"card__value"},[_vm._v(" "+_vm._s(_vm.infoData.totalOutros)+" ")]):_vm._e()])]),_c('div',{staticClass:"card__arrow"})])])],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }