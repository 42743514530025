<template>
	<background-view>
		<div>
			<div class="accessOptions__header">
				<div class="accessOptions__headerNav">
					<div v-bind:class="{
						accessOptions__headerNavAccess: headerNavAccess,
						accessOptions__headerNavAccessSuccess: !headerNavAccess,
					}">
						<div class="accessOptions__headerNavAccessNumber">1</div>
						<div class="accessOptions__headerNavAccessText">
							{{ $locale.baseText(accessOptions__headerNavAccessText1) }}
						</div>
					</div>
					<div v-bind:class="{
						accessOptions__headerNavLine: !headerNavAccess,
						accessOptions__headerNavLineDeactive: headerNavAccess,
					}"></div>
					<div v-bind:class="{
						accessOptions__headerNavAccess: !headerNavAccess,
						accessOptions__headerNavAccessDeactive: headerNavAccess,
					}">
						<div class="accessOptions__headerNavAccessNumber">2</div>
						<div class="accessOptions__headerNavAccessText">
							{{ $locale.baseText(accessOptions__headerNavAccessText2) }}
						</div>
					</div>
				</div>
				<div class="accessOptions__headerCancel">
					<div class="accessOptions__headerCancelIcon"></div>
					<div class="accessOptions__headerCancelText">
						{{ $locale.baseText(accessOptions__headerCancelText) }}
					</div>
				</div>
			</div>

			<div class="accessOptions__headerShadow"></div>

			<div class="accessOptions__body">
				<div v-show="headerNavAccess" @click="advanceButton">
					<div class="accessOptions__bodyTitle">
						{{ $locale.baseText(accessOptions__bodyTitle) }}
					</div>

					<div class="accessOptions__bodySection accessOptions__label">
						<div class="accessOptions__bodySectionTitle">
							{{ $locale.baseText(accessOptions__bodySubtitle1) }}
						</div>
						<div class="accessOptions__bodySectionLine"></div>
						<div class="accessOptions__bodySectionCenter">
							<select class="accessOptions__bodySectionSelect" name="select1" id="select1" @click="changeLabelSelect"
								required>
								<option></option>
								<option>teste</option>
								<option>teste2</option>
							</select>
							<label for="select1" class="accessOptions__bodySectionLabel accessOptions__bodySectionLabelAuxFocus"
								:class="{ accessOptions__bodySectionLabelAux: labelFloatSelect }">{{
										$locale.baseText(accessOptions__bodySelectTitle)
								}}
							</label>
						</div>
					</div>

					<div class="accessOptions__bodySection accessOptions__label">
						<div class="accessOptions__bodySectionTitle">
							{{ $locale.baseText(accessOptions__bodySubtitle2) }}
						</div>
						<div class="accessOptions__bodySectionLine"></div>
						<div class="accessOptions__bodySectionCenter">
							<input type="text" name="input1" id="input1" class="accessOptions__bodySectionInput" required
								v-model="inputValue" />
							<label for="input1" class="accessOptions__bodySectionLabel accessOptions__bodySectionLabelAuxFocus"
								:class="{ accessOptions__bodySectionLabelAux: labelFloatInput }">{{
										$locale.baseText(accessOptions__bodyInputTitle)
								}}
							</label>
						</div>
					</div>

					<div class="accessOptions__bodySection accessOptions__label">
						<div class="accessOptions__bodySectionTitle">
							{{ $locale.baseText(accessOptions__bodySubtitle3) }}
						</div>
						<div class="accessOptions__bodySectionLine"></div>
						<cell final="12" init="1" class="accessOptions__bodySectionCenterCell" @cellSelect="selectMenu"></cell>
					</div>
					<div class="accessOptions__bodySection accessOptions__label">
						<div class="accessOptions__bodySectionTitle">
							{{ $locale.baseText(accessOptions__bodySubtitle4) }}
						</div>
						<div class="accessOptions__bodySectionLine"></div>
						<cell final="14" init="12" class="accessOptions__bodySectionCenterCell" @cellSelect="selectMenu"></cell>
					</div>
					<div class="accessOptions__bodySection accessOptions__label">
						<div class="accessOptions__bodySectionTitle">
							{{ $locale.baseText(accessOptions__bodySubtitle5) }}
						</div>
						<div class="accessOptions__bodySectionLine"></div>
						<cell final="21" init="14" class="accessOptions__bodySectionCenterCell" @cellSelect="selectMenu"></cell>
					</div>
				</div>
				<div v-show="!headerNavAccess">
					<div class="accessOptions__bodyTitle">
						{{ $locale.baseText(accessOptions__bodyTitle) }}
					</div>
					<div class="accessOptions__bodySectionTitle">
						{{ $locale.baseText(accessOptions__bodySubtitle2) }}
					</div>
					<div class="accessOptions__bodySectionLine"></div>
					<Table :tableOverflow="false" class="accessOptions__table">
						<template v-slot:headerColumns>
							<tr class="table__tableTheadTr">
								<th class="table__tableTheadTrTh">
									<div class="table__tableTheadTrThTitle">
										<div class="table__tableTheadTrThTitleText">
											<label>
												<input type="checkbox" v-show="false" v-model="allSelected"
													@click="selectAllOptions()" />
												<div class="accessOptions__headerTableCheckbox"></div>
											</label>

											{{ $locale.baseText(description) }}
										</div>
										<div class="table__tableTheadTrThTitleFilterIcon"></div>
									</div>
								</th>
								<th class="table__tableTheadTrTh">
									<div class="table__tableTheadTrThTitle">
										<div class="table__tableTheadTrThTitleText">
											{{ $locale.baseText(Email) }}
										</div>
										<div class="table__tableTheadTrThTitleFilterIcon"></div>
									</div>
								</th>

								<th class="table__tableTheadTrTh">
									<div class="table__tableTheadTrThTitle">
										<div class="table__tableTheadTrThTitleText">
											{{ $locale.baseText(userProfile) }}
										</div>
										<div class="table__tableTheadTrThTitleFilterIcon"></div>
									</div>
								</th>
							</tr>
						</template>
						<template v-slot:columns>
							<tr v-for="column in page" :key="column.id">
								<td>
									<label class="accessOptions__bodyTableDescription">
										<input @click="select" @change="selectCheckbox()" type="checkbox" v-show="false" v-model="userIds"
											:value="column.id" class="accessOptions__bodyTableDescriptionCheckbox" />
										<div class="accessOptions__bodyTableCheckbox"></div>
										<img :src="column.src" v-if="column.src" class="accessOptions__bodyTableImg" />
										<div v-if="!column.src" class="accessOptions__bodyTableImg"></div>
										<div>{{ column.description }}</div>
									</label>
								</td>
								<td>
									<div>
										<div>{{ column.Email }}</div>
										<div v-if="column.confirmation" class="accessOptions__bodyTableConfirmation">
											{{ column.confirmation }}
										</div>
										<div v-if="!column.confirmation" class="accessOptions__bodyTableWaitingConfirmation">
											{{ column.confirmation }}
										</div>
									</div>
								</td>
								<td>
									<div v-if="column.userProfile" class="accessOptions__bodyTableExistingUserProfile">
										{{ column.userProfile }}
									</div>
									<div v-if="!column.userProfile" class="accessOptions__bodyTableNonExistingUserProfile">
										{{ $locale.baseText(accessOptions__bodyTableNonExistingUserProfile) }}
									</div>
								</td>
							</tr>
						</template>
					</Table>
					<div class="accessOptions__footerTable">
						<div class="accessOptions__footerTableLeftSide">
							<div class="accessOptions__footerTableLeftSideNumber">{{ selected }}</div>
							{{ $locale.baseText(accessOptions__footerTableLeftSideNumber) }}
						</div>
						<div class="accessOptions__footerTableRightSide">
							<div>
								{{ $locale.baseText(footerTableRightSide_1) }}{{ pageSelect + 1 }}
								{{ $locale.baseText(footerTableRightSide_2) }} {{ dataTableDivided.length }}
							</div>
							<button @click="readDataTableDivided(0)" class="accessOptions__footerTableButtonImgFirst"
								:disabled="buttonPrevious"></button>
							<button v-on:click.prevent="readDataTableDivided(pageSelect - 1)"
								class="accessOptions__footerTableButtonImgPreview" :disabled="buttonPrevious"></button>
							<label class="accessOptions__footerTableButton" v-for="(button, index) in dataTableDivided"
								:key="button.length" @click="readDataTableDivided(index)">
								<div class="accessOptions__footerTableButtonAux">{{ index + 1 }}</div>

								<input type="radio" name="pages" v-show="false" />
							</label>
							<button v-on:click.prevent="readDataTableDivided(pageSelect + 1)" :disabled="buttonNext"
								class="accessOptions__footerTableButtonImgNext"></button>
							<button @click="readDataTableDivided(dataTableDivided.length - 1)" :disabled="buttonNext"
								class="accessOptions__footerTableButtonImgLast"></button>
						</div>
					</div>
				</div>
			</div>

			<div class="accessOptions__headerShadowInverted"></div>

			<div class="accessOptions__footer">
				<div v-show="headerNavAccess"></div>
				<button class="accessOptions__footerAdvance"
					v-bind:class="{ accessOptions__footerAdvanceActive: advanceButtonStyle }" @click="headerNavAccessStyle"
					:disabled="!advanceButtonStyle" v-show="headerNavAccess">
					<div class="accessOptions__footerAdvanceIcon"></div>
					<div class="accessOptions__footerAdvanceText">
						{{ $locale.baseText(accessOptions__footerAdvanceText) }}
					</div>
				</button>
				<button class="accessOptions__footerBack" @click="headerNavAccessStyle" v-show="!headerNavAccess">
					<div class="accessOptions__footerBackImg"></div>
					{{ $locale.baseText(accessOptions__footerBackImg) }}
				</button>
				<button class="accessOptions__footerConclude" v-show="!headerNavAccess">
					<div class="accessOptions__footerConcludeImg"></div>
					{{ $locale.baseText(accessOptions__footerConcludeImg) }}
				</button>
			</div>
		</div>
	</background-view>
</template>

<script>
import ActiveDeactivateButton from './ActiveDeactivateButton.vue';
import BackgroundView from './BackgroundView.vue';
import Cell from './AccessCells.vue';
import Table from './Table.vue';
export default {
	components: { BackgroundView, ActiveDeactivateButton, Cell, Table },
	name: 'AccessOptions',
	data() {
		return {
			labelFloatSelect: false,
			labelFloatInput: false,
			inputValue: '',
			headerNavAccess: true,
			advanceButtonStyle: false,
			selectItem: '',
			itens: [],
			rowSelect: false,
			buttonPrevious: false,
			buttonNext: false,
			selected: 0,
			selectedAll: false,

			accessOptions__footerConcludeImg: 'accessOptions.accessOptions__footerConcludeImg',
			accessOptions__footerBackImg: 'accessOptions.accessOptions__footerBackImg',
			accessOptions__footerAdvanceText: 'accessOptions.accessOptions__footerAdvanceText',
			accessOptions__bodySubtitle2: 'accessOptions.accessOptions__bodySubtitle2',
			accessOptions__bodyTitle: 'accessOptions.accessOptions__bodyTitle',
			accessOptions__bodySubtitle5: 'accessOptions.accessOptions__bodySubtitle5',
			accessOptions__bodySubtitle4: 'accessOptions.accessOptions__bodySubtitle4',
			accessOptions__bodySubtitle3: 'accessOptions.accessOptions__bodySubtitle3',
			accessOptions__bodySubtitle: 'accessOptions.accessOptions__bodySubtitle',
			accessOptions__bodySelectTitle: 'accessOptions.accessOptions__bodySelectTitle',
			accessOptions__bodySubtitle1: 'accessOptions.accessOptions__bodySubtitle1',
			accessOptions__headerCancelText: 'accessOptions.accessOptions__headerCancelText',
			accessOptions__headerNavAccessText2: 'accessOptions.accessOptions__headerNavAccessText2',
			accessOptions__headerNavAccessText1: 'accessOptions.accessOptions__headerNavAccessText1',
			accessOptions__bodyInputTitle: 'accessOptions.accessOptions__bodyInputTitle',

			description: 'accessOptions.accessOptions__headerTableDescription',
			Email: 'accessOptions.accessOptions__headerTableEmail',
			userProfile: 'accessOptions.accessOptions__headerTableUserProfile',
			accessOptions__bodyTableNonExistingUserProfile:
				'accessOptions.accessOptions__bodyTableNonExistingUserProfile',
			accessOptions__footerTableLeftSideNumber:
				'accessOptions.accessOptions__footerTableLeftSideNumber',
			footerTableRightSide_1: 'accessOptions.footerTableRightSide_1',
			footerTableRightSide_2: 'accessOptions.footerTableRightSide_2',

			quantityPerPage: 6,
			pageInit: 1,
			pageSelect: 1,

			dataTableDivided: [],
			page: [],

			dataTable: [
				{
					id: '1',
					description: 'description 1',
					Email: 'E-mail 1',
					userProfile: 'Perfil de Usuário 1',
					src: 'https://media-exp1.licdn.com/dms/image/C4E03AQHWp2boi6oNTg/profile-displayphoto-shrink_400_400/0/1649963987517?e=1659571200&v=beta&t=xuWUsieYkvZaSxq9xy29BLtgw96s7giZe1F2pBybJBE',
					confirmation: true,
				},
				{
					id: '2',
					description: 'description 2',
					Email: 'E-mail 2',
					userProfile: '',
					src: 'https://media-exp1.licdn.com/dms/image/C4E03AQH1dGrPpQi9Fw/profile-displayphoto-shrink_400_400/0/1645573428103?e=1659571200&v=beta&t=m0IoPBDpodIslAG645TLrooN5Y--3ojjZCb4o6Yswgo',
					confirmation: false,
				},
				{
					id: '3',
					description: 'description 3',
					Email: 'E-mail 3',
					userProfile: 'Perfil de Usuário 3',
					src: 'https://media-exp1.licdn.com/dms/image/C4E03AQHxIC2NZD2XRQ/profile-displayphoto-shrink_200_200/0/1584539812936?e=1659571200&v=beta&t=9v3cEo172CBIXSDlJv_MICgLrFFSxZP8BOuyknYSz2A',
					confirmation: true,
				},
				{
					id: '4',
					description: 'description 4',
					Email: 'E-mail 4',
					userProfile: 'Perfil de Usuário 4',
					src: '/img/profile.13305cf0.svg',
					confirmation: false,
				},
				{
					id: '5',
					description: 'description 5',
					Email: 'E-mail 5',
					userProfile: 'Perfil de Usuário 5',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '6',
					description: 'description 6',
					Email: 'E-mail 6',
					userProfile: 'Perfil de Usuário 6',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '7',
					description: 'description 7',
					Email: 'E-mail 7',
					userProfile: 'Perfil de Usuário 7',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '8',
					description: 'description 8',
					Email: 'E-mail 8',
					userProfile: 'Perfil de Usuário 8',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '9',
					description: 'description 9',
					Email: 'E-mail 9',
					userProfile: 'Perfil de Usuário 9',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '10',
					description: 'description 10',
					Email: 'E-mail 10',
					userProfile: 'Perfil de Usuário 10',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '11',
					description: 'description 11',
					Email: 'E-mail 11',
					userProfile: 'Perfil de Usuário 11',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '12',
					description: 'description 12',
					Email: 'E-mail 12',
					userProfile: 'Perfil de Usuário 12',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '13',
					description: 'description 13',
					Email: 'E-mail 13',
					userProfile: 'Perfil de Usuário 13',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '14',
					description: 'description 14',
					Email: 'E-mail 14',
					userProfile: 'Perfil de Usuário 14',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '15',
					description: 'description 15',
					Email: 'E-mail 15',
					userProfile: 'Perfil de Usuário 15',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '16',
					description: 'description 16',
					Email: 'E-mail 16',
					userProfile: 'Perfil de Usuário 16',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '17',
					description: 'description 17',
					Email: 'E-mail 17',
					userProfile: 'Perfil de Usuário 17',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '18',
					description: 'description 18',
					Email: 'E-mail 18',
					userProfile: 'Perfil de Usuário 18',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '19',
					description: 'description 19',
					Email: 'E-mail 19',
					userProfile: 'Perfil de Usuário 19',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '20',
					description: 'description 20',
					Email: 'E-mail 20',
					userProfile: 'Perfil de Usuário 20',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '21',
					description: 'description 21',
					Email: 'E-mail 21',
					userProfile: 'Perfil de Usuário 21',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
				{
					id: '22',
					description: 'description 22',
					Email: 'E-mail 22',
					userProfile: 'Perfil de Usuário 22',
					src: '/img/profile.13305cf0.svg',
					confirmation: true,
				},
			],
			userIds: [],
			allSelected: false,
		};
	},
	created() {
		this.dividedArray();
		this.readDataTableDivided(0);
		e.preventDefault();
	},
	watch: {
		inputValue() {
			const res = document.getElementById('input1').value;
			if (res !== '') {
				this.labelFloatInput = true;
				return true;
			} else {
				this.labelFloatInput = false;
				return false;
			}
		},
		pageSelect() {
			if (this.pageSelect === 0) {
				this.buttonPrevious = true;
				this.buttonNext = false;
			} else if (this.pageSelect > this.dataTableDivided.length - 2) {
				this.buttonNext = true;
				this.buttonPrevious = false;
			} else {
				this.buttonPrevious = false;
				this.buttonNext = false;
			}
		},
	},
	methods: {
		selectCheckbox() {

			this.selected = this.userIds.length;
		},
		select() {
			this.allSelected = false;
		},
		selectAllOptions() {
			this.userIds = [];

			for (const user in this.dataTable) {
				if (!this.allSelected) {
					this.userIds.push(this.dataTable[user].id);
					this.selected = this.dataTable.length;
				} else {
					this.selected = 0;
					user.checked = false;
				}
			}

		},
		dividedArray() {
			for (let i = 0; i < this.dataTable.length; i = i + 6) {
				this.dataTableDivided.push(this.dataTable.slice(i, i + 6));
			}
		},
		readDataTableDivided(res) {
			this.page = this.dataTableDivided[res];

			this.pageSelect = res;
		},
		changeLabelSelect() {
			const res = document.querySelector('#select1');
			const valor = res.options[res.selectedIndex].value;
			if (valor !== '') {
				this.labelFloatSelect = true;
			} else {
				this.labelFloatSelect = false;
			}
		},
		selectMenu(res) {
			this.selectItem = res;
		},
		advanceButton() {
			const input2 = document.querySelector('#select1');
			const valor = input2.options[input2.selectedIndex].value;
			this.itens.push(this.selectItem);
			const itensActive = this.itens.reduce((obj, { active, name }) => {
				if (!obj[name]) obj[name] = [];
				obj[name] = active;
				return obj;
			}, {});

			const outraVariante = Object.keys(itensActive).map((name) => {
				return {
					name,
					active: itensActive[name],
				};
			});
			const found = outraVariante.find((element) => element.active === true);

			if (!!this.inputValue && !!valor && !!found) {
				this.advanceButtonStyle = true;
			} else {
				this.advanceButtonStyle = false;
			}
		},

		headerNavAccessStyle() {
			this.headerNavAccess = !this.headerNavAccess;
		},
	},
};
</script>

<style lang="scss">
.backgroundView {
	padding: 0px;
}

.accessOptions__header {
	background-color: #1c2638;
	border-bottom: 1px solid #34476a;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3px 15px 3px 33px;
	border-radius: 6px 6px 0px 0px;

	.accessOptions__headerNav {
		display: flex;
		align-items: center;

		.accessOptions__headerNavAccess {
			cursor: pointer;
			display: flex;
			align-items: center;

			.accessOptions__headerNavAccessNumber {
				width: 20px;
				height: 20px;
				background-color: #00c3dd;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #070707;
			}

			.accessOptions__headerNavAccessText {
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #00c3dd;
				margin: 0px 0px 0px 8px;
			}
		}

		.accessOptions__headerNavAccessSuccess {
			cursor: pointer;
			display: flex;
			align-items: center;

			.accessOptions__headerNavAccessNumber {
				width: 20px;
				height: 20px;
				background-image: url('../assets/grayRoundIconCheck.svg');
				background-color: #00c3dd00;
				color: #07070700;
			}

			.accessOptions__headerNavAccessText {
				color: rgba(255, 255, 255, 0.64);
				margin: 0px 0px 0px 8px;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
			}
		}

		.accessOptions__headerNavAccessDeactive {
			display: flex;
			align-items: center;

			.accessOptions__headerNavAccessNumber {
				width: 20px;
				height: 20px;
				background-color: rgba(255, 255, 255, 0.08);
				color: rgba(255, 255, 255, 0.64);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.accessOptions__headerNavAccessText {
				color: rgba(255, 255, 255, 0.64);
				margin: 0px 0px 0px 8px;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
			}
		}

		.accessOptions__headerNavLine {
			width: 48px;
			height: 1px;
			background-color: #00c3dd;
			margin: 0px 8px;
		}

		.accessOptions__headerNavLineDeactive {
			background-color: #385386;
			width: 48px;
			height: 1px;
			margin: 0px 8px;
		}
	}

	.accessOptions__headerCancel {
		width: 110px;
		height: 32px;
		border-radius: 3px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		padding: 9px 16px;
		align-items: center;

		.accessOptions__headerCancelIcon {
			height: 14px;
			width: 14px;
			background-image: url('../assets/closeIconRed.svg');
			margin-right: 8px;
		}

		.accessOptions__headerCancelText {
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			text-transform: capitalize;
			color: #ff495f;
		}
	}

	.accessOptions__headerCancel:hover {
		background-color: #ff495f;

		.accessOptions__headerCancelText {
			color: #000000;
		}

		.accessOptions__headerCancelIcon {
			background-image: url('../assets/closeIconBlack.svg');
		}
	}
}

.accessOptions__footerTableButtonImgFirst {
	margin: 0px 4px;
	width: 28px;
	height: 28px;
	background-color: rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	border: none;
	background-image: url('../assets/firstOrLastPageIconGray.svg');
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.accessOptions__footerTableButtonImgFirst:hover {
	background-color: rgba(0, 195, 221, 0.16);
	border: 1px solid #00c3dd;
	border-radius: 4px;
	background-image: url('../assets/firstOrLastPageIconGray.svg');
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.accessOptions__footerTableButtonImgPreview {
	margin: 0px 4px;
	width: 28px;
	height: 28px;
	background-color: rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	border: none;
	background-image: url('../assets/cardArrowLightGray.svg');
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	transform: rotate(180deg);
}

.accessOptions__footerTableButtonImgPreview:hover {
	background-color: rgba(0, 195, 221, 0.16);
	border: 1px solid #00c3dd;
	border-radius: 4px;
}

.accessOptions__footerTableButtonImgNext {
	margin: 0px 4px;
	width: 28px;
	height: 28px;
	background-color: rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	border: none;
	background-image: url('../assets/cardArrowLightGray.svg');
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.accessOptions__footerTableButtonImgNext:hover {
	background-color: rgba(0, 195, 221, 0.16);
	border: 1px solid #00c3dd;
	border-radius: 4px;
}

.accessOptions__footerTableButtonImgLast {
	margin: 0px 4px;
	width: 28px;
	height: 28px;
	background-color: rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	border: none;
	background-image: url('../assets/firstOrLastPageIconGray.svg');
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	transform: rotate(180deg);
}

.accessOptions__footerTableButtonImgLast:hover {
	background-color: rgba(0, 195, 221, 0.16);
	border: 1px solid #00c3dd;
	border-radius: 4px;
	background-image: url('../assets/firstOrLastPageIconGray.svg');
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.accessOptions__footerTable {
	display: flex;
	justify-content: space-between;

	.accessOptions__footerTableLeftSide {
		display: flex;

		.accessOptions__footerTableLeftSideNumber {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 28px;
			background-color: #070707;
			border-radius: 3px;
			margin-right: 8px;
		}
	}

	.accessOptions__footerTableRightSide {
		display: flex;

		.accessOptions__footerTableButton {
			background-color: #1c2638;
			border: 1px solid #4a638f;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.32);
			border-radius: 4px;
			width: 28px;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0px 4px;
			cursor: pointer;
		}

		.accessOptions__footerTableButton:hover {
			background-color: rgba(0, 195, 221, 0.16);
			border: 1px solid #00c3dd;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.32);
			border-radius: 4px;
			width: 28px;
			color: #00c3dd;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0px 4px;
			cursor: pointer;
		}

		.accessOptions__footerTableButton input:checked+.accessOptions__footerTableButtonAux {
			background-color: rgba(221, 195, 0, 0.16) !important;
			border: 1px solid #dd3000;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.32);
			border-radius: 4px;
			width: 28px;
			color: #00c3dd;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0px 4px;
			cursor: pointer;
		}
	}
}

.accessOptions__table {
	display: flex;
	justify-content: space-around;
}

.accessOptions__headerTableCheckbox {
	width: 18px;
	height: 18px;
	border: 2px solid #749bde;
	border-radius: 10%;
	margin-right: 16px;
}

.accessOptions__headerTableCheckbox:hover {
	width: 18px;
	height: 18px;
	background-color: #749bde;
	border: 2px solid #749bde;
	border-radius: 10%;
}

.table__tableTheadTrThTitleText {
	display: flex;
	align-items: center;
}

.table__tableTheadTrThTitleText input:checked+.accessOptions__headerTableCheckbox {
	width: 18px;
	height: 18px;
	background-image: url('../assets/blueCheckboxCheck.svg');
	border: none;
	border-radius: 0%;
}

.table__tableTheadTrThTitleText input:checked+.accessOptions__headerTableCheckbox:hover {
	background-color: #fff;
	border-radius: 10%;
}

.accessOptions__bodyTableRow {
	background-color: #1b2d3f !important;
}

.accessOptions__bodySectionLine {
	background-color: #385386;
	height: 1px;
	width: 100%;
	margin: 8px 0px 16px 0px;
}

.accessOptions__body {
	padding: 33px;
	max-height: 394px;
	overflow-y: scroll;

	.accessOptions__bodyTableConfirmation {
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #00c0ff;
	}

	.accessOptions__bodyTableWaitingConfirmation {
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		color: #ffa53b;
	}

	.accessOptions__bodyTableExistingUserProfile {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: rgba(255, 255, 255, 0.88);
	}

	.accessOptions__bodyTableNonExistingUserProfile {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: rgba(255, 255, 255, 0.54);
	}

	.accessOptions__bodyTableDescription {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: rgba(255, 255, 255, 0.88);

		.accessOptions__bodyTableCheckbox {
			width: 18px;
			height: 18px;
			border: 2px solid #749bde;
			border-radius: 10%;
		}

		.accessOptions__bodyTableImg {
			border: 1px solid #4a638f;
			height: 32px;
			width: 32px;
			padding: 0px !important;
			border-radius: 50%;
			margin: 0px 16px;
		}
	}

	.accessOptions__bodyTableDescription:hover {
		.accessOptions__bodyTableCheckbox {
			width: 18px;
			height: 18px;
			border: 2px solid #749bde;
			border-radius: 10%;
			background-color: #749bde;
		}
	}

	.accessOptions__bodyTableDescriptionCheckbox:checked+.accessOptions__bodyTableCheckbox {
		width: 18px;
		height: 18px;
		background-image: url('../assets/blueCheckboxCheck.svg');
		border: none;
		border-radius: 0%;
	}

	.accessOptions__bodyTableDescription:hover .accessOptions__bodyTableDescriptionCheckbox:checked+.accessOptions__bodyTableCheckbox {
		background-color: #fff;
		border-radius: 10%;
	}

	.accessOptions__bodyTitle {
		font-weight: 500;
		font-size: 20px;
		line-height: 23px;
		color: #00c3dd;
	}

	.accessOptions__bodySection {
		padding: 32px 0px 0px 0px;

		.accessOptions__bodySectionTitle {
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: rgba(255, 255, 255, 0.88);
		}

		.accessOptions__bodySectionCenter {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			padding: 0px 1.6%;

			.accessOptions__bodySectionSelect {
				width: 100%;
				height: 40px;
				background-color: #223049;
				border: 1px solid #4a638f;
				border-radius: 4px;
				color: rgba(255, 255, 255, 0.88);
				padding: 3px;
			}

			.accessOptions__bodySectionSelect:focus {
				background-color: #334057;
				border: 1px solid #677ca1;
				border-radius: 4px;
				color: rgba(255, 255, 255, 0.88);
				padding: 3px;
			}

			.accessOptions__bodySectionInput {
				width: 100%;
				height: 40px;
				background-color: #223049;
				border: 1px solid #4a638f;
				border-radius: 4px;
				color: rgba(255, 255, 255, 0.88);
				padding: 7px;
			}

			.accessOptions__bodySectionInput:focus {
				background-color: #334057;
				border: 1px solid #677ca1;
				border-radius: 4px;
				color: rgba(255, 255, 255, 0.88);
				padding: 7px;
			}
		}

		.accessOptions__bodySectionCenterCell {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			flex-direction: row;
			padding: 0px 1.4%;

			.accessOptions__bodyCell {
				width: 48.7%;
				height: 46px;
				background-color: #273a5b;
				border: 1px solid #46587c;
				border-radius: 3px;
				margin: 4px;
				padding: 9px;
				display: flex;
				justify-content: space-between;
			}
		}
	}

	.accessOptions__label {
		position: relative;
	}

	.accessOptions__label select {
		-webkit-transition: all 0.1s linear;
		-moz-transition: all 0.1s linear;
	}

	.accessOptions__bodySectionLabel {
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: rgba(255, 255, 255, 0.56);
	}

	.accessOptions__bodySectionLabel {
		pointer-events: none;
		position: absolute;
		left: 1.5%;
		transition: all 0.1s linear;
		-webkit-transition: all 0.1s linear;
		-moz-transition: all 0.1s linear;
		margin: 0px 8px;
		box-sizing: border-box;
		align-items: center;
	}

	.accessOptions__label select:focus+.accessOptions__bodySectionLabelAuxFocus {
		transform: translateY(-22px);
		background-image: linear-gradient(to top, #334057 40%, #1c263800 55%);
		color: #fff;
	}

	.accessOptions__label select+.accessOptions__bodySectionLabelAux {
		transform: translateY(-20px);
		background-image: linear-gradient(to top, #223049 50%, #1c263800 50%);
	}

	.accessOptions__label input:focus+.accessOptions__bodySectionLabelAuxFocus {
		transform: translateY(-22px);
		background-image: linear-gradient(to top, #334057 40%, #1c263800 55%);
		color: #fff;
	}

	.accessOptions__label input+.accessOptions__bodySectionLabelAux {
		transform: translateY(-20px);
		background-image: linear-gradient(to top, #223049 50%, #1c263800 50%);
	}
}

.accessOptions__footer {
	border-top: 1px solid #34476a;
	border-radius: 0px 0px 3px 3px;
	display: flex;
	justify-content: space-between;
	padding: 9px 32px;

	.accessOptions__footerBack {
		display: flex;
		padding: 12px 16px;
		background-color: #1c2638;
		border: 1px solid #00c3dd;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-transform: capitalize;
		color: #00c3dd;
		transition: all 0.1s;

		.accessOptions__footerBackImg {
			transform: rotate(180deg);
			width: 16px;
			height: 16px;
			background-image: url('../assets/bigBlueArrow.svg');
			margin-right: 8px;
		}
	}

	.accessOptions__footerBack:hover {
		background-color: #00c3dd;
		color: #000;

		.accessOptions__footerBackImg {
			background-image: url('../assets/bigBlackArrow.svg');
		}
	}

	.accessOptions__footerBack:active {
		transform: scale(0.9);
	}

	.accessOptions__footerConclude {
		display: flex;
		padding: 12px 16px;
		background-color: #00c3dd;
		border: 1px solid #00c3dd;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-transform: capitalize;
		color: #000;
		transition: all 0.1s;

		.accessOptions__footerConcludeImg {
			margin-right: 8px;
			width: 18px;
			height: 13.7px;
			background-image: url('../assets/blackCheckIcon.svg');
		}
	}

	.accessOptions__footerConclude:active {
		transform: scale(0.9);
	}

	.accessOptions__footerAdvanceActive {
		background-color: #00c3dd !important;
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 107px;
		height: 40px;
		border-radius: 4px;
		border: none;
		padding: 0px 15px;
		justify-content: space-between;
		transition: all 0.1s;

		.accessOptions__footerAdvanceText {
			background-color: #00000000;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: #070707 !important;
		}

		.accessOptions__footerAdvanceIcon {
			width: 16px;
			height: 16px;
			background-image: url('../assets/bigBlackArrow.svg') !important;
		}
	}

	.accessOptions__footerAdvanceActive:active {
		transform: scale(0.9);
	}

	.accessOptions__footerAdvance {
		display: flex;
		align-items: center;
		width: 107px;
		height: 40px;
		background-color: rgba(255, 255, 255, 0.08);
		border: none;
		border-radius: 4px;
		padding: 0px 15px;
		justify-content: space-between;

		.accessOptions__footerAdvanceIcon {
			width: 16px;
			height: 16px;
			background-image: url('../assets/bigGrayArrow.svg');
		}

		.accessOptions__footerAdvanceText {
			background-color: #00000000;
			border: none;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: rgba(255, 255, 255, 0.4);
		}
	}
}

.accessOptions__headerShadow {
	height: 6px;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0) 100%);
}

.accessOptions__headerShadowInverted {
	height: 6px;
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%);
}
</style>
