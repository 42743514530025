import { appConfig } from '@/config/appConfig';
import OidcService from '@/services/oidc.service';
import { authorizationApi } from "@/api/internalApis";
import TokenService from "@/services/token.service";
import WebStorage from '@/common/WebStorage';


class AuthService {

	constructor() {
		this.basicAuthorization = {
			username: appConfig('auth.basic.username'),
			password: appConfig('auth.basic.password'),
		};
		this.signInUrl = 'connect/token';
		this.signOutUrl = 'connect/revocation';
		this.silentRefreshUrl = 'silent-refresh';
		this.recover = 'api/SendEmail/ForgetPassword';
		this.firstAccess = 'api/SendEmail/FirtAccess';
	}

	async getUserInfo() {
		const userInfo = await authorizationApi.get("/connect/userinfo");

		return userInfo;
	}

	getSignInUrl() {
		return this.signInUrl;
	}

	async login({ username, password }) {
		const payload = new URLSearchParams({
			client_id: appConfig('auth.client_id'),
			client_secret: appConfig('auth.client_secret'),
			scope: appConfig('auth.scope'),
			grant_type: appConfig('auth.grant_type'),
			username,
			password,
		});
		try {
			new Promise(resolve => setTimeout(resolve, 5000));
			const result = await authorizationApi.post(this.signInUrl, payload);

			if (result?.data?.access_token) TokenService.setTokens(result.data);

			return result;
		} catch (error) {
			throw error;
		}

	}

	async recoverPassword(email) {
		return authorizationApi.get(this.recover, {
		  params: { email, client_id: appConfig("auth.client_id") },
		});
	}

	async sendConfirmationEmail(email) {
		return authorizationApi.get(this.firstAccess, {
		  params: { email, client_id: appConfig("auth.client_id") },
		});
	}

	async logout() {
		const id_token_hint = TokenService.getTokens().id_token;
		const params = {
			post_logout_redirect_uri: `${appConfig('host')}` + '/login',
			id_token_hint,
		};

		if (TokenService.getTokens()) {
			window.localStorage.clear();
		}
		if (TokenService.getTokens()) {
			window.sessionStorage.clear();
		}

		OidcService.clearStaleState();
		await OidcService.revokeTokens();
		await OidcService.signoutRedirect(params);
  }

	async silentRefresh() {
		const refreshToken = TokenService.getStoredRefreshToken();
		const payload = new URLSearchParams({
			client_id: 'aliare-integra',
			client_secret: 'Aliare Integra',
			grant_type: 'refresh_token',
			refresh_token: refreshToken
		});
		const result = await authorizationApi.post(this.signInUrl, payload);
		const { access_token } = result.data;

		TokenService.updateStoredAccessToken(access_token);

		return result;
	}
}

export default new AuthService();
