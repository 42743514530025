
import Vue from "vue";
import {
	CONTACT_PROMPT_MODAL_KEY,
	CREDENTIAL_EDIT_MODAL_KEY,
	CREDENTIAL_LIST_MODAL_KEY,
	CREDENTIAL_SELECT_MODAL_KEY,
	TIMEZONE_SELECT_MODAL_KEY,
	EXECUTIONS_LIST_MODAL_KEY,
	RESULT_EXECUTION_MODAL_KEY,
	TIMEOUT_EXECUTION_MODAL_KEY,
	SHARE_MODAL_KEY,
	SELECT_NODE_MODAL_KEY,
	NOTIFICATION_MODAL_KEY,
	VALIDATION_RESOURCE_MODAL_KEY,
	ATTACHED_WORKFLOW_MODAL_KEY,
	CREATE_EQUIVALENCE_OPTIONS_MODAL,
	ATTACHED_EXECUTION_MODAL_KEY,
	HISTORY_VERSION_MODAL_KEY,
	RETRY_EXECUTION_MODAL_KEY,
	DUPLICATE_MODAL_KEY,
	DUPLICATE_WORKFLOW_MODAL_KEY,
	DUPLICATE_PROJECT_MODAL_KEY,
	EXECUTIONS_MODAL_KEY,
	PERSONALIZATION_MODAL_KEY,
	TAGS_MANAGER_MODAL_KEY,
	VALUE_SURVEY_MODAL_KEY,
	WORKFLOW_ACTIVE_MODAL_KEY,
	WORKFLOW_OPEN_MODAL_KEY,
	WORKFLOW_SETTINGS_MODAL_KEY,
	MANAGER_VARIABLE_MODAL_KEY,
	IMPORT_INTEGRATION_MODAL_KEY,
	RENAME_WORKFLOW_MODAL_KEY,
	CREATE_EDIT_PROJECT_MODAL_KEY,
	INACTIVE_USER_MODAL_KEY,
	MODALS_REGISTER_USER_MODAL_KEY,
	DEFINE_FAILURE_FLOW_MODAL_KEY,
	FILTER_PANEL_MODAL_KEY,
} from '@/constants';

import ContactPromptModal from './ContactPromptModal.vue';
import NotificationPage from './NotificationPage.vue';
import ValidationResource from './ValidationResourceModal.vue';
import CredentialEdit from "./CredentialEdit/CredentialEdit.vue";
import CredentialsList from "./CredentialsList.vue";
import CredentialsSelectModal from "./CredentialsSelectModal.vue";
import TimezoneSelectModal from "./TimezoneSelectModal.vue";
import ShareModal from "./ShareModal.vue";
import SelectNodeModal from "./SelectNodeModal.vue";
import RetryExecutionModal from "./RetryExecutionModal.vue";
import ExecutionListModal from "./ExecutionListModal.vue";
import ResultExecutionModal from "./ResultExecutionModal.vue";
import TimeoutExecutionModal from "./TimeoutExecutionModal.vue";
import HistoryVersionModal from "./HistoryVersionModal.vue";
import DuplicateWorkflowDialog from "./DuplicateWorkflowDialog.vue";
import ModalDuplicateWorkflow from "./ModalDuplicateWorkflow.vue";
import DuplicateProject from "./ModalDuplicateProject.vue";
import AttachedWorkflowModal from "./AttachedWorkflowModal.vue";
import CreateEquivalenceOptions from "./CreateEquivalenceOptions.vue";
import AttachedExecutionModal from "./AttachedExecutionModal.vue";
import ManageVariables from "./ModalManageVariable.vue";
import RenameWorkflow from "./ModalRenameWorkflow.vue";
import CreateEditProject from "./ModalCreateEditProject.vue";
import ModalRoot from "./ModalRoot.vue";
import PersonalizationModal from "./PersonalizationModal.vue";
import TagsManager from "./TagsManager/TagsManager.vue";
import ValueSurvey from "./ValueSurvey.vue";
import WorkflowSettings from "./WorkflowSettings.vue";
import WorkflowOpen from "./WorkflowOpen.vue";
import FilterPanelModal from "./FilterPanelModal.vue";
import ExecutionsList from "./ExecutionsList.vue";
import ActivationModal from "./ActivationModal.vue";
import ModalInactiveUser from "./ModalInactiveUser.vue"
import ModalsRegisterUser from "./ModalsRegisterUser";
import DefineFailureFlow from "./DefineFailureFlowModal.vue"
import ImportIntegration from "./ImportIntegrationModal.vue"

export default Vue.extend({
	name: "Modals",
	components: {
		ActivationModal,
		ContactPromptModal,
		CredentialEdit,
		CredentialsList,
		NotificationPage,
		ValidationResource,
		CredentialsSelectModal,
		DuplicateWorkflowDialog,
		ModalDuplicateWorkflow,
		ExecutionsList,
		ModalRoot,
		PersonalizationModal,
		TagsManager,
		ValueSurvey,
		WorkflowSettings,
		WorkflowOpen,
		TimezoneSelectModal,
		RetryExecutionModal,
		ExecutionListModal,
		ResultExecutionModal,
		TimeoutExecutionModal,
		HistoryVersionModal,
		ManageVariables,
		SelectNodeModal,
		RenameWorkflow,
		ShareModal,
		AttachedWorkflowModal,
		CreateEquivalenceOptions,
		CreateEditProject,
		ModalInactiveUser,
		ModalsRegisterUser,
		DefineFailureFlow,
		AttachedExecutionModal,
		DuplicateProject,
		ImportIntegration,
		FilterPanelModal,
	},
	data: () => ({
		CONTACT_PROMPT_MODAL_KEY,
		CREDENTIAL_EDIT_MODAL_KEY,
		CREDENTIAL_LIST_MODAL_KEY,
		CREDENTIAL_SELECT_MODAL_KEY,
		EXECUTIONS_LIST_MODAL_KEY,
		RESULT_EXECUTION_MODAL_KEY,
		TIMEOUT_EXECUTION_MODAL_KEY,
		ATTACHED_WORKFLOW_MODAL_KEY,
		ATTACHED_EXECUTION_MODAL_KEY,
		CREATE_EQUIVALENCE_OPTIONS_MODAL,
		SHARE_MODAL_KEY,
		SELECT_NODE_MODAL_KEY,
		NOTIFICATION_MODAL_KEY,
		VALIDATION_RESOURCE_MODAL_KEY,
		TIMEZONE_SELECT_MODAL_KEY,
		HISTORY_VERSION_MODAL_KEY,
		RETRY_EXECUTION_MODAL_KEY,
		DUPLICATE_MODAL_KEY,
		DUPLICATE_WORKFLOW_MODAL_KEY,
		DUPLICATE_PROJECT_MODAL_KEY,
		PERSONALIZATION_MODAL_KEY,
		TAGS_MANAGER_MODAL_KEY,
		WORKFLOW_OPEN_MODAL_KEY,
		WORKFLOW_SETTINGS_MODAL_KEY,
		VALUE_SURVEY_MODAL_KEY,
		EXECUTIONS_MODAL_KEY,
		WORKFLOW_ACTIVE_MODAL_KEY,
		MANAGER_VARIABLE_MODAL_KEY,
		IMPORT_INTEGRATION_MODAL_KEY,
		RENAME_WORKFLOW_MODAL_KEY,
		CREATE_EDIT_PROJECT_MODAL_KEY,
		INACTIVE_USER_MODAL_KEY,
		MODALS_REGISTER_USER_MODAL_KEY,
		DEFINE_FAILURE_FLOW_MODAL_KEY,
		FILTER_PANEL_MODAL_KEY,
	}),
});
