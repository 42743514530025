<template>
	<div class="searchBar" >
		<div v-if="typeFilter == 'search'">
			<input
				class="searchBar--input"
				type="text"
				:placeholder="$props.placeholder"
				v-model="queryInput"
				@input="searchInfoByName()"
				@focus="inputHasFocus = true"
			>

			<div class="searchBar--container" v-if="!isSearching" :class="{'visible': queryInput.length > 2 && inputHasFocus }">

				<!-- Equivalencias -->
				<div class="searchBar--container__item" v-if="$props.filter == 'equivalence'">
					<div
						v-if="equivalencesListSearch.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in equivalencesListSearch"
							:key="item.id"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id"
								:title="item.nome"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('searchBar.equivalences.notFound') }}
					</div>
				</div>

				<!-- Detalhes Projetos workflows -->
				<div class="searchBar--container__item" v-if="$props.filter == 'workflows'">
					<div
						v-if="workflowsListSearch.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in workflowsListSearch"
							:key="item.id"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id"
								:title="item.nome"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('searchBar.workflows.notFound') }}
					</div>
				</div>

				<!-- Credenciais -->
				<div class="searchBar--container__item" v-if="$props.filter == 'credential'">
					<div
						v-if="credentialsListSearch.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in credentialsListSearch"
							:key="item.id"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id"
								:title="item.nome"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('searchBar.credentials.notFound') }}
					</div>
				</div>

				<!-- Execucoes -->
				<div class="searchBar--container__item" v-if="$props.filter == 'executions'">
					<div
						v-if="executionsListSearch.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in executionsListSearch"
							:key="item.id"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id.toString()"
								:title="item.id.toString()"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('searchBar.executions.notFound') }}
					</div>
				</div>

				<div class="searchBar--container__item" v-if="$props.filter == 'executionsName'">
					<div
						v-if="executionsListSearchName.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in executionsListSearchName"
							:key="item.id"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id.toString()"
								:title="item.workflow.nome"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('searchBar.executions.notFound') }}
					</div>
				</div>

				<div class="searchBar--container__item" v-if="$props.filter == 'profile'">
					<div
						v-if="profileListSearch.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in profileListSearch"
							:key="item.id.toString()"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id.toString()"
								:title="item.productProfile.description"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('profile.notFound') }}
					</div>
				</div>

				<div class="searchBar--container__item" v-if="$props.filter == 'user'">
					<div
						v-if="userListSearch.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in userListSearch"
							:key="item.id.toString()"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id.toString()"
								:title="item.person"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('searchBar.users.notFound') }}
					</div>
				</div>

				<div class="searchBar--container__item" v-if="$props.filter == 'userEmail'">
					<div
						v-if="userEmailListSearch.length"
						class="searchBar--container__itemList"
					>
						<div
							v-for="item in userEmailListSearch"
							:key="item.id.toString()"
							class="searchBar--container__item--link"
						>
							<OptionFilter
								:selected="selectedArray"
								:value="item.id.toString()"
								:title="item.email"
								@click="selectedOption"
							/>
						</div>
					</div>
					<div v-else-if="!isSearching" class="searchBar--container__item--notFound">
						{{ $locale.baseText('searchBar.users.emailNotFound') }}
					</div>
				</div>
			</div>
			<div class="searchBar--container" v-if="isSearching" :class="{'visible': queryInput.length > 2 && inputHasFocus }">
				<div
						class="searchBar--container__itemList"
				>
					Carregando...
				</div>
			</div>
		</div>

		<div class="filter-options__container" v-if="filterOptions && typeFilter == 'multi-fixed'">
			<div
				class="filter-options--item"
				v-for="(item, index) in filterOptions"
				:key="index"
				@click="toggleOption(item)"
			>
				<div class="inputCheckbox" :class="{ active: isSelected(item) }"></div>
				<p class="filter-options--item__text">{{ item }}</p>
			</div>
		</div>

		<div class="filter-options__container" v-if="filterOptions && typeFilter == 'status'">
			<div class="filter-options--item"
				v-for="(option, index) in filterOptions"
				:key="index"
				@click="selectFilter(option.value)"
			>
				<div class="inputCheckbox" :class="{ active: isSelectedStatus(option.value) }"></div>
				<p class="filter-options--item__text">{{ option.label }}</p>
			</div>
		</div>

	</div>
</template>

<script>
import mixins from 'vue-typed-mixins';

import { ref } from 'vue';
import { mapActions } from 'vuex';
import debounce from 'lodash.debounce';

import { restApi } from '@/components/mixins/restApi';
import OptionFilter from '@/components/OptionFilter';
import {	addPictureFromUsers, removeDuplicateUsers} from '@/components/helpers';

export default mixins(
	restApi,
).extend({
	components: {
		OptionFilter,
	},
	name: "SelectFilter",
	props: {
		filter: String,
		placeholder: String,
		clearInput: Boolean,
		typeFilter: {
			type: String,
			default: 'search',
		},
		filterOptions: [],
		defaultSelectedMultiFixed: {
			type: Array,
			default: function () {
				return [];
			}
		},
		defaultStatus: {
			type: String,
			default: '',
		}
	},
	data() {
		return {
			queryInput: '',
			isSearching: true,
			equivalencesListSearch: [],
			credentialsListSearch: [],
			workflowsListSearch: [],
			executionsListSearch: [],
			executionsListSearchName: [],
			profileListSearch: [],
			userListSearch: [],
			userEmailListSearch: [],
			selectedArray: [],
			selectedOptionMultiFixed: [],
			selectedOptionStatus: null,
		};
	},
	mounted() {
		this.loadSelects();
	},
	computed: {
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods:{
		...mapActions('aliareAccount', ['getProfiles', 'getUsersByTenantId']),

		selectedOption(id) {
			this.filterArraySelected(id);
			this.$emit('selectFilter', this.selectedArray);
		},
		filterArraySelected(id) {
			let removed = false;
			this.selectedArray.forEach((el, index) => {
				if (el.id.toString() === id) {
					removed = true;
					this.selectedArray = this.selectedArray.filter(function(ele){
						return ele.id != id;
					});
				}
			});

			if(!removed) {
				if (this.filter == 'equivalence') {
					this.equivalencesListSearch.forEach(el => {
						if (el.id === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'credential') {
					this.credentialsListSearch.forEach(el => {
						if (el.id === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'workflows') {
					this.workflowsListSearch.forEach(el => {
						if (el.id === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'executions') {
					this.executionsListSearch.forEach(el => {

						if (el.id.toString() === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'executionsName') {
					this.executionsListSearchName.forEach(el => {

						if (el.id.toString() === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'profile') {
					this.profileListSearch.forEach(el => {

						if (el.id.toString() === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'user') {
					this.userListSearch.forEach(el => {

						if (el.id.toString() === id) {
							this.selectedArray.push(el);
						}
					});
				} else if (this.filter == 'userEmail') {
					this.userEmailListSearch.forEach(el => {

						if (el.id.toString() === id) {
							this.selectedArray.push(el);
						}
					});
				}
			}
		},
		searchInfoByName: _.debounce(async function (e) {
			try {
				if (this.queryInput.length > 2) {
					this.isSearching = true;
					const payload = {
						nome: this.queryInput,
						size: 5,
					};
					const payloadCredential = {
						searchNome: this.queryInput,
						size: 5,
					};
					const payloadEquivalence = {
						searchNome: this.queryInput,
						size: 5,
					};
					const payloadExecutions = {
						identificador: this.queryInput,
						//id: this.queryInput,
						size: 5,
						...this.$route.query,
					};
					const payloadExecutionsName = {
						searchNome: this.queryInput,
						size: 5,
						...this.$route.query,
					};
					const payloadProfile = {
						productProfileDescription: this.queryInput,
						pageSize: 5,
					};
					const payloadUser = {
						search: this.queryInput,
						pageSize: 5,
					};

					if (this.filter == 'equivalence') {
						try {
							await Promise.all([
								this.restApi().getEquivalences(this.workspace.id, payloadEquivalence),
							]).then((equivalencesList) => {
								this.equivalencesListSearch = equivalencesList[0];
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					} else if (this.filter == 'credential') {
						try {
							await Promise.all([
								this.restApi().getCredentials(this.workspace.id, payloadCredential),
							]).then((credentialsList) => {
								this.credentialsListSearch = credentialsList[0];
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					} else if (this.filter == 'workflows') {
						try {
							await Promise.all([
								this.restApi().getWorkflows(this.workspace.id, payload),
							]).then((workflowsList) => {
								this.workflowsListSearch = workflowsList[0];
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					}
					else if (this.filter == 'executions') {
						try {
							await Promise.all([
								this.restApi().getExecutions(this.workspace.id, payloadExecutions),
							]).then((executionsList) => {
								this.executionsListSearch = executionsList[0];
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					}
					else if (this.filter == 'executionsName') {
						try {
							await Promise.all([
								this.restApi().getExecutions(this.workspace.id, payloadExecutionsName),
							]).then((executionsName) => {
								this.executionsListSearchName = executionsName[0];
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					}
					else if (this.filter == 'profile') {
						try {
							await Promise.all([
								this.getProfiles(payloadProfile),
							]).then(async (profileList) => {
								this.profileListSearch = profileList[0];
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					}
					else if (this.filter == 'user') {
						try {
							await Promise.all([
								this.getUsersByTenantId(payloadUser),
							]).then(async (userList) => {
								this.userListSearch = userList[0].data;
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					}
					else if (this.filter == 'userEmail') {
						try {
							await Promise.all([
								this.getUsersByTenantId(payloadUser),
							]).then((userEmailList) => {
								this.userEmailListSearch = userEmailList[0].data;
							}).catch((err) => {
									console.log(err);
							});
						} catch (e) {
							console.log(e);
						}
					}
				}
			} finally {
				this.isSearching = false;
			}

		}, 1000),
		toggleOption(item) {
      if (this.isSelected(item)) {
        this.selectedOptionMultiFixed = this.selectedOptionMultiFixed.filter(option => option !== item);
      } else {
        this.selectedOptionMultiFixed.push(item);
      }
      this.$emit('selectFilter', this.selectedOptionMultiFixed);
    },
    isSelected(item) {
			return this.selectedOptionMultiFixed.includes(item);
    },

		clearFilter() {
			this.selectedArray = [];
			this.queryInput = '';
			this.equivalencesListSearch = [];
			this.credentialsListSearch = [];
			this.workflowsListSearch = [];
			this.executionsListSearch = [];
			this.executionsListSearchName = [];
			this.profileListSearch = [];
			this.userListSearch = [];
			this.userEmailListSearch = [];
			this.selectedOptionMultiFixed = [];
			this.selectedOptionStatus = [];
		},
		selectFilter(item) {
      this.selectedOptionStatus = item;
      this.$emit('selectFilter', item);
    },
		isSelectedStatus(value) {
      return this.selectedOptionStatus === value || this.selectedOptionStatus?.toString() === value?.toString();
    },
		loadSelects() {
			if(this.typeFilter == 'multi-fixed') {
				this.selectedOptionMultiFixed = this.defaultSelectedMultiFixed?.map(str => {
					if (str === 'Nao_Armazenado') {
						return 'Não Armazenado';
					} else if (str === 'Nao_Iniciado') {
						return 'Não Iniciado';
					} else {
						return str;
					}
				});
			}
			if(this.typeFilter == 'status') {
				this.selectedOptionStatus = this.defaultStatus ?? null;
			}
		},
	},
	watch: {
    clearInput(newValue) {
			if (newValue !== true) this.clearFilter();
    },
  },
});
</script>

<style scoped lang="scss">
.searchBar {
	position: absolute;
	width: 90%;
	right: 4px;
	top: calc(100% - 20px);


	* {
		color: rgba(255, 255, 255, 0.88);
		text-decoration: none;
		line-height: 1;
	}

	&--container {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		top: 39px;
		display: flex;
		flex-direction: column;
		row-gap: 16px;
		background: #223049;
		border: 1px solid #4A638F;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.32);
		border-radius: 4px;
		padding: 16px;
		width: 100%;
		max-height: 369px;
		overflow-y: scroll;


		&.visible {
			visibility: visible;
			opacity: 1;
		}

		&__item {
			cursor: default;

			&--link {
				font-size: 14px;
				text-transform: capitalize;
				min-height: 40px;
				display: flex;
				align-items: center;
				word-break: break-all;
				cursor: pointer;
			}
			&List {
				display: flex;
				flex-direction: column;
			}
			&--notFound {
				min-height: 40px;
				display: flex;
				align-items: center;
				padding-left: 15px;
				padding-top: 16px;
				padding-bottom: 8px;
				font-size: 14px;
				font-style: italic;
				color: rgba(255, 255, 255, 0.56);
			}
			&--title {
				margin: 0;
				font-weight: 400;
				font-size: 16px;
			}
		}
	}
	&--input {
		width: 100%;
		padding: 9px;
		background: #223049;
		border: 1px solid #4A638F;
		border-radius: 4px;
		outline: none;

		&:focus {
			border: 1px solid #00C3DD;
		}

		&::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
			color: rgba(255, 255, 255, 0.56);
			opacity: 1; /* Firefox */
		}
	}
}

.filter-options__container {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	border-radius: 2px;
	border: 1.5px solid #384669;
	background: #1E2638;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.32);
	width: max-content;
	padding: 16px;

	.filter-options--item {
		cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 9px;
    text-transform: capitalize;

		.inputCheckbox {
			width: 18px;
			height: 18px;
			border-radius: 2px;
			border: 1.5px solid #384669;
			flex-shrink: 0;
			&.active {
				border: none;
				background-image: url('../assets/checkIconBlue.svg');
				background-position: center;
				background-size: 18px 18px;
				background-repeat: no-repeat;
			}
		}

		&__text {
			font-size: 14px;
		}
	}
}


</style>
