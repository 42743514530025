
import { HTTP_REQUEST_NODE_TYPE, REQUEST_NODE_FORM_URL, WEBHOOK_NODE_TYPE } from '@/constants';
import Vue from 'vue';
import NoResultsIcon from './NoResultsIcon.vue';

export default Vue.extend({
	name: 'NoResults',
	components: {
		NoResultsIcon,
	},
	data() {
		return {
			REQUEST_NODE_FORM_URL,
		};
	},
	computed: {
		basePath(): string {
			return this.$store.getters.getBaseUrl;
		},
	},
	methods: {
		selectWebhook() {
			this.$emit('nodeTypeSelected', WEBHOOK_NODE_TYPE);
		},

		selectHttpRequest() {
			this.$emit('nodeTypeSelected', HTTP_REQUEST_NODE_TYPE);
		},
	},
});
