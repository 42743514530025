<template>
  <div>
    <b-avatar
      v-if="hasImage"
      :src="image"
      :size="size"
      class="avatar-border"
    />
    <b-avatar
      v-else
      :text="getInitials(text)"
      :size="size"
      :style="{ backgroundColor: randomColor, color: 'white' }"
      class="avatar-border"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    text: String,
    size: String,
  },
	data() {
		return {
			defaultImage: "https://projetoxdevstorage.blob.core.windows.net/aliare-people",
		};
	},
  methods: {
    getInitials(text) {
			if (!text) return;
			const nome = text?.split(' ');
			return (nome[0]?.charAt(0) + (nome[1] ? nome[1]?.charAt(0) : ''))?.toUpperCase();
		},
  },
  computed: {
    hasImage() {
      return this.image && this.image !== this.defaultImage;
    },
    randomColor() {
      const colors = [
        "#3f51b5",
        "#2196f3",
        "#00bcd4",
        "#4caf50",
        "#ff9800",
        "#f44336",
        "#9c27b0",
				"#b80090",
				"#6c1305",
				"#080d0d",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
  },
};
</script>

<style lang="scss">
.avatar-border {
	border: 2px solid #4a638f;

	&:hover {
		cursor: pointer;
		border: 2px solid #00bcd4;
	}
}
</style>
