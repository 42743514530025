
import mixins from 'vue-typed-mixins';
import { appConfig } from "@/config/appConfig";
import OidcService from '@/services/oidc.service';
import { mapActions, mapGetters } from 'vuex';
import { pushConnection } from '@/components/mixins/pushConnection';
import EventBus from '@/common/EventBus';
import { loadLanguage } from '@/plugins/i18n';
import SearchBar from '@/components/SearchBar/SearchBar.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import Modal from '@/components/ModalAliare.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import NotificationPanel from '@/components/NotificationPanel.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import TokenService from '@/services/token.service';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import AliareAccountService from '../../services/aliareAccount.service';
import Alert from '@/components/Alert.vue';
import WebStorage from '@/common/WebStorage';
import RoundPhotoFrame from '@/components/RoundPhotoFrame.vue';
import { TECHNICAL_DOCUMENTATION } from '@/constants';

export default mixins(pushConnection).extend({
	name: 'MainHeader',
	components: {
		SearchBar,
		Modal,
		NotificationPanel,
		ModalButtonSuccess,
		ModalButtonCancel,
		RoundPhotoFrame,
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		isExecutionPage() {
			return ['ExecutionById'].includes(this.$route.name);
		},
		workspace() {
			return this.$store.getters.workspace || JSON.parse(WebStorage.getItem('workspace'));
		},
	},

	data() {
		return {
			notificationPermissionData: false,
			showQuantity: false,
			userName: '',
			userEmail: '',
			profilePicture: '',
			initials: '',
			data: [],
			accountInfo: [],
			dataOrganization: [],
			organizationName: '',
			identificationDocument: '',
			mask: false,
			newNotificationCount: 0,
			tokenData: '',
			notificationIsOpen: false,
			switchIsOpen: false,
		};
	},

	methods: {
		...mapActions('auth', ['revokeTokens', 'recoverPassword', 'logout']),
		...mapActions('aliareAccount', ['getUserAccountId', 'getUserId', 'peopleId', 'putPeopleId']),
		...mapActions('alert', ['postAlert']),
		verifyNotificationIsOpen(notificationOpen) {
			this.notificationIsOpen = notificationOpen;
		},
		openSolutionPanel() {
			const json = {
				Bearer: `Bearer ${TokenService.getTokens().access_token}`,
				tenant: WebStorage.getItem("tenantId"),
			};

			if (!this.switchIsOpen) {
				window.AliareSolutions.build(JSON.stringify(json), 'dataUser');
				this.switchIsOpen = true;
				document.getElementById('close-solution').addEventListener('closeSolutionButton', (event) => {
					const switchIcon = document.getElementsByClassName('switchApp__icon');
					const aliSolutions = document.getElementsByClassName('content-complex');

					this.switchIsOpen = false;

					aliSolutions[0].style.display = 'none';
				});
			}
		},
		openSearch() {
			this.$root.$emit("openSearch");
		},
		toggleNotification() {
			this.$root.$emit("toggleNotification");
		},
		maskCpf(cpf) {
      return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    maskCnpj(cnpj) {
      return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },

		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},

		async fileChange(event) {
			if (
				event.target.files[0].type === 'image/jpeg' ||
				event.target.files[0].type === 'image/jpg' ||
				event.target.files[0].type === 'image/png'
			) {
				const payload = this.data;
				payload.picture = await this.toBase64(event.target.files[0]);
				this.putPeopleId({personId:this.data.id, payload: payload})
					.then(async () => {
						this.profilePicture = await this.toBase64(event.target.files[0]);
					})
					.then(() => {
						console.log('Sucesso ao atualizar foto de perfil.');
						this.$store.commit('activeAlert', {
							message: this.$locale.baseText('header.update.sucessUpdatePicture'),
						});
					});
			} else if (event.target.files[0].size >= 1048576) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('header.update.errorSize'),
					status: 'error',

				});
			} else {
				this.$store.commit('activeAlert', {
						message: this.$locale.baseText('header.update.errorFormat'),
						status: 'error',
				});
			}
		},

		async toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},

		async userProfileInfo() {
			let userId = this.tokenData.sub;

			if(this.tokenData.Level === 'N2' ||	(this.tokenData.Level !== 'N2' && !this.tokenData.hasPermission)) {
				userId = this.accountInfo.userId;
			}

			await this.getUserId(userId).then((responseUserId) => {
				this.userName = responseUserId?.person;
				this.userEmail = responseUserId?.email;
				this.profilePicture = responseUserId?.viewLinkPicture;
				this.peopleId(responseUserId.personId).then((responsePeopleId) => {
					this.data = responsePeopleId;
				});
			});
		},

		async getInfos() {
			this.dataOrganization = JSON.parse(WebStorage.getItem('cardOrganization'));
			this.accountInfo = JSON.parse(WebStorage.getItem('accountInfo'));
			this.tokenData = WebStorage.getItem('tokenData');

			this.organizationName = this.accountInfo.name;
			this.identificationDocument = this.accountInfo?.owner?.identificationDocument;
		},

		changeOrganization() {
			this.$router.push('/mudanca-de-organizacao');
		},

		handleRecover() {
			const user = this.userEmail;

			this.recoverPassword(user)
				.then(() => {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.emailSucces'),
					});
				})
				.catch((error) => {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.recover'),
						status: 'error',
					});
				});
		},

		async logoutAccount() {
			this.logout();
		},

		changeVisibility() {
			const $showup = document.getElementById('showup');
			const $icon = document.getElementById('imageDropdown');

			if ($icon.classList.contains('fa-angle-down')) {
				$icon.classList.add('fa-angle-up');
				$icon.classList.remove('fa-angle-down');
			} else if ($icon.classList.contains('fa-angle-up')) {
				$icon.classList.add('fa-angle-down');
				$icon.classList.remove('fa-angle-up');
			}

			if ($showup.style.display === 'none') {
				$showup.style.display = 'flex';
				this.mask = true;
			} else {
				$showup.style.display = 'none';
				this.mask = false;
			}
		},

		visibleOutside() {
			const $showup = document.getElementById('showup');
			const $icon = document.getElementById('imageDropdown');

			$showup.style.display = 'none';
			this.mask = false;
		},

		valueHasChanged(e) {
			const val = e.target.value;
			loadLanguage(val);
		},
		eventHandler() {
			this.userProfileInfo();
			this.getInfos();
    },
		accessProject() {
			if(Object.keys(this.workspace).length && this.workspace !== null) {
				this.$router.push({
					name: 'project',
				});
			}
		},
		startNotificationCount() {
			this.$root.$on('toggleNotification', () => {
				this.newNotificationCount = 0;
			});
			window.addEventListener('notificationPermissionChanged', (event) => {
				this.notificationPermissionData = true;
			});
			let countInitial = JSON.parse(window.localStorage.getItem('newNotificationData'));
			if (countInitial?.length > 0) {
				this.newNotificationCount = countInitial.length;
			}
			window.addEventListener('localstorage-changed-new', (event) => {
				this.newNotificationCount = event.detail.count;
			});
		},
		redirectTechnical(){
			window.open(TECHNICAL_DOCUMENTATION, '_blank');
		}
	},

	created() {
		this.startNotificationCount();
		this.getInfos();
		this.userProfileInfo();
	},
	mounted() {
		this.$root.$on("reloadHeader", this.eventHandler);
		EventBus.on('logout', this.logoutAccount);
	},
	// @ts-ignore
	beforeUnmount() {
		EventBus.remove('logout');
	},
	beforeDestroy() {
    this.$root.$off("reloadHeader", this.eventHandler);
  },
});
